/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {CustomAutocomplete} from '../../../App/components/Input/CustomAutocomplete';
import {TextFieldWithError} from '../../../App/components/Input/TextField';
import {EmployeePositionActions} from "../../actions/employeePositions";
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {textFieldProps} from "../../../App/helpers/textField";
import {Submit} from "../../../App/components/Button/Submit";
import {saveObject} from "../../../App/helpers/saveObject";
import {Grid, InputLabel} from '@mui/material';
import {Field, Form, Formik} from "formik";
import {useDispatch} from "react-redux";
import {makeStyles} from "@mui/styles";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    container: {
        display: "grid",
        gridTemplateRows: "repeat(6, 70px)",
        alignItems: "start",
    },
    content: {
        height: "100%",
        width: "100%",
        padding: "10px",
        background: "#f5f5f5"
    },
    label: {
        marginTop: "15px",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
    },
    submit: {
        marginTop: "25px",
        width: '100%',
        backgroundColor: "#2595FF",
        textTransform: "none",
        fontWeight: 600,
        borderRadius: '2px',
        '&:hover': {
            backgroundColor: "#2595FF",
        }
    },
    text_field: {
        marginTop: "5px",
        width: "100%",
        fontSize: "14px",
        background: "white",
        '& .MuiInputLabel-root': {
            marginLeft: "5px",
            marginTop: "2px"
        },
        '& .MuiInput-root': {
            paddingLeft: "5px",
            borderRadius: "2px",
            border: "1px solid #DBDEDF",
        },
        '& input:-webkit-autofill': {
            "-webkit-box-shadow": "0 0 0px 1000px white inset"
          }
    },
}))

export const Filter = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const status = [
        {
            id: 0,
            active: true,
            name: "Активный"
        },
        {
            id: 1,
            active: false,
            name: "Не активный"
        }
    ]

    const submit = (values, {setSubmitting}) => {
        const params = saveObject(values)
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "EMPLOYEE_POSITIONS_FILTER", payload: params})
        dispatch(EmployeePositionActions.get(httpBuildQuery(params))).then(
            () => dispatch({type: "LOADING", payload: false}),
            () => dispatch({type: "LOADING", payload: false})
        )
        setSubmitting(false)
    }

    return (
        <Grid className={classes.content}>
            <Formik
                initialValues={{
                    name: '',
                    isActive: null,           
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={submit}
            >
                {({
                    values,
                    handleSubmit,
                    setFieldValue
                }) => (
                    <Form>
                        <Grid className={classes.container}>
                            <Grid>
                                <InputLabel id="employee-position-label" className={classes.label}>
                                    Должность
                                </InputLabel>
                                <Field
                                    align="center"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    name={`name`}
                                    inputProps={textFieldProps}
                                    className={classes.text_field}
                                    component={TextFieldWithError}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="email-label" className={classes.label}>
                                    Статус
                                </InputLabel>
                                <CustomAutocomplete
                                    options={status}
                                    id="isActive"
                                    name={'isActive'}
                                    className={classes.autocomplete}
                                    getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                                    value={values.isActive}
                                    onChange={(e, value) => {
                                        setFieldValue('isActive', value)
                                    }}
                                />
                            </Grid>               
                            <Grid> 
                                <Submit
                                    className={classes.submit}
                                    variant="contained"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Найти
                                </Submit>
                            </Grid>
                        </Grid>                        
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}