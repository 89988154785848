import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from 'react-router-dom';
import {CustomUploadPopover} from "../App/components/Popover/Popover/CustomPopover";
import getActiveStyle from "../App/helpers/activeStyleOrPath";
import {AuthActions} from "../Auth/actions/authentication";
import {PasswordPopover} from "./Dialogs/PasswordPopover";
import {ExitDialog} from "./Dialogs/ExitDialog";
import {UploadManager} from "./UploadManager";
import {makeStyles} from '@mui/styles';
import {
    AccountCircle,
    Sync,
    UploadFileOutlined
} from '@mui/icons-material';
import {
    Typography,
    IconButton,
    Container,
    MenuItem,
    Toolbar,
    Tooltip,
    AppBar,
    Avatar,
    Button,
    Badge,
    Grid,
    Menu,
    Box,
} from '@mui/material';

import clsx from "clsx";

const useStyles = makeStyles(() => ({
    app_bar: {
        borderBottom: "1px solid #e0e0e1",
        zIndex: 1000,
        background: "white",
        boxShadow: "none",
    },
    parent: {
        fontWeight: 600,
        textDecoration: "none",
        color: "black",
        fontSize: "19px",
        lineHeight: "1.6",
        fontFamily: "Open Sans",
        background: "white",
        "&:hover": {
            background: "white",
        }
    },
    title: {
        fontWeight: 600,
        lineHeight: 1,
        textDecoration: "none",
        fontSize: "19px"
    },
    subtitle: {
        fontWeight: 600,
        lineHeight: 1.5
    },
    menu_item: {
        width: "100%",
        height: "4px",
        transition: "0.2s"
    },
    active_menu_item: {
        background: "#2595FF",
        borderRadius: "0px 0px 4px 4px"
    },
    menu_item_button: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        color: "#495055",
        textDecoration: "none",
        padding: "6px 8px",
        marginTop: "16px",
        marginBottom: "16px",
        display: "block",
        textTransform: "none",
        "&:hover": {
            background: "transparent"
        }
    },
    active_menu_item_button: {
        "color": "#2595FF",
    },
    upload_manager: {
        "margin-right": "22px"
    },
    upload_manager_icon: {
        width: "32px",
        height: "32px",
    },
    upload_manager_rotate: {
        width: "32px",
        height: "32px",
        transition: "all 1s ease",
        animation: "rotate-counter-clockwise 1s infinite linear",
    },
}))

export const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const location = useLocation();
    const {isDirty} = useSelector(state => state.pathomorphology);
    const {files} = useSelector(state => state.uploadManager);
    const {account} = useSelector(state => state.account);

    const [anchorElUser, setAnchorElUser] = useState(null);
    const [modalActive, setModalActive] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false)

    const openUploadManager = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logout = () => {
        setLoading(true)
        return dispatch(AuthActions.logout()).then(
            () => {
                localStorage.clear()
                setLoading(false)
                stateClear()
            }
        )
    }

    const stateClear = () => {
        dispatch({type: "CLEAR"})
        dispatch({type: "PATHOMORPHOLOGY_CLEAR"})
        dispatch({type: "RADIODIAGNOSIS_CLEAR"})
        dispatch({type: "GENETIC_CLEAR"})
        dispatch({type: "EMC_CLEAR"})
    }
    const pages = [
        {
            "name": 'База данных',
            "path": "/database/pathomorphology"
        },
        ...(account?.role?.toString().toLowerCase() === "admin" ? [{
            "name": 'Справочник',
            "path": "/dictionary"
        }] : []),
        ...(account?.role?.toString().toLowerCase() === "admin" ? [{
            "name": 'Логирование',
            "path": "/audit"
        }] : []),
    ];

    const goHome = () => {
        if (isDirty) {
            dispatch({
                type: "PATHOMORPHOLOGY_OPEN_EXIT_DIALOG", payload: {
                    value: '/database/pathomorphology',
                    path: '/'
                }
            })
        } else {
            stateClear()
            navigate('/')
        }
    }
    return (
        <AppBar
            position="static"
            color="transparent"
            className={classes.app_bar}
        >
            <Container maxWidth="false">
                <Toolbar disableGutters>
                    <Box sx={{flexGrow: 0}}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                            <Grid item>
                                <Button
                                    onClick={goHome}
                                    className={classes.parent}
                                    sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}
                                >
                                    <Grid item>
                                        <img
                                            width="50"
                                            loading="lazy"
                                            src="/logo192.png"
                                            alt={'Лаборатория Искусственного интеллекта'}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        direction="column"
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                        style={{marginLeft: "10px"}}
                                    >
                                        <Typography variant="h6" className={classes.title}>Лаборатория</Typography>
                                        <Typography variant="button" className={classes.subtitle}>Искусственного интеллекта</Typography>
                                    </Grid>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page) => (
                            <Grid key={page.name}>
                                <Grid
                                    className={clsx(classes.menu_item, getActiveStyle(page.path, location.pathname, classes.active_menu_item))}
                                />
                                <Button
                                    sx={{my: 2, color: 'black', display: 'block'}}
                                    className={clsx(classes.menu_item_button, getActiveStyle(page.path, location.pathname, classes.active_menu_item_button))}
                                    onClick={() => {
                                        if (isDirty) {
                                            dispatch({
                                                type: "PATHOMORPHOLOGY_OPEN_EXIT_DIALOG", payload: {
                                                    value: '/database',
                                                    path: page.path
                                                }
                                            })
                                        } else {
                                            navigate(page.path)
                                        }
                                    }}
                                >
                                    <Typography>
                                        {page.name}
                                    </Typography>
                                </Button>
                            </Grid>
                        ))}
                    </Box>
                    <Box sx={{flexGrow: 0}}>
                        <CustomUploadPopover className={classes.popover}/>
                        <IconButton
                            className={classes.upload_manager}
                            onClick={openUploadManager}
                        >
                            {Boolean(files.filter(file => file.percent < 100).length) &&
                                <Badge badgeContent={files.length} color="error">
                                    <Sync className={classes.upload_manager_rotate}/>
                                </Badge>
                            }
                            {!Boolean(files.filter(file => file.percent < 100).length) &&
                                <Badge badgeContent={files.length} color="error">
                                    <Tooltip title='Локальная загрузка файлов'>
                                        <UploadFileOutlined className={classes.upload_manager_icon}/>
                                    </Tooltip>
                                </Badge>
                            }
                        </IconButton>
                        <Tooltip title={account.name ?? ''}>
                            <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                <Avatar alt="">
                                    <AccountCircle/>
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            keepMounted
                            id="menu-appbar"
                            sx={{mt: '45px'}}
                            anchorEl={anchorElUser}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={() => setModalActive(true)} loading={loading}>
                                <Typography textAlign="center">Смена пароля</Typography>
                            </MenuItem>
                            <MenuItem onClick={logout} loading={loading}>
                                <Typography textAlign="center">Выход</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <UploadManager
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                    />
                    <ExitDialog/>
                    {modalActive && <PasswordPopover active={modalActive} setActive={setModalActive}/>}
                </Toolbar>
            </Container>
        </AppBar>
    );
};
