import React from "react";

function Add(props) {
    const {color} = props
    return (
        <svg {...props} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.6666 7.00001H7.49992V0.833344H6.49992V7.00001H0.333252V8.00001H6.49992V14.1667H7.49992V8.00001H13.6666V7.00001Z"
                fill={color ?? "#2595FF"}/>
        </svg>
    );
}

export default Add;