const MIME_TYPE_JSON = "application/json";

function getQueryParams(params) {
    let queryParams = ''

    if (typeof params === 'string') {
        return params
    }

    for (let name in params) {
        if (queryParams) {
            queryParams = `${queryParams}&`
        }

        queryParams = `${queryParams}${name}=${params[name]}`
    }

    return queryParams
}

function getOptions(values = []) {
    let isJSON = typeof values === 'string' || values.length === 0;

    return {
        data: values,
        ...(isJSON ? {headers: {"Content-Type": MIME_TYPE_JSON}} : {}),
    }
}

export {
    getOptions,
    getQueryParams,
    MIME_TYPE_JSON,
};
