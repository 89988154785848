import {combineReducers} from "redux"
import medicalInstitutions from "../../Dictionary/reducers/medicalInstitutions";
import biomaterialSources from "../../Dictionary/reducers/biomaterialSources";
import scannerResolutions from "../../Dictionary/reducers/scannerResolutions";
import employeePositions from "../../Dictionary/reducers/employeePositions";
import operationTypes from "../../Dictionary/reducers/operationTypes";
import pathomorphology from "../../Database/reducers/pathomorphology";
import radiodiagnosis from "../../Database/reducers/radiodiagnosis";
import uploadManager from "../../Account/reducers/uploadManager";
import authentication from "../../Auth/reducers/authentication";
import icd10Table from "../../Dictionary/reducers/icd10_table";
import employees from "../../Dictionary/reducers/employees";
import scanners from "../../Dictionary/reducers/scanners";
import doctors from "../../Dictionary/reducers/doctors";
import genetic from "../../Database/reducers/genetic";
import account from "../../Account/reducers/account";
import icd10 from "../../Dictionary/reducers/icd10";
import tags from "../../Dictionary/reducers/tags";
import audit from '../../Audit/reducers/audit';
import emc from "../../Database/reducers/emc";
import loading from "./loading";
import alerts from "./alerts";
import users from './users';

const CombineReducers = combineReducers({
    medicalInstitutions,
    biomaterialSources,
    scannerResolutions,
    employeePositions,
    pathomorphology,
    operationTypes,
    radiodiagnosis,
    authentication,
    uploadManager,
    icd10Table,
    employees,
    scanners,
    genetic,
    loading,
    account,
    doctors,
    alerts,
    audit,
    users,
    icd10,
    tags,
    emc,
})

const AppReducers = (state, action) => {
    return CombineReducers(state, action)
}

export default AppReducers

