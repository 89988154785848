import {PathomorphologyService} from "../services/pathomorphology";
import httpBuildQuery from "../../App/helpers/httpBuildQuery";


export const PathomorphologyActions = {
    studies,
    addStudy,
    editStudy,
    getStudy,
    sendSlide,
    addSeries,
    editSlide,
    getSeries,
    editSeries,
    deleteStudy,
    deleteSlide,
    refreshFile,
    deleteSeries,
    finalizeStudy,
    getRemoteFiles,
    sendStudiesTags,
    excelImportParse,
    excelImportUpload,
    clearRemoteFilesProgress
}

function studies(filter) {
    return dispatch => new Promise((resolve, reject) => {
        PathomorphologyService.studies(httpBuildQuery({...{limit: 15}, ...filter}))
            .then(
                response => {
                    dispatch({type: "PATHOMORPHOLOGY_STUDIES", payload: response})
                    resolve(response)
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getRemoteFiles(filter) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true}) 
        PathomorphologyService.getRemoteFiles(httpBuildQuery(filter))
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "PATHOMORPHOLOGY_REMOTE_FILES_TOTAL", payload: response.total})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function clearRemoteFilesProgress() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.clearRemoteFilesProgress()
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getStudy(id, sendToReducer = true) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.getStudy(id)
            .then(
                response => {
                    if (sendToReducer) {
                        dispatch({
                            type: "PATHOMORPHOLOGY_STUDY", payload: {
                                ...response,
                                ...{
                                    series: response.series.map(series => {
                                        series.localId = series.id
                                        series.new = false
                                        return series
                                    })
                                }
                            }
                        })
                    }
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function editStudy(studyInstanceUid, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.editStudy(studyInstanceUid, values)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message: 'Вы успешно сохранили исследование!',
                            type: 'success'
                        }
                    })
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function addStudy(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.addStudy(values)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function deleteStudy(studyInstanceUid) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.deleteStudy(studyInstanceUid)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function deleteSeries(studyInstanceUid, seriesInstanceUid) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.deleteSeries(studyInstanceUid, seriesInstanceUid)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function editSeries(studyInstanceUid, seriesInstanceUid, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.editSeries(studyInstanceUid, seriesInstanceUid, values)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function addSeries(studyInstanceUid, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.addSeries(studyInstanceUid, values)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getSeries(studyInstanceUid, seriesInstanceUid) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.getSeries(studyInstanceUid, seriesInstanceUid)
            .then(
                response => {
                    dispatch({type: "PATHOMORPHOLOGY_SERIES", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function excelImportParse(params, formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        PathomorphologyService.excelImportParse(params, formData)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error.message)
                }
            )
    })
}

function excelImportUpload(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.excelImportUpload(params)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error.message)
                }
            )
    })
}

function sendSlide(params, formData, events) {
    return dispatch => new Promise((resolve, reject) => {
        PathomorphologyService.sendSlide(params, formData, events)
            .then(
                response => {
                    dispatch({type: "UPLOAD_MANAGER_SUCCESS_SEND", payload: {guid: params.guid}})
                    resolve(response)
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function editSlide(studyInstanceUid, seriesInstanceUid, slideInstanceUid, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.editSlide(studyInstanceUid, seriesInstanceUid, slideInstanceUid, params)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function deleteSlide(studyInstanceUid, seriesInstanceUid, slideInstanceUid) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.deleteSlide(studyInstanceUid, seriesInstanceUid, slideInstanceUid)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function finalizeStudy(studyInstanceUid) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.finalizeStudy(studyInstanceUid)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function refreshFile(params, formData, events) {
    return dispatch => new Promise((resolve, reject) => {
        PathomorphologyService.refreshFile(params, formData, events)
            .then(
                response => {
                    dispatch({type: "UPLOAD_MANAGER_SUCCESS_SEND", payload: {guid: params.guid}})
                    resolve(response)
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function sendStudiesTags(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PathomorphologyService.sendStudiesTags(values)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}