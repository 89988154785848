import React from 'react'
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export const CustomSkeleton = (props) => {
    const {count} = props

    const getSkeleton = () => {
        let rows = [],
            cells = []

        for (let i = 0; i < count; i++) {
            cells[i] = <TableCell key={i}>
                <Skeleton variant="text" width={"100%"} height={40}/>
            </TableCell>
        }
        for (let i = 0; i < 9; i++) {
            rows[i] = <TableRow key={i}>
                {cells}
                <TableCell>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Skeleton variant="text" width={40} height={40}/>
                        <Skeleton style={{marginLeft: "5px"}} variant="text" width={40} height={40}/>
                    </Grid>
                </TableCell>
            </TableRow>
        }
        return rows
    }

    return getSkeleton()
}
