import {HttpService} from "../../App/services/http";

export const Icd10Service = {
    get,
    add,
    edit,
    getAll,
    getRoot,
    deleteIcd10,
    getChildren
}


function getRoot(params) {
    return HttpService.getMethod('/api/icd-10/root', `${params}`)
}

function getAll(params){
    return HttpService.getMethod('/api/icd-10', `${params}`)
}

function get(id) {
    return HttpService.getMethod(`/api/icd-10/${id}`)
}

function getChildren(id, params) {
    return HttpService.getMethod(`/api/icd-10/${id}/children`, `${params}`)
}

function add(values) {
    return HttpService.postMethod('/api/icd-10', JSON.stringify(values))
}

function edit(id, values) {
    return HttpService.patchMethod(`/api/icd-10/${id}`, values)
}

function deleteIcd10(id) {
    return HttpService.deleteMethod(`/api/icd-10/${id}`)
}
