import React from 'react'
import Button from "@mui/material/Button";
import {makeStyles} from '@mui/styles';
import Grid from "@mui/material/Grid";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    send_button: {
        marginTop: "25px",
        background: "#33BE63",
        color: "white",
        border: "none",
        boxShadow: "none",
        borderRadius: "2px",
        fontFamily: "Open Sans",
        textTransform: "none",
        fontWeight: 600,
        transition: "0.3s",
        "&:hover": {
            background: "#33BE63",
            color: "white",
            filter: "drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.16))",
        },
        height: "30px",
        marginRight: "10px",
        "&:disabled": {
            background: "rgba(0, 0, 0, 0.12)",
            color: "rgba(0, 0, 0, 0.26)",
        },
    },
    footer: {
        width: "100%",
        borderTop: "1px solid #DBDEDF",
        padding: "0px 30px"
    },
}))

export const SaveButtons = (props) => {
    const classes = useStyles()
    const {values, onSubmitSaveStudy, errors, isDisabledAfterSubmit} = props

    const getDisabledButton = () => {
        let disabled = true
        if (errors && Object.keys(errors).length === 0 && values?.files?.length !== 0 && isDisabledAfterSubmit === false) {
            disabled = false
        }
        return disabled
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.footer}
        >
            <Button
                disabled={getDisabledButton()}
                onClick={onSubmitSaveStudy}
                className={clsx(classes.send_button)}
            >
                Сохранить исследование
            </Button>
        </Grid>
    )
}

