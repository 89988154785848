/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useDebounce} from "use-debounce";
import {TagsActions} from '../../../Dictionary/actions/tags';
import httpBuildQuery from '../../helpers/httpBuildQuery';
import {getTargetValue} from '../../helpers/textField';
import {makeStyles} from '@mui/styles';
import clsx from "clsx";
import {
    Autocomplete,
    InputLabel,
    TextField,
    Chip,
    Grid,
} from '@mui/material';

const useStyles = makeStyles(() => ({
    autocomplete_text_field: {
        width: "100%",
        fontSize: "14px",
        background: "white",
        '& .MuiInputLabel-root': {
            marginLeft: "5px",
            marginTop: "2px"
        },
        '& .MuiInput-root': {
            paddingLeft: "5px",
            borderRadius: "2px",
            border: "1px solid #DBDEDF",
        },
        '& input:-webkit-autofill': {
            "-webkit-box-shadow": "0 0 0px 1000px white inset"
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    autocomplete: {
        width: "100%",
        '& .MuiInputBase-input': {
            background: "white",
        },
        '& .MuiChip-root': {
            borderRadius: '12px'
        },
        '& .MuiAutocomplete-tag': {
            margin: "0px"
        }
    },
    label: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        fontStyle: 'normal',
        marginTop: "15px !important",
    },
}))

export const Tags = (props) => {
    const {className, onChange, value, fixed_tags, freeSolo, limitTags} = props

    const classes = useStyles()
    const dispatch = useDispatch()

    const {tags} = useSelector(state => state.tags);

    const [loadingTags, setLoadingTags] = useState(false)
    const [tagsSearch, setTagsSearch] = useState(null)
    const [tagsSearchRequest] = useDebounce(tagsSearch, 400)

    useEffect(() => {
        if (!loadingTags) {
            let params = httpBuildQuery({
                offset: 0,
                limit: 100,
                isActive: true,
                ...(tagsSearchRequest ? {
                    name: tagsSearchRequest
                } : {})
            })

            dispatch(TagsActions.get(params)).then(_ => {
                setLoadingTags(true)
            })
        }
    }, [dispatch, loadingTags]);


    const tagsInputChange = (e) => {
        setTagsSearch(getTargetValue(e))
    }

    return (
        <Grid>
            <InputLabel id="tag-label" className={classes.label}>
                Теги
            </InputLabel>
            <Autocomplete
                multiple
                id="tags"
                disablePortal
                value={value}
                options={tags.data}
                onChange={onChange}
                disableCloseOnSelect
                loading={!loadingTags}
                loadingText='Загрузка...'
                limitTags={limitTags ?? 7}
                freeSolo={freeSolo ?? true}
                inputChange={tagsInputChange}
                noOptionsText='Ничего не найдено!'
                getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                className={className ? clsx(className, classes.autocomplete) : classes.autocomplete}
                renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                        <Chip
                            size="small"
                            variant="outlined"
                            {...getTagProps({index})}
                            label={option.value ?? option.name}
                            disabled={fixed_tags?.find(tag => tag.name === option.name) ?? false}
                        />
                    ))
                }
                }
                renderInput={(params) => (
                    <TextField
                        variant="standard"
                        className={className ?? classes.autocomplete_text_field}
                        {...{
                            ...params,
                            ...{InputProps: {...params.InputProps, ...{disableUnderline: true}}}
                        }}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                        }}
                    />
                )}
            />
        </Grid>
    )
}
