export default function scanners(state = {
    scanners: {
        data: [],
        total: 0,
        total_pages: 0
    },
    scanner: null,
    filter: {}
}, action) {
    switch (action.type) {
        case "SCANNERS":
            return {
                ...state,
                ...{
                    scanners: {
                        data: action.payload.data,
                        total: action.payload.total,
                        total_pages: action.payload.totalPages
                    }
                }
            }
        case "SCANNER":
            return {
                ...state,
                ...{
                    scanner: action.payload
                }
            }
        case "SCANNERS_FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload
                }
            }
        default:
            return state
    }
}
