import {Microbiome} from "../components/Genetic/UploadStudy/Microbiome";
import {Onco} from "../components/Genetic/UploadStudy/Onco";
import {Wgs} from "../components/Genetic/UploadStudy/Wgs";
import TableCell from "@mui/material/TableCell";
import Skeleton from "@mui/material/Skeleton";
import TableRow from "@mui/material/TableRow";
import * as Yup from "yup";
import React from "react";

const validates = {
    'oncology': Yup.object().shape({
        genotype_data: Yup.object().shape({
            value: Yup.mixed().required('Загрузите Файл генотипа'),
            use: Yup.boolean().oneOf([false], 'Данный файл уже используется')
        })
    }),
    'wgs': Yup.object().shape({
        forward_sequencing: Yup.object().shape({
            value: Yup.mixed().required('Загрузите Файл первичной обработки'),
            use: Yup.boolean().oneOf([false], 'Данный файл уже используется')
        }),
        reverse_sequencing: Yup.object().shape({
            value: Yup.mixed().required('Загрузите Файл обратной обработки'),
            use: Yup.boolean().oneOf([false], 'Данный файл уже используется')
        })
    }),
    'microbiome': Yup.object().shape({
        sample: Yup.object().shape({
            value: Yup.mixed().required('Загрузите Файл генетического образца'),
            use: Yup.boolean().oneOf([false], 'Данный файл уже используется')
        }),
    }),
}

const modulFiles = {
    'oncology': {
        genotype_data: {
            value: null,
            resource_type: 'genotype_data',
            use: false,
            name: "Файл генотипа",
            new: true,
            oldName: ""
        },
    },
    'wgs': {
        forward_sequencing: {
            value: null,
            resource_type: 'forward_sequencing',
            use: false,
            name: "Файл первичной обработки",
            new: true,
            oldName: ""
        },
        reverse_sequencing: {
            value: null,
            resource_type: 'reverse_sequencing',
            use: false,
            name: "Файл обратной обработки",
            new: true,
            oldName: ""
        },
    },
    'microbiome': {
        sample: {
            value: null,
            resource_type: 'sample',
            use: false,
            name: "Файл генетического образца",
            new: true,
            oldName: ""
        },
        positive_control: {
            value: null,
            resource_type: 'positive_control',
            use: false,
            name: "Позитивный контроль",
            new: true,
            oldName: ""
        },
        negative_control: {
            value: null,
            resource_type: 'negative_control',
            use: false,
            name: "Негативный контроль",
            new: true,
            oldName: ""
        },
    },
}

const getModuleFiles = (values, setFieldValue, moduleId, errors) => {
    const modules = {
        'oncology': <Onco
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
        />,
        'wgs': <Wgs
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
        />,
        'microbiome': <Microbiome
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
        />,
    }
    return modules[moduleId]
}

const geneticModules = [
    'oncology',
    'wgs',
    'microbiome',
]

const emcModules = [
    'patients', 'tricks', 'results', 'recipes', 'procedures', 'surveys', 'diagnostics'
]

const getModuleIdByName = (moduleId, geneticId = null, emcId = null) => {
    const moduls = [
        'pathomorphology',
        'radiodiagnosis'
    ]

    if (moduls.indexOf(moduleId) !== -1) {
        return moduls.indexOf(moduleId)
    } else if (emcId && (emcModules.indexOf(emcId) !== -1)) {
        return emcModules.indexOf(emcId) + 3
    }
    if (geneticId && (geneticModules.indexOf(geneticId) !== -1)) {
        return geneticModules.indexOf(geneticId) + 4
    }
}

const getInterpretationType = (moduleId) => {
    const types = [0, 1, 2];

    return types[geneticModules.indexOf(moduleId)]
}


const getSkeleton = (rowCount = 9, cellCount = 13) => {
    let i,
        cells = [],
        rows = []
    for (i = 0; i < cellCount; i++) {
        cells[i] = <TableCell key={i}><Skeleton variant="text" width="100%" height={40}/></TableCell>
    }
    for (i = 0; i < rowCount; i++) {
        rows[i] = <TableRow key={i}>{cells}</TableRow>
    }
    return rows
}

const getExtensionFile = (name) => {
    return name.match(/\.([^.]+)$|$/)?.[1] !== undefined ? name.match(/\.([^.]+)$|$/)[1].toUpperCase() : "FILE"
}

const getSlideFullName = (slide) => {
    return `${slide.slideInstanceUid}.${slide.fileType?.toString().toLowerCase()}`

}

const translateStudyFields = {
    studyInstanceUid: "Номер исследования",
    macroDescription: "Макроописание",
    microDescription: "Микроописание",
    medicalInstitution: "Организация",
    personAccepted: "Принял",
    seriesInstanceUid: "Номер серии",
    histologicalDiagnosis: "Гистологический диагноз",
    icd10: "Код МКБ-10",
    icdO: "Код МКБ-О-3",
    scannerResolution: "Разрешение сканера",
    scanner: "Сканер"
}

export {
    validates,
    emcModules,
    modulFiles,
    getSkeleton,
    getModuleFiles,
    geneticModules,
    getExtensionFile,
    getSlideFullName,
    getModuleIdByName,
    getInterpretationType,
    translateStudyFields
};
