import React from 'react';
import {FileUpload} from "./FileUpload";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles(() => ({
    file_type: {
        width: "290px",
        fontWeight: 500,
        fontSize: "16px"
    },
    error: {
        fontSize: "12px",
        color: '#d32f2f',
    }
}))

export const Wgs = (props) => {
    const classes = useStyles()
    const {values, setFieldValue, errors} = props


    return (
        <Grid>
            <Stack direction="row" alignItems="center" spacing={2}>
                <Typography className={classes.file_type}>Файл первичной обработки*</Typography>
                <FileUpload
                    value="files.forward_sequencing"
                    type="forward_sequencing"
                    values={values}
                    setFieldValue={setFieldValue}
                />
            </Stack>
            {(errors && errors.hasOwnProperty('files') && errors.files.hasOwnProperty('forward_sequencing')) && <React.Fragment>
                    <Typography className={classes.error}>{errors.files.forward_sequencing.value ?? ''}</Typography>
                    <Typography className={classes.error}>{errors.files.forward_sequencing.use ?? ''}</Typography>
                </React.Fragment>
            }
            <Stack direction="row" alignItems="center" spacing={2}>
                <Typography className={classes.file_type}>Файл обратной обработки*</Typography>
                <FileUpload
                    value="files.reverse_sequencing"
                    type="reverse_sequencing"
                    values={values}
                    setFieldValue={setFieldValue}
                />
            </Stack>
            {(errors && errors.hasOwnProperty('files') && errors.files.hasOwnProperty('reverse_sequencing')) && <React.Fragment>
                <Typography className={classes.error}>{errors.files.reverse_sequencing.value ?? ''}</Typography>
                <Typography className={classes.error}>{errors.files.reverse_sequencing.use ?? ''}</Typography>
            </React.Fragment>
            }
        </Grid>
    )
}
