/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {PathomorphologyActions} from '../../../../Database/actions/pathomorphology';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@mui/styles';
import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/system';
import {
    CheckCircleOutlineOutlined,
    CloudUploadOutlined,
    ErrorOutlined,
    InfoOutlined,
    Sync,
} from '@mui/icons-material';
import {
    LinearProgress,
    IconButton,
    Typography,
    Popover,
    Tooltip,
    Button,
    Badge,
    Stack,
    Grid,
} from '@mui/material';

const useStyles = makeStyles(() => ({
    icon: {
        width: "35px",
        height: "35px",
    },
    container: {
        margin: "15px 0",
        minWidth: "350px",
        maxHeight: "560px",
        padding: "0 0 0 15px",
    },
    remoteFiles: {
        overflow: 'auto',
        maxHeight: "350px"
    },
    title: {
        top: "0",
        zIndex: "1",
        fontWeight: 600,
        padding: "15px 0",
        position: "sticky",
        textAlign: "center",
        background: "white",
    },
    file_info: {
        display: "flex",
        padding: "0 0 5px 0",
        justifyContent: "space-between"
    },
    no_upload: {
        textAlign: "center",
    },
    no_upload_text: {
        color: "#9da5a9"
    },
    inProgress_icon: {
        '& .MuiSvgIcon-root': {
            transition: "all 1s ease",
            transform: "rotate(0deg)",
            animation: "rotate-counter-clockwise 1s infinite linear"
        },
    },
    progress: {
        width: "360px",
        padding: "2px",
        marginTop: "0px",
        background: "white",
        borderRadius: "7px",
        border: "1px solid #DBDEDF",
    },
    linear_progress: {
        height: "7px",
        background: "white",
        borderRadius: "7px",
        '& .MuiLinearProgress-bar': {
            backgroundColor: "#2595FF"
        }
    },
}))

export const CustomUploadPopover = () => {
    const classes = useStyles();
    const dispatch = useDispatch()

    const {total_remote_files} = useSelector(state => state.pathomorphology)
    const {loading} = useSelector(state => state.loading)

    const [totalRemoteFiles, setTotalRemoteFiles] = useState(total_remote_files)
    const [remoteFiles, setRemoteFiles] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [fetching, setFetching] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null)
    
    useEffect(() => {
        if (fetching) {
            dispatch(PathomorphologyActions.getRemoteFiles({offset: currentPage, limit: 10}))  
            .then(response => {
                setRemoteFiles([...remoteFiles, ...response.data])
                setCurrentPage(prevState => prevState + 10)
                setTotalRemoteFiles(response.total)
            })
            .finally(() => setFetching(false))
        }
    }, [fetching])
 
    const fetchRemoteFiles = () => {
        setCurrentPage(0)
        setRemoteFiles([])
        dispatch(PathomorphologyActions.getRemoteFiles({offset: 0, limit: 10}))  
        .then(response => {
            setRemoteFiles(response.data)
            setTotalRemoteFiles(response.total ?? 0)
            setCurrentPage(prevState => prevState + 10)
        })
    }

    const handleClickMore = () => {
        if (remoteFiles.length < totalRemoteFiles) {
            setFetching(true)
        }
    }

    const handleClickClearProgress = () => {
        dispatch(PathomorphologyActions.clearRemoteFilesProgress()).then(
            setTimeout(() => {fetchRemoteFiles()}, 700)
        )
    }

    useEffect(() => {
        setCurrentPage(0)
        fetchRemoteFiles()
    }, [total_remote_files])

    const handleClick = (event) => {
        setCurrentPage(0)
        setAnchorEl(event.currentTarget);
        fetchRemoteFiles()
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const setFileError = (error) => {
        return error.toLowerCase().includes('not found') ? 'Файл не найден' : 'Ошибка загрузки файла'
    }
  
    const open = Boolean(anchorEl);
    const popover_id = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconButton
                variant="contained"
                onClick={handleClick}
                aria-describedby={popover_id}
            >
                <Badge badgeContent={total_remote_files} color="error">
                    <Tooltip title='Удаленная загрузка файлов'>
                        {Boolean(remoteFiles.filter(file => file.status === "Created" || file.status === "InProgress").length)
                            ? <div className={classes.inProgress_icon}>
                                <Sync style={{width: '32px', height: '32px'}}/>
                            </div>
                            : <CloudUploadOutlined className={classes.icon}/>
                        }
                    </Tooltip>
                </Badge>
            </IconButton>
            <Popover
                open={open}
                id={popover_id}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Grid className={classes.container}>
                    <Typography className={classes.title}>Удаленная загрузка файлов</Typography>
                    {remoteFiles?.length > 0 
                        ? <Grid className={classes.remoteFiles}>
                            {remoteFiles.map (file => {
                                return (
                                    <Grid
                                        key={file.id}
                                        className={classes.file_info}
                                    >
                                        <Grid>
                                            <Typography color={file.status === 'Failed' ? 'error' : null}>
                                                {file.slideInstanceUid}
                                            </Typography>
                                            {file.status !== 'Failed' && 
                                                <Box
                                                    sx={{flexGrow: 1}}
                                                    className={classes.progress}
                                                >
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={file.progress}
                                                        className={classes.linear_progress}
                                                    />
                                                </Box>
                                            }
                                        </Grid>
                                        <Grid>
                                            {file.status &&
                                                <>
                                                    {file.status === 'Created' &&
                                                        <Tooltip title='Подготовка загрузки файла'>
                                                            <IconButton>
                                                                <InfoOutlined color="primary"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {file.status === 'InProgress' &&
                                                        <Tooltip title={`Загрузка файла ${file.progress}%`}>
                                                            <IconButton className={classes.inProgress_icon}>
                                                                <Sync color="primary"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {file.status === 'Complete' &&
                                                        <Tooltip title='Успешная загрузка файла'>
                                                            <IconButton>
                                                                <CheckCircleOutlineOutlined color="success"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {file.status === 'Failed' &&
                                                        <Tooltip title={setFileError(file.error)}>
                                                            <IconButton>
                                                                <ErrorOutlined color="error"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        : <Grid className={classes.no_upload}>
                            <Typography className={classes.no_upload_text}>
                                В данный момент нет загрузок
                            </Typography>
                        </Grid>
                    }
                    <Stack 
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        sx={{ marginTop: '1rem' }}
                    >
                        <Button
                            variant="outlined"
                            onClick={handleClickMore}
                            disabled={Boolean(!(remoteFiles.length < totalRemoteFiles))}
                        >
                            Показать еще
                        </Button>
                        {!loading ?
                        <Button
                            variant="outlined"
                            onClick={handleClickClearProgress}
                            disabled={Boolean(!(remoteFiles.find(item => item.status === 'Complete' || item.status === 'Failed')))}
                        >
                            Очистить
                        </Button> :
                        <LoadingButton
                            loading
                            variant="outlined"
                        >
                            Очистить
                        </LoadingButton>}
                    </Stack>
                </Grid>
            </Popover>
        </>
    )
}
