import {HttpService} from "../../App/services/http";

export const ScannerResolutionsService = {
    get,
    add,
    edit,
    getScannerResolution,
    deleteScannerResolution
}

function get(params) {
    return HttpService.getMethod('/api/scanner-resolutions', `limit=15&${params}`)
}

function getScannerResolution(id) {
    return HttpService.getMethod(`/api/scanner-resolutions/${id}`)
}

function add(values) {
    return HttpService.postMethod('/api/scanner-resolutions', JSON.stringify(values))
}
function edit(id, values) {
    return HttpService.patchMethod(`/api/scanner-resolutions/${id}`, values)
}

function deleteScannerResolution(id) {
    return HttpService.deleteMethod(`/api/scanner-resolutions/${id}`)
}
