import React from 'react'
import {useSelector} from "react-redux";
import {makeStyles} from '@mui/styles';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
    container: {
        height: "100%",
        width: "100%",
        zIndex: 100,
        position: "fixed",
    },
    hide: {
        height: "100%",
        width: "100%",
        zIndex: 100,
        position: "fixed",
        opacity: "0.3",
        background: "#f1f1f1",
    },
    circular: {
        zIndex: 1000,
        position: "absolute"
    }
}))

function FacebookCircularProgress(props) {
    return (
        <Box sx={{position: 'relative'}}>
            <CircularProgress
                variant="determinate"
                sx={{
                    color: (theme) =>
                        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                }}
                size={40}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    zIndex: 1000,
                    color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                    animationDuration: '750ms',
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                }}
                size={40}
                thickness={4}
                {...props}
            />
        </Box>
    );
}

export const Loading = () => {
    const classes = useStyles()
    const {loading} = useSelector(state => state.loading)


    return (
        loading ? <Grid item className={classes.container}>
            <Grid className={classes.hide}/>
            <Grid
                className={classes.container}
                container
                alignItems="center"
                direction="column"
                justifyContent="center"
            >
                <Grid item>
                    <FacebookCircularProgress className={classes.circular}/>
                </Grid>
            </Grid>
        </Grid> : null
    )
}
