import React from 'react'
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import {DeleteSweepOutlined, ModeEditOutlineOutlined} from "@mui/icons-material";
import {MedicalInstitutionsActions} from "../../actions/medicalInstitution";
import {BiomaterialSourcesActions} from "../../actions/biomaterialSources";
import {ScannerResolutionsActions} from "../../actions/scannerResolutions";
import {EmployeePositionActions} from "../../actions/employeePositions";
import {Icd10TableActions} from "../../actions/icd10_table";
import {EmployeesActions} from "../../actions/employees";
import {ScannersActions} from "../../actions/scanners";
import {getUserTypeId} from '../../helpers/user';
import {TagsActions} from '../../actions/tags';
import Tooltip from "@mui/material/Tooltip";
import {IconButton} from "@mui/material";
import {useDispatch} from "react-redux";

export const Actions = (props) => {
    const dispatch = useDispatch()
    const {moduleId, row, setUseRow, setDeleteDialog, setValue, setLoading} = props

    const getActions = (id, params) => {
        let getAction,
            editAction,
            restoreAction

        switch (moduleId) {
            case 'scanners':
                getAction = async () => {
                    return await dispatch(ScannersActions.getScanner(id))
                }
                editAction = async () => {
                    return await dispatch(ScannersActions.edit(id, params))
                }
                break
            case 'scanner_resolutions':
                getAction = async () => {
                    return await dispatch(ScannerResolutionsActions.getScannerResolution(id))
                }
                editAction = async () => {
                    return await dispatch(ScannerResolutionsActions.edit(id, params))
                }
                break
            case 'medicalInstitutions':
                getAction = async () => {
                    return await dispatch(MedicalInstitutionsActions.getMedicalInstitution(id))
                }
                editAction = async () => {
                    return await dispatch(MedicalInstitutionsActions.edit(id, params))
                }
                restoreAction = async () => {
                    return await dispatch(MedicalInstitutionsActions.restoreMedicalInstitution(id))
                }
                break
            case 'employee_positions':
                getAction = async () => {
                    return await dispatch(EmployeePositionActions.getPosition(id))
                }
                editAction = async () => {
                    return await dispatch(EmployeePositionActions.edit(id, params))
                }
                break
            case 'employees':
                getAction = async () => {
                    return await dispatch(EmployeesActions.getEmployee(id))
                }
                editAction = async () => {
                    const userType = getUserTypeId(params)
                    return await dispatch(EmployeesActions.edit(id, {...params, userType}))
                }
                break
            case 'icd10':
                getAction = async () => {
                    return await dispatch(Icd10TableActions.get(id))
                }
                editAction = async () => {
                    return await dispatch(Icd10TableActions.edit(id, params))
                }
                break
            case 'tags':
                getAction = async () => {
                    return await dispatch(TagsActions.getTag(id))
                }
                editAction = async () => {
                    return await dispatch(TagsActions.edit(id, params))
                }
                break
            default:
                getAction = async () => {
                    return await dispatch(BiomaterialSourcesActions.getBiomaterialSource(id))
                }
                editAction = async () => {
                    return await dispatch(BiomaterialSourcesActions.edit(id, params))
                }
                break
        }
        return {getAction, editAction, restoreAction}
    }

    const handleClickEdit = (id) => {
        const {getAction} = getActions(id)

        getAction().then(() => {
            setValue('3')
        })
    };


    const handleClickRestoreMO = (row) => {
        row.isActive = true
        const {restoreAction} = getActions(row.id)
        restoreAction().then(() => {
            setLoading(false)
        })
    }

    const handleClickRestore = (row) => {
        row.isActive = true
        if(row.medicalInstitution){
            row.medicalInstitutionId = row.medicalInstitution.id
            delete(row.organization)
        }
        if(row.user){
            row.userId = row.user.id
            delete(row.medicalInstitution)
        }
        if(row.employeePosition){
            row.employeePositionId = row.employeePosition.id
            delete(row.employeePosition)
        }
        if(row.parent){
            row.parentId = row.parent.id
            delete(row.parent)
        }
        if(row.scanner){
            row.scannerId = row.scanner.id
            delete(row.scanner)
        }
        const {editAction} = getActions(row.id, row)
            editAction().then(() => {
                setLoading(false)
            })
    }

    const handleClickActive = (row, moduleId) => {
        moduleId === 'medicalInstitutions' ? handleClickRestoreMO(row) : handleClickRestore(row)
    }

    const handleClickOpenDelete = (id) => {
        setUseRow({
            id: id,
        })
        setDeleteDialog(true)
        setLoading(true)
    };

    return row.isActive ? (
        <React.Fragment>
            <Tooltip placement="left-start" arrow title="Редактировать">
                <IconButton
                    color="primary"
                    onClick={() => handleClickEdit(row.id)}
                >
                    <ModeEditOutlineOutlined/>
                </IconButton>
            </Tooltip>
            <Tooltip placement="left-start" arrow title="Удалить">
                <IconButton
                    color="error"
                    onClick={() => handleClickOpenDelete(row.id)}
                >
                    <DeleteSweepOutlined/>
                </IconButton>
            </Tooltip>
        </React.Fragment>
    ) : (
        <Tooltip placement="left-start" arrow title="Восстановить">
            <IconButton
                color="success"
                onClick={() => handleClickActive(row, moduleId)}
            >
                <RestoreFromTrashOutlinedIcon/>
            </IconButton>
        </Tooltip>
    )
}

