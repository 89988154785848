const initialState = {
    account: {
        id: null,
        name: null,
        role: null,
        email: null,
        employee: {},
    }
}

export default function account(state = initialState, action) {
    switch (action.type) {
        case "ACCOUNT":
            return {
                ...state,
                ...{
                    account: action.payload,
                }
            }
        case "CLEAR":
            return initialState
        default:
            return state
    }
}
