/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { ArrowCircleDown, ArrowCircleUp, DeleteSweepOutlined } from "@mui/icons-material";
import { MedicalInstitutionsActions } from '../../../Dictionary/actions/medicalInstitution';
import { PathomorphologyActions } from '../../../Database/actions/pathomorphology';
import { EmployeesActions } from '../../../Dictionary/actions/employees';
import { CustomAutocomplete } from '../Input/CustomAutocomplete';
import { CustomDatePicker } from '../Input/CustomDatePicker';
import httpBuildQuery from '../../helpers/httpBuildQuery';
import VirtualScrollList from '../VirtualScrollAlert';
import { getShortName } from '../../helpers/row';
import { UploadMenu } from './UploadMenu';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from "formik";
import { ru } from "date-fns/locale";
import { format } from "date-fns";
import * as Yup from "yup";
import {
    TableContainer,
    Typography,
    IconButton,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Tooltip,
    Dialog,
    Button,
    Stack,
    Paper,
    Table,
    Grid,
} from '@mui/material';

const useStyles = makeStyles(() => ({
    submit_upload_button: {
        color: "white",
        background: "#33BE63",
        border: "none",
        boxShadow: "none",
        borderRadius: "2px",
        fontFamily: "Open Sans",
        textTransform: "none",
        fontWeight: 600,
        transition: "0.3s",
        height: "40px",
        width: "auto",
        marginRight: "10px",
        "&:hover": {
            background: "#33BE63",
            color: "white",
            filter: "drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.16))",
        }
    },
    table_head_cell: {
        fontSize: "13px",
        fontWeight: 600,
        lineHeight: "18px",
    },
}))

export function UploadForm(props) {

    const { open, onClose } = props;

    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isParse, setIsParse] = useState(false)
    const [excelFile, setExcelFile] = useState(null)
    const [parsedData, setParsedData] = useState(null)
    const [showErrors, setShowErrors] = useState(false)
    const [inputKey, setInputKey] = useState(Date.now())
    const [uploadErrors, setUploadErrors] = useState(null)
    const [isOkBtnActive, setIsOkBtnActive] = useState(false)

    const {loading: globalLoading} = useSelector(state => state.loading)
    const {employees} = useSelector(state => state.employees)
    const {medicalInstitutions} = useSelector(state => state.medicalInstitutions)

    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState({search: '', type: null})
    const [searchRequest] = useDebounce(search, 400)

    function handleFileChange(event) {
        const newFile = event.target.files[0];
        if (newFile && newFile !== excelFile) {
            setExcelFile(newFile);
        }
        setInputKey(Date.now());
    }
  
    const inputChange = (e, type = null) => {
        setSearch((e && (typeof e.target.value === 'string')) ? {search: e.target.value, type: type} : {search: '', type: null})
    }

    const handleClearImportState = () => {
        setUploadErrors(null)
        setExcelFile(null)
    }

    const onDeleteStatement = () => {
        handleClearImportState()
        dispatch({
            type: "PATHOMORPHOLOGY_CLEAR_IMPORT",
            payload: null
        })
        setParsedData(null)
        setExcelFile(null)
        setIsOkBtnActive(false)
        setIsParse(false)
    }

    const onUploadStatement = useCallback(() => {
        dispatch(PathomorphologyActions.excelImportUpload(parsedData))
    
          .then((response) => {
                const err = response.errors.concat(response.warnings);
                return dispatch(PathomorphologyActions.getRemoteFiles())
                .then(
                    () => { return err }
                );
          })
          .then((err) => {
            if (err.length > 0) {
                dispatch({
                    type: "ALERT_ERROR", payload: {
                        message: <VirtualScrollList items={err}/>,
                        type: 'error',
                        hasVirtualScroll: true
                    }
                })
            } else {
                dispatch({
                    type: "ALERT_SUCCESS", payload: {
                        message: 'Ведомость отправлена',
                        type: 'success',
                    }
                })
            }
          })
          .then(() => {
            navigate('/refresh?to=/database/pathomorphology/needs-verification');
          })
          .catch(error => {
            setUploadErrors(JSON.parse(error).data.errors);
          });
    }, [parsedData, dispatch]);

    const importFromExcel = (values) => {
        let params = {
            receivedDate: format(values.receivedDate, "yyyy-MM-dd", {locale: ru}),
            personAcceptedId: values.personAccepted.id,
            medicalInstitutionId: values.medicalInstitution.id
        }
        const formData = new FormData()
        formData.append(`excelFile`, excelFile)

        if (!isParse) {

            dispatch(PathomorphologyActions.excelImportParse(httpBuildQuery(params), formData)).then(
                response => {
                    if (response.errors.length) {
                        setUploadErrors(response.errors.concat(response?.warnings))
                    } else {
                        setParsedData(response)
                        setIsOkBtnActive(true)
                        setIsParse(true)
                    }
                },
                error => {
                    setUploadErrors(JSON.parse(error).data.errors)
                }
            )
        }
    }

    const errorRender = (message, idx) => {
        let line = null
        if (message.indexOf('Ошибка: ') !== -1) {
            const messageArray = message.split('Ошибка: ')
            line = <TableCell align="left">{parseInt(messageArray[0].match(/\d+/))}</TableCell>
        }
        return (
            <TableRow key={idx}>
                <TableCell component="th" scope="row">{idx + 1}</TableCell>
                    {line}
                <TableCell align="left">{message}</TableCell>
            </TableRow>
        )
    }

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (!loading) {
            let params = httpBuildQuery({
                offset: 0,
                isActive: true,
                ...(searchRequest.search ? {
                    name: searchRequest.search
                } : {})
            })
            switch (searchRequest.type) {
                case 'medicalInstitution':
                    dispatch(MedicalInstitutionsActions.get(params))
                    break
                case 'personAccepted':
                    dispatch(EmployeesActions.get(params))
                    break
                default:
            }

            setLoading(true)
        }
    }, [dispatch, searchRequest, loading]);

    useEffect(() => {
        setLoading(false)
    }, [searchRequest]);   

    return (
        <Formik
            initialValues={{
                personAccepted: null,
                receivedDate: new Date(),
                medicalInstitution: null,
            }}
            validationSchema={Yup.object().shape({
                personAccepted: Yup.mixed().required(`Выберите сотрудника`),
                medicalInstitution: Yup.mixed().required(`Выберите медицинскую организацию`),
                receivedDate: Yup.string().required(`Выберите дату поступления исследования`),
            })}
            onSubmit={importFromExcel}
        >
            {({
                errors,
                values,
                isValid,
                validateForm,
                handleSubmit,
                setFieldValue,
            }) => {
                useEffect(() => {
                    validateForm(values)
                }, [])

                const isActiveOKButton = () => {
                    const errors =uploadErrors?.filter((error) => error.indexOf('Ошибка: ') !== -1).length
                    return Boolean(!excelFile?.name || !isOkBtnActive || errors)
                }

                return (
                    <Dialog
                        fullWidth
                        open={open}
                        maxWidth='md'
                        sx={{ '& .MuiDialog-paper': {position: 'fixed', top: '100px' } }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <CloseIcon
                            sx={{
                                right: '0',
                                top: '16px',
                                width: '50px',
                                cursor: 'pointer',
                                position: 'absolute',
                            }}
                            onClick={handleClose}
                        />
                        <Form>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Grid item xs={12} sx={{p: 2}}>
                                    <UploadMenu onFileChange={handleFileChange} key={inputKey}/>
                                </Grid>
                                <Grid item xs={6} sx={{px: 2}}>
                                    <CustomDatePicker
                                        required
                                        disableFuture
                                        label="Поступило"
                                        inputFormat="dd/MM/yyyy"
                                        value={values.receivedDate}
                                        views={["day", "month", "year"]}
                                        onChange={(newValue) => setFieldValue('receivedDate', newValue)}
                                    />
                                </Grid>
                                <Grid
                                    xs={6}
                                    container
                                    sx={{px: 2, minHeight: 180}}
                                    rowSpacing={2}
                                    direction="column"
                                >
                                    <Grid item>
                                        <CustomAutocomplete
                                            required
                                            label="Принял"
                                            loading={!loading}
                                            id="personAccepted"
                                            disablePortal={false}
                                            options={employees.data}
                                            value={values.personAccepted}
                                            helperText={errors.personAccepted}
                                            error={Boolean(errors.personAccepted)}
                                            getOptionLabel={option => getShortName(option)}
                                            onFocus={e => inputChange(e, 'personAccepted')}
                                            inputChange={e => inputChange(e, 'personAccepted')}
                                            onChange={(e, value) => setFieldValue('personAccepted', value)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CustomAutocomplete
                                            required
                                            loading={!loading}
                                            label="Организация"
                                            disablePortal={false}
                                            id="medicalInstitution"
                                            value={values.medicalInstitution}
                                            options={medicalInstitutions.data}
                                            helperText={errors.medicalInstitution}
                                            getOptionLabel={option => option.name}
                                            error={Boolean(errors.medicalInstitution)}
                                            onFocus={e => inputChange(e, 'medicalInstitution')}
                                            inputChange={e => inputChange(e, 'medicalInstitution')}
                                            onChange={(e, value) => setFieldValue("medicalInstitution", value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Typography sx={{px: 2, minHeight: 30}}>
                                {excelFile ? `Загруженная ведомость: ${excelFile.name}` : null}
                            </Typography>
                            <Stack
                                sx={{p: 2}}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                className={classes.action_grid}
                            >
                                {<Button
                                    type="submit"
                                    disabled={!excelFile || !isValid}
                                    variant="contained"
                                    color="success"
                                    className={classes.submit_upload_button}
                                    onClick={handleSubmit}
                                >
                                    Загрузить ведомость
                                </Button>}
                                <Tooltip title={"Удалить ведомость"}>
                                    <IconButton onClick={onDeleteStatement}>
                                        <DeleteSweepOutlined color={"error"}/>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            <Grid container sx={{
                                px: 2,
                                maxHeight: 400,
                                overflowY: 'scroll'
                            }}>
                                {uploadErrors?.length && (
                                <Stack
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                >
                                    <Typography>Найдено ошибок: {uploadErrors.length}</Typography>
                                    {!showErrors && (
                                    <IconButton
                                        aria-label="show-errors"
                                        onClick={() => setShowErrors(true)}
                                    >
                                        <ArrowCircleDown />
                                    </IconButton>
                                    )}
                                    {showErrors && (
                                    <IconButton
                                        aria-label="hide-errors"
                                        onClick={() => setShowErrors(false)}
                                    >
                                        <ArrowCircleUp />
                                    </IconButton>
                                    )}
                                </Stack>
                                )}
                                {showErrors && (
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650, border: '1px solid #e0e0e0' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                        <TableCell className={classes.table_head_cell}>№</TableCell>
                                        {uploadErrors?.filter((error) => error.indexOf('Ошибка: ') !== -1).length ? (
                                            <TableCell className={classes.table_head_cell}>Строка</TableCell>
                                        ) : null}
                                        <TableCell align="left" className={classes.table_head_cell}>Ошибка</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {uploadErrors?.map((error, idx) => errorRender(error, idx))}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                )}
                            </Grid>
                            <Grid container columnSpacing={4} direction="row" alignItems="center" justifyContent="end" sx={{px: 3, py: 2, m: 0}} xs={12}>
                                <Grid item>
                                    <LoadingButton
                                        type="submit"
                                        disabled={isActiveOKButton()}
                                        variant="contained"
                                        color="success"
                                        sx={{
                                            color: 'white',
                                            boxShadow: 0,
                                            borderRadius: 0,
                                            minWidth: 100,
                                        }}
                                        onClick={onUploadStatement}
                                        loading={globalLoading}
                                    >
                                        <span>ОК</span>
                                    </LoadingButton>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            boxShadow: 0,
                                            borderRadius: 0,
                                            minWidth: 100,
                                        }}
                                        onClick={() => {
                                            onDeleteStatement()
                                        }}
                                    >
                                        Отмена
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </Dialog>
                )
            }}
        </Formik>
    );
}
