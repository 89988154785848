const userTypes = [
  {
      id: 0,
      role: "Employee",
      name: "Пользователь"
  },
  {
      id: 1,
      role: "Assistant",
      name: "Лаборант"
  },
  {
      id: 2,
      role: "Admin",
      name: "Администратор"
  }
]

const getUserType = (employee) => {
  let userType = ""
  if (employee?.user?.role) {
      userTypes.forEach(item => {
          if (item.role === employee.user.role) {
              userType = item
          }
      })
  }
  return userType
}

const getUserTypeId = (employee) => {
  let userTypeId = null
  if (employee?.user?.role) {
      userTypes.forEach(item => {
          if (item.role === employee.user.role) {
              userTypeId = item.id
          }
      })
  }
  return userTypeId
}

export { getUserType, getUserTypeId, userTypes }