function httpBuildQuery(params) {
    let urlParams = {}
    for (const key in params) {
        if (params[key] !== null && params[key] !== undefined) {
            urlParams[key] = params[key]
        }
    }
    return Object.keys(urlParams).length ? new URLSearchParams(urlParams).toString() : ''
}

export default httpBuildQuery;