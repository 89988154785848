import React, {useState} from "react"
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import {Filter as PathomorphologyFilter} from "./components/Pathomorphology/Filter";
import {Filter as RadiodiagnosisFilter} from "./components/Radiodiagnosis/Filter";
import RadiationDiagnostics from "../App/components/Svg/RadiationDiagnostics";
import {Pathomorphology} from "./components/Pathomorphology/Pathomorphology";
import {Radiodiagnosis} from "./components/Radiodiagnosis/Radiodiagnosis";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Filter as EMCFilter} from "./components/EMC/Filter";
import {emcModules, geneticModules} from "./helpers/study";
import Microscope from "../App/components/Svg/Microscope";
import {useDispatch, useSelector} from "react-redux";
import {Genetic} from "./components/Genetic/Genetic";
import {Filter} from "./components/Genetic/Filter";
import {getModuleIdByName} from "./helpers/study";
import {allyProps} from "../App/helpers/tabPanel";
import Typography from "@mui/material/Typography";
import {Grid, Tab, Tabs} from '@mui/material';
import Dna from "../App/components/Svg/Dna";
import Emc from "../App/components/Svg/Emc";
import Button from "@mui/material/Button";
import {EMC} from "./components/EMC/EMC";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    content: {
        height: "100%",
        display: 'grid',
        "grid-template-columns": "max-content 1fr",
    },
    container: {
        margin: 0,
        width: "100%",
        height: "100%",
    },
    icon: {
        width: "36px",
        height: "26px",
        display: "block"
    },
    tab_text: {
        fontWeight: 400,
        fontSize: "14px",
        fontStyle: "normal",
        textTransform: "none",
    },
    sub_tab_text: {
        fontWeight: 400,
        fontSize: "14px",
        textAlign: "left",
        marginLeft: "45px",
        fontStyle: "normal",
        textTransform: "none",
    },
    tab: {
        height: "40px",
        minHeight: "40px",
        textAlign: "left",
        marginLeft: "15px",
        color: 'rgba(0, 0, 0, 0.6)',
        justifyContent: "flex-start",
    },
    sub_tab: {
        height: "auto",
        minHeight: "40px",
        textAlign: "left",
        paddingLeft: "60px",
        color: 'rgba(0, 0, 0, 0.6)',
        justifyContent: "flex-start",
    },
    filter: {
        backgroundColor: "#F5F5F5",
        padding: "10px 10px 10px 10px"
    },
    filter_name: {
        color: "black",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "25px",
        fontStyle: "normal",
    },
    arrow: {
        width: "16px",
        marginLeft: "10px",
    },
    arrow_tab: {
        paddingLeft: "20px",
        color: 'rgba(0, 0, 0, 0.6)',
        justifyContent: "flex-start",
    }
}))

export const Index = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [moduleValue, setModuleValue] = useState('1')
    const [hideTabs, setHideTabs] = useState(false)
    const {moduleId, emcId, geneticId, tabId} = useParams()
    const {isDirty} = useSelector(state => state.pathomorphology);

    const handleClick = (value) => {
        let path
        switch (value) {
            case 0:
                path = '/database/pathomorphology/verification'
                break
            case 1:
                path = '/database/radiodiagnosis/data'
                break
            case 2:
                path = '/database/emc/patients'
                break
            case 3:
                path = '/database/emc/patients'
                break
            case 4:
                path = '/database/emc/tricks'
                break
            case 5:
                path = '/database/emc/results'
                break
            case 6:
                path = '/database/emc/recipes'
                break
            case 7:
                path = '/database/emc/procedures'
                break
            case 8:
                path = '/database/emc/surveys'
                break
            case 9:
                path = '/database/emc/diagnostics'
                break
            case 10:
                path = '/database/genetic/oncology'
                break
            case 11:
                path = '/database/genetic/oncology'
                break
            case 12:
                path = '/database/genetic/wgs'
                break
            case 13:
                path = '/database/genetic/microbiome'
                break
            case 14:
                setHideTabs(!hideTabs)
                break
            default:
                path = '/404'
                break
        }
        if (isDirty) {
            dispatch({
                type: "PATHOMORPHOLOGY_OPEN_EXIT_DIALOG", payload: {
                    value: path,
                    path: path
                }
            })
        } else {
            navigate(path)
        }
        setModuleValue('1')
        dispatch({type: "GENETIC_FILTER_CLEAR", payload: null})
        dispatch({type: "PATHOMORPHOLOGY_FILTER_CLEAR", payload: null})
        dispatch({type: "GENETIC_STUDY", payload: null})
    };


    const getColorIcon = (name) => {
        return (moduleId === name) ? '#2595FF' : 'black'
    }

    const getColorIconGenetic = () => {
        return (geneticModules.indexOf(geneticId) !== -1) ? '#2595FF' : 'black'
    }

    const getColorIconEMC = () => {
        return (emcModules.indexOf(emcId) !== -1) ? '#2595FF' : 'black'
    }

    const getTabLabel = (label, path = null) => {
        const pathname = location.pathname.split('/')
        return hideTabs ? "" : <Typography
            className={classes.tab_text}
            style={path && pathname[pathname.length - 1] === path ? {color: "#2595FF"} : null}
        >
            {label}
        </Typography>
    }

    const getCustomModuleId = (value) => {
        if (emcId) {
            return 2
        } else if (geneticId) {
            return 3
        }
        return value
    }

    return (
        <Grid className={classes.content}>
            <Tabs
                variant="scrollable"
                scrollButtons={false}
                orientation="vertical"
                aria-label="Выбор меню"
                sx={{borderRight: 1, borderColor: 'divider'}}
                value={hideTabs ? getCustomModuleId(getModuleIdByName(moduleId, geneticId, emcId)) : getModuleIdByName(moduleId, geneticId, emcId)}
            >
                <Button
                    className={classes.tab}
                    onClick={() => handleClick(0)}
                    startIcon={<Microscope className={classes.icon} color={getColorIcon('pathomorphology')}/>}
                >
                    {getTabLabel("Патоморфология", 'pathomorphology')}
                </Button>
                <Button
                    className={classes.tab}
                    onClick={() => handleClick(1)}
                    startIcon={<RadiationDiagnostics className={classes.icon} color={getColorIcon('radiodiagnosis')}/>}
                >
                    {getTabLabel("Лучевая диагностика", 'radiodiagnosis')}
                </Button>
                <Button
                    className={classes.tab}
                    onClick={() => handleClick(2)}
                    startIcon={<Emc className={classes.icon} color={getColorIconEMC()}/>}
                >
                    {getTabLabel("ЭМК")}
                </Button>
                {((!hideTabs && moduleId === undefined) && emcId) && <Button
                    className={classes.sub_tab}
                    onClick={() => handleClick(3)}
                >
                    {getTabLabel("Пациенты", 'patients')}
                </Button>}
                {((!hideTabs && moduleId === undefined) && emcId) && <Button
                    className={classes.sub_tab}
                    onClick={() => handleClick(4)}
                >
                    {getTabLabel("Приёмы", 'tricks')}
                </Button>}
                {((!hideTabs && moduleId === undefined) && emcId) && <Button
                    className={classes.sub_tab}
                    onClick={() => handleClick(5)}
                >
                    {getTabLabel("Результаты визита", 'results')}
                </Button>}
                {((!hideTabs && moduleId === undefined) && emcId) && <Button
                    className={classes.sub_tab}
                    onClick={() => handleClick(6)}
                >
                    {getTabLabel("Рецепты", 'recipes')}
                </Button>}
                {((!hideTabs && moduleId === undefined) && emcId) && <Button
                    className={classes.sub_tab}
                    onClick={() => handleClick(7)}
                >
                    {getTabLabel("Процедуры", 'procedures')}
                </Button>}
                {((!hideTabs && moduleId === undefined) && emcId) && <Button
                    className={classes.sub_tab}
                    onClick={() => handleClick(8)}
                >
                    {getTabLabel("Обследования", 'surveys')}
                </Button>}
                {((!hideTabs && moduleId === undefined) && emcId) && <Button
                    className={classes.sub_tab}
                    onClick={() => handleClick(9)}
                >
                    {getTabLabel("Диагностические отчёты", 'diagnostics')}
                </Button>}
                <Button
                    className={classes.tab}
                    onClick={() => handleClick(10)}
                    startIcon={<Dna className={classes.icon} color={getColorIconGenetic()}/>}
                >
                    {getTabLabel("Генетические данные")}
                </Button>
                {((!hideTabs && moduleId === undefined) && geneticId) && <Tab
                    {...allyProps(11)}
                    iconPosition="start"
                    onClick={() => handleClick(11)}
                    className={classes.sub_tab_text}
                    label="Подсистема анализа и интерпретации онкологических генетических биомаркеров"
                />}
                {((!hideTabs && moduleId === undefined) && geneticId) && <Tab
                    {...allyProps(12)}
                    iconPosition="start"
                    onClick={() => handleClick(12)}
                    className={classes.sub_tab_text}
                    label="Подсистема анализа и интерпретации полногеномных данных"
                />}
                {((!hideTabs && moduleId === undefined) && geneticId) && <Tab
                    {...allyProps(13)}
                    iconPosition="start"
                    onClick={() => handleClick(13)}
                    className={classes.sub_tab_text}
                    label="Подсистема анализа и интерпретации микробиома"
                />}
                <Button
                    className={classes.arrow_tab}
                    onClick={() => handleClick(14)}
                    startIcon={hideTabs
                        ? <ArrowForwardIosOutlinedIcon color="primary" className={classes.arrow}/>
                        : <ArrowBackIosNewOutlinedIcon color="primary" className={classes.arrow}/>
                    }
                >
                    {getTabLabel("Свернуть")}
                </Button>
                {(!hideTabs && emcId && (moduleValue === '1')) && <EMCFilter moduleId={moduleId}/>}
                {(!hideTabs && geneticId && (moduleValue === '1')) && <Filter moduleId={moduleId}/>}
                {(!hideTabs && moduleId === 'pathomorphology') && <PathomorphologyFilter moduleId={moduleId}/>}
                {(!hideTabs && moduleId === 'radiodiagnosis') && <RadiodiagnosisFilter moduleId={moduleId}/>}
            </Tabs>
            {(moduleId === 'pathomorphology') &&
                <Pathomorphology
                    partitionId={0}
                    moduleId={moduleId}
                    tabId={encodeURIComponent(tabId)}
                />
            }
            {(moduleId === 'radiodiagnosis') &&
                <Radiodiagnosis
                    tabId={tabId}
                    partitionId={0}
                />
            }
            {((moduleId === undefined) && emcId) &&
                <EMC
                    moduleId={emcId}
                    moduleValue={moduleValue}
                    setModuleValue={setModuleValue}
                />
            }
            {((moduleId === undefined) && geneticId) &&
                <Genetic
                    moduleId={geneticId}
                    moduleValue={moduleValue}
                    setModuleValue={setModuleValue}
                />}
        </Grid>
    );
}
