import {HttpService} from "../../App/services/http";

export const MedicalInstitutionsService = {
    get,
    add,
    edit,
    getMedicalInstitution,
    deleteMedicalInstitution,
    restoreMedicalInstitution,
}

function get(params) {
    return HttpService.getMethod('/api/medical-institutions', `limit=15&${params}`)
}

function getMedicalInstitution(id) {
    return HttpService.getMethod(`/api/medical-institutions/${id}`)
}

function add(values) {
    return HttpService.postMethod('/api/medical-institutions', JSON.stringify(values))
}

function edit(id, values) {
    return HttpService.patchMethod(`/api/medical-institutions/${id}`, values)
}

function restoreMedicalInstitution(id) {
    return HttpService.patchMethod(`/api/medical-institutions/${id}/restore`) 
}

function deleteMedicalInstitution(id) {
    return HttpService.deleteMethod(`/api/medical-institutions/${id}`)
}
