import React from "react";

function RadiationDiagnostics(props) {
    const {color} = props
    return (
        <svg {...props} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.9339 1.80887L15.2672 0.142148C15.1761 0.0511328 15.0527 0 14.9238 0H3.07606C2.94731 0 2.82383 0.0511328 2.73281 0.142148L1.06602 1.80887C0.974961 1.89992 0.923828 2.02336 0.923828 2.15219V19.5146C0.923828 19.7827 1.14117 20 1.40926 20H16.5906C16.8586 20 17.076 19.7827 17.076 19.5146V2.15219C17.076 2.02344 17.0249 1.89992 16.9339 1.80887ZM16.1051 19.0291H1.89477V2.3532L3.27711 0.970859H14.7228L16.1052 2.3532V19.0291H16.1051Z" fill={color}/>
            <path d="M5.84184 12.3232L7.47168 11.6356C8.13097 11.3575 8.55691 10.7155 8.55691 10.0001V5.48991C8.55691 4.08398 7.01269 3.22757 5.81988 3.97296C5.29625 4.30023 4.90422 4.80437 4.71594 5.39257L3.25496 9.95815C2.75227 11.529 4.3243 12.9634 5.84184 12.3232ZM4.17965 10.254L5.64062 5.68847C5.75895 5.31874 6.00535 5.00194 6.33449 4.79624C6.87961 4.45554 7.58609 4.84679 7.58609 5.48995V9.99999C7.58609 10.3242 7.39308 10.6151 7.09437 10.7411L5.46453 11.4287C4.70824 11.7476 3.92942 11.036 4.17965 10.254Z" fill={color}/>
            <path d="M12.0032 3.86236C10.8878 3.16544 9.44312 3.96583 9.44312 5.28126V10C9.44312 10.7156 9.86905 11.3576 10.5284 11.6356L12.1582 12.3232C13.6774 12.9643 15.2473 11.5271 14.7451 9.95806L13.3477 5.59119C13.1185 4.87497 12.641 4.26091 12.0032 3.86236ZM12.5355 11.4287L10.9057 10.7411C10.607 10.6151 10.414 10.3242 10.414 10V5.28122C10.414 4.72919 11.0206 4.39294 11.4886 4.68568C11.9318 4.96263 12.2637 5.38935 12.423 5.88716L13.8203 10.254C14.0705 11.0357 13.2921 11.7477 12.5355 11.4287Z" fill={color}/>
            <path d="M14.3542 14.7289H3.64583C3.37774 14.7289 3.1604 14.9461 3.1604 15.2143C3.1604 15.4825 3.37774 15.6997 3.64583 15.6997H14.3541C14.6222 15.6997 14.8395 15.4825 14.8395 15.2143C14.8396 14.9461 14.6223 14.7289 14.3542 14.7289Z" fill={color}/>
            <path d="M14.3542 16.5859H3.64583C3.37774 16.5859 3.1604 16.8033 3.1604 17.0714C3.1604 17.3395 3.37774 17.5568 3.64583 17.5568H14.3541C14.6222 17.5568 14.8395 17.3395 14.8395 17.0714C14.8396 16.8032 14.6223 16.5859 14.3542 16.5859Z" fill={color}/>
        </svg>
    );
}

export default RadiationDiagnostics;
