/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {getLink} from '../../../../Database/helpers/pagination'
import {PaginationItem, Pagination} from '@mui/material'
import {DoctorsActions} from '../../../actions/doctors'

export const DoctorsModulePagination = (props) => {
    const {setLoading} = props

    const dispatch = useDispatch()
    const {values, page, filter, bundle} = useSelector(state => state.doctors)

    const nextLink = getLink('nextLink', values, bundle)
    const previousLink = getLink('previousLink', values, bundle)

    useEffect(() => {
        setLoading(false)
    }, [filter]);

    return (
        <Pagination
            count={page}
            page={page}
            renderItem={(item) => {
                switch (item.type) {
                    case 'next':
                        return <PaginationItem
                            {...{
                                ...item,
                                ...{
                                    disabled: (!values.length || !nextLink),
                                    onClick: () => {
                                        dispatch(DoctorsActions.get(nextLink ? Object.assign(nextLink, filter) : filter))
                                        dispatch({type: "DOCTORS_CURRENT_PAGE", payload: page + 1})
                                    }
                                }
                            }}
                        />
                    case 'previous':
                        return <PaginationItem
                            {...{
                                ...item,
                                ...{
                                    disabled: (!values.length || !previousLink),
                                    onClick: () => {
                                        dispatch(DoctorsActions.get(previousLink ? Object.assign(previousLink, filter) : filter))
                                        dispatch({type: "DOCTORS_CURRENT_PAGE", payload: page - 1})
                                    }
                                }
                            }}
                        />
                    case 'page':
                        return <PaginationItem
                            {...{
                                ...item,
                                ...{
                                    disabled: ((item.page !== 1) && (item.page !== page)),
                                    onClick: () => {
                                        if (item.page === 1) {
                                            dispatch({type: 'DOCTORS_CLEAR'})
                                            setLoading(false)
                                        }
                                    }
                                }
                            }}
                        />
                    default:
                        return <PaginationItem
                            {...item}
                        />
                }
            }}
            variant="outlined"
        />
  )
}
