import React from "react";

function Emc(props) {
    const {color} = props
    return (
        <svg {...props} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.84158 0.666672H0.333252V17.3333H13.6666V4.49167L9.84158 0.666672ZM12.4166 16.0833H1.58325V1.91667H9.32492L12.4166 5.00834V16.0833ZM3.66658 12.3333H4.83325V9.00001H3.66658V12.3333ZM9.16658 12.3333H10.3333V7.33334H9.16658V12.3333ZM6.41658 12.3333H7.58325V5.66667H6.41658V12.3333Z" fill={color}/>
        </svg>
    );
}

export default Emc;