import React, {useState} from "react"
import {useDispatch} from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import {GeneticActions} from "../../../actions/genetics";
import {getShortName} from "../../../../App/helpers/row";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import LensIcon from '@mui/icons-material/Lens';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import format from "date-fns/format"
import Box from '@mui/material/Box';
import {ru} from "date-fns/locale";
import {Link} from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    download_report_link: {
        color: "#2595FF",
        transition: "0.3s",
        textDecoration: 'none',
    },
    download_report_link_diabled: {
        color: "gray",
        transition: "0.3s",
        textDecoration: 'none',
        fontSize: "14px"
    },
    download_report_icon: {
        height: "16px",
        width: "16px"
    },
    status_indicator: {
        padding: "5px",
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "16px",
        borderRadius: "2px",
        width: "max-content"
    },
    status_indicator_gainsboro: {
        color: "gray",
        background: "gainsboro"
    },
    status_indicator_light_blue: {
        color: "#2f7fff",
        background: "#caddff"
    },
    status_indicator_green: {
        color: "#009132",
        background: "#E4F6E9"
    },
    status_indicator_orange: {
        color: "#ff4600",
        background: "#FEECB3"
    },
    status_indicator_red: {
        color: "red",
        background: "#ffd2d2"
    },
    status_indicator_blue: {
        color: "#0083ff",
        background: "#ccdeff"
    },
    status_indicator_lens: {
        fontSize: "8px",
        marginRight: "2px"
    },
    atlas_button: {
        fontSize: "12px",
        padding: "2px 5px"
    },
    send_button: {
        fontSize: "13px",
        color: "#2595FF",
        fontWeight: 'normal',
        background: "none",
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        padding: "2px",
        textDecoration: 'underline',
        textTransform: 'none',
        textAlign: 'left',
        lineHeight: '14px',
        "&:hover": {
            background: "none",
            color: "#2595FF",
        }
    },
    edit_button: {
        width: '20px',
        height: '20px',
        color: '#2595FF'
    },
    table_body_cell_open: {
        paddingBottom: 0,
        paddingTop: 0,
    },
    table_body_cell_close: {
        paddingBottom: 0,
        paddingTop: 0,
        border: "none"
    },
    cell: {
        padding: '5px',
        lineHeight: '18px',
        fontSize: '13px'
    },
    tooltip_text: {
        fontSize: '13px',
        whiteSpace: 'pre-line'
    }
}))

const accessResourceTypes = ["sample", "positive_control", "negative_control", "forward_sequencing", "reverse_sequencing", "genotype_data"]
const mdash = `\u2014`;

export const Row = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {row, popoverClick, moduleId} = props;
    const [open, setOpen] = useState(false);

    const onClickOpen = () => {
        setOpen((prevState) => !prevState)
    }

    const getConfiguratorButton = (row, file_name) => {
        return moduleId !== 'oncology' ? <IconButton
            aria-describedby="reports_popover"
            variant="contained"
            component="span"
            onClick={e => popoverClick(e, file_name, row)}
        >
            <KeyboardArrowDownIcon className={classes.download_report_icon}/>
        </IconButton> : null
    }

    const getReportButtons = (row) => {
        return row.reports.map(report => {
            if (report) {
                return <Stack direction="row" alignItems="center" key={report.id}>
                    {report.resourceCode === 'custom' && <Link
                        target="_blank"
                        className={classes.send_button}
                        href={`/api/gen/studies/${report.studyInstanceUid}/slides/${report.slideInstanceUid}?access_token=${localStorage.getItem('token')}`}
                    >
                        Скачать*
                    </Link>}
                    {report.resourceCode === 'default' && <React.Fragment>
                        <Link
                            target="_blank"
                            className={classes.send_button}
                            href={`/api/gen/studies/${report.studyInstanceUid}/slides/${report.slideInstanceUid}?access_token=${localStorage.getItem('token')}`}
                        >
                            Скачать
                        </Link>
                        {getConfiguratorButton(row, report.fileName)}
                    </React.Fragment>}
                </Stack>
            }
            return null
        })
    }

    const getStatusIndicator = (status) => {
        let ids = {
            'error': {
                text: "Ошибка",
                style: classes.status_indicator_red
            },
            'created': {
                text: "Создано",
                style: classes.status_indicator_blue
            },
            'inprogress': {
                text: "Обрабатывается",
                style: classes.status_indicator_orange
            },
            'completed': {
                text: "Выполнено",
                style: classes.status_indicator_green
            },
            'inqc': {
                text: "На рассмотрении",
                style: classes.status_indicator_blue
            },
            'rendering': {
                text: "Рендеринг",
                style: classes.status_indicator_blue
            },
            'sent': {
                text: "Отправлено",
                style: classes.status_indicator_blue
            },
        }
        return ids[status.toLowerCase()] ? <Typography
            className={clsx(classes.status_indicator, ids[status.toLowerCase()].style)}
        >
            <LensIcon className={classes.status_indicator_lens}/>
            {ids[status.toLowerCase()].text}
        </Typography> : null
    }

    const isSendAtlasButtonDisabled = (files) => {
        const modules = {
            'oncology': 1,
            'wgs': 2,
            'microbiome': 1,
        }

        return (files.filter(file => accessResourceTypes.indexOf(file.resourceType) !== -1).length < modules[moduleId])
    }

    const getSendAtlasButton = (studyInstanceUid) => {
        return <Button
            variant="outlined"
            startIcon={<BackupOutlinedIcon style={{width: "20px"}}/>}
            disabled={isSendAtlasButtonDisabled(row.files)}
            onClick={() => sendAtlas(studyInstanceUid)}
            className={classes.atlas_button}
        >
            Отправить
        </Button>
    }

    const sendAtlas = (studyInstanceUid) => {
        dispatch(GeneticActions.finalize(studyInstanceUid)).then(
            () => {
                dispatch(GeneticActions.studies({offset: 0}, moduleId)).then(() => {
                })
                dispatch({
                    type: "ALERT_SUCCESS", payload: {
                        message: `Вы успешно отправили исследование на обработку!`,
                        type: 'success'
                    }
                })
            })
    }

    const editRow = (id) => {
        dispatch({
            type: "GENETIC_STUDY", payload: {
                id: id
            }
        })
    }

    const getArrow = (row) => {
        return <TableCell>
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                {row.files.filter(file => accessResourceTypes.indexOf(file.resourceType) !== -1).length > 0 ?
                    <IconButton
                        aria-label="Раскрыть"
                        size="small"
                        onClick={onClickOpen}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton> : null}
                {!row.interpretationStatus &&
                    <IconButton
                        aria-label="edit"
                        component="span"
                        onClick={() => editRow(row.studyInstanceUid)}
                    >
                        <ModeEditOutlinedIcon
                            className={classes.edit_button}
                        />
                    </IconButton>
                }
            </Stack>
        </TableCell>
    }

    const getInterpretationDate = (reports) => {
        return format(new Date(reports[reports.length - 1].createdDate), 'PPPpp', {locale: ru})
    }

    return (
        <React.Fragment>
            <TableRow hover>
                {getArrow(row)}
                <TableCell className={classes.cell}>{row.studyInstanceUid}</TableCell>
                <TableCell className={classes.cell}>{row.patientId ?
                    <Tooltip placement="left-start" arrow title={row.patientId}>
                        <Typography
                            className={classes.tooltip_text}
                        >
                            {row.patientId.substr(0, 20)}
                        </Typography>
                    </Tooltip> : mdash}</TableCell>
                <TableCell
                    className={classes.cell}>{row.patientBirthDate ? format(new Date(row.patientBirthDate), 'PP', {locale: ru}) : mdash}</TableCell>
                <TableCell
                    className={classes.cell}>{row.medicalInstitution ? row.medicalInstitution.name : mdash}</TableCell>
                <TableCell
                    className={classes.cell}>{row.biomaterialSource ? row.biomaterialSource.name : mdash}</TableCell>
                <TableCell
                    className={classes.cell}>{row.finalized ? getStatusIndicator(row.interpretationStatus) : getSendAtlasButton(row.studyInstanceUid)}</TableCell>
                <TableCell
                    className={classes.cell}>{row.sampleDate ? format(new Date(row.sampleDate), 'PPP', {locale: ru}) : mdash}</TableCell>
                <TableCell
                    className={classes.cell}>{row.interpretationCreatedAt ? format(new Date(row.interpretationCreatedAt), 'PPPpp', {locale: ru}) : mdash}</TableCell>
                <TableCell
                    className={classes.cell}>{row.createdDate ? format(new Date(row?.createdDate), 'PPPpp', {locale: ru}) : mdash}</TableCell>
                <TableCell
                    className={classes.cell}>{row.reports.length ? getInterpretationDate(row.reports) : mdash}</TableCell>
                <TableCell className={classes.cell}>{getShortName(row.operator)}</TableCell>
                <TableCell className={classes.cell}>{getReportButtons(row)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={open ? classes.table_body_cell_open : classes.table_body_cell_close}/>
                <TableCell className={open ? classes.table_body_cell_open : classes.table_body_cell_close} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Grid container direction="column" justify="center" alignItems="flex-start">
                                {row.files.map(file => {
                                    if (accessResourceTypes.indexOf(file.resourceType) !== -1) {
                                        return <Stack direction="row" justifyContent="flex-start" key={file.id}>
                                            <Link
                                                className={classes.download_report_link}
                                                href={`/api/gen/studies/${row.studyInstanceUid}/slides/${file.slideInstanceUid}?access_token=${localStorage.getItem('token')}`}
                                            >
                                                {file.slideInstanceUid} ({file.resourceType})
                                            </Link>
                                        </Stack>
                                    }
                                    return null
                                })}
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
