import axios from 'axios';
import {
    getOptions,
    getQueryParams,
    MIME_TYPE_JSON,
} from "../helpers/http";
import {getError} from '../helpers/httpHandler'

export const HttpService = {
    http,
    getMethod,
    postMethod,
    patchMethod,
    deleteMethod,
}

const TOKEN = "token";

function handleResponse(response, options) {
    if (response.status === 204) {
        throw new Error(getError(204))
    }
    if (response.headers.hasOwnProperty(TOKEN)) {
        localStorage.setItem(TOKEN, response.headers.token)
    }
    return options.hasOwnProperty("responseType") ? response : response.data
}

function getOnUploadProgressOptions(events, guid){
    const source = axios.CancelToken.source();

    return {
        cancelToken: source.token,
        onUploadProgress: progressEvent => {
            events.onUploadProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total), guid, source.cancel)
        }
    }
}

function http(url, options = {}, needAuthorization = true) {
    options.headers = Object.assign(
        { "Accept": MIME_TYPE_JSON },
        options.headers ? options.headers : {}
    )

    if (needAuthorization) {
        options.headers.Authorization = `Bearer ${localStorage.getItem(TOKEN)}`
    }

    return axios.request({ url: url, ...options}).then(e => handleResponse(e, options))
}

function handlerUploadError(error, events, guid){
    events.onUploadError(guid, getError(error.response))
}

function defaultHandlerError(error, changePass = false) {
    if (error?.response) {
        switch (error?.response?.status) {
            case 401:
                let isNotAuthorized = !!(typeof changePass !== Boolean && error?.response?.data?.info?.accessFailedCount === '0')
                if (isNotAuthorized) {
                    localStorage.removeItem("account")
                    localStorage.removeItem(TOKEN)
                    window.location.href = "/logout"
                    throw new Error(getError(error.response))
                } else if (!error.response.data) {
                    localStorage.removeItem("account")
                    localStorage.removeItem(TOKEN)
                    window.location.href = "/login"
                }
                else {
                    localStorage.removeItem("account")
                    localStorage.removeItem(TOKEN)
                    throw new Error(getError(error.response))
                }
                break;
            default:
                if (error.response?.data?.errors) {
                    throw new Error(JSON.stringify(error.response))
                } else if (error.response?.message) {
                    throw new Error(error.response.message)
                } else {
                    throw new Error(getError(error.response))
                }
        }
    }
}

async function getMethod(url, params = {}, needAuthorization = true, responseType = null) {
    const queryParams = getQueryParams(params); 

    const options = {
        method: "GET",
        ...(responseType ? { responseType: responseType} : {})
    };

    return await HttpService.http(`${url}?${queryParams}`, options, needAuthorization).catch(err => defaultHandlerError(err));
}

async function postMethod(url, values = [], needAuthorization = true, events = null, guid = null, changePass = false) {
    const options = {
        method: "POST",
        ...getOptions(values),
        ...(events !== null ? getOnUploadProgressOptions(events, guid) : {}),
    }

    return await HttpService.http(url, options, needAuthorization).catch(err => {
        if (events !== null) {
            handlerUploadError(err, events, guid)
        }
        defaultHandlerError(err, changePass)
    });
}

async function patchMethod(url, values = [], needAuthorization = true, events = null, guid = null, changePass = false) {
    const options = {
        method: "PATCH",
        ...getOptions(values),
        ...(events !== null ? getOnUploadProgressOptions(events, guid) : {}),
    }

    return await HttpService.http(url, options, needAuthorization).catch(err => {
        if (events !== null) {
            handlerUploadError(err, events, guid)
        }
        defaultHandlerError(err, changePass)
    });
}

async function deleteMethod(url, needAuthorization = true) {
    const options = {
        method: "DELETE",
    }

    return await HttpService.http(url, options, needAuthorization).catch(err => defaultHandlerError(err));
}
