import React, {useEffect} from 'react'
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {CustomSkeleton} from "../DataTable/CustomSkeleton";
import TableContainer from '@mui/material/TableContainer';
import {EmployeesActions} from "../../actions/employees";
import {getShortName} from "../../../App/helpers/row";
import {Status} from "../../../App/components/Status";
import {useDispatch, useSelector} from "react-redux";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {Actions} from "../Buttons/Actions";
import Table from '@mui/material/Table';
import {Head} from "../DataTable/Head";
import {Stats} from '../Stats/Stats';

const columns = [
    {field: 'fio', name: 'ФИО', width: 100},
    {field: 'employeePosition', name: 'Должность', width: 100},
    {field: 'organization', name: 'Организация', width: 100},
    {field: 'email', name: 'Эл. адрес', width: 100},
    {field: 'status', name: 'Статус', width: 50},
    {field: 'actions', name: 'Действия', width: 30},
];

export const Employees = (props) => {
    const dispatch = useDispatch()
    const {employees, filter} = useSelector(state => state.employees);

    const {
        setDeleteDialog,
        setEditDialog,
        setLoading,
        setUseRow,
        moduleId,
        setValue,
        loading,
        page
    } = props

    useEffect(() => {
        const params = {
            ...{
                offset: page === 1 ? 0 : (15 * page) - 15,
            },
            ...filter
        }
        if (!loading) {
            dispatch(EmployeesActions.get(httpBuildQuery(params))).then(() => {
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading]);

    return (
        <>
            <Stats amount={employees?.total}/>
            <TableContainer style={{height: "calc(100vh - 315px)"}}>
                <Table stickyHeader aria-label='sticky table'>
                    <Head columns={columns}/>
                    <TableBody>
                        {loading ? employees.data.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{getShortName(row)}</TableCell>
                                <TableCell>{row.employeePosition ? row.employeePosition.name : null}</TableCell>
                                <TableCell>{row.medicalInstitution ? row.medicalInstitution.name : null}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell><Status active={row.isActive}/></TableCell>
                                <TableCell>
                                    <Actions
                                        row={row}
                                        setValue={setValue}
                                        moduleId={moduleId}
                                        setUseRow={setUseRow}
                                        setLoading={setLoading}
                                        setEditDialog={setEditDialog}
                                        setDeleteDialog={setDeleteDialog}
                                    />
                                </TableCell>
                            </TableRow>
                        )) : <CustomSkeleton count={4}/>}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
