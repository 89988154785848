export default function scannerResolutions(state = {
    scannerResolutions: {
        data: [],
        total: 0,
        total_pages: 0
    },
    scannerResolution: null,
    filter: {}
}, action) {
    switch (action.type) {
        case "SCANNER_RESOLUTIONS":
            return {
                ...state,
                ...{
                    scannerResolutions: {
                        data: action.payload.data,
                        total: action.payload.total,
                        total_pages: action.payload.totalPages
                    }
                }
            }
        case "SCANNER_RESOLUTION":
            return {
                ...state,
                ...{
                    scannerResolution: action.payload
                }
            }
        case "SCANNER_RESOLUTIONS_FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload
                }
            }
        default:
            return state
    }
}
