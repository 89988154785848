import {EmployeesService} from '../services/employees'

export const EmployeesActions = {
    add,
    edit,
    get,
    getEmployee,
    deleteEmployee
}

function get(values) {
    return dispatch => new Promise((resolve, reject) => {
        EmployeesService.get(values)
            .then(
                response => {
                    dispatch({type: "EMPLOYEES", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getEmployee(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        EmployeesService.getEmployee(id)
            .then(
                response => {
                    dispatch({type: "EMPLOYEE", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function edit(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        EmployeesService.edit(id, values)
            .then(
                _ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function deleteEmployee(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        EmployeesService.deleteEmployee(id)
            .then(
                _ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        EmployeesService.add(values)
            .then(
                _ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}