import React from 'react';
import {Submit} from '../../../../../App/components/Button/Submit';
import {createGuid} from '../../../../../App/helpers/createGuid';
import {getExtensionFile} from '../../../../helpers/study';
import Input from '@mui/material/Input';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    button: {
        marginRight: '32px',
        marginLeft: '14px',
        background: '#2595FF',
        color: 'white',
        boxShadow: 'none',
        '&:hover': {
            background: '#2595FF',
        },
    },
}));

export const UploadButton = (props) => {
    const classes = useStyles();
    const {setFieldValue, files} = props;

    return (
        <Submit
            variant="contained"
            component="label"
            size="small"
            className={classes.button}
        >
            Выбрать файлы
            <Input
                style={{display: 'none'}}
                inputProps={{
                    ...{
                        type: 'file',
                        name: 'file',
                        webkitdirectory: '',
                        directory: '',
                        multiple: '',
                        onChange: (event) => {
                            let uploadFiles = [];
                            for (const key in event.target.files) {
                                if (event.target.files[key] instanceof File) {
                                    uploadFiles.push({
                                        value: event.target.files[key],
                                        extension: getExtensionFile(event.target.files[key].name),
                                        guid: createGuid(),
                                        code: '',
                                    });
                                }
                            }
                            setFieldValue(`files`, files.concat(uploadFiles));
                        },
                    },
                }}
            />
        </Submit>
    );
};
