/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MedicalInstitutionsActions} from '../../../Dictionary/actions/medicalInstitution';
import {CustomAutocomplete} from '../../../App/components/Input/CustomAutocomplete';
import {SaveButtons} from '../Radiodiagnosis/components/Button/SaveButtons';
import NotSelected from '../../../App/components/Svg/Checkbox/NotSelected';
import Selected from '../../../App/components/Svg/Checkbox/Selected';
import {RadiodiagnosisActions} from "../../actions/radiodiagnosis";
import httpBuildQuery from '../../../App/helpers/httpBuildQuery';
import {FilesTable} from './components/FilesTable/FilesTable';
import {UploadButton} from './components/Button/UploadButton';
import {getTargetValue} from '../../../App/helpers/textField';
import {createGuid} from '../../../App/helpers/createGuid';
import {getShortName} from '../../../App/helpers/row';
import {getExtensionFile} from '../../helpers/study';
import {useDebounce} from 'use-debounce';
import {makeStyles} from '@mui/styles';
import {Form, Formik} from 'formik';

import * as Yup from 'yup';
import {
    Grid,
    Select,
    Checkbox,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';

const useStyles = makeStyles(() => ({
    container: {
        height: "100px",
        paddingTop: "30px",
        background: 'white',
        display: 'flex',
        alignItems: 'flex-start',
        alignContent: 'center',
    },
    autocomplete: {
        width: '350px',
        marginRight: '32px',
        marginTop: '-16px',
        background: 'transparent',
        '& .MuiInputBase-input': {
            background: 'transparent',
        },
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        color: '#495055;',
    },
    label: {
        marginLeft: '-8px',
        marginTop: '-7px',
    },
    select: {
        width: '350px',
        marginTop: '0px !important',
        marginRight: '32px',
    },
}));

export const UploadStudy = () => {

    window.onbeforeunload = function () {
        return true
    }

    const classes = useStyles();
    const dispatch = useDispatch();

    const {account} = useSelector(state => state.account);
    const {value} = useSelector(state => state.radiodiagnosis);
    const {medicalInstitutions} = useSelector(state => state.medicalInstitutions);

    const [medicalInstitutionsSearch, setMedicalInstitutionsSearch] = useState(null);
    const [loadingMedicalInstitutions, setLoadingMedicalInstitutions] = useState(false);
    const [medicalInstitutionsSearchRequest] = useDebounce(medicalInstitutionsSearch, 400);
    const [isDisabledAfterSubmit, setDisabledAfterSubmit] = useState(false)

    const validationSchema = Yup.object().shape({
        medicalInstitution: Yup.mixed().required('Выберите организацию из списка'),
        anonymize: Yup.boolean(),
        files: Yup.array(Yup.object().shape({
            extension: Yup.string().notOneOf(['DS_Store', 'DS_STORE'], 'Данный файл не поддерживается'),
        }))
    });

    const medicalInstitutionsInputChange = (e) => {
        setMedicalInstitutionsSearch(getTargetValue(e));
    };

    const onUploadProgress = (percent, guid, cancel) => {
        dispatch({
            type: "UPLOAD_MANAGER_CHANGE", payload: {
                percent: percent,
                guid: guid,
                cancel: cancel
            }
        })
    }

    const onUploadError = (guid, message) => {
        dispatch({
            type: "UPLOAD_MANAGER_ERROR", payload: {
                guid: guid,
                message: message
            }
        })
    }


    const events = {
        onUploadProgress: onUploadProgress,
        onUploadError: onUploadError
    }

    useEffect(() => {
        if (!loadingMedicalInstitutions) {
            const params = httpBuildQuery({
                offset: 0,
                isActive: true,
                ...(medicalInstitutionsSearchRequest ? {
                    name: medicalInstitutionsSearchRequest,
                } : {}),
            });

            dispatch(MedicalInstitutionsActions.get(params)).then((_) => {
                setLoadingMedicalInstitutions(true);
            });
        }
    }, [dispatch, loadingMedicalInstitutions]);

    useEffect(() => {
        setLoadingMedicalInstitutions(false)
    }, [medicalInstitutionsSearchRequest]);


    const getValueFiles = () => {
        return value.files.map(file => {
            return {
                ...file,
                ...{
                    guid: createGuid(),
                    extension: file.extension ? file.extension : getExtensionFile(file.slideInstanceUid)
                }
            }
        })
    }

    const sendSlide = async (params, formData) => {
        return await dispatch(RadiodiagnosisActions.sendSlide(params, formData, events)).then(
            () => {
                dispatch({
                    type: "ALERT_SUCCESS", payload: {
                        message: 'Слайды отправлены',
                        type: 'success'
                    }
                })
            }
        )
    }

    const onSubmit = (values) => {
        const files = values.files;
        const formData = new FormData();
        let params = {
            percent: 0,
            status: 'waiting',
            guid: createGuid(),
            message: "В ожидании очереди",
            files: values.files,
            anonymize: values.anonymize,
            medicalInstitutionId: values.medicalInstitution.id,
        };

        for (const key in files) {
            formData.append(`files`, files[key].value);
        }
        dispatch({
            type: "UPLOAD_MANAGER_ADD_FILES", payload: {
                ...params,
                ...{
                    upload: sendSlide,
                    uploadParams: [params, formData],
                }
            },
        });
      
        setDisabledAfterSubmit(true);
    }

    return account?.id ? (
        <Grid>
            <Formik
                initialValues={{
                    medicalInstitution: null,
                    anonymize: false,
                    labAssistant: account?.employee.id,
                    files: (value && value.files.length) ? getValueFiles() : []
                }}
                validationSchema={validationSchema}
                validateOnMount
                onSubmit={onSubmit}
            >
                {({
                      errors,
                      values,
                      setFieldValue,
                      handleSubmit,
                  }) => {
                    let {anonymize, medicalInstitution, files} = values
                    useEffect(() => {
                        setDisabledAfterSubmit(false)
                    }, [anonymize, medicalInstitution?.id, files])
                    return (
                        <Form>
                            <Grid className={classes.container}>
                                <UploadButton
                                    files={values.files}
                                    handleSubmit={handleSubmit}
                                    setFieldValue={setFieldValue}
                                />
                                <Grid>
                                    <CustomAutocomplete
                                        required
                                        label="Организация"
                                        id="medicalInstitution"
                                        className={classes.autocomplete}
                                        options={medicalInstitutions.data}
                                        loading={!loadingMedicalInstitutions}
                                        helperText={errors.medicalInstitution}
                                        error={Boolean(errors.medicalInstitution)}
                                        getOptionLabel={(option) => option.name}
                                        inputChange={medicalInstitutionsInputChange}
                                        onChange={(e, value) =>
                                            setFieldValue('medicalInstitution', value)
                                        }
                                        value={values.medicalInstitution}
                                        renderOption={(props, option) => {
                                            return <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        }}
                                    />
                                </Grid>
                                <Grid>
                                    <FormControl sx={{width: 380}}>
                                        <InputLabel id="labAssistant-label" className={classes.label}>
                                            Лаборант
                                        </InputLabel>
                                        <Select
                                            disabled
                                            sx={{height: 35}}
                                            variant="standard"
                                            label="Лаборант"
                                            id="labAssistant"
                                            labelId="labAssistant-label"
                                            className={classes.select}
                                            value={getShortName(account?.employee)}
                                            disableUnderline
                                        >
                                            <MenuItem value={getShortName(account?.employee)}>
                                                {getShortName(account?.employee)}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <label className={classes.checkbox}>
                                    <Checkbox
                                        name={`anonymize`}
                                        icon={<NotSelected/>}
                                        checkedIcon={<Selected/>}
                                        value={values.anonymize}
                                        className={classes.checkbox}
                                        onChange={(e) =>
                                            setFieldValue('anonymize', e.target.checked)
                                        }
                                    />
                                    Анонимизация
                                </label>
                            </Grid>
                            <Grid
                                container
                                wrap="nowrap"
                                direction="column"
                            >
                                <FilesTable
                                    errors={errors}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                            </Grid>
                            <Grid className={classes.saveButtons}>
                                <SaveButtons
                                    isDisabledAfterSubmit={isDisabledAfterSubmit}
                                    values={values}
                                    errors={errors}
                                    onSubmitSaveStudy={handleSubmit}
                                />
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </Grid>
    ) : null
};
