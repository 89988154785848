import React from "react";

function StepDone() {
    return (
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.74986 8.02498L0.734863 4.00998L1.26486 3.47998L4.74986 6.95998L10.7349 0.97998L11.2649 1.50498L4.74986 8.02498Z" fill="white"/>
        </svg>
    );
}

export default StepDone;
