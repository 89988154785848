import React from "react"
import {AddAndEdit as MedicalInstitutionAddAndEdit} from "./components/MedicalInstitution/AddAndEdit";
import {AddAndEdit as ScannerResolutionsAddAndEdit} from "./components/ScannerResolutions/AddAndEdit";
import {AddAndEdit as BiomaterialSourceAddAndEdit} from "./components/BiomaterialSource/AddAndEdit";
import {AddAndEdit as EmployeePositionAddAndEdit} from "./components/EmployeePosition/AddAndEdit";
import {AddAndEdit as EmployeeAddAndEdit} from "./components/Employees/AddAndEdit";
import {AddAndEdit as ScannerAddAndEdit} from "./components/Scanners/AddAndEdit";
import {AddAndEdit as Icd10AddAndEdit} from "./components/Icd10/AddAndEdit";
import {AddAndEdit as TagAddAndEdit} from "./components/Tag/AddAndEdit";
import {CustomBreadcrumb} from "../App/components/CustomBreadcrumb";
import {ModeEditOutlineOutlined} from "@mui/icons-material";
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {DataTable} from "./components/DataTable";
import {getShortName} from "../App/helpers/row";
import {Box, Grid, Tab} from '@mui/material';
import Add from "../App/components/Svg/Add";
import {useSelector} from "react-redux";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        height: "100%",
        background: "#F9FAFA",
        padding: "24px 0px 0px 24px;"
    },
    icon: {
        height: "16px",
        width: "16px",
        display: "block",
        marginTop: "2px",
        marginRight: "5px"
    },
    tab_list: {
        marginTop: "12px",
        backgroundColor: "white",
        borderBottom: "1px solid #DBDEDF",
        color: "#495055",
        height: "60px",
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#2595FF',
            borderRadius: "4px 4px 0px 0px",
            height: "4px"
        },
    },
    tab: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        textTransform: "none",
        '&.Mui-selected': {
            color: '#2595FF',
        },
    },
    tab_panel: {
        padding: 0
    }
}))

export const Data = (props) => {
    const classes = useStyles()
    const {tag} = useSelector(state => state.tags);
    const {scanner} = useSelector(state => state.scanners);
    const {employee} = useSelector(state => state.employees);
    const {code: icd10_code} = useSelector(state => state.icd10Table);
    const {employeePosition} = useSelector(state => state.employeePositions);
    const {biomaterialSource} = useSelector(state => state.biomaterialSources);
    const {scannerResolution} = useSelector(state => state.scannerResolutions);
    const {medicalInstitution} = useSelector(state => state.medicalInstitutions);
    const {
        page,
        value,
        loading,
        setPage,
        moduleId,
        setValue,
        setLoading,
        clearState,
    } = props

    const handleChange = (event, newValue) => {
        if (newValue !== '3') {
            clearState()
        }
        if (newValue === '1'){
            setLoading(false)
        }
        setValue(newValue);
    };

    const cancelAddButton = () => {
        return (moduleId !== 'doctors') ? setValue("3") : null
    }

    const getEditTabTitle = () => {
        if (scanner) {
            return scanner.code
        }
        if (medicalInstitution) {
            return medicalInstitution.name
        }
        if (employeePosition) {
            return employeePosition.name
        }
        if (employee) {
            return getShortName(employee)
        }
        if (biomaterialSource) {
            return biomaterialSource.name
        }
        if (scannerResolution) {
            return scannerResolution.value
        }
        if (icd10_code) {
            return icd10_code.code
        }
        if (tag) {
            return tag.name
        }
        return "Редактирование"
    }

    const getTabAddAndEdit = () => {
        let CustomAddAndEdit
        switch (moduleId) {
            case 'scanners':
                CustomAddAndEdit = ScannerAddAndEdit
                break
            case 'medicalInstitutions':
                CustomAddAndEdit = MedicalInstitutionAddAndEdit
                break
            case 'employees':
                CustomAddAndEdit = EmployeeAddAndEdit
                break
            case 'employee_positions':
                CustomAddAndEdit = EmployeePositionAddAndEdit
                break
            case 'scanner_resolutions':
                CustomAddAndEdit = ScannerResolutionsAddAndEdit
                break
            case 'icd10':
                CustomAddAndEdit = Icd10AddAndEdit
                break
            case 'tags':
                CustomAddAndEdit = TagAddAndEdit
                break
            default:
                CustomAddAndEdit = BiomaterialSourceAddAndEdit
        }
        return <CustomAddAndEdit
            setLoading={setLoading}
            setValue={setValue}
        />
    }

    const isActiveEditTab = () => {
        return Boolean(scanner || medicalInstitution || employeePosition || employee || biomaterialSource || scannerResolution || icd10_code || tag)
    }

    return (
        <Grid className={classes.container}>
            <CustomBreadcrumb
                partitionId={1}
                onClickPath={cancelAddButton}
            />
            <Grid style={{width: "98%"}}>
                <TabContext value={value}>
                    <Box>
                        <TabList
                            className={classes.tab_list}
                            onChange={handleChange}
                            aria-label="Пункт меню"
                        >
                            <Tab
                                className={classes.tab}
                                label="Данные"
                                value="1"
                            />
                            {moduleId !== 'doctors' &&
                            <Tab
                                className={classes.tab}
                                label="Добавить"
                                icon={<Add className={classes.icon}/>}
                                iconPosition="start"
                                value="2"
                            />}
                            {isActiveEditTab() && <Tab
                                className={classes.tab}
                                label={getEditTabTitle()}
                                icon={<ModeEditOutlineOutlined className={classes.icon}/>}
                                iconPosition="start"
                                value="3"
                            />}
                        </TabList>
                    </Box>
                    <TabPanel className={classes.tab_panel} value="1">
                        <DataTable
                            page={page}
                            setPage={setPage}
                            loading={loading}
                            moduleId={moduleId}
                            setValue={setValue}
                            setLoading={setLoading}
                        />
                    </TabPanel>
                    <TabPanel className={classes.tab_panel} value="2">
                        {getTabAddAndEdit()}
                    </TabPanel>
                    <TabPanel className={classes.tab_panel} value="3">
                        {getTabAddAndEdit()}
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    )
}
