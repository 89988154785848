/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useDebounce} from "use-debounce";

import {CustomAutocomplete} from "../../../App/components/Input/CustomAutocomplete";
import {TextFieldWithError} from "../../../App/components/Input/TextField";

import {MedicalInstitutionsActions} from "../../actions/medicalInstitution";
import {EmployeePositionActions} from "../../actions/employeePositions";
import {EmployeesActions} from "../../actions/employees";

import {getTargetValue, textFieldProps} from "../../../App/helpers/textField";
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {saveObject} from "../../../App/helpers/saveObject";

import {getUserType, userTypes} from '../../helpers/user';
import {EditOrSave} from "../Buttons/EditOrSave";
import {Field, Form, Formik} from 'formik';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    form: {
        background: "white",
        padding: "10px",
        minHeight: "730px",
    },
    text_field: {
        marginLeft: "5px",
        width: "250px",
        fontSize: "14px",
        '& .MuiInputLabel-root': {
            marginLeft: "5px",
            marginTop: "2px"
        },
        '& .MuiInput-root': {
            paddingLeft: "5px",
            borderRadius: "2px",
            border: "1px solid #DBDEDF",
        },
    },
    autocomplete: {
        marginLeft: "5px",
        width: "250px",
    }
}))

export const AddAndEdit = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {employee} = useSelector(state => state.employees);
    const {medicalInstitutions} = useSelector(state => state.medicalInstitutions);
    const {employeePositions} = useSelector(state => state.employeePositions);

    const [loadingMedicalInstitutions, setLoadingMedicalInstitutions] = useState(false)
    const [medicalInstitutionsSearch, setMedicalInstitutionsSearch] = useState(null)
    const [medicalInstitutionsSearchRequest] = useDebounce(medicalInstitutionsSearch, 400)

    const [loadingEmployeePositions, setLoadingEmployeePositions] = useState(false)
    const [employeePositionsSearch, setEmployeePositionsSearch] = useState(null)
    const [employeePositionsSearchRequest] = useDebounce(employeePositionsSearch, 400)

    const {setValue, setLoading} = props

    useEffect(() => {
        if (!loadingMedicalInstitutions) {
            let params = httpBuildQuery({
                offset: 0,
                ...(medicalInstitutionsSearchRequest ? {
                    name: medicalInstitutionsSearchRequest
                } : {})
            })

            dispatch(MedicalInstitutionsActions.get(params)).then(_ => {
                setLoadingMedicalInstitutions(true)
            })
        }
    }, [dispatch, loadingMedicalInstitutions]);

    useEffect(() => {
        if (!loadingEmployeePositions) {
            let params = httpBuildQuery({
                offset: 0,
                ...(employeePositionsSearchRequest ? {
                    name: employeePositionsSearchRequest
                } : {})
            })

            dispatch(EmployeePositionActions.get(params)).then(_ => {
                setLoadingEmployeePositions(true)
            })
        }
    }, [dispatch, loadingEmployeePositions]);

    useEffect(() => {
        setLoadingMedicalInstitutions(false)
    }, [medicalInstitutionsSearchRequest]);

    const submit = async (values) => {
        const params = saveObject(values)
        return dispatch(employee ? EmployeesActions.edit(employee.id, params) : EmployeesActions.add(params)).then(
            () => {
                setValue('1')
                setLoading(false)
                dispatch({type: "EMPLOYEE", payload: null})
            }
        )
    }

    const medicalInstitutionsInputChange = (e) => {
        setMedicalInstitutionsSearch(getTargetValue(e))
    }

    const employeePositionsInputChange = (e) => {
        setEmployeePositionsSearch(getTargetValue(e))
    }


    return (
        <Grid>
            <Formik
                initialValues={{
                    email: employee ? employee.email : "",
                    firstName: employee ? employee.firstName : "",
                    lastName: employee ? employee.lastName : "",
                    middleName: employee ? employee.middleName : "",
                    medicalInstitution: employee ? employee.medicalInstitution : "",
                    employeePosition: employee ? employee.employeePosition : "",
                    employeeName: employee ? employee?.user?.name : "",
                    userType: getUserType(employee),
                    isActive: employee ? employee.isActive : true,
                }}
                validationSchema={Yup.object().shape({
                    ...{
                        firstName: Yup.string().required("Введите имя"),
                        lastName: Yup.string().required("Введите фамилию"),
                        employeeName: Yup.mixed().required("Введите пользователя"),
                        medicalInstitution: Yup.mixed().required("Введите организацию"),
                        employeePosition: Yup.mixed().required("Введите должность"),
                        email: Yup.string().email("Некорректный формат электронной почты").required("Введите email"),
                        userType: Yup.mixed().required("Выберите роль"),
                    },
                    ...(employee ?  {} : {password: Yup.string().min(8, "Длина пароля должна быть не менее 8 символов").required("Введите пароль")})
                })}
                onSubmit={submit}
            >
                {({
                    errors,
                    values,
                    handleSubmit,
                    setFieldValue
                }) => (
                    <Form className={classes.form}>
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                            >
                                <Field
                                    className={classes.text_field}
                                    align="center"
                                    type="text"
                                    size="small"
                                    label="Фамилия"
                                    required
                                    variant="standard"
                                    name={`lastName`}
                                    inputProps={textFieldProps}
                                    component={TextFieldWithError}
                                />
                                <Field
                                    className={classes.text_field}
                                    align="center"
                                    type="text"
                                    size="small"
                                    label="Имя"
                                    required
                                    variant="standard"
                                    name={`firstName`}
                                    inputProps={textFieldProps}
                                    component={TextFieldWithError}
                                />
                                <Field
                                    className={classes.text_field}
                                    align="center"
                                    type="text"
                                    size="small"
                                    label="Отчество"
                                    variant="standard"
                                    name={`middleName`}
                                    inputProps={textFieldProps}
                                    component={TextFieldWithError}
                                />
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                <Field
                                    align="center"
                                    type="text"
                                    size="small"
                                    label="E-mail"
                                    variant="standard"
                                    name={`email`}
                                    required
                                    inputProps={textFieldProps}
                                    className={classes.text_field}
                                    component={TextFieldWithError}
                                />
                                <Grid item>
                                    <CustomAutocomplete
                                        required
                                        label="Организация"
                                        id="medicalInstitutionId"
                                        className={classes.autocomplete}
                                        options={medicalInstitutions.data}
                                        loading={!loadingMedicalInstitutions}
                                        getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                                        inputChange={medicalInstitutionsInputChange}
                                        error={Boolean(errors.medicalInstitution)}
                                        helperText={errors.medicalInstitution}
                                        onChange={(e, value) => setFieldValue('medicalInstitution', value)}
                                        value={values.medicalInstitution}
                                    />
                                </Grid>
                                <Grid item>
                                    <CustomAutocomplete
                                        required
                                        options={employeePositions.data}
                                        label="Должность"
                                        id="employeePositionId"
                                        className={classes.autocomplete}
                                        loading={!loadingEmployeePositions}
                                        getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                                        inputChange={employeePositionsInputChange}
                                        error={Boolean(errors.employeePosition)}
                                        helperText={errors.employeePosition}
                                        value={values.employeePosition}
                                        onChange={(e, value) => setFieldValue('employeePosition', value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                <Grid item>
                                    <Field
                                        align="center"
                                        type="text"
                                        required
                                        size="small"
                                        label="Пользователь"
                                        variant="standard"
                                        name={`employeeName`}
                                        onChange={setFieldValue}
                                        inputProps={textFieldProps}
                                        className={classes.text_field}
                                        component={TextFieldWithError}
                                    />
                                </Grid>
                                <Field
                                    align="center"
                                    type="text"
                                    required={!employee}
                                    size="small"
                                    label="Пароль"
                                    variant="standard"
                                    name={`password`}
                                    onChange={setFieldValue}
                                    inputProps={textFieldProps}
                                    className={classes.text_field}
                                    component={TextFieldWithError}
                                />
                            </Grid>
                            <Grid item>
                                <CustomAutocomplete
                                    required
                                    options={userTypes}
                                    label="Роль"
                                    id="userType"
                                    name={'userType'}
                                    className={classes.autocomplete}
                                    getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                                    error={Boolean(errors.userType)}
                                    helperText={errors.userType}
                                    value={values.userType}
                                    onChange={(e, value) => {
                                        setFieldValue('userType', value)
                                    }}
                                />
                            </Grid>
                            <EditOrSave
                                entity={employee}
                                handleSubmit={handleSubmit}
                            />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}

