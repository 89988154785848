import {HttpService} from "../../App/services/http";

export const TagsService = {
    get,
    add,
    edit,
    getTag,
    deleteTag,
}

function get(params) {
    return HttpService.getMethod('/api/tags', `${params}`)
}

function getTag(id) {
    return HttpService.getMethod(`/api/tags/${id}`)
}

function add(values) {
    return HttpService.postMethod('/api/tags', JSON.stringify(values))
}

function edit(id, values) {
    return HttpService.patchMethod(`/api/tags/${id}`, values)
}

function deleteTag(id) {
    return HttpService.deleteMethod(`/api/tags/${id}`)
}
