export default function icd10(state = {
    codes: {
        data: [],
        total_pages: 0
    },
    opens: [],
}, action) {
    switch (action.type) {
        case "ICD10_CODES":
            return {
                ...state,
                ...{
                    codes: {
                        data: action.payload.data.map(code => {
                            code.level = 0
                            return code
                        }),
                        total_pages: action.payload.totalPages
                    }
                }
            }
        case "ICD10_CODE_ADD_CHILDREN":
            return {
                ...state,
                ...{
                    codes: {
                        data: action.payload,
                        total_pages: state.codes.total_pages
                    }
                }
            }
        case "ICD10_CODE_SET_OPENS":
            return {
                ...state,
                ...{
                    opens: action.payload
                }
            }
        default:
            return state
    }
}

