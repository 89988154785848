import React, {useState} from "react"
import {useSelector} from "react-redux";

import {DeleteSweepOutlined, ModeEditOutlineOutlined, FileDownload} from "@mui/icons-material";
import NotSelected from "../../../../App/components/Svg/Checkbox/NotSelected";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Selected from "../../../../App/components/Svg/Checkbox/Selected";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import httpBuildQuery from "../../../../App/helpers/httpBuildQuery";
import {getShortName} from "../../../../App/helpers/row";
import {useNavigate} from "react-router-dom";
import {makeStyles} from '@mui/styles';
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import {
    Typography,
    IconButton,
    TableCell,
    TableRow,
    Checkbox,
    Tooltip,
    Stack,
    Chip,
    Grid,
    Link,
} from "@mui/material";
import clsx from "clsx";


const useStyles = makeStyles(() => ({
    cell: {
        padding: '5px',
        fontSize: '13px',
        lineHeight: '18px',
    },
    series: {
        '&:hover': {
            background: "#F5F6F6"
        }
    },
    slide: {
        height: "67px"
    },
    red_background: {
        background: "#f0808075",
    },
    tooltip_text: {
        fontSize: '13px',
        whiteSpace: 'pre-line'
    },
    slideInstanceUid: {
        color: '#000',
        fontSize: '13px',
        textDecoration: 'underline',
        textDecorationColor: '#fff',
    }
}))

const mdash = `\u2014`;

export const Row = (props) => {
    const {row, type, setShowDialog, isRed, onCheckStudy} = props;

    const classes = useStyles()
    const navigate = useNavigate()
    
    const {account} = useSelector(state => state.account);
    const {studies} = useSelector(state => state.pathomorphology);
    
    const [open, setOpen] = useState(false);

    const allSlidesInStudy = row && row.series && row.series.map(item => item.slides)
    let totalCountSlidesInStudy = 0
    allSlidesInStudy?.forEach(item => {
        totalCountSlidesInStudy += Object.keys(item).length
    })

    const studiesWithoutSeries = studies.filter(study => study.series.length === 0)
    const seriesWithoutSlides = studies.filter(study => study.series.filter(series => series.slides.length === 0).length > 0)

    let studiesObjWithErrors = new Set([
        ...studiesWithoutSeries,
        ...seriesWithoutSlides
    ])

    const studiesArrWithErrors = [...studiesObjWithErrors]
    const downloadableObj = new Set(studiesArrWithErrors)
    const downloadableStudiesArray = studies.filter(e => !downloadableObj.has(e))
    const downloadableStudy = downloadableStudiesArray.filter(study => study.studyInstanceUid === row.studyInstanceUid)

    const onClickOpen = () => {
        setOpen(!open)
    }

    const onClickEditStudy = () => {
        navigate(`/database/pathomorphology/${encodeURIComponent(row.studyInstanceUid)}`)
    }

    const getCheckbox = (row) => {
        return (
            <Checkbox
                name={`checkedStudy`}
                icon={<NotSelected/>}
                checkedIcon={<Selected/>}
                className={classes.checkbox}
                onChange={(e) => onCheckStudy(e, row)}
            />
        )
    }
   
    const getArrow = () => {
        return (
            <TableCell>
                <Stack direction="row" alignItems="center" justifyContent="flex-end">
                {type === 'study' && getCheckbox(row)}
                    {((row.series && row.series.length) || (row.slides && row.slides.length)) ? 
                        <>
                            <IconButton
                                size="small"
                                aria-label="Раскрыть"
                                onClick={onClickOpen}
                            >
                                {["study", "series"].indexOf(type) !== -1 && (open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>)}
                            </IconButton>
                            {type === 'series' && row.studyInstanceUid === downloadableStudy[0]?.studyInstanceUid &&
                                <Link
                                    target="_blank"
                                    underline={"none"}
                                    href={`/api/patho/studies/${row.studyInstanceUid}/series/bulk-download?access_token=${localStorage.getItem('token')}&${httpBuildQuery({
                                        ...{
                                            limit: null,
                                            offset: null,
                                            seriesInstanceUid: row.seriesInstanceUid,
                                        }
                                    })}`}
                                >
                                    <IconButton
                                        size='small'
                                        color='primary'
                                        aria-label='Скачать'
                                    >
                                        <FileDownload/>
                                    </IconButton>
                                </Link>
                            }
                        </> :  
                        <IconButton
                            style={{opacity: "0"}}
                            size="small"
                            disabled
                        >
                            <KeyboardArrowDownIcon/>
                        </IconButton>
                    }
                    {type === 'study' && (row?.finalized === false || (account?.role?.toString().toLowerCase() === 'admin' || account?.role?.toString().toLowerCase() === 'assistant')) &&
                        <IconButton
                            size="small"
                            color="primary"
                            aria-label="Редактировать"
                            onClick={onClickEditStudy}
                        >
                            <ModeEditOutlineOutlined/>
                        </IconButton>
                    }
                    {(type === 'study' && (account?.role?.toString().toLowerCase() === 'admin' || account?.role?.toString().toLowerCase() === 'assistant')) &&
                        <Tooltip title="Удалить">
                            <IconButton
                                color="error"
                                onClick={() => {
                                    setShowDialog({
                                        isShowDialog: true,
                                        id: row.studyInstanceUid,
                                    })
                                }}
                            >
                                <DeleteSweepOutlined/>
                            </IconButton>
                        </Tooltip>
                    }
                    {type === 'study' && (row.studyInstanceUid === downloadableStudy[0]?.studyInstanceUid && !isRed ? (
                        <Link
                            target="_blank"
                            underline={"none"}
                            href={`/api/patho/studies/${row.studyInstanceUid}/series/bulk-download?access_token=${localStorage.getItem('token')}&${httpBuildQuery({
                                ...{
                                    limit: null,
                                    offset: null,
                                }
                            })}`}
                        >
                            <IconButton
                                size='small'
                                color='primary'
                                aria-label='Скачать'
                            >
                                <FileDownload/>
                            </IconButton>
                        </Link>
                        ) : (
                        <IconButton
                            disabled
                            size='small'
                        >
                            <FileDownload/>
                        </IconButton>
                        )
                    )}
                </Stack>
            </TableCell>
        )
    }

    const getTableCells = (data) => {
        return <React.Fragment>
            <TableCell className={classes.cell}>
                {type === 'study' && data?.studyInstanceUid}
            </TableCell>
            <TableCell className={classes.cell}>
                {type === 'series' && (data?.seriesInstanceUid ?? mdash)}
            </TableCell>
            <TableCell className={classes.cell}>
                {type === 'slide' && data.slideInstanceUid &&
                    <>
                        {data.fileType.toUpperCase() !== 'UNDEFINED' &&
                            <Link
                                target="_blank"
                                href={`/api/patho/studies/${data.studyInstanceUid}/series/${data.seriesInstanceUid}/slides/${data.slideInstanceUid}?access_token=${localStorage.getItem('token')}`}
                            >
                                <Typography style={{fontSize: "13px"}}>{data.slideInstanceUid}</Typography>
                            </Link>
                        }
                        {(account?.role?.toString().toLowerCase() === 'admin' || account?.role?.toString().toLowerCase() === 'assistant') &&
                            <Grid className={classes.slideInstanceUid}>{decodeURIComponent(data?.fileName)}</Grid>
                        }
                    </>
                }
            </TableCell>
            <TableCell className={classes.cell}>
                {type === 'study' && totalCountSlidesInStudy}
                {type === 'series' && data.slides && data.slides?.length}
            </TableCell>
            <TableCell className={classes.cell}>
                {data.studyDate ? data.studyDate : mdash}
            </TableCell>
            <TableCell className={classes.cell}>
                {(["series", "slide"].indexOf(type) !== -1 && data.icd10) ? data.icd10.code : mdash}
            </TableCell>
            <TableCell className={classes.cell}>
                {(["series", "slide"].indexOf(type) !== -1 && data.icdO) ? data.icdO : mdash}
            </TableCell>
            <TableCell className={classes.cell}>
                {data.histologicalDiagnosis ? <Tooltip placement="left-start" arrow title={data.histologicalDiagnosis}>
                    <Typography className={classes.tooltip_text}>
                        {data.histologicalDiagnosis.substr(0, 150)}
                    </Typography>
                </Tooltip> : mdash}
            </TableCell>
            <TableCell className={classes.cell}>
                {type === 'slide' && data.scanner ? data.scanner.code : mdash}
            </TableCell>
            <TableCell className={classes.cell}>
                {type === 'slide' && data.scannerResolution ? data.scannerResolution.value : mdash}
            </TableCell>
            <TableCell className={classes.cell}>
                {data.macroDescription ? (
                    <Tooltip
                        arrow
                        placement="left-start"
                        title={data.macroDescription}
                    >
                        <Typography className={classes.tooltip_text}>
                            {data.macroDescription.substr(0, 150)}
                        </Typography>
                    </Tooltip>
                ) : (
                    mdash
                )}
            </TableCell>
            <TableCell className={classes.cell}>
                {data.microDescription ? (
                    <Tooltip
                        arrow
                        placement="left-start"
                        title={data.microDescription}
                    >
                        <Typography className={classes.tooltip_text}>
                            {data.microDescription.substr(0, 150)}
                        </Typography>
                    </Tooltip>
                ) : (
                    mdash
                )}
            </TableCell>
            <TableCell
                style={{textAlign: 'center'}}
                className={classes.cell}
            >
                {row.medicalInstitution ? (
                    <Tooltip
                        arrow
                        placement="left-start"
                        title={row.medicalInstitution?.name}
                    >
                        <Chip
                            color="primary"
                            variant="outlined"
                            label={row.medicalInstitution?.code}
                        />
                    </Tooltip>
                ) : (
                    mdash
                )}
            </TableCell>
            <TableCell className={classes.cell}>
                {data.createdDate ? format(new Date(data.createdDate), 'PPP', {locale: ru}) : mdash}
            </TableCell>
            <TableCell className={classes.cell}>
                {data.receivedDate ? format(new Date(data.receivedDate), 'PPP', {locale: ru}) : mdash}
            </TableCell>
            <TableCell className={classes.cell}>
                {data.personAccepted ? getShortName(data.personAccepted) : mdash}
            </TableCell>
            <TableCell className={classes.cell}>
                {data.operator ? getShortName(data.operator) : mdash}
            </TableCell>
            <TableCell className={classes.cell}>
                {data?.age ?? mdash}
            </TableCell>
            <TableCell className={classes.cell}>
                {data?.gender ? data?.gender.toUpperCase()[0] : mdash}
            </TableCell>
        </React.Fragment>
    }

    const getClassNameRow = () => {
        switch (type) {
            case 'study':
                return isRed ? classes.red_background : (open ? {background: "#F6F8FF"} : null)
            case 'series':
                return isRed ? clsx(classes.series, classes.red_background) : classes.series
            default:
                return isRed ? clsx(classes.series, classes.red_background) : classes.slide
        }
    }

    return (
        <>
            <TableRow
                hover
                className={getClassNameRow()}
            >
                {getArrow()}
                {getTableCells(row)}
            </TableRow>
            {open && row.series && row.series.map(series => (
                <Row
                    row={series}
                    isRed={isRed}
                    type="series"
                    key={series.id}
                />
            ))}
            {open && row.slides && row.slides.map(slides => (
                <Row
                    type="slide"
                    row={slides}
                    key={slides.id}
                    isRed={slides.fileType.toUpperCase() === 'UNDEFINED'}
                />
            ))}
        </>
    );
}
