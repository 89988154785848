import {HttpService} from "../../App/services/http"

export const EMCService = {
    tricks,
    results,
    recipes,
    surveys,
    patients,
    procedures,
    diagnostics,
    patientStatistic
}

function patients(filter = '') {
    return HttpService.getMethod(`/api/patients`, filter)
}

function tricks(filter = '') {
    return HttpService.getMethod(`/api/appointments`, filter)
}

function results(filter = '') {
    return HttpService.getMethod(`/api/appointment-results`, filter)
}

function recipes(filter = '') {
    return HttpService.getMethod(`/api/prescriptions`, filter)
}

function procedures(filter = '') {
    return HttpService.getMethod(`/api/procedures`, filter)
}

function surveys(filter = '') {
    return HttpService.getMethod(`/api/observations`, filter)
}

function diagnostics(filter = '') {
    return HttpService.getMethod(`/api/diagnostic-reports`, filter)
}

function patientStatistic(hash) {
    return HttpService.getMethod(`/api/patients/${hash}/statistic`)
}


