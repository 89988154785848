import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
    Paper,
    Table,
    Tooltip,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    IconButton,
    TableContainer,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {DeleteSweepOutlined} from '@mui/icons-material'
import {DeleteDicom} from '../../../../../Account/Dialogs/DeleteDicom'
import {RadiodiagnosisActions} from "../../../../actions/radiodiagnosis";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";


const useStyles = makeStyles(() => ({
    paper: {
        boxShadow: "none",
    },
    container: {
        height: "calc(100vh - 400px)",
    },
    cell: {
        fontWeight: 600,
        padding: '12px 16px 12px 8px',
        fontSize: "13px",
        lineHeight: "14px",
    },
    error_icon: {
        color: "#DF3826",
        fontSize: "18px"
    }
}))


const columns = [
    {field: 'studyInstanceUid', name: '№ исследования', width: 100},
    {field: 'name', name: 'Имя файла', width: 300},
    {field: 'actions', name: 'Действия', width: 24},
];

export const defaultDialogState = {
    id: null,
    guid: null,
    isShowDialog: false
}

export const FilesTable = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const fileListRef = useRef(null);
    const {errors, values, setFieldValue} = props

    const {account} = useSelector(state => state.account);

    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(15);
    const [showDialog, setShowDialog] = useState(defaultDialogState);

    const handleScroll = () => {
        const element = fileListRef.current;
        const scrollTop = element.scrollTop;
        const visibleHeight = element.clientHeight;
        const totalHeight = element.scrollHeight;
   
        const isScrolledToBottom = scrollTop + visibleHeight >= totalHeight;

        if (isScrolledToBottom) {
            setEndIndex((prevEndIndex) => prevEndIndex + 15);
        }
    };

    const handleWheel = (event) => {
        const element = fileListRef.current;
        const scrollTop = element.scrollTop;
        const wheelDelta = event.deltaY;

        if (wheelDelta < 0 && scrollTop === 0) {
            setStartIndex((prevStartIndex) => Math.max(prevStartIndex - 15, 0));
        } else if (
            wheelDelta > 0 &&
            scrollTop + element.clientHeight === element.scrollHeight
        ) {
            setEndIndex((prevEndIndex) => prevEndIndex + 15);
        }
    };

    useEffect(() => {
        const element = fileListRef.current;
        element.addEventListener('scroll', handleScroll);
        element.addEventListener('wheel', handleWheel);

        return () => {
            element.removeEventListener('scroll', handleScroll);
            element.removeEventListener('wheel', handleWheel);
        };
    }, []);

    const visibleFiles = values.files.slice(startIndex, endIndex);

    const onClickDeleteFile = (slide) => {
        if (slide?.id) {
            dispatch(RadiodiagnosisActions.deleteFile(slide.studyInstanceUid, slide.seriesInstanceUid, slide.sopInstanceUid)).then(() => {
            })
        } else {
            setFieldValue('files', values.files.filter(file => file.guid !== slide.guid))
        }
        setShowDialog(defaultDialogState)
    }

    return (
        <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    className={classes.cell}
                                    key={column.field}
                                    width={column.width}
                                >
                                    {column.name}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{overflowY: 'auto'}} ref={fileListRef}>
                        {visibleFiles.map((file, index) => (
                            <TableRow key={file.guid}>
                                <TableCell
                                    className={classes.cell}
                                >
                                    {(index + 1)}
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                >
                                    {file.value.name}
                                </TableCell>
                                <TableCell>
                                    {(account?.role?.toString().toLowerCase() === 'admin' || account?.role?.toString().toLowerCase() === 'assistant') ? (
                                        <Tooltip title="Удалить">
                                            <IconButton
                                                color="error"
                                                onClick={() => {
                                                    setShowDialog({
                                                        slide: file,
                                                        isShowDialog: true
                                                    })
                                                }}
                                            >
                                                <DeleteSweepOutlined/>
                                            </IconButton>
                                        </Tooltip>
                                    ) : null}
                                    {errors?.files?.length && errors.files[index] !== undefined && <Tooltip title={Object.values(errors?.files[index]).join(',')}>
                                        <IconButton>
                                            <ErrorOutlinedIcon className={classes.error_icon}/>
                                        </IconButton>
                                    </Tooltip>}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <DeleteDicom
                open={showDialog.isShowDialog}
                closeDialog={() => setShowDialog(defaultDialogState)}
                onClickFileDelete={() => onClickDeleteFile(showDialog.slide)}
            />
        </Paper>
    )
}

