const filter = {
    institutionCode: null,
    studyUploadedStartDate: null,
    studyUploadedEndDate: null,
    studyInstanceUid: null,
    patientHash: null,
    studyDescription: null,
    modality: null,
}

const exitDialog = {
    open: false,
    path: null
}

const initialState = {
    page: 1,
    values: [],
    stats: null,
    value: null,
    nextLink: null,
    filter: filter,
    value_errors: {},
    exitDialog: exitDialog
}

export default function radiodiagnosis(state = initialState, action) {
    switch (action.type) {
        case "RADIODIAGNOSIS_VALUES":
            return {
                ...state,
                ...{
                    stats: action.payload.stats,                 
                    bundle: action.payload.bundle,
                    values: action.payload.bundle.resources,
                }
            }

        case "RADIODIAGNOSIS_VALUE":
            return {
                ...state,
                ...{
                    value: action.payload
                }
            }
        case "RADIODIAGNOSIS_DELETE_VALUE":
            return {
                ...state,
                ...{
                    values: state.values.filter(value => value.studyInstanceUid !== action.payload),
                    next: null,
                }
            }
        case "RADIODIAGNOSIS_FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload,
                    next: null,
                    page: 1
                }
            }
        case "RADIODIAGNOSIS_FILTER_CLEAR":
            return {
                ...state,
                ...{
                    values: [],
                    stats: null,
                    next: null,
                    page: 1,
                    filter: filter
                }
            }
        case "RADIODIAGNOSIS_FILTER_INITIAL":
            return {
                ...state,
                ...{
                    next: null,
                }
            }
        case "RADIODIAGNOSIS_OPEN_EXIT_DIALOG":
            return {
                ...state,
                ...{
                    exitDialog: {
                        ...action.payload,
                        ...{
                            open: true
                        }
                    }
                }
            }
        case "RADIODIAGNOSIS_CLOSE_EXIT_DIALOG":
            return {
                ...state,
                ...{
                    exitDialog: exitDialog
                }
            }
        case "RADIODIAGNOSIS_VALUE_ERRORS":
            return {
                ...state,
                ...{
                    value_errors: action.payload
                }
            }
        case "RADIODIAGNOSIS_CURRENT_PAGE":
            return {
                ...state,
                ...{
                    page: action.payload,
                }
            }
        case "RADIODIAGNOSIS_CLEAR":
            return initialState
        default:
            return state
    }
}
