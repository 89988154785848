/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { MedicalInstitutionsActions } from "../Dictionary/actions/medicalInstitution";
import { EmployeePositionActions } from "../Dictionary/actions/employeePositions";
import { CustomAutocomplete } from "../App/components/Input/CustomAutocomplete";
import { OperationTypesActions } from "../Dictionary/actions/operationTypes";
import { getTargetValue, textFieldProps } from "../App/helpers/textField";
import { TextFieldWithError } from "../App/components/Input/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import httpBuildQuery from "../App/helpers/httpBuildQuery";
import { Submit } from "../App/components/Button/Submit";
import { saveObject } from "../App/helpers/saveObject";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AuditActions } from "./actions/audit";
import { Field, Form, Formik } from "formik";
import { useDebounce } from 'use-debounce';
import { DateTimePicker } from "@mui/lab";
import ruLocale from "date-fns/locale/ru";
import { makeStyles } from '@mui/styles';
import * as Yup from "yup";
import {
    InputLabel,
    Typography,
    TextField,
    Grid,
} from "@mui/material";

const useStyles = makeStyles(() => ({
    container: {
        margin: "15px",
        display: "grid",
        gridTemplateRows: "repeat(6, 70px)",
        alignItems: "start",
    },
    content: {
        height: "100%",
        width: "100%",
        padding: "10px",
        borderRight: "solid 1px rgba(0, 0, 0, 0.1)",
        background: "#f5f5f5"
    },
    title: {
        margin: "15px",
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '25px',
        color: "black",
    },
    autocomplete: {
        marginTop: "5px",
        height: "24px",
        width: "100%",
        background: "white",
        '& .MuiInputBase-input': {
            background: "white",
        }
    },
    label: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
    },
    date_label: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
    },
    datePicker_field: {
        width: "350px",
        background: "white",
        fontSize: '12px',
        lineHeight: '16px',
        '& .MuiInputBase-root': {
            padding: "0 8px",
        },
    },
    submit: {
        marginTop: "25px",
        width: '100%',
        backgroundColor: "#2595FF",
        textTransform: "none",
        fontWeight: 600,
        borderRadius: '2px',
        '&:hover': {
            backgroundColor: "#2595FF",
        }
    },
    text_field: {
        width: "100%",
        fontSize: "14px",
        background: "white",
        '& .MuiInputLabel-root': {
            marginLeft: "5px",
            marginTop: "2px"
        },
        '& .MuiInput-root': {
            paddingLeft: "5px",
            borderRadius: "2px",
            border: "1px solid #DBDEDF",
        },
        '& .MuiInputBase-root': {
            paddingRight: "5px",
        }
    }       
}))

export const Filter = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const operationStatuses = [
        {
            id: 0,
            operationStatus: "Success",
            name: "Успешно"
        },
        {
            id: 1,
            operationStatus: "Failure",
            name: "Не успешно"
        },
    ]


    const {medicalInstitutions} = useSelector(state => state.medicalInstitutions)
    const {employeePositions} = useSelector(state => state.employeePositions);
    const {operationTypes} = useSelector(state => state.operationTypes);

    const [loadingMedicalInstitutions, setLoadingMedicalInstitutions] = useState(false)
    const [medicalInstitutionsSearch, setMedicalInstitutionsSearch] = useState(null)
    const [medicalInstitutionsSearchRequest] = useDebounce(medicalInstitutionsSearch, 400)

    const [loadingEmployeePositions, setLoadingEmployeePositions] = useState(false)
    const [employeePositionsSearch, setEmployeePositionsSearch] = useState(null)
    const [employeePositionsSearchRequest] = useDebounce(employeePositionsSearch, 400)

    const [loadingOperationTypes, setLoadingOperationTypes] = useState(false)
    const [operationTypesSearch, setOperationTypesSearch] = useState(null)
    const [operationTypesSearchRequest] = useDebounce(operationTypesSearch, 400)

    const [operationStatusSearch, setOperationStatusSearch] = useState(null)


    useEffect(() => {
        if (!loadingMedicalInstitutions) {
            const params = httpBuildQuery({
                offset: 0,
                ...(medicalInstitutionsSearchRequest ? {
                    name: medicalInstitutionsSearchRequest
                } : {})
            })
            dispatch(MedicalInstitutionsActions.get(params)).then(_ => {
                setLoadingMedicalInstitutions(true)
            })
        }
    }, [dispatch, loadingMedicalInstitutions]);

    useEffect(() => {
        if (!loadingEmployeePositions) {
            let params = httpBuildQuery({
                offset: 0,
                ...(employeePositionsSearchRequest ? {
                    name: employeePositionsSearchRequest
                } : {})
            })
            dispatch(EmployeePositionActions.get(params)).then(_ => {
                setLoadingEmployeePositions(true)
            })
        }
    }, [dispatch, loadingEmployeePositions]);

    useEffect(() => {
        if (!loadingOperationTypes) {
            let params = httpBuildQuery({
                offset: 0,
                ...(operationTypesSearchRequest ? {
                    description: operationTypesSearchRequest
                } : {})
            })
            dispatch(OperationTypesActions.get(params)).then(_ => {
                setLoadingOperationTypes(true)
            })
        }
    }, [dispatch, loadingOperationTypes]);
   
    useEffect(() => {
        setLoadingMedicalInstitutions(false)
    }, [medicalInstitutionsSearchRequest]);

    useEffect(() => {
        setLoadingEmployeePositions(false)
    }, [employeePositionsSearchRequest]);

    useEffect(() => {
        setLoadingOperationTypes(false)
    }, [operationTypesSearchRequest]);

    const medicalInstitutionsInputChange = (e) => {
        setMedicalInstitutionsSearch(getTargetValue(e))
    }

    const employeePositionsInputChange = (e) => {
        setEmployeePositionsSearch(getTargetValue(e))
    }

    const operationTypesInputChange = (e) => {
        setOperationTypesSearch(getTargetValue(e))
    }

    const operationStatusInputChange = (e) => {
        setOperationStatusSearch(getTargetValue(e))
    }
   
    const submit = (values, {setSubmitting}) => {
        const params = saveObject(values)
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "AUDIT_FILTER", payload: params})
        dispatch(AuditActions.get(httpBuildQuery(params))).then(
            () => dispatch({type: "LOADING", payload: false}),
            () => dispatch({type: "LOADING", payload: false})
        )
        setSubmitting(false)
    }

    return (
        <Grid className={classes.content}>
            <Typography className={classes.title}>Фильтры</Typography>
            <Formik
                initialValues={{
                    medicalInstitutionName: null,
                    employeePositionName: null,
                    operationStatus: null,
                    operationTypeId: null,
                    timestampBegin: null,
                    timestampEnd: null,
                    userName: '',
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={submit}
            >
                {({
                    values,
                    handleSubmit,
                    setFieldValue
                }) => (
                    <Form className={classes.form}>
                        <Grid className={classes.container}>
                            <Grid>
                                <InputLabel className={classes.date_label}>Период от</InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                                    <DateTimePicker
                                        name='timestampBegin'
                                        value={values.timestampBegin}
                                        onChange={e => setFieldValue('timestampBegin', new Date(e).toISOString())}
                                        maxDate={new Date()}
                                        renderInput={(params) => 
                                            <TextField
                                                name="timestamp_begin"
                                                variant="standard"
                                                className={classes.datePicker_field}
                                                {...params}
                                                InputProps={
                                                    {
                                                        ...params.InputProps,
                                                        ...{
                                                            disableUnderline: true,
                                                        }
                                                    }
                                                }
                                                inputProps={
                                                    {
                                                        ...params.inputProps,
                                                        ...{
                                                            placeholder: "дд.мм.гггг чч:мм"
                                                        }
                                                    }
                                                }
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid>
                                <InputLabel className={classes.date_label}>Период до</InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                                    <DateTimePicker
                                    
                                        value={values.timestampEnd}
                                        onChange={e => setFieldValue('timestampEnd', new Date(e).toISOString())}
                                        minDate={values.timestampBegin}
                                        minTime={values.timestampBegin}
                                        maxTime={new Date()}
                                        disableFuture
                                        disableIgnoringDatePartForTimeValidation
                                        renderInput={(params) => 
                                            <TextField
                                                name="timestamp_end"
                                                variant="standard"
                                                className={classes.datePicker_field}
                                                {...params}
                                                InputProps={
                                                    {
                                                        ...params.InputProps,
                                                        ...{
                                                            disableUnderline: true,
                                                        }
                                                    }
                                                }
                                                inputProps={
                                                    {
                                                        ...params.inputProps,
                                                        ...{
                                                            placeholder: "дд.мм.гггг чч:мм"
                                                        }
                                                    }
                                                }
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid>
                                <InputLabel id="user-name-label" className={classes.label}>
                                    ФИО
                                </InputLabel>
                                <Field
                                    align="center"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    name={`userName`}
                                    onChange={setFieldValue}
                                    inputProps={textFieldProps}
                                    className={classes.text_field}
                                    component={TextFieldWithError}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="medical-institutions-label" className={classes.label}>
                                    Организация
                                </InputLabel>
                                <CustomAutocomplete
                                    id="medicalInstitutionName"
                                    className={classes.autocomplete}
                                    options={medicalInstitutions.data}
                                    loading={!loadingMedicalInstitutions}
                                    getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                                    inputChange={medicalInstitutionsInputChange}
                                    onChange={(e, value) => setFieldValue('medicalInstitutionName', value)}
                                    value={values.medicalInstitutionName}
                                    renderOption={(props, option) => {
                                        return <li {...props} key={option.id}>
                                            {option.name}
                                        </li>
                                    }}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="employee-positions-label" className={classes.label}>
                                    Должность
                                </InputLabel>
                                <CustomAutocomplete
                                    id="employeePositionName"
                                    className={classes.autocomplete}
                                    options={employeePositions.data}
                                    loading={!loadingEmployeePositions}
                                    getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                                    inputChange={employeePositionsInputChange}
                                    onChange={(e, value) => setFieldValue('employeePositionName', value)}
                                    value={values.employeePosition}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="operation-type-label" className={classes.label}>
                                    Действие
                                </InputLabel>
                                <CustomAutocomplete
                                    id="operationTypeId"
                                    className={classes.autocomplete}
                                    options={operationTypes.data}
                                    loading={!loadingOperationTypes}
                                    getOptionLabel={option => option.hasOwnProperty('description') ? option.description : option}
                                    inputChange={operationTypesInputChange}
                                    onChange={(e, value) => setFieldValue('operationTypeId', value)}
                                    value={values.operationTypeId}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="operation-status-label" className={classes.label}>
                                    Статус
                                </InputLabel>
                                <CustomAutocomplete
                                    id="operationStatus"
                                    className={classes.autocomplete}
                                    options={operationStatuses}
                                    getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                                    inputChange={operationStatusInputChange}
                                    onChange={(e, value) => setFieldValue('operationStatus', value)}
                                    value={values.operationStatus}
                                />
                            </Grid>
                            <Grid>
                                <Submit
                                    className={classes.submit}
                                    variant="contained"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Найти
                                </Submit>
                            </Grid>
                        </Grid>                        
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
