/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Autocomplete, Chip, Grid, InputLabel, Stack, TextField} from '@mui/material';
import {CustomAutocomplete} from "../../../App/components/Input/CustomAutocomplete";
import { MedicalInstitutionsActions } from '../../actions/medicalInstitution';
import {getTargetValue, textFieldProps} from "../../../App/helpers/textField";
import { ScannerResolutionsActions } from '../../actions/scannerResolutions';
import {TextFieldWithError} from '../../../App/components/Input/TextField';
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {Submit} from "../../../App/components/Button/Submit";
import {saveObject} from "../../../App/helpers/saveObject";
import {ScannersActions} from '../../actions/scanners';
import {useDispatch, useSelector} from "react-redux";
import {Field, Form, Formik} from "formik";
import {useDebounce} from "use-debounce";
import {makeStyles} from "@mui/styles";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    container: {
        display: "grid",
        gridTemplateRows: "repeat(6, 70px)",
        alignItems: "start",
    },
    content: {
        height: "100%",
        width: "100%",
        padding: "10px",
        background: "#f5f5f5"
    },
    title: {
        margin: "15px",
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '25px',
        color: "black",
    },
    autocomplete: {
        marginTop: "5px",
        width: "100%",
        '& .MuiInputBase-input': {
            background: "white",
        },
        '& .MuiChip-root': {
            borderRadius: '12px'
        },
        '& .MuiAutocomplete-tag': {
            margin: "0px"
        }
    },
    label: {
        marginTop: "15px",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
    },
    text_field: {
        marginTop: "5px",
        width: "100%",
        fontSize: "14px",
        background: "white",
        '& .MuiInputLabel-root': {
            marginLeft: "5px",
            marginTop: "2px"
        },
        '& .MuiInput-root': {
            paddingLeft: "5px",
            borderRadius: "2px",
            border: "1px solid #DBDEDF",
        },
        '& input:-webkit-autofill': {
            "-webkit-box-shadow": "0 0 0px 1000px white inset"
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    submit: {
        marginTop: "25px",
        width: '100%',
        backgroundColor: "#2595FF",
        textTransform: "none",
        fontWeight: 600,
        borderRadius: '2px',
        '&:hover': {
            backgroundColor: "#2595FF",
        }
    },
}))

export const Filter = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const {medicalInstitutions} = useSelector(state => state.medicalInstitutions);
    const {scannerResolutions} = useSelector(state => state.scannerResolutions);

    const [loadingMedicalInstitutions, setLoadingMedicalInstitutions] = useState(false)
    const [medicalInstitutionsSearch, setMedicalInstitutionsSearch] = useState(null)
    const [medicalInstitutionsSearchRequest] = useDebounce(medicalInstitutionsSearch, 400)

    const [loadingScannerResolutions, setLoadingScannerResolutions] = useState(false)
    const [scannerResolutionsSearch, setScannerResolutionsSearch] = useState(null)
    const [scannerResolutionsSearchRequest] = useDebounce(scannerResolutionsSearch, 400)

    const [autocompleteValues, setAutocompleteValues] = useState([]);

    const status = [
        {
            id: 0,
            active: true,
            name: "Активный"
        },
        {
            id: 1,
            active: false,
            name: "Не активный"
        }
    ]

    useEffect(() => {
        if (!loadingMedicalInstitutions) {
            const params = httpBuildQuery({
                offset: 0,
                ...(medicalInstitutionsSearchRequest ? {
                    name: medicalInstitutionsSearchRequest
                } : {})
            })

            dispatch(MedicalInstitutionsActions.get(params)).then(_ => {
                setLoadingMedicalInstitutions(true)
            })
        }
    }, [dispatch, loadingMedicalInstitutions]);

    useEffect(() => {
        if (!loadingScannerResolutions) {
            let params = httpBuildQuery({
                offset: 0,
                ...(scannerResolutionsSearchRequest ? {
                    name: scannerResolutionsSearchRequest
                } : {})
            })

            dispatch(ScannerResolutionsActions.get(params)).then(_ => {
                setLoadingScannerResolutions(true)
            })
        }
    }, [dispatch, loadingScannerResolutions]);

    useEffect(() => {
        setLoadingMedicalInstitutions(false)
    }, [medicalInstitutionsSearchRequest]);

    const medicalInstitutionsInputChange = (e) => {
        setMedicalInstitutionsSearch(getTargetValue(e))
    }

    const scannerResolutionsInputChange = (e) => {
        setScannerResolutionsSearch(getTargetValue(e))
    }

    const submit = (values, {setSubmitting}) => {
        
        const scannerResolutionValues = autocompleteValues.map(item => item.value).join(',')
        const searchValues = {...values, scannerResolutionValues}
        const params = saveObject(searchValues)
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SCANNERS_FILTER", payload: params})
        dispatch(ScannersActions.get(httpBuildQuery(params))).then(
            () => dispatch({type: "LOADING", payload: false}),
            () => dispatch({type: "LOADING", payload: false})
        )
        setSubmitting(false)
    }

    return (
        <Grid className={classes.content}>
            <Formik
                initialValues={{
                    fullName: '',
                    code: '',
                    sIndex: '',
                    medicalInstitutionName: '',
                    scannerResolutionValues: '',
                    isActive: null,                
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={submit}
            >
                {({
                    values,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <Form>
                        <Grid className={classes.container}>
                            <Grid>
                                <InputLabel id="scanner-name-label" className={classes.label}>
                                    Название
                                </InputLabel>
                                <Field
                                    align="center"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    name={`fullName`}
                                    inputProps={textFieldProps}
                                    className={classes.text_field}
                                    component={TextFieldWithError}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="medical-institutions-label" className={classes.label}>
                                    Код
                                </InputLabel>
                                <Field
                                    align="center"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    name={`code`}
                                    required
                                    inputProps={textFieldProps}
                                    className={classes.text_field}
                                    component={TextFieldWithError}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="scanner-mi-label" className={classes.label}>
                                    Организация
                                </InputLabel>
                                <CustomAutocomplete
                                    id="medicalInstitution"
                                    className={classes.autocomplete}
                                    options={medicalInstitutions.data}
                                    loading={!loadingMedicalInstitutions}
                                    getOptionLabel={option => option.name}
                                    inputChange={medicalInstitutionsInputChange}
                                    onChange={(e, value) => setFieldValue('medicalInstitutionName', value)}
                                    value={values.medicalInstitution}
                                    renderOption={(props, option) => {
                                        return <li {...props} key={option.id}>
                                            {option.name}
                                        </li>
                                    }}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="scanner-index-label" className={classes.label}>
                                    Порядковый номер
                                </InputLabel>
                                <Field
                                    align="center"
                                    type="number"
                                    size="small"
                                    variant="standard"
                                    name={`sIndex`}
                                    inputProps={textFieldProps}
                                    className={classes.text_field}
                                    component={TextFieldWithError}
                                />
                            </Grid>
                            <Stack direction="column" spacing={1}>
                                <Grid>
                                    <InputLabel id="scanner-resolutions-label" className={classes.label}>
                                        Разрешения
                                    </InputLabel>
                                    <Autocomplete
                                        multiple
                                        id="scannerResolutionValues"
                                        freeSolo
                                        limitTags={2}
                                        disablePortal
                                        loadingText='Загрузка...'
                                        noOptionsText='Ничего не найдено!'
                                        className={classes.autocomplete}
                                        options={scannerResolutions.data}
                                        loading={!loadingScannerResolutions}
                                        getOptionLabel={option => option.hasOwnProperty('value') ? option.value : option}
                                        value={autocompleteValues}
                                        inputChange={scannerResolutionsInputChange}
                                        onChange={(event, newValue) => {
                                            let newQuery = newValue.map((query) => {
                                                if (typeof query === "string") {
                                                return {id: new Date(), value: query};
                                                }
                                                return query;
                                            });
                                            setAutocompleteValues(newQuery);
                                            }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    variant="outlined"
                                                    size="small"
                                                    label={option.value}
                                                    {...getTagProps({ index })}
                                            />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                variant="standard"
                                                className={classes.text_field}
                                                {...{
                                                    ...params,
                                                    ...{InputProps: {...params.InputProps, ...{disableUnderline: true}}}
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid>
                                    <InputLabel id="email-label" className={classes.label}>
                                        Статус
                                    </InputLabel>
                                    <CustomAutocomplete
                                        options={status}
                                        id="isActive"
                                        name={'isActive'}
                                        className={classes.autocomplete}
                                        getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                                        value={values.isActive}
                                        onChange={(e, value) => {
                                            setFieldValue('isActive', value)
                                        }}
                                    />
                                </Grid>
                                <Grid> 
                                    <Submit
                                        className={classes.submit}
                                        variant="contained"
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Найти
                                    </Submit>
                                </Grid>
                            </Stack>
                        </Grid>                        
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}