import {format} from "date-fns"
import {ru} from "date-fns/locale"

const saveObject = (object) => {
    let params = {}
    for (const key in object) {
        if (!!object[key]) {
            switch (key) {
                case 'icd10':
                case 'parent':
                case 'scanner':
                case 'operator':
                case 'personAccepted':
                case 'employeePosition':
                case 'scannerResolution':
                case 'biomaterialSource':
                case 'medicalInstitution':
                    params[`${key}Id`] = object[key].id
                    break
                case 'medicalInstitutionIds':
                    params[key] = object[key].fhirId
                    break
                case 'employeePositionName':
                case 'medicalInstitutionName':
                    params[key] = object[key].name
                    break
                case 'employeeName':
                    params.login = typeof object[key] === 'object' ? object[key].name : object[key]
                    break
                case 'icdO_search':
                case 'icd10_search':
                    params.search = object[key].diagnosis
                    break
                case 'isActive':
                    params.isActive = object[key].active ?? object[key]
                    break
                case 'userType':
                case 'operationTypeId':
                case 'operationStatus':
                    params[key] = object[key].id
                    break
                case 'createRangeDate':
                    if (object[key][0] && object[key][1]) {
                        object[key][1] = new Date(object[key][1].setHours(23))
                        object[key][1] = new Date(object[key][1].setMinutes(59))
                        params.createdDateBegin = format(object[key][0], "yyyy-MM-dd", {locale: ru})
                        params.createdDateEnd = format(object[key][1], "yyyy-MM-dd", {locale: ru})
                    }
                    break
                case 'receiveRangeDate':
                    if (object[key][0] && object[key][1]) {
                        object[key][1] = new Date(object[key][1].setHours(23))
                        object[key][1] = new Date(object[key][1].setMinutes(59))
                        params.receivedDateBegin = format(object[key][0], "yyyy-MM-dd", {locale: ru})
                        params.receivedDateEnd = format(object[key][1], "yyyy-MM-dd", {locale: ru})
                    }
                    break
                case 'studyDate':
                    params.studyDate = format(new Date(object[key].setMonth(0, 1)), "yyyy", {locale: ru})
                    break
                case 'receivedDate':
                    params.receivedDate = new Date(object[key])
                    break
                case 'timestampBegin':
                        params.timestampBegin = object[key]
                    break    
                case 'timestampEnd':
                        params.timestampEnd = object[key]
                    break    
                case 'tags':
                        params.tags = object[key].length > 0 ?  object[key].map(item => item.name) : null
                    break
                default:
                    params[key] = object[key]
            }
        }
    }
    return params
}

export {saveObject};
