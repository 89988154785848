/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { defaultDialogState } from './components/FilesTable/FilesTable';
import {RadioFilePreview} from './components/Preview/RadioFilePreview';
import { DeleteStudy } from '../../../Account/Dialogs/DeleteStudy';
import {RadiodiagnosisActions} from "../../actions/radiodiagnosis";
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {getLink} from '../../helpers/pagination';
import {getSkeleton} from "../../helpers/study";
import {makeStyles} from '@mui/styles';
import {Row} from "./Data/Row";
import {
    TableContainer,
    PaginationItem,
    Pagination,
    Typography,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Table,
    Paper,
    Link,
    Grid
} from '@mui/material';

const useStyles = makeStyles(() => ({
    footer: {
        paddingTop: "30px",
        paddingBottom: "30px",
        margin: "auto",
        width: "max-content"
    },
    stats:{
        padding: "16px"
    },
    stats_title:{
        paddingRight: "4px",
        fontWeight: "600"
    },
    paper: {
        boxShadow: 'none'
    },
    container: {
        height: "calc(100vh - 374px)"
    },
    arrow_cell: {
        width: 20,
    },
    cell: {
        fontWeight: 600,
        padding: '5px',
        lineHeight: '18px',
        fontSize: '13px'
    },
    download_all_button: {
        marginTop: "-10px",
        fontSize: "12px",
        padding: "3px",
        border: "1px solid #2595FF",
        borderRadius: "2px"
    },
    inactive_MO: {
        background: '#00000005',
        '& .MuiTableCell-root': {
            color: "#858181"
        }
    },
}))


const columns = [
    {field: 'delete', name: '', width: 24},
    {field: 'download', name: '', width: 24},
    {field: 'patientId', name: 'ID пациента', width: 100},
    {field: 'studyInstanceUid', name: '№ исследования', width: 100},
    {field: 'modal', name: 'Модальность', width: 100},
    {field: 'medicalInstitution', name: 'МО', width: 100},
    {field: 'seriesNumber', name: 'Кол-во серий', width: 100},
    {field: 'studyInstanceNumber', name: 'Кол-во снимков в исследовании', width: 100},
    {field: 'description', name: 'Описание', width: 100},
    {field: 'accessionNumber', name: '№ поступления', width: 100},
    {field: 'studyId', name: 'ID экзамена', width: 100},
    {field: 'referringPhysicianName', name: 'Лечащий врач', width: 100},
    {field: 'nameOfPhysiciansReadingStudy', name: 'Читающий врач', width: 100},
    {field: 'performingPhysicianName', name: 'Выступающий врач', width: 100},
    {field: 'pacsInfo', name: 'Pacs Info', width: 100},
    {field: 'studyDate', name: 'Дата исследования', width: 100},
    {field: 'uploadDate', name: 'Дата загрузки', width: 100},
    {field: 'operator', name: 'Оператор', width: 100},
];

export const Data = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {values, stats, page, filter, bundle} = useSelector(state => state.radiodiagnosis);

    const nextLink = getLink('nextLink', values, bundle)
    const previousLink = getLink('previousLink', values, bundle)

    const [isLoaded, setLoaded] = useState(false);
    const [initial, setInitial] = useState(false);
    const [showPreview, setShowPreview] = useState(false)
    const [showDialog, setShowDialog] = useState(defaultDialogState);

    const [currentSeriesUid, setCurrentInstanceUid] = useState(null);
    const [currentStudyUid, setCurrentStudyUid] = useState(null);

    const onClickGetCurrentSeriesUid = (currentSeriesUid) => { 
        setCurrentInstanceUid(currentSeriesUid) 
    }

    const onClickGetCurrentStudyUid = (currentStudyUid) => { 
        setCurrentStudyUid(currentStudyUid) 
    }

    const onClickDeleteStudy = (studyInstanceUid) => {
        dispatch(RadiodiagnosisActions.deleteStudy(studyInstanceUid)).then(() => {
            setShowDialog({id: null, isShowDialog: false})
        })
    }

    useEffect(() => {
        setLoaded(false)
    }, [filter]);

    useEffect(() => {
        if (!initial) {
            dispatch({type: "RADIODIAGNOSIS_FILTER_CLEAR"})
            setInitial(true)
        }
    }, [initial]);

    useEffect(() => {
        if (initial && !isLoaded) {
            dispatch(RadiodiagnosisActions.radioValues({...filter, ...{count: 15 }})).then(() => {
                setLoaded(true)
            })
        }
    }, [dispatch, isLoaded, initial, filter]);





    return (
        <Grid>
            <Paper className={classes.paper}>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" className={classes.stats}>
                    {stats &&
                      <Grid item>
                          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                              <Grid item>
                                  <Typography component="span" variant="subtitle2" className={classes.stats_title}>Исследований:</Typography>
                                  <Typography component="span" variant="body2">{stats?.studyCount}</Typography>
                              </Grid>
                              <Grid item>
                                  <Typography component="span" variant="subtitle2" className={classes.stats_title}>Серий:</Typography>
                                  <Typography component="span" variant="body2">{stats?.seriesCount}</Typography>
                              </Grid>
                              <Grid item>
                                  <Typography component="span" variant="subtitle2" className={classes.stats_title}>Слайдов:</Typography>
                                  <Typography component="span" variant="body2">{stats?.instanceCount}</Typography>
                              </Grid>
                          </Grid>
                      </Grid>
                    }
                    {!!stats?.studyCount &&
                        <Link
                            href={`/api/radio/studies/bulk-download?access_token=${localStorage.getItem('token')}&${httpBuildQuery({
                                ...filter,
                                ...{
                                    limit: null,
                                    offset: null,
                                }
                            })}`}
                            target="_blank"
                            underline={"none"}
                        >
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                className={classes.download_all_button}
                            >
                                <DownloadOutlinedIcon/>
                                Скачать все
                            </Grid>
                        </Link>
                    }
                </Grid>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.arrow_cell}/>
                                {columns.map(column => (
                                    <TableCell
                                        className={classes.cell}
                                        key={column.field}
                                        width={column.width}
                                    >
                                        {column.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {isLoaded ? values.map((row, index) => (
                            <TableBody key={index} className={row.medicalInstitution?.isActive ? null : classes.inactive_MO}>
                                <Row 
                                    onClickGetCurrentSeriesUid={onClickGetCurrentSeriesUid}
                                    onClickGetCurrentStudyUid={onClickGetCurrentStudyUid}
                                    key={row.studyInstanceUid}
                                    onClickDeleteStudy={onClickDeleteStudy}
                                    setShowDialog={setShowDialog}
                                    setShowPreview={setShowPreview}
                                    index={index}
                                    row={row}
                                />
                            </TableBody>
                        )) : getSkeleton(9, 18)}
                    </Table>
                </TableContainer>
                <Grid className={classes.footer}>
                    <Pagination
                        page={page}
                        count={page}
                        variant="outlined"
                        renderItem={(item) => {
                            switch (item.type) {
                                case 'next':
                                    return <PaginationItem
                                        {...{
                                            ...item,
                                            ...{
                                                disabled: (!values.length || !nextLink),
                                                onClick: () => {
                                                    dispatch(RadiodiagnosisActions.radioValues(
                                                        nextLink ? Object.assign(nextLink, {...filter, ...{count: 15 }}) : {...filter, ...{count: 15 }}
                                                        ))
                                                    dispatch({type: "RADIODIAGNOSIS_CURRENT_PAGE", payload: page + 1})
                                                }
                                            }
                                        }}
                                    />
                                case 'previous':
                                    return <PaginationItem
                                        {...{
                                            ...item,
                                            ...{
                                                disabled: (!values.length || !previousLink),
                                                onClick: () => {
                                                    dispatch(RadiodiagnosisActions.radioValues(
                                                        previousLink ? Object.assign(previousLink, {...filter, ...{count: 15 }}) : {...filter, ...{count: 15 }}))
                                                    dispatch({type: "RADIODIAGNOSIS_CURRENT_PAGE", payload: page - 1})
                                                }
                                            }
                                        }}
                                    />
                                case 'page':
                                    return <PaginationItem
                                        {...{
                                            ...item,
                                            ...{
                                                disabled: ((item.page !== 1) && (item.page !== page)),
                                                onClick: () => {
                                                    if (item.page === 1) {
                                                        dispatch({type: "RADIODIAGNOSIS_FILTER_CLEAR"})
                                                        setLoaded(false)
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                default:
                                    return <PaginationItem
                                        {...item}
                                    />
                            }
                        }}
                    />
                </Grid>
            </Paper>
            {showDialog.isShowDialog && <DeleteStudy
                open={showDialog.isShowDialog}
                closeDialog={() => setShowDialog({id: null, isShow: false})}
                onClickStudyDelete={() => {
                    onClickDeleteStudy(showDialog.id)    
                }}
            />}
            {showPreview && 
                <RadioFilePreview
                    currentSeriesUid={currentSeriesUid}
                    currentStudyUid={currentStudyUid}
                    onClosePreview={() => setShowPreview(false)}
                    key={currentStudyUid}
                />
            }     
        </Grid>
    )
}

