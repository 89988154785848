import {HttpService} from "../../App/services/http"


const ATLAS_URL = process.env.REACT_APP_ATLAS_HOST

export const GeneticService = {
    studies,
    getStudy,
    finalize,
    getResource,
    editStudies,
    sendStudies,
    sendResource,
    requestReport,
    deleteResource,
    downloadResource,
}

function studies(filter = '') {
    return HttpService.getMethod(`/api/gen/studies`, filter)
}

function getStudy(id) {
    return HttpService.getMethod(`/api/gen/studies/${id}`)
}

function downloadResource(id) {
    return HttpService.getMethod(`/api/gen/slides/${id}`, {}, true, "blob")
}

function getResource(name) {
    return HttpService.getMethod(`/api/gen/slides`, `fileName=${name}`)
}

function sendStudies(values) {
    return HttpService.postMethod(`/api/gen/studies`, JSON.stringify(values))
}

function sendResource(file, values, events) {
    return HttpService.postMethod(`/api/gen/studies/${file.studyInstanceUid}/slides?resourceType=${file.resource_type}`, values, true, events, file.guid)
}

function finalize(studyInstanceUid) {
    return HttpService.postMethod(`/api/gen/studies/${studyInstanceUid}/finalize`)
}

function editStudies(id, values) {
    return HttpService.patchMethod(`/api/gen/studies/${id}`, JSON.stringify(values))
}

function deleteResource(studyInstanceUid, slideInstanceUid) {
    return HttpService.deleteMethod(`/api/gen/studies/${studyInstanceUid}/slides/${slideInstanceUid}`)
}

function requestReport(studyInstanceUid, values) {
    return HttpService.postMethod(`${ATLAS_URL}/api/integrations/atlas/studies/${studyInstanceUid}/request-report`, JSON.stringify(values))
}
