import {Icd10Service} from '../services/Icd10'

export const Icd10TableActions = {
    edit,
    get,
    getAll,
    getRoot,
    deleteIcd10,
    getChildren,
}

function getRoot(values) {
    return dispatch => new Promise((resolve, reject) => {
        Icd10Service.getRoot(values)
            .then(
                response => {
                    dispatch({type: "ICD10_TABLE_CODES", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getAll(values) {
    return dispatch => new Promise((resolve, reject) => {
        Icd10Service.getAll(values)
            .then(
                response => {
                    dispatch({type: "ICD10_TABLE_CODES", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function get(id){
    return dispatch => new Promise((resolve, reject) => {
        Icd10Service.get(id)
            .then(
                response => {
                    dispatch({type: "ICD10_TABLE_CODE", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getChildren(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        Icd10Service.getChildren(id, '')
            .then(
                response => {
                    dispatch({type: "ICD10_TABLE_CODE_ADD_CHILDREN", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function edit(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        Icd10Service.edit(id, values)
            .then(
                _ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function deleteIcd10(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        Icd10Service.deleteIcd10(id)
            .then(
                _ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}
