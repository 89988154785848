import {ScannersService} from '../services/scanners'

export const ScannersActions = {
    add,
    edit,
    get,
    getScanner,
    deleteScanner
}

function get(values) {

    return dispatch => new Promise((resolve, reject) => {
        ScannersService.get(values)
            .then(
                response => {
                    dispatch({type: "SCANNERS", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getScanner(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        ScannersService.getScanner(id)
            .then(
                response => {
                    dispatch({type: "SCANNER", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function edit(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        ScannersService.edit(id, values)
            .then(
                _ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function deleteScanner(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        ScannersService.deleteScanner(id)
            .then(
                _ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        ScannersService.add(values)
            .then(
                _ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}