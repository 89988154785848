/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux"

import {Grid, IconButton, InputAdornment, Typography} from '@mui/material';
import {TextFieldWithError} from "../App/components/Input/TextField";
import {Submit} from "../App/components/Button/Submit.jsx";
import {makeStyles} from '@mui/styles';
import {AuthActions} from "./actions/authentication";
import Lock from "../App/components/Svg/Lock.jsx";
import User from "../App/components/Svg/User.jsx";
import Eye from "../App/components/Svg/Eye.jsx";
import {Field, Form, Formik} from 'formik';
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    container: {
        "background-color": 'white',
        "min-height": '100vh',
        "height": 'auto'
    },
    form: {
        "width": '328px',
        "height": '318px',
        "margin-left": 'auto',
        "margin-right": 'auto',
        "box-shadow": '0px 0px 0px 1px rgba(219, 222, 223, 0.44)',
        "border-radius": '2px'
    },
    title_box: {
        "padding-left": '24px',
        "height": '44px',
        "box-shadow": 'inset 0px -1px 0px #DBDEDF',
    },
    input_group: {
        "width": '280px',
        "margin-left": 'auto',
        "margin-right": 'auto',
    },
}))

const styles = {
    title: {
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '44px',
    },
    submit: {
        marginTop: '80px',
        width: '100%',
        backgroundColor: "#2595FF",
        textTransform: "none",
        fontWeight: 600,
        borderRadius: '2px'
    },
    input: {
        width: "100%",
        height: '50px',
        marginTop: '19px'
    }
}

export const Login = () => {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const classes = useStyles()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const type = searchParams.get("type")
    useEffect(() => {
        if (type === 'logout') {
            dispatch({
                type: "ALERT_ERROR", payload: {
                    message: 'Требуется авторизация',
                    type: 'error'
                }
            })
        }
    }, [type])

    const [textFieldPassType, setTextFieldPassType] = useState('password')

    const loginProps = {
        startAdornment: (
            <InputAdornment position="start">
                <User/>
            </InputAdornment>
        ),
    }
    const passwordProps = {
        startAdornment: (
            <InputAdornment position="start">
                <Lock/>
            </InputAdornment>
        ),
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    component="span"
                    onMouseDown={() => {
                        setTextFieldPassType('text')
                    }}
                    onMouseUp={() => {
                        setTextFieldPassType('password')
                    }}
                >
                    <Eye/>
                </IconButton>
            </InputAdornment>
        ),
    }

    const submit = (values, {setSubmitting}) => {
        setLoading(true)
        return dispatch(AuthActions.login(values)).then(
            () => {
                setLoading(false)
                setSubmitting(false)
                navigate('/')
            },
            () => {
                setLoading(false)
                setSubmitting(false);
            }
        )

    }

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.container}>
            <Formik
                initialValues={{
                    username: "",
                    password: ""
                }}
                validationSchema={Yup.object().shape({
                    username: Yup.string()
                        .required("Введите имя пользователя"),
                    password: Yup.string()
                        .required("Введите пароль"),
                })}
                onSubmit={submit}
            >
                {({
                      handleSubmit
                  }) => (
                    <Form className={classes.form}>
                        <Grid className={classes.title_box}>
                            <Typography sx={styles.title}>Авторизация</Typography>
                        </Grid>
                        <Grid className={classes.input_group}>
                            <Field
                                align="center"
                                sx={styles.input}
                                type="text"
                                name={`username`}
                                size="small"
                                placeholder="Пользователь"
                                inputProps={loginProps}
                                component={TextFieldWithError}
                            />
                            <Field
                                align="center"
                                sx={styles.input}
                                type={textFieldPassType}
                                name={`password`}
                                size="small"
                                placeholder="Пароль"
                                inputProps={passwordProps}
                                component={TextFieldWithError}
                            />
                            <Submit
                                loading={loading}
                                sx={styles.submit}
                                variant="contained"
                                onClick={handleSubmit}
                                type="submit"
                            >
                                Войти
                            </Submit>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}