import React from "react";

function File(props) {
    const {type} = props
    return (
        <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M46 47.5H2C1.17157 47.5 0.5 46.8284 0.5 46V2C0.5 1.17157 1.17157 0.5 2 0.5H35.1716C35.5694 0.5 35.9509 0.658035 36.2322 0.939339L47.0607 11.7678C47.342 12.0491 47.5 12.4306 47.5 12.8284V46C47.5 46.8284 46.8284 47.5 46 47.5Z"
                fill={type === 'error' ? "#FFF2F4" : "#F1F9FF"} stroke={type === 'error' ? "#DF3826" : "#2595FF"}/>
            <path d="M46.7929 11.5H38C37.1716 11.5 36.5 10.8284 36.5 10L36.5 1.20711L46.7929 11.5Z" fill={type === 'error' ? "#F7B2B0" : "#F1F9FF"}
                  stroke={type === 'error' ? "#DF3826" : "#2595FF"}/>
        </svg>
    );
}

export default File;
