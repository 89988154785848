/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {useDispatch} from "react-redux";
import NotSelected from "../../../App/components/Svg/Checkbox/NotSelected";
import Selected from "../../../App/components/Svg/Checkbox/Selected";
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {Submit} from "../../../App/components/Button/Submit";
import {Icd10TableActions} from "../../actions/icd10_table";
import {Icd10} from "../../../App/components/Input/Icd10";
import {Checkbox, Grid, InputLabel} from '@mui/material';
import Stack from "@mui/material/Stack";
import {makeStyles} from "@mui/styles";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {saveObject} from "../../../App/helpers/saveObject";

const useStyles = makeStyles(() => ({
    container: {
        display: "grid",
        gridTemplateRows: "repeat(6, 70px)",
        alignItems: "start",
    },
    content: {
        height: "100%",
        width: "100%",
        padding: "10px",
        background: "#f5f5f5"
    },
    autocomplete: {
        marginTop: "5px",
        height: "24px",
        width: "100%",
        background: "white",
        '& .MuiInputBase-input': {
            background: "white",
        }
    },
    label: {
        marginTop: "15px",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
    },
    checkbox_stack: {
        marginTop: "20px"
    },
    checkbox: {
        padding: 0
    },
    checkbox_label: {
        fontSize: "14px",
    },
    submit: {
        marginTop: "25px",
        width: '100%',
        backgroundColor: "#2595FF",
        textTransform: "none",
        fontWeight: 600,
        borderRadius: '2px',
        '&:hover': {
            backgroundColor: "#2595FF",
        }
    },
}))

export const Filter = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {setPage} = props

    const submit = (values, {setSubmitting}) => {
        setPage(1)
        const useRoot = !(values.isActive !== null || values.icd10_search)
        const params = {
            ...saveObject(values),
            ...{
                isActive: values.isActive !== null ? false : null,
            }
        }
        dispatch({type: "ICD10_TABLE_FILTER", payload: params})
        dispatch(useRoot ? Icd10TableActions.getRoot(httpBuildQuery(params)) : Icd10TableActions.getAll(httpBuildQuery(params))).then(
            () => {
                setSubmitting(false)
            },
            () => {
                setSubmitting(false)
            }
        )
    }

    return (
        <Grid className={classes.content}>
            <Formik
                enableReinitialize
                initialValues={{
                    isActive: null,
                    icd10_search: null,
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={submit}
            >
                {({
                      values,
                      handleSubmit,
                      setFieldValue,
                  }) => (
                    <Form>
                        <Grid className={classes.container}>
                            <Grid>
                                <InputLabel id="icd10Id-label" className={classes.label}>
                                    МКБ-10
                                </InputLabel>
                                <Icd10
                                    writeValue={`icd10_search`}
                                    readValue={values.icd10_search}
                                    className={classes.autocomplete}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                                <Stack
                                    className={classes.checkbox_stack}
                                    direction="row"
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                >
                                    <Checkbox
                                        className={classes.checkbox}
                                        name={`isActive`}
                                        icon={values.isActive ? <Selected/> : <NotSelected/>}
                                        checkedIcon={<Selected/>}
                                        onChange={e => setFieldValue(e.target.name, e.target.checked ? true : null)}
                                    />
                                    <InputLabel className={classes.checkbox_label}>
                                        Только неактивные
                                    </InputLabel>
                                </Stack>
                                <Submit
                                    className={classes.submit}
                                    variant="contained"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Найти
                                </Submit>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}