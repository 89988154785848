import {HttpService} from "../../App/services/http"
import httpBuildQuery from "../../App/helpers/httpBuildQuery";

export const PathomorphologyService = {
    studies,
    addStudy,
    getStudy,
    sendSlide,
    editStudy,
    addSeries,
    editSlide,
    getSeries,
    editSeries,
    deleteStudy,
    deleteSlide,
    refreshFile,
    deleteSeries,
    finalizeStudy,
    getRemoteFiles,
    sendStudiesTags,
    excelImportParse,
    excelImportUpload,
    clearRemoteFilesProgress
}

function studies(filter) {
    return HttpService.getMethod('/api/patho/studies', filter)
}

function getStudy(id) {
    return HttpService.getMethod(`/api/patho/studies/${encodeURIComponent(id)}`)
}
function getRemoteFiles(filter) {
    return HttpService.getMethod(`/api/progress`, filter)
}
function clearRemoteFilesProgress() {
    return HttpService.deleteMethod(`/api/progress`)
}

function getSeries(studyInstanceUid, seriesInstanceUid) {
    return HttpService.getMethod(`/api/patho/studies/${studyInstanceUid}/series/${seriesInstanceUid}`)
}

function addStudy(values) {
    return HttpService.postMethod('/api/patho/studies', values)
}

function addSeries(studyInstanceUid, values) {
    return HttpService.postMethod(`/api/patho/studies/${encodeURIComponent(studyInstanceUid)}/series`, values)
}

function sendSlide(params, values, events) {
    let url = `/api/patho/studies/${encodeURIComponent(params.studyInstanceUid)}/series/${encodeURIComponent(params.seriesInstanceUid)}/slides?${httpBuildQuery(params)}`;

    return HttpService.postMethod(url, values, true, events, params.guid)
}

function excelImportParse(params, values) {
    return HttpService.postMethod(`/api/patho/import/parse?${params}`, values)
}

function excelImportUpload(values) {
    return HttpService.postMethod('/api/patho/import/upload', values)
}

function finalizeStudy(studyInstanceUid) {
    return HttpService.postMethod(`/api/patho/studies/${encodeURIComponent(studyInstanceUid)}/finalize`)
}

function sendStudiesTags(values) {
    return HttpService.postMethod(`/api/patho/studies/tags`, JSON.stringify(values))
}

function editStudy(studyInstanceUid, values) {
    return HttpService.patchMethod(`/api/patho/studies/${encodeURIComponent(studyInstanceUid)}`, values)
}

function editSeries(studyInstanceUid, seriesInstanceUid, values) {
    return HttpService.patchMethod(`/api/patho/studies/${encodeURIComponent(studyInstanceUid)}/series/${encodeURIComponent(seriesInstanceUid)}`, values)
}

function editSlide(studyInstanceUid, seriesInstanceUid, slideInstanceUid, values) {
    return HttpService.patchMethod( `/api/patho/studies/${encodeURIComponent(studyInstanceUid)}/series/${encodeURIComponent(seriesInstanceUid)}/slides/${slideInstanceUid}`, values)
}

function refreshFile(params, values, events) {
    return HttpService.patchMethod(`/api/patho/studies/${params.studyInstanceUid}/series/${params.seriesInstanceUid}/slides/${params.slideInstanceUid}/file`, values, true, events);
 }
 
function deleteStudy(studyInstanceUid) {
    return HttpService.deleteMethod(`/api/patho/studies/${encodeURIComponent(studyInstanceUid)}`)
}

function deleteSeries(studyInstanceUid, seriesInstanceUid) {
    return HttpService.deleteMethod(`/api/patho/studies/${encodeURIComponent(studyInstanceUid)}/series/${encodeURIComponent(seriesInstanceUid)}`)
}

function deleteSlide(studyInstanceUid, seriesInstanceUid, slideInstanceUid) {
    return HttpService.deleteMethod(`/api/patho/studies/${encodeURIComponent(studyInstanceUid)}/series/${encodeURIComponent(seriesInstanceUid)}/slides/${slideInstanceUid}`)
}
