import React, {useEffect, useState} from 'react'
import {CustomAutocomplete} from "../../../App/components/Input/CustomAutocomplete";
import {getTargetValue, textFieldProps} from "../../../App/helpers/textField";
import {MedicalInstitutionsActions} from "../../actions/medicalInstitution";
import {TextFieldWithError} from "../../../App/components/Input/TextField";
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {ScannersActions} from "../../actions/scanners";
import {useDispatch, useSelector} from "react-redux";
import {EditOrSave} from "../Buttons/EditOrSave";
import {Field, Form, Formik} from 'formik';
import {useDebounce} from "use-debounce";
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    form: {
        background: "white",
        padding: "10px",
        minHeight: "730px",
    },
    text_field: {
        padding: "2px",
        width: "350px",
        fontSize: "14px",
        '& .MuiInputLabel-root': {
            marginLeft: "5px",
            marginTop: "2px"
        },
        '& .MuiInput-root': {
            paddingLeft: "5px",
            borderRadius: "2px",
            border: "1px solid #DBDEDF",
        },
    },
    autocomplete:{
        width: "350px",
    }
}))

export const AddAndEdit = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {scanner} = useSelector(state => state.scanners);
    const {medicalInstitutions} = useSelector(state => state.medicalInstitutions);
    const [loadingMedicalInstitutions, setLoadingMedicalInstitutions] = useState(false)
    const [medicalInstitutionsSearch, setMedicalInstitutionsSearch] = useState(null)
    const [medicalInstitutionsSearchRequest] = useDebounce(medicalInstitutionsSearch, 400)
    const {setValue, setLoading} = props


    useEffect(() => {
        if (!loadingMedicalInstitutions) {
            let params = httpBuildQuery({
                offset: 0,
                ...(medicalInstitutionsSearchRequest ? {
                    name: medicalInstitutionsSearchRequest
                } : {})
            })

            dispatch(MedicalInstitutionsActions.get(params)).then(_ => {
                setLoadingMedicalInstitutions(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loadingMedicalInstitutions]);

    useEffect(() => {
        setLoadingMedicalInstitutions(false)
    }, [medicalInstitutionsSearchRequest]);

    const submit = (values, {setSubmitting, setErrors}) => {
        let params = {}
        for (const key in values) {
            if(values[key]){
                switch (key) {
                    case 'medicalInstitution':
                        if(!values[key]){
                            setErrors({medicalInstitution: "Выберите организацию"})
                            setSubmitting(false)
                            return null
                        }
                        params.medicalInstitutionId = values[key].id
                        break
                    default:
                        params[key] = values[key]
                }
            }
        }
        return dispatch(scanner ? ScannersActions.edit(scanner.id, params) : ScannersActions.add(params)).then(
            _ => {
                setValue('1')
                setLoading(false)
                dispatch({type: "SCANNER", payload: null})
            }
        )
    }

    const medicalInstitutionsInputChange = (e) => {
        setMedicalInstitutionsSearch(getTargetValue(e))
    }

    return (
        <Grid>
            <Formik
                initialValues={{
                    fullName: scanner ? scanner.fullName : "",
                    code: scanner ? scanner.code : "",
                    index: scanner ? scanner.index : null,
                    medicalInstitution: scanner ? scanner.medicalInstitution : null,
                    isActive: scanner ? scanner.isActive : true
                }}
                validationSchema={Yup.object().shape({
                    fullName: Yup.string()
                        .required("Введите название"),
                    code: Yup.string()
                        .required("Введите код")
                })}
                onSubmit={submit}
            >
                {({
                      values,
                      errors,
                      handleSubmit,
                      setFieldValue,
                  }) => (
                    <Form className={classes.form}>
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Field
                                align="center"
                                type="text"
                                size="small"
                                label="Название"
                                required
                                variant="standard"
                                name={`fullName`}
                                inputProps={textFieldProps}
                                className={classes.text_field}
                                component={TextFieldWithError}
                            />
                            <Field
                                align="center"
                                type="text"
                                size="small"
                                required
                                label="Код"
                                variant="standard"
                                name={`code`}
                                inputProps={textFieldProps}
                                className={classes.text_field}
                                component={TextFieldWithError}
                            />
                            <Field
                                align="center"
                                type="number"
                                size="small"
                                label="Порядковый номер"
                                variant="standard"
                                name={`index`}
                                inputProps={textFieldProps}
                                className={classes.text_field}
                                component={TextFieldWithError}
                            />
                            <CustomAutocomplete
                                required
                                label="Организация"
                                className={classes.autocomplete}
                                options={medicalInstitutions.data}
                                loading={!loadingMedicalInstitutions}
                                inputChange={medicalInstitutionsInputChange}
                                error={Boolean(errors.medicalInstitution)}
                                getOptionLabel={(option) => option.name}
                                helperText={errors.medicalInstitution}
                                value={values.medicalInstitution}
                                onChange={(e, value) => setFieldValue('medicalInstitution', value)}
                            />
                            <EditOrSave
                                entity={scanner}
                                handleSubmit={handleSubmit}
                            />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
