import React from 'react'
import clsx from "clsx";
import {makeStyles} from '@mui/styles';


const useStyles = makeStyles(() => ({
    root: {
        top: "0",
        left: "0",
        opacity: "0",
        zIndex: "10",
        width: "100vw",
        height: "100vh",
        display: "flex",
        position: "fixed",
        transition: "0.5s",
        alignItems: "center",
        pointerEvents: "none",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,0.4)"
    },
    content: {
        padding: "20px",
        background: "white",
        borderRadius: "12px",
    },
    active: {
        opacity: "1",
        pointerEvents: "all",
    },
}))

export const Modal = ({active, setActive, children, className}) => {
    const classes = useStyles()
  return (
    <div
        className={active ? clsx(classes.root, classes.active) : classes.root}
        onClick={() => setActive(false)}
    >
        <div
            className={className ? clsx(classes.content, className) : classes.content}
            onClick={(e) => e.stopPropagation()}
        >
            {children}
        </div>
    </div>
  )
}
