export default function uploadManager(state = {
    files: []
}, action) {
    switch (action.type) {
        case "UPLOAD_MANAGER_FILES":
            return {
                ...state,
                ...{
                    files: action.payload
                }
            }
        case "UPLOAD_MANAGER_ADD_FILES":
            window.onbeforeunload = function () {
                return true
            }
            return {
                ...state,
                ...{
                    files: [action.payload].concat(state.files)
                }
            }
        case "UPLOAD_MANAGER_CHANGE":
            const newFiles = {
                ...state,
                ...{
                    files: state.files.length ? state.files.map(file => {
                        if (file.guid === action.payload.guid) {
                            file.percent = action.payload.percent
                            file.cancel = action.payload.cancel
                            file.message = action.payload.percent === 100 ? 'Обработка' : 'Отменить'
                            file.status = action.payload.percent === 100 ? 'loading' : 'pending'
                        }
                        return file
                    }) : []
                }
            }
            window.onbeforeunload = newFiles.files.filter(file => file.percent < 100).length ? function () {
                return true
            } : null
            return newFiles
        case "UPLOAD_MANAGER_CHANGE_STATUS":
            return {
                ...state,
                ...{
                    files: state.files.length ? state.files.map(file => {
                        if (file.guid === action.payload.guid) {
                            file.status = action.payload.status
                        }
                        return file
                    }) : []
                }
            }
        case "UPLOAD_MANAGER_SUCCESS_SEND":
            return {
                ...state,
                ...{
                    files: state.files.length ? state.files.map(file => {
                        if (file.guid === action.payload.guid) {
                            file.message = 'Отправлено'
                            file.status = 'success'
                        }
                        return file
                    }) : []
                }
            }
        case "UPLOAD_MANAGER_DELETE":
            return {
                ...state,
                ...{
                    files: state.files.length ? state.files.filter(file => {
                        return file.guid !== action.payload.guid
                    }) : []
                }
            }
        case "UPLOAD_MANAGER_ERROR":
            return {
                ...state,
                ...{
                    files: state.files.length ? state.files.map(file => {
                        if (file.guid === action.payload.guid) {
                            file.status = 'error'
                            file.message = action.payload.message
                        }
                        return file
                    }) : []
                }
            }
        case "UPLOAD_MANAGER_ADD_FILE_INFO":
            return {
                ...state,
                ...{
                    files: state.files.length ? state.files.map(file => {
                        return file.guid === action.payload.guid ? {
                            ...file,
                            ...action.payload
                        } : file
                    }) : []
                }
            }
        default:
            return state
    }
}
