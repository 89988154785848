import React, {useEffect, useState} from 'react';
import {MedicalInstitutionsActions} from "../../../Dictionary/actions/medicalInstitution";
import {BiomaterialSourcesActions} from "../../../Dictionary/actions/biomaterialSources";
import {getModuleFiles, modulFiles, validates} from "../../helpers/study";
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {saveObject} from "../../../App/helpers/saveObject";
import {createGuid} from '../../../App/helpers/createGuid';
import {useDispatch, useSelector} from "react-redux";
import {GeneticActions} from '../../actions/genetics';
import LoadingButton from '@mui/lab/LoadingButton';
import {StaticForm} from './UploadStudy/StaticForm';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import {Form, Formik} from 'formik';
import * as Yup from "yup";
import {useDebounce} from "use-debounce";

const useStyles = makeStyles(() => ({
    form: {
        background: "white",
        padding: "35px"
    },
    send_button: {
        border: "1px solid #33BE63",
        boxSizing: "border-box",
        background: "#33BE63",
        borderRadius: "2px",
        boxShadow: "none",
        marginTop: "35px",
        color: "white",
        "&:hover": {
            background: "#33BE63",
        }
    },
    cancel_button: {
        border: "1px solid #d32f2f",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        marginTop: "35px",
        marginLeft: "15px",
        color: "white",
    }
}))

export const EditStudy = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {account} = useSelector(state => state.account);
    const {study} = useSelector(state => state.genetic);
    const {biomaterialSources} = useSelector(state => state.biomaterialSources);
    const {medicalInstitutions} = useSelector(state => state.medicalInstitutions);
    const [medicalInstitutionsSearch, setMedicalInstitutionsSearch] = useState(null)
    const [medicalInstitutionsSearchRequest] = useDebounce(medicalInstitutionsSearch, 400)
    const [biomaterialSourcesSearch, setBiomaterialSourcesSearch] = useState(null)
    const [biomaterialSourcesSearchRequest] = useDebounce(biomaterialSourcesSearch, 400)
    const [loadingMi, setLoadingMi] = useState(false)
    const [loadingBs, setLoadingBs] = useState(false)
    const [loadingRow, setLoadingRow] = useState(false)
    const {moduleId, cancelButton, events} = props

    useEffect(() => {
        if (!loadingRow) {
            dispatch(GeneticActions.getStudy(study.id)).then(_ => {
                setLoadingRow(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (!loadingMi) {
            const params = httpBuildQuery({
                offset: 0,
                isActive: true,
                ...(medicalInstitutionsSearchRequest ? {
                    name: medicalInstitutionsSearchRequest
                } : {})
            })
            dispatch(MedicalInstitutionsActions.get(params)).then(_ => {
                setLoadingMi(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loadingMi]);

    useEffect(() => {
        if (!loadingBs) {
            const params = httpBuildQuery({
                offset: 0,
                isActive: true,
                ...(biomaterialSourcesSearchRequest ? {
                    name: biomaterialSourcesSearchRequest
                } : {})
            })
            dispatch(BiomaterialSourcesActions.get(params)).then(_ => {
                setLoadingBs(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loadingBs]);

    useEffect(() => {
        setLoadingBs(false)
    }, [biomaterialSourcesSearchRequest]);

    useEffect(() => {
        setLoadingMi(false)
    }, [medicalInstitutionsSearchRequest]);

    const sendResource = async (studyInstanceUid, file) => {
        const formData = new FormData()
        formData.append('file', file.value)
        file = {
            ...file,
            ...{
                guid: createGuid(),
                percent: 0,
                status: 'pending',
                message: "Отменить",
                studyInstanceUid: studyInstanceUid
            }
        }
        dispatch({
            type: "UPLOAD_MANAGER_ADD_FILES", payload: file
        })
        await dispatch(GeneticActions.sendResource(file, formData, events))
    }

    const prepareResources = (values, {setSubmitting}) => {
        setSubmitting(false)
        const params = saveObject(values)
        delete (params.interpretation)
        dispatch(GeneticActions.editStudies(study.studyInstanceUid, params)).then(
            _ => {
                dispatch({
                    type: "ALERT_SUCCESS", payload: {
                        message: 'Вы успешно отредактировали исследование.',
                        type: 'success'
                    }
                })
                for (const key in values.files) {
                    if (values.files[key].value !== null && values.files[key].new) {
                        sendResource(study.studyInstanceUid, values.files[key]) 
                    }
                }
                cancelButton()
            }
        )
    }

    const getStudyFiles = (files) => {
        let useFiles = {}
        for (const key in modulFiles[moduleId]) {
            const resourceFiles = files.filter(files => files.resourceType === key)
            useFiles[key] = (resourceFiles.length) ? {
                value: true,
                resource_type: modulFiles[moduleId][key].resource_type,
                use: modulFiles[moduleId][key].use,
                new: false,
                slideInstanceUid: resourceFiles[0].slideInstanceUid,
                id: resourceFiles[0].id
            } : modulFiles[moduleId][key]
        }
        return useFiles
    }

    return (study && loadingRow) ? (
        <Grid key={moduleId}>
            <Formik
                initialValues={{
                    id: study.id,
                    patientId: study?.patientId,
                    interpretationType: moduleId,
                    operatorId: account?.employee.id,
                    studyInstanceUid: study.studyInstanceUid,
                    sampleDate: study.sampleDate ?? null,
                    biomaterialSource: study?.biomaterialSource,
                    medicalInstitution: study?.medicalInstitution,
                    patientBirthDate: study.patientBirthDate ?? null,
                    files: study.files.length ? getStudyFiles(study.files) : modulFiles[moduleId]
                }}
                validationSchema={Yup.object().shape({
                    studyInstanceUid: Yup.string()
                        .required("Введите идентификатор образца"),
                    medicalInstitution: Yup.mixed()
                        .required("Выберите организацию из списка"),
                    biomaterialSource: Yup.mixed()
                        .required("Выберите источник биоматериала из списка"),
                    files: validates[moduleId]
                })}
                onSubmit={prepareResources}
            >
                {({
                      errors,
                      values,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form className={classes.form}>
                        <StaticForm
                            errors={errors}
                            values={values}
                            loadingBi={loadingBs}
                            loadingMi={loadingMi}
                            setFieldValue={setFieldValue}
                            biomaterialSources={biomaterialSources}
                            medicalInstitutions={medicalInstitutions}
                            setBiomaterialSourcesSearch={setBiomaterialSourcesSearch}
                            setMedicalInstitutionsSearch={setMedicalInstitutionsSearch}
                        />
                        {getModuleFiles(values, setFieldValue, moduleId, errors)}
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                            <LoadingButton
                                variant="contained"
                                onClick={handleSubmit}
                                className={classes.send_button}
                            >
                                Сохранить
                            </LoadingButton>
                            <LoadingButton
                                color="error"
                                variant="contained"
                                onClick={cancelButton}
                                className={classes.cancel_button}
                            >
                                Отменить
                            </LoadingButton>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    ) : null
}
