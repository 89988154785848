import React from 'react'
import {BiomaterialSourcesActions} from "../../actions/biomaterialSources";
import {TextFieldWithError} from "../../../App/components/Input/TextField";
import {textFieldProps} from "../../../App/helpers/textField";
import {useDispatch, useSelector} from "react-redux";
import {EditOrSave} from "../Buttons/EditOrSave";
import {Field, Form, Formik} from 'formik';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    form: {
        background: "white",
        padding: "10px",
        minHeight: "730px",
    },
    text_field: {
        padding: "2px",
        width: "350px",
        fontSize: "14px",
        '& .MuiInputLabel-root': {
            marginLeft: "5px",
            marginTop: "2px"
        },
        '& .MuiInput-root': {
            paddingLeft: "5px",
            borderRadius: "2px",
            border: "1px solid #DBDEDF",
        },
    }
}))

export const AddAndEdit = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {biomaterialSource} = useSelector(state => state.biomaterialSources);
    const {setValue, setLoading} = props

    const submit = (values) => {
        return dispatch(biomaterialSource ? BiomaterialSourcesActions.edit(biomaterialSource.id, values) : BiomaterialSourcesActions.add(values)).then(
            _ => {
                setValue('1')
                setLoading(false)
                dispatch({type: "BIOMATERIAL_SOURCE", payload: null})
            }
        )
    }

    return (
        <Grid>
            <Formik
                initialValues={{
                    name: biomaterialSource ? biomaterialSource.name : "",
                    isActive: biomaterialSource ? biomaterialSource.isActive : true
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .required("Введите источник биоматериала"),
                })}
                onSubmit={submit}
            >
                {({
                      handleSubmit
                  }) => (
                    <Form className={classes.form}>
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Field
                                align="center"
                                type="text"
                                size="small"
                                label="Источник биоматериала"
                                required
                                variant="standard"
                                name={`name`}
                                inputProps={textFieldProps}
                                className={classes.text_field}
                                component={TextFieldWithError}
                            />
                            <EditOrSave
                                entity={biomaterialSource}
                                handleSubmit={handleSubmit}
                            />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
