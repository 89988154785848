function allyProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function isEditTab(tabId) {
    return !['add', 'verification', 'needs-verification'].includes(tabId)
}

export {allyProps, isEditTab};