import React, { useEffect, useState } from 'react'
import {useDispatch} from 'react-redux';
import {PathomorphologyActions} from '../../Database/actions/pathomorphology';
import {saveObject} from '../helpers/saveObject';
import {Tags as PathoTags} from './Input/Tags';
import {Grid, Typography} from '@mui/material';
import {Submit} from './Button/Submit';
import {makeStyles} from '@mui/styles';
import clsx from "clsx";


const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    autocomplete: {
        width: "400px"
    },
    btn_container: {
        paddingTop: "16px",
    },
    submit: {
        color: "white",
        margin: "15px",
        boxShadow: "none",
        borderRadius: "4px",
    },
    cancel_button: {
        background: "#d32f2f",
        '&:hover': {
            background: "#ae2a2a",
        },
    },
}))


export const Tags = ({setActive, checkedStudies}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [tagNames, setTagNames] = useState([])
    const [studiesTags, setStudiesTags] = useState({studyUids: [], studyTags: []})

    useEffect(() => {
        const studyIds = checkedStudies
        const studyTags = tagNames.map(item => item.name)
        setStudiesTags({studyUids: studyIds, studyTags: studyTags})
    }, [checkedStudies, tagNames])

    const handleChangeTags = (_, newValue) => {
        let newQuery = newValue.map((query) => {
            if (typeof query === "string") {
                return {id: Date.now(), name: query};
            }
            return query;
        });

        setTagNames(newQuery)
    }

    const handleSubmit = () => {
        let params = saveObject(studiesTags)
        return dispatch(PathomorphologyActions.sendStudiesTags(params))
    }

    return (
        <>
            <Typography variant='h5'>Выбрать теги</Typography>
            <Grid className={classes.root}>
                <PathoTags
                    value={tagNames}
                    onChange={handleChangeTags}
                    className={classes.autocomplete}
                />
                <Grid className={classes.btn_container}>
                    <Submit
                        type="submit"
                        onClick={() => {
                            setTagNames([])
                            setActive(false)    
                        }}
                        variant="contained"
                        className={clsx(classes.cancel_button, classes.submit)}
                    >
                        Отменить
                    </Submit>
                    <Submit
                        type="submit"
                        onClick={() => {
                            handleSubmit()
                            setActive(false)
                            setTagNames([])
                        }}
                        variant="contained"
                        className={classes.submit}
                        disabled={!studiesTags.studyTags.length || !studiesTags.studyUids.length}
                    >
                        Сохранить
                    </Submit>
                </Grid>         
            </Grid>
        </>
    )
}
