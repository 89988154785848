import {MedicalInstitutionsService} from "../services/medicalInstitutions";

export const MedicalInstitutionsActions = {
    add,
    edit,
    get,
    getMedicalInstitution,
    deleteMedicalInstitution,
    restoreMedicalInstitution,
}

function get(values) {
    return dispatch => new Promise((resolve, reject) => {
        MedicalInstitutionsService.get(values)
            .then(
                response => {
                    dispatch({type: "MEDICAL_INSTITUTIONS", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getMedicalInstitution(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        MedicalInstitutionsService.getMedicalInstitution(id)
            .then(
                response => {
                    dispatch({type: "MEDICAL_INSTITUTION", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function edit(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        MedicalInstitutionsService.edit(id, values)
            .then(
                _ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function restoreMedicalInstitution(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        MedicalInstitutionsService.restoreMedicalInstitution(id)
            .then(
                _ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function deleteMedicalInstitution(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        MedicalInstitutionsService.deleteMedicalInstitution(id)
            .then(
                _ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        MedicalInstitutionsService.add(values)
            .then(
                _ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}
