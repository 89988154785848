export default function employeePositions(state = {
    employeePositions: {
        data: [],
        total: 0,
        total_pages: 0
    },
    employeePosition: null,
    filter:{}
}, action) {
    switch (action.type) {
        case "EMPLOYEE_POSITIONS":
            return {
                ...state,
                ...{
                    employeePositions: {
                        data: action.payload.data,
                        total: action.payload.total,
                        total_pages: action.payload.totalPages
                    }
                }
            }
        case "EMPLOYEE_POSITION":
            return {
                ...state,
                ...{
                    employeePosition: action.payload
                }
            }
        case "EMPLOYEE_POSITIONS_FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload
                }
            }
        default:
            return state
    }
}
