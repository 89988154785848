import React from 'react'
import {TextFieldWithError} from "../../../App/components/Input/TextField";
import {MedicalInstitutionsActions} from "../../actions/medicalInstitution";
import {textFieldProps} from "../../../App/helpers/textField";
import {useDispatch, useSelector} from "react-redux";
import {EditOrSave} from "../Buttons/EditOrSave";
import {Field, Form, Formik} from 'formik';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    form: {
        background: "white",
        padding: "10px",
        minHeight: "730px",
    },
    text_field: {
        padding: "2px",
        width: "350px",
        fontSize: "14px",
        '& .MuiInputLabel-root': {
            marginLeft: "5px",
            marginTop: "2px"
        },
        '& .MuiInput-root': {
            paddingLeft: "5px",
            borderRadius: "2px",
            border: "1px solid #DBDEDF",
        },
    }
}))

export const AddAndEdit = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {medicalInstitution} = useSelector(state => state.medicalInstitutions);
    const {setValue, setLoading} = props

    const submit = (values) => {
        return dispatch(medicalInstitution ? MedicalInstitutionsActions.edit(medicalInstitution.id, values) : MedicalInstitutionsActions.add(values)).then(
            _ => {
                setValue('1')
                setLoading(false)
                dispatch({type: "MEDICAL_INSTITUTION", payload: null})
            }
        )
    }

    return (
        <Grid>
            <Formik
                initialValues={{
                    fullName: medicalInstitution?.fullName,
                    name: medicalInstitution?.name,
                    code: medicalInstitution?.code,
                    password: medicalInstitution?.password,
                    isActive: medicalInstitution ? medicalInstitution.isActive : true
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .required("Введите полное название"),
                    fullName: Yup.string()
                        .required("Введите полное название"),
                    code: Yup.string()
                        .required("Введите код")
                })}
                onSubmit={submit}
            >
                {({
                      handleSubmit
                  }) => (
                    <Form className={classes.form}>
                        <Grid
                            container direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Field
                                align="center"
                                type="text"
                                size="small"
                                label="Полное название"
                                required
                                variant="standard"
                                name={`fullName`}
                                inputProps={textFieldProps}
                                className={classes.text_field}
                                component={TextFieldWithError}
                            />
                            <Field
                                required
                                type="text"
                                size="small"
                                name={`name`}
                                align="center"
                                variant="standard"
                                label="Краткое название"
                                inputProps={textFieldProps}
                                style={{marginLeft: "20px"}}
                                className={classes.text_field}
                                component={TextFieldWithError}
                            />
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            style={{marginTop: "5px"}}
                        >
                            <Field
                                align="center"
                                type="text"
                                size="small"
                                label="Код"
                                required
                                variant="standard"
                                name={`code`}
                                inputProps={textFieldProps}
                                className={classes.text_field}
                                component={TextFieldWithError}
                            />
                            <Field
                                align="center"
                                type="text"
                                size="small"
                                label="Пароль"
                                required
                                variant="standard"
                                name={`password`}
                                inputProps={textFieldProps}
                                style={{marginLeft: "20px"}}
                                className={classes.text_field}
                                component={TextFieldWithError}
                            />
                        </Grid>
                        <Grid>
                            <EditOrSave
                                entity={medicalInstitution}
                                handleSubmit={handleSubmit}
                            />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
