import {setMessage} from "./alert"

function getError(response) {
    switch (response.status) {
        case 204:
            return "Ошибка, Нет содержимого!"
        case 400:
            return "Неверный запрос!"
        case 401:
            if (response.data.error === "Превышен лимит попыток") {
                return 'Повторный вход в систему возможен через 30 секунд'
            } else {
                return "Неверный логин или пароль!"
            }
        case 403:
            return "Доступ запрещен!"
        case 404:
            return "Ошибка, ничего не найдено!"
        case 405:
            return "Ошибка, неверный метод!"
        case 409:
            if (response.data.statusCode === 'duplicate') {
                if (response.data.controller === 'radio') {
                    return setMessage(response.data.info[0])
                } else {
                    return "Ошибка, ресурс уже существует"
                }
            }
            break
        case 413:
            return "Ошибка, слишком большой ресурс!"
        case 415:
            return "Ошибка, некорректный формат файла!"
        case 422:
            if (response.data.controller === 'radio' && response.data.error === 'A part of study was already uploaded with another medical institution.') {
                return setMessage(response.data.info[0])
            } else  if (response.data.statusCode === 'exception') {
                return "Ошибка, файлы содержат персональные данные!"
            } else if (response.data.controller === 'patho' && response.data.error === 'Cannot finalize study with undefined slides') {
                return 'Финализация исследования невозможна до завершения загрузки файлов. Дождитесь её окончания и попробуйте снова.'
            } else {
                return 'Исследование уже загружено в базу данных с привязкой к другой организации'
            }
        case 500:
            return "Внутренняя ошибка сервера!"
        case 504:
            return "Ошибка, превышено время запроса!"
        default:
            return "Ошибка запроса!"
    }
}

export {getError};

