import React from 'react';
import {BrowserRouter, Navigate, Outlet, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Provider, useDispatch, useSelector} from "react-redux";
import {AccountActions} from "./Account/actions/account";
import {applyMiddleware, createStore} from "redux"
import {Audit} from './Audit/components/Index';
import {Refresh} from "./App/components/Refresh";
import {Dictionary} from './Dictionary/Index'
import AppReducers from "./App/reducers";
import {Header} from "./Account/Header";
import {Index} from "./Database/Index";
import {Loading} from "./App/Loading";
import {NoPage} from "./App/NoPage";
import {Login} from "./Auth/Login";
import {Grid} from '@mui/material';
import {Alert} from "./App/Alert";
import logger from "redux-logger";
import {render} from "react-dom";
import thunk from "redux-thunk";
import './index.css';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans',
        ].join(','),
    },
    palette: {
        secondary: {
            main: "#808080"
        },
        success: {
            main: "#33BE63"
        },
        primary: {
            main: "#2595FF"
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '12px',
                    lineHeight: '15px',
                    borderRadius: '2px',
                    backgroundColor: '#FFFFFF',
                    color: 'rgba(0, 0, 0, 0.87)',
                    padding: '15px 17px 15px 12px',
                    boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.3);',
                },
                arrow: {
                    color: '#FFFFFF'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                outlined: {
                    borderRadius: '4px'
                }
            }
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: 'white',
                        border: 'none',
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontStyle: 'normal',
                        fontFamily: 'Open Sans',
                        backgroundColor: '#2595FF',
                    }
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    padding: "2px",
                    fontSize: "14px",
                    paddingLeft: "5px",
                    borderRadius: "2px",
                    border: "1px solid #DBDEDF",
                    '&.Mui-focused': {
                        borderRadius: "2px",
                        border: "1px solid #7BBFFF",
                    },
                    '&.MuiInputLabel-root': {
                        marginTop: "2px",
                        marginLeft: "5px",
                    },
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '&.MuiInput-focused': {
                        borderRadius: "2px",
                        border: "1px solid #7BBFFF",
                    },
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    marginTop: "2px",
                    marginLeft: "5px",
                }
            }
        }
    }
});

let middleware = [thunk]
if (process.env.REACT_APP_MODE !== 'production') {
    middleware = [...middleware, logger]
}

const store = createStore(
    AppReducers,
    applyMiddleware(...middleware)
)

function RequireAuth({children}) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation()
    const {account} = useSelector(state => state.account);

    if(!account.name){
        if (localStorage.getItem("token") !== null) {
            let auth = dispatch(AccountActions.account()).then(_ => {
                return true
            }).catch(()=> navigate('/login'))
            if (auth.result === undefined) {
                return children;
            }
        }
        return <Navigate to="/login" state={{from: location}} replace/>;
    }else{
        return children;
    }
}

render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Grid style={{
                width: "100vw",
                display: "grid",
                height: "100vh",
                gridTemplateRows: "max-content 1fr",
            }}>
                <Loading/>
                <Alert/>
                <BrowserRouter>
                    <Routes>
                        <Route element={<Outlet/>}>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/audit" element={<RequireAuth><Header/><Audit/></RequireAuth>}/>
                            <Route path="/logout" element={<Login/>}/>
                            <Route path="/dictionary/:moduleId" element={<RequireAuth><Header/><Dictionary/></RequireAuth>}/>
                            <Route path="/dictionary" element={<Navigate replace to="/dictionary/biomaterial_sources"/>}/>
                            <Route path="/database/:moduleId/:tabId" element={<RequireAuth><Header/><Index/></RequireAuth>}/>
                            <Route path="/database/genetic/:geneticId" element={<RequireAuth><Header/><Index/></RequireAuth>}/>
                            <Route path="/database/emc/:emcId" element={<RequireAuth><Header/><Index/></RequireAuth>}/>
                            <Route path="/database/pathomorphology" element={<Navigate replace to="/database/pathomorphology/verification"/>}/>
                            <Route path="/database/radiodiagnosis" element={<Navigate replace to="/database/radiodiagnosis/data"/>}/>
                            <Route path="/refresh" element={<RequireAuth><Header/><Refresh/></RequireAuth>}/>
                            <Route path="/" element={<Navigate replace to="/database/pathomorphology"/>}/>
                            <Route path="*" element={<NoPage/>}> </Route>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Grid>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);
