/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {allyProps} from "../App/helpers/tabPanel";
import {Grid, Tab, Tabs} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Filter} from "./Filter";
import {Data} from "./Data"

const useStyles = makeStyles(() => ({
    content: {
        height: "100%",
        display: "grid",
        gridTemplateColumns: "350px 1fr"
    },
    side_menu_container: {
        display: "grid",
        gridTemplateRows: "390px 1fr",
        borderRight: "solid 1px rgba(0, 0, 0, 0.1)"
    },
    menu_container: {
        '& .MuiTabs-root': {
            borderRight: "none"
        },
    },
    filter_container: {
        height: "100%",
    },
    tab_list: {
        "color": "#495055",
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '& .MuiTabs-indicator': {
            left: 0,
            width: "4px",
            backgroundColor: '#2595FF',
            borderRadius: "0px 4px 4px 0px",
        },
    },
    tab: {
        height: "40px",
        fontWeight: 400,
        fontSize: "14px",
        minHeight: "40px",
        textAlign: "left",
        fontStyle: "normal",
        textTransform: "none",
        justifyContent: "flex-start",
        '&.Mui-selected': {
            color: '#2595FF',
            backgroundColor: "#F1F9FF",
        }
    }
}))

export const Dictionary = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {moduleId} = useParams()
    const navigate = useNavigate()
    const {tag} = useSelector(state => state.tags)
    const {account} = useSelector(state => state.account);
    const {scanner} = useSelector(state => state.scanners);
    const {employee} = useSelector(state => state.employees);
    const {code: icd10_code} = useSelector(state => state.icd10Table);
    const {employeePosition} = useSelector(state => state.employeePositions);
    const {biomaterialSource} = useSelector(state => state.biomaterialSources);
    const {scannerResolution} = useSelector(state => state.scannerResolutions);
    const {medicalInstitution} = useSelector(state => state.medicalInstitutions);
    const [componentLoading, setComponentLoading] = useState(false);
    const [moduleValue, setModuleValue] = useState('1');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (account.role) {
            if (account?.role?.toString().toLowerCase()=== 'admin') {
                if (!componentLoading && getModuleIdByName() === -1) {
                    navigate('/404')
                } else {
                    setComponentLoading(true)
                }
            } else {
                navigate('/404')
            }
        }
    }, [dispatch, account.role]);

    const handleChange = (event, newValue) => {
        switch (newValue) {
            case 0:
                navigate('/dictionary/biomaterial_sources')
                break
            case 1:
                navigate('/dictionary/employees')
                break
            case 2:
                navigate('/dictionary/employee_positions')
                break
            case 3:
                navigate('/dictionary/doctors')
                break
            case 4:
                navigate('/dictionary/medicalInstitutions')
                break
            case 5:
                navigate('/dictionary/scanners')
                break
            case 6:
                navigate('/dictionary/scanner_resolutions')
                break
            case 7:
                navigate('/dictionary/icd10')
                break
            case 8:
                navigate('/dictionary/tags')
                break
            default:
                navigate('/404')
                break
        }
    };

    const handleClick = () => {
        setPage(1)
        setModuleValue('1')
        clearState()
        setLoading(false)
    }

    const clearState = () => {
        if (scannerResolution !== null) {
            dispatch({type: "SCANNER_RESOLUTION", payload: null})
        }
        if (biomaterialSource !== null) {
            dispatch({type: "BIOMATERIAL_SOURCE", payload: null})
        }
        if (employeePosition !== null) {
            dispatch({type: "EMPLOYEE_POSITION", payload: null})
        }
        if (medicalInstitution !== null) {
            dispatch({type: "MEDICAL_INSTITUTION", payload: null})
        }
        if (icd10_code !== null) {
            dispatch({type: "ICD10_TABLE_CODE", payload: null})
        }
        if (employee !== null) {
            dispatch({type: "EMPLOYEE", payload: null})
        }
        if (scanner !== null) {
            dispatch({type: "SCANNER", payload: null})
        }
        if (tag !== null) {
            dispatch({type: "TAG", payload: null})
        }
    }

    const getModuleIdByName = () => {
        const modules = [
            'biomaterial_sources',
            'employees',
            'employee_positions',
            'doctors',
            'medicalInstitutions',
            'scanners',
            'scanner_resolutions',
            'icd10',
            'tags',
        ]

        return modules.indexOf(moduleId)
    }

    return componentLoading ? (
        <Grid className={classes.content}>
             <Grid className={classes.side_menu_container}>
                <Grid className={classes.menu_container}>
                    <Tabs
                        orientation="vertical"
                        value={getModuleIdByName()}
                        className={classes.tab_list}
                        onChange={handleChange}
                        aria-label="Выбор меню"
                        sx={{borderRight: 1, borderColor: 'divider'}}
                    >
                        <Tab
                            onClick={handleClick}
                            className={classes.tab}
                            iconPosition="start"
                            label="Источники биоматериала"
                            {...allyProps(3)}
                        />
                        <Tab
                            onClick={handleClick}
                            className={classes.tab}
                            iconPosition="start"
                            label="Сотрудники"
                            {...allyProps(4)}
                        />
                        <Tab
                            onClick={handleClick}
                            className={classes.tab}
                            iconPosition="start"
                            label="Должности"
                            {...allyProps(5)}
                        />
                        <Tab
                            onClick={handleClick}
                            className={classes.tab}
                            iconPosition="start"
                            label="Врачи"
                            {...allyProps(5)}
                        />
                        <Tab
                            onClick={handleClick}
                            className={classes.tab}
                            iconPosition="start"
                            label="Организации"
                            {...allyProps(7)}
                        />
                        <Tab
                            onClick={handleClick}
                            className={classes.tab}
                            iconPosition="start"
                            label="Сканеры"
                            {...allyProps(8)}
                        />
                        <Tab
                            onClick={handleClick}
                            className={classes.tab}
                            iconPosition="start"
                            label="Разрешение сканеров"
                            {...allyProps(9)}
                        />
                        <Tab
                            onClick={handleClick}
                            className={classes.tab}
                            iconPosition="start"
                            label="МКБ-10"
                            {...allyProps(10)}
                        />
                        <Tab
                            onClick={handleClick}
                            className={classes.tab}
                            iconPosition="start"
                            label="Теги"
                            {...allyProps(11)}
                        />
                    </Tabs>
                </Grid>
                {moduleValue === '1' && moduleId !== "biomaterial_sources" && moduleId !== "tags" && <Grid className={classes.filter_container}>
                    <Filter moduleId={moduleId} setPage={setPage}/>
                </Grid>}
            </Grid>
            <Grid>
                <Data
                    page={page}
                    setPage={setPage}
                    loading={loading}
                    value={moduleValue}
                    moduleId={moduleId}
                    setLoading={setLoading}
                    clearState={clearState}
                    setValue={setModuleValue}
                />
            </Grid>
        </Grid>
    ) : null
}
