import React from 'react';
import {CustomAutocomplete} from "../../../../App/components/Input/CustomAutocomplete";
import {CustomDatePicker} from '../../../../App/components/Input/CustomDatePicker';
import {TextFieldWithError} from '../../../../App/components/Input/TextField';
import {getTargetValue} from "../../../../App/helpers/textField";
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import {Field} from 'formik';

const useStyles = makeStyles(() => ({
    text_field: {
        borderRadius: "2px",
        // width: "350px",
        width: "100%",

    },
    autocomplete: {
        // width: "350px",
        width: "100%",

        background: "transparent",
        '& .MuiInputBase-input': {
            background: "transparent",
        },
    },
}))

export const StaticForm = (props) => {
    const classes = useStyles()
    const {
        setMedicalInstitutionsSearch,
        setBiomaterialSourcesSearch,
        medicalInstitutions,
        biomaterialSources,
        setFieldValue,
        loadingMi,
        loadingBs,
        errors,
        values
    } = props
    const textFieldProps = {
        disableUnderline: true,
    }

    const medicalInstitutionsInputChange = (e) => {
        setMedicalInstitutionsSearch(getTargetValue(e))
    }
    const biomaterialSourcesInputChange = (e) => {
        setBiomaterialSourcesSearch(getTargetValue(e))
    }

    return (
        <React.Fragment>
            <Grid
                container
                spacing={4}
                direction="row"
                sx={{height: '120px'}}
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={4} >
                    <Field
                        align="center"
                        type="text"
                        size="small"
                        label="Идентификатор образца"
                        required
                        placeholder="Введите идентификатор образца"
                        variant="standard"
                        disabled={values.id}
                        name={`studyInstanceUid`}
                        inputProps={textFieldProps}
                        className={classes.text_field}
                        component={TextFieldWithError}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CustomAutocomplete
                        label="Организация"
                        id="medicalInstitution"
                        required
                        helperText={errors?.medicalInstitution}
                        error={Boolean(errors?.medicalInstitution)}
                        className={classes.autocomplete}
                        options={medicalInstitutions.data}
                        loading={!loadingMi}
                        getOptionLabel={option => option.name}
                        inputChange={medicalInstitutionsInputChange}
                        onChange={(e, value) => setFieldValue('medicalInstitution', value)}
                        value={values.medicalInstitution}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        type="text"
                        size="small"
                        align="center"
                        variant="standard"
                        label="ID Пациента"
                        name={`patientId`}
                        inputProps={textFieldProps}
                        className={classes.text_field}
                        component={TextFieldWithError}
                        placeholder="Введите ID пациента"
                    />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={4}
                direction="row"
                sx={{height: '120px'}}
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={4}>
                    <CustomAutocomplete
                        label="Источник биоматериала"
                        id="medicalInstitution"
                        required
                        className={classes.autocomplete}
                        options={biomaterialSources.data}
                        loading={!loadingBs}
                        helperText={errors?.biomaterialSource}
                        error={Boolean(errors?.biomaterialSource)}
                        getOptionLabel={option => option.name}
                        inputChange={biomaterialSourcesInputChange}
                        onChange={(e, value) => setFieldValue('biomaterialSource', value)}
                        value={values.biomaterialSource}
                    />
                </Grid>
                <Grid item xs={4}>
                <CustomDatePicker
                    value={values.sampleDate}
                    label="Дата забора образца"
                    onChange={(newValue) => {
                        setFieldValue('sampleDate', newValue)
                    }}
                />
                </Grid>
                 <Grid item xs={4}>
                <CustomDatePicker
                    value={values.patientBirthDate}
                    label="Дата рождения"
                    onChange={(newValue) => {                      
                        setFieldValue('patientBirthDate', newValue)
                    }}
                />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
