import React from "react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {DesktopDatePicker} from "@mui/lab";
import ruLocale from 'date-fns/locale/ru';
import {TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";

const useStyles = makeStyles(() => ({
    date_picker: {
        background: "transparent",
        borderRadius: "2px"
    },
    text_field: {
        width: "350px",
        "& .MuiInputAdornment-root": {
            paddingRight: "10px"
        },
        "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#2595FF"
        },
    },
    text_field_error: {
        width: "350px",
         "& .MuiInputAdornment-root": {
             paddingRight: "10px"
         },
         "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
             color: "#2595FF"
         },
         "& .MuiFormLabel-root": {
             color: "#d32f2f"
         }
     } 
}))
export const CustomDatePicker = (props) => {
    const classes = useStyles()
    const {
        value,
        onChange,
        style,
        label,
        required,
        views,
        inputFormat,
        disableFuture,
    } = (props)

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
            <DesktopDatePicker
                value={value}
                onChange={onChange}
                disableFuture={disableFuture}
                views={views}
                inputFormat={inputFormat ?? "dd/MM/yyyy"}
                renderInput={({inputRef, inputProps, InputProps, error}) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        style={style}
                        className={classes.date_picker}
                    >
                        <TextField
                            label={label}
                            required={required ?? false}
                            variant="standard"
                            className={error ? classes.text_field_error : classes.text_field}
                            ref={inputRef}
                            placeholder="Выберите дату"
                            {...inputProps}
                            InputProps={
                                {
                                    ...InputProps,
                                    ...{
                                        disableUnderline: true,
                                    }
                                }
                            }
                        />
                    </Box>
                )}
            />
        </LocalizationProvider>
    )
}
