export default function biomaterialSources(state = {
    biomaterialSources: {
        data: [],
        total: 0,
        total_pages: 0
    },
    biomaterialSource: null,
    filter: {}
}, action) {
    switch (action.type) {
        case "BIOMATERIAL_SOURCES":
            return {
                ...state,
                ...{
                    biomaterialSources: {
                        data: action.payload.data,
                        total: action.payload.total,
                        total_pages: action.payload.totalPages
                    }
                }
            }
        case "BIOMATERIAL_SOURCE":
            return {
                ...state,
                ...{
                    biomaterialSource: action.payload
                }
            }
        case "BIOMATERIAL_SOURCES_FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload
                }
            }
        default:
            return state
    }
}