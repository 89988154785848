/* eslint-disable no-unused-expressions */
import React, {createRef, useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {PathomorphologyActions} from "../../actions/pathomorphology";
import {DeleteSeries} from "../../../Account/Dialogs/DeleteSeries";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import StepDone from "../../../App/components/Svg/StepDone";
import {createGuid} from "../../../App/helpers/createGuid";
import Add from "../../../App/components/Svg/Add";
import {makeStyles} from '@mui/styles';
import clsx from "clsx";
import {
    IconButton,
    Typography,
    Button,
    Stack,
    Grid,
} from "@mui/material";


const useStyles = makeStyles(() => ({
    status: {
        padding: "21px 30px",
    },
    circle: {
        width: "27px",
        height: "27px",
        fontWeight: 600,
        color: "#A7ADB2",
        transition: "0.5s",
        textAlign: "center",
        borderRadius: "100%",
        border: "2px solid #A7ADB2",
    },
    active_circle: {
        color: "#2595FF",
        border: "2px solid #2595FF",
    },
    old_circle: {
        background: "#2595FF",
        border: "2px solid #2595FF",
    },
    status_names: {
        padding: "40px 0px",
    },
    status_name: {
        fontWeight: 600,
        marginLeft: "8px",
    },
    vi: {
        height: "auto",
        margin: "-20px 0",
        minHeight: "100px",
        marginLeft: "43px",
        transition: "0.5s",
        borderLeft: "2px solid #DBDEDF",
    },
    active_vi: {
        borderLeft: "2px solid #2595FF",
    },
    status_block: {
        height: "auto",
        minWidth: "80%",
        minHeight: "129px",
        '& .MuiGrid-root': {
            flexDirection: "row",
            justifyContent: "space-between"
        }
    },
    add_series: {
        fontWeight: 600,
        fontSize: "12px",
        color: "#495055",
        lineHeight: "16px",
        background: "transparent",
        "&:hover": {
            background: "transparent",
        }
    },
    error_series: {
        color: "#DF3826"
    },
    active_series: {
        fontWeight: 600,
        color: "#2595FF",
        fontSize: "12px",
        lineHeight: "16px",
        background: "transparent",
        "&:hover": {
            background: "transparent",
        }
    },
    add_series_icon: {
        width: "10px",
        height: "10px"
    }
}))

export const Upload = (props) => {
    const {uploadStatus, setUploadStatus, study, seriesIndex, errors, setFieldValue} = props

    const classes = useStyles()
    const seriesRef = createRef()
    const dispatch = useDispatch()

    const [seriesHeight, setSeriesHeight] = useState('100px')
    const [deleteSeriesDialog, setDeleteSeriesDialog] = useState({
        open: false,
        series: null
    })

    window.onbeforeunload = function () {
        return true
    }

    useEffect(() => {
        const height = seriesRef.current.getBoundingClientRect().height;
        setSeriesHeight(`${height - 400}px`)
    }, [seriesRef]);

    const getCircleContent = (index) => {
        if (uploadStatus > index) {
            return <Typography
                className={clsx(classes.circle, classes.old_circle)}
            >
                <StepDone/>
            </Typography>
        } else if (uploadStatus === index) {
            return <Typography
                className={clsx(classes.circle, classes.active_circle)}
            >
                {index + 1}
            </Typography>
        } else {
            return <Typography
                className={classes.circle}
            >
                {index + 1}
            </Typography>
        }
    }

    const goAddSlidesToSeries = (localId) => {
        setSeries(localId)
        setUploadStatus(2)
    }

    const addSeries = () => {
        const useSeries = {
            studyInstanceUid: study.studyInstanceUid,
            histologicalDiagnosis: null,
            macroDescription: null,
            microDescription: null,
            tags: study.tags ?? [],
            localId: createGuid(),
            seriesInstanceUid: '',
            active: true,
            icd10: null,
            slides: [],
            icdO: null,
            new: true,
        }
        setFieldValue('series', study.series.map(item => {
            item.active = false
            return item
        }).concat(useSeries))
        setUploadStatus(1)
    }

    const setSeries = (localId) => {
        setFieldValue('series', study.series.map((item) => {
            return {
                ...item,
                active: item.localId === localId
            }
        }))
    }

    const goSeries = (localId) => {
        setSeries(localId)
        setUploadStatus(1)
    }

    const getSlideClassName = (seriesValue) => {
        if (seriesValue?.slides?.find(item => item.error)) {
            return clsx(classes.add_series, classes.error_series)
        }
        if (seriesValue.localId === study.series[seriesIndex]?.localId && uploadStatus === 2) {
            return classes.active_series
        }
        return classes.add_series
    }

    const getSeriesClassName = (seriesValue) => {
        if (seriesValue.error) {
            return clsx(classes.add_series, classes.error_series)
        }
        if (seriesValue.localId === study.series[seriesIndex]?.localId && uploadStatus === 1) {
            return classes.active_series
        }
        return classes.add_series
    }

    const getStudyClassName = () => {
        if (Object.keys(errors).filter(item => item !== 'series').length) {
            return clsx(classes.add_series, classes.error_series)
        }
        if (uploadStatus === 0) {
            return classes.active_series
        }
        return classes.add_series
    }

    const deleteSeries = (seriesValue) => {
        setUploadStatus(0)
        if (seriesValue.id) {
            dispatch(PathomorphologyActions.deleteSeries(study.studyInstanceUid, seriesValue.seriesInstanceUid)).then(
                () => setFieldValue('series', study.series.filter(item => item.localId !== seriesValue.localId))
            )
        } else {
            setFieldValue('series', study.series.filter(item => item.localId !== seriesValue.localId))
        }
    }

    const onClickDeleteSeriesDialog = () => {
        deleteSeries(deleteSeriesDialog.series)
        setDeleteSeriesDialog({open: false})
    }

    const onCloseDeleteSeriesDialog = () => {
        setDeleteSeriesDialog({open: false})
    }

    return (
        <>
            <Stack className={classes.status} direction="column" alignItems="center">
                <Grid>
                    <Stack className={classes.status} direction="row" alignItems="center">
                        {getCircleContent(0)}
                    </Stack>
                    <Stack direction="row" alignItems="flex-start" justifyContent="flex-start">
                        <Grid className={uploadStatus > 0 ? clsx(classes.vi, classes.active_vi) : classes.vi}/>
                    </Stack>
                </Grid>
                <Grid>
                    <Stack className={classes.status} direction="row" alignItems="center">
                        {getCircleContent(1)}
                    </Stack>
                    <Stack direction="row" alignItems="flex-start" justifyContent="flex-start">
                        <Grid style={{height: seriesHeight}}
                              className={uploadStatus > 1 ? clsx(classes.vi, classes.active_vi) : classes.vi}/>
                    </Stack>
                </Grid>
                <Grid>
                    <Stack className={classes.status} direction="row" alignItems="center">
                        {getCircleContent(2)}
                    </Stack>
                </Grid>
            </Stack>
            <Stack ref={seriesRef} className={classes.status_names} direction="column" alignItems="flex-start">
                <Stack className={classes.status_block} direction="column"
                       alignItems="flex-start">
                    <Typography className={classes.status_name}>Исследование</Typography>
                    {study?.studyInstanceUid && (study?.studyInstanceUid !== '') && <Button
                        onClick={() => setUploadStatus(0)}
                        className={getStudyClassName()}
                    >
                        <Grid style={{textAlign: "left"}}>
                            № {study?.studyInstanceUid.length > 20 ? `${study.studyInstanceUid.substr(0, 20)}...` : study.studyInstanceUid}<br/>{study.medicalInstitution?.name}
                        </Grid>
                    </Button>}
                </Stack>
                <Stack
                    direction="column"
                    alignItems="flex-start"
                    className={classes.status_block}
                >
                    <Typography className={classes.status_name}>Серии</Typography>
                    {study.series.map(
                        seriesValue => {
                            return (
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="flex-start"
                                    key={seriesValue.localId}
                                    justifyContent="flex-start"
                                >
                                    <Button
                                        key={seriesValue.localId}
                                        className={getSeriesClassName(seriesValue, 1)}
                                        onClick={() => goSeries(seriesValue.localId)}
                                    >
                                        {seriesValue.seriesInstanceUid ? (seriesValue.seriesInstanceUid?.length > 17 ? ` № ${seriesValue.seriesInstanceUid.substr(0, 17)}...` : ` № ${seriesValue.seriesInstanceUid}`) : (` id ${seriesValue.localId.substr(0, 7)}`)}

                                    </Button>
                                    <IconButton
                                        onClick={() =>
                                            setDeleteSeriesDialog({
                                                open: true,
                                                series: seriesValue
                                            })
                                        }
                                    >
                                        <ClearOutlinedIcon
                                            className={classes.add_series_icon}
                                        />
                                    </IconButton>

                                </Grid>
                            )
                        }
                    )}
                    <Button
                        onClick={addSeries}
                        className={classes.add_series}
                        disabled={!study?.studyInstanceUid}
                        startIcon={<Add
                            color="#495055"
                            className={classes.add_series_icon}
                        />}
                    >
                        Добавить
                    </Button>
                </Stack>
                <Stack
                    direction="column"
                    alignItems="flex-start"
                    className={classes.status_block}
                >
                    <Typography className={classes.status_name}>Слайды</Typography>
                    {study.series.filter(seriesValue => seriesValue.seriesInstanceUid).map(seriesValue => {
                        return <Button
                            key={seriesValue.seriesInstanceUid}
                            className={getSlideClassName(seriesValue, 2)}
                            onClick={() => goAddSlidesToSeries(seriesValue.localId)}
                        >
                            № {seriesValue?.seriesInstanceUid?.length > 15 ? `${seriesValue.seriesInstanceUid.substr(0, 15)}...` : seriesValue.seriesInstanceUid} ({seriesValue?.slides?.length ?? 0} шт.)
                        </Button>
                    })}
                </Stack>
            </Stack>
            <DeleteSeries
                open={deleteSeriesDialog.open}
                onClose={onCloseDeleteSeriesDialog}
                onClickDelete={onClickDeleteSeriesDialog}
            />
        </>
    )
}