import React from 'react'
import {makeStyles} from '@mui/styles';
import {Typography, Grid} from '@mui/material';

const useStyles = makeStyles(() => ({

    stats_container: {
        display: "flex",
        padding: "16px 0",
    },
    stats_text: {
        padding: "0 8px",
        fontWeight: "600",
    },
  
}))

export const Stats = (props) => {
    const {amount} = props
    const classes = useStyles()
  return (
    <Grid className={classes.stats_container}>
    {amount &&
        <>
            <Typography className={classes.stats_text}>Записей:</Typography>
            <Typography>{amount}</Typography>
        </>
    }
</Grid>
  )
}
