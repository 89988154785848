import React from 'react';
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import Tooltip from "@mui/material/Tooltip";
import {Chip} from "@mui/material";

const columns = [
  {
    field: 'id', name: 'ID', width: 100, getData: function (row) {
      return row.patientHash
    }
  },
  {
    field: 'name', name: 'ФИО', width: 200, getData: function (row) {
      return null
    }
  },
  {
    field: 'phone', name: 'Телефон', width: 100, getData: function (row) {
      return row.phone
    }
  },
  {
    field: 'email', name: 'Почта', width: 100, getData: function (row) {
      return row.email
    }
  },
  {
    field: 'gender', name: 'Пол', width: 200, getData: function (row) {
      switch (row.gender) {
        case 'Female':
          return 'Ж'
        case 'Male':
          return 'М'
        default:
          return null
      }
    }
  },
  {
    field: 'birthday', name: 'Дата рождения', width: 150, getData: function (row) {
      return row.birthDate ? format(new Date(row.birthDate), 'dd.MM.yyyy', {locale: ru}) : null
    }
  },
  {
    field: 'deceased', name: 'Умер', width: 150, getData: function (row) {
      return (row.hasOwnProperty('deceased') && row.deceased) ? 'Да' : 'Нет'
    }
  },
  {
    field: 'married', name: 'В браке', width: 150, getData: function (row) {
      switch (row.maritalStatus) {
        case 'S':
          return 'Не состоит в браке'
        case 'M':
          return 'Состоит в браке'
        case 'T':
          return 'Незарегистрированный брак'
        case 'W':
          return 'Одинок(а)'
        default:
          return 'Нет данных'
      }
    }
  },
  {
    field: 'address', name: 'Адрес', width: 150, getData: function (row) {
      return null
    }
  },
  {
    field: 'number', name: 'Номер медкарты', width: 150, getData: function (row) {
      return row.cardNumber
    }
  },
  {
    field: 'institution', name: 'МО', width: 150, getData: function (row) {
      return row.medicalInstitution ? <Tooltip placement="left-start" arrow title={row.medicalInstitution?.name}>
        <Chip color="primary" variant="outlined" label={row.medicalInstitution?.code} />
      </Tooltip> : null
    }
  },
  {
    field: 'loaded', name: 'Дата загрузки', width: 100, getData: function (row) {
      return row.uploadedDate ? format(new Date(row.uploadedDate), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  }
]

export {columns as PatientsTableColumns}