const getShortName = (user) => {
    return user?.middleName
        ? `${user?.lastName} ${user?.firstName.charAt(0)}.${user?.middleName.charAt(0)}.`
        : `${user?.lastName} ${user?.firstName.charAt(0)}.`
}

const getShortOperatorName = (operatorName) => {
    let operator = '\u2014'
    if (operatorName) {
        const fullName = operatorName.split(' ')
        operator = fullName.length === 3
            ? `${fullName[0]} ${fullName[1].charAt(0)}.${fullName[2].charAt(0)}.`
            : (operator.length === 2
                ? `${operator[0]} ${operator[1].charAt(0)}.`
                : operatorName
            )
    }
    return operator
}

export {
    getShortName,
    getShortOperatorName
};