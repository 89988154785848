import {HttpService} from "../../App/services/http"

export const AuthService = {
    login,
    logout,
    change_pass
}

function login(values) {
    return HttpService.postMethod("/api/auth/login", values, false)
}

function logout() {
    return HttpService.postMethod("/api/auth/logout")
}

function change_pass(params) {
    return HttpService.postMethod(`/api/auth/change-pass?${params}`, [], true, null, null, true)
}