import React from 'react';

import {Data} from '../Data';
import {Filter} from '../Filter';
import {Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        height: "calc(100vh - 75px)",
        display: "grid",
        gridTemplateColumns: "400px 5fr",
    }
}))

export const Audit = () => {
    const classes = useStyles()
  
    return (
        <Grid className={classes.container}>
            <Filter />
            <Data/>
        </Grid>
    );
}



