/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {CustomSkeleton} from "../DataTable/CustomSkeleton"
import {useDispatch, useSelector} from "react-redux"
import {DoctorsActions} from "../../actions/doctors"
import {Head} from "../DataTable/Head"
import {Stats} from "../Stats/Stats"
import {
    TableContainer,
    TableCell,
    TableBody,
    TableRow,
    Table,
} from '@mui/material'

const columns = [
    {field: 'fullName', name: 'ФИО', width: 300},
    {field: 'specialty', name: 'Специальность', width: 300},
    {field: 'medicalInstitution', name: 'Организация', width: 300}
]

export const Doctors = (props) => {
    const {loading, setLoading} = props

    const dispatch = useDispatch()
    const [initial, setInitial] = useState(false)
    const {total, values, filter} = useSelector(state => state.doctors)

    useEffect(() => {
        if (!initial) {
            dispatch({type: 'DOCTORS_CLEAR'})
            setInitial(true)
        }
    }, [initial]);
    
    useEffect(() => {
        if (initial) {
            if (!loading) {
                dispatch(DoctorsActions.get(filter)).then(() => {
                    setLoading(true)
                })
            }
        }
    }, [dispatch, loading, initial, filter]);

    useEffect(() => {
        setLoading(false)
    }, [filter]);

    return (
        <>
           <Stats amount={total}/>
            <TableContainer style={{height: "calc(100vh - 315px)"}}>
                <Table stickyHeader aria-label='sticky table'>
                    <Head columns={columns}/>
                    <TableBody>
                        {loading ? values.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.name ?? `\u2014`}</TableCell>
                                <TableCell>{item.specialization ?? `\u2014`}</TableCell>
                                <TableCell>{item.medicalInstitution.name ?? `\u2014`}</TableCell>
                            </TableRow>
                        )): <CustomSkeleton count={2}/>}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
