import {createGuid} from "../helpers/createGuid";

export default function alerts(state = {
    alerts: []
}, action) {
    switch (action.type) {
        case "ALERT_ERROR":
        case "ALERT_SUCCESS":
        case "ALERT_INFO":
            return {
                ...state,
                ...{
                    alerts: state.alerts.concat({
                        ...action.payload,
                        ...{
                            id: createGuid()
                        }
                    })
                }
            }
        case "ALERT_CLEAR":
            let alerts = state.alerts
            delete alerts[action.payload]
            return {
                ...state,
                ...{
                    alerts: alerts.filter(alert => alert)
                }
            }
        default:
            return state
    }
}