/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {CustomAutocomplete} from "../../../App/components/Input/CustomAutocomplete";
import {getTargetValue, textFieldProps} from "../../../App/helpers/textField";
import {MedicalInstitutionsActions} from "../../actions/medicalInstitution";
import {TextFieldWithError} from "../../../App/components/Input/TextField";
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {Submit} from "../../../App/components/Button/Submit";
import {saveObject} from "../../../App/helpers/saveObject";
import {DoctorsActions} from '../../actions/doctors';
import {InputLabel, Grid} from '@mui/material';
import {Field, Form, Formik} from "formik";
import {useDebounce} from "use-debounce";
import {makeStyles} from "@mui/styles";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    container: {
        display: "grid",
        alignItems: "start",
        gridTemplateRows: "repeat(6, 70px)",
    },
    content: {
        width: "100%",
        height: "100%",
        padding: "10px",
        background: "#f5f5f5"
    },
    autocomplete: {
        height: "24px",
        width: "100%",
        marginTop: "5px",
        background: "white",
        '& .MuiInputBase-input': {
            background: "white",
        }
    },
    label: {
        fontWeight: 400,
        fontSize: '14px',
        marginTop: "15px",
        lineHeight: '16px',
        fontStyle: 'normal',
    },
    text_field: {
        width: "100%",
        marginTop: "5px",
        fontSize: "14px",
        background: "white",
        '& .MuiInputLabel-root': {
            marginTop: "2px",
            marginLeft: "5px",
        },
        '& .MuiInput-root': {
            paddingLeft: "5px",
            borderRadius: "2px",
            border: "1px solid #DBDEDF",
        },
        '& input:-webkit-autofill': {
            "-webkit-box-shadow": "0 0 0px 1000px white inset"
        }
    },
    submit: {
        width: '100%',
        fontWeight: 600,
        marginTop: "25px",
        borderRadius: '2px',
        textTransform: "none",
        backgroundColor: "#2595FF",
        '&:hover': {
            backgroundColor: "#2595FF",
        }
    },
}))

export const Filter = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const {medicalInstitutions} = useSelector(state => state.medicalInstitutions);

    const [loadingMedicalInstitutions, setLoadingMedicalInstitutions] = useState(false)
    const [medicalInstitutionsSearch, setMedicalInstitutionsSearch] = useState(null)
    const [medicalInstitutionsSearchRequest] = useDebounce(medicalInstitutionsSearch, 400)

    const medicalInstitutionsInputChange = (e) => {
        setMedicalInstitutionsSearch(getTargetValue(e))
    }

    useEffect(() => {
        setLoadingMedicalInstitutions(false)
    }, [medicalInstitutionsSearchRequest]);

    useEffect(() => {
        if (!loadingMedicalInstitutions) {
            const params = httpBuildQuery({
                offset: 0,
                ...(medicalInstitutionsSearchRequest ? {
                    name: medicalInstitutionsSearchRequest
                } : {})
            })

            dispatch(MedicalInstitutionsActions.get(params)).then(_ => {
                setLoadingMedicalInstitutions(true)
            })
        }
    }, [dispatch, loadingMedicalInstitutions]);

    const submit = (values, {setSubmitting}) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DOCTORS_FILTER", payload: saveObject(values)})
        dispatch(DoctorsActions.get(saveObject(values))).then(
            () => dispatch({type: "LOADING", payload: false}),
            () => dispatch({type: "LOADING", payload: false})
        )
        setSubmitting(false)
    }


    return (
        <Grid className={classes.content}>
            <Formik
                initialValues={{
                    name: '',
                    specialization: '',
                    medicalInstitutionIds: '',
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={submit}
            >
                {
                    ({
                        values,
                        handleSubmit,
                        setFieldValue
                    }) => (
                    <Form>
                        <Grid className={classes.container}>
                            <Grid>
                                <InputLabel id="user-name-label" className={classes.label}>
                                    ФИО
                                </InputLabel>
                                <Field
                                    type="text"
                                    size="small"
                                    name={`name`}
                                    align="center"
                                    variant="standard"
                                    inputProps={textFieldProps}
                                    className={classes.text_field}
                                    component={TextFieldWithError}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="employee-position-label" className={classes.label}>
                                    Специализация
                                </InputLabel>
                                <Field
                                    type="text"
                                    size="small"
                                    align="center"
                                    variant="standard"
                                    name={`specialization`}
                                    inputProps={textFieldProps}
                                    className={classes.text_field}
                                    component={TextFieldWithError}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="medical-institutions-label" className={classes.label}>
                                    Организация
                                </InputLabel>
                                <CustomAutocomplete
                                    id="medicalInstitutionIds"
                                    className={classes.autocomplete}
                                    options={medicalInstitutions.data}
                                    loading={!loadingMedicalInstitutions}
                                    inputChange={medicalInstitutionsInputChange}
                                    onChange={(e, value) => setFieldValue('medicalInstitutionIds', value)}
                                    getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                                    value={values.medicalInstitutions}
                                    renderOption={(props, option) => {
                                        return <li {...props} key={option.id}>
                                            {option.name}
                                        </li>
                                    }}
                                />
                            </Grid>
                            <Grid>
                                <Submit
                                    type="submit"
                                    variant="contained"
                                    onClick={handleSubmit}
                                    className={classes.submit}
                                >
                                    Найти
                                </Submit>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}