import {Icd10Service} from '../services/Icd10'

export const Icd10Actions = {
    add,
    getAll,
    getRoot,
    getChildren,
}

function getRoot(values) {
    return dispatch => new Promise((resolve, reject) => {
        if (values?.icd10 || values?.isActive) {
            dispatch({type: "LOADING", payload: true})
        }
        Icd10Service.getRoot(values)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ICD10_CODES", payload: response})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getAll(params){
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        Icd10Service.getAll(params)
            .then(
                response => {
                    dispatch({type: "ICD10_CODES", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getChildren(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        Icd10Service.getChildren(id, '')
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        Icd10Service.add(values)
            .then(
                _ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

