import React from 'react'
import {Submit} from "../../../../../App/components/Button/Submit";
import {createGuid} from "../../../../../App/helpers/createGuid";
import {getExtensionFile} from "../../../../helpers/study";
import {Stack, Typography} from '@mui/material';
import Input from "@mui/material/Input";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        paddingLeft: "8px",
        justifyContent: "flex-start",
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    button: {
        color: "white",
        boxShadow: "none",
        marginLeft: "15px",
        background: "#2595FF",
        "&:hover": {
            background: "#2595FF"
        }
    },
    series: {
        fontWeight: 600,
        lineHeight: "20px",
        paddingRight: "12px"
    }
}))
export const UploadButtons = (props) => {
    const classes = useStyles()
    const {setFieldValue, seriesIndex, study} = props

    const userPlatform = window.navigator.platform ?? window.navigator.userAgentData.platform


    return (
        <Stack className={classes.container}>
            <Typography className={classes.series}>№ серии:</Typography>
            <Typography className={classes.series_id}>{study?.series[seriesIndex]?.seriesInstanceUid}</Typography>
            <Submit
                size="small"
                component="label"
                variant="contained"
                className={classes.button}
            >
                Обзор
                <Input
                    style={{display: "none"}}
                    inputProps={{
                        ...{
                            type: 'file',
                            name: "file",
                            directory: "",
                            multiple: "multiple",
                            accept: ".svs,application/svs",
                            onChange: (event) => {
                                let uploadFiles = []
                                for (const key in event.target.files) {
                                    if (event.target.files[key] instanceof File) {
                                        uploadFiles.push({
                                            seriesInstanceUid: study?.series[seriesIndex]?.seriesInstanceUid,
                                            extension: getExtensionFile(event.target.files[key].name),
                                            studyInstanceUid: study?.studyInstanceUid,
                                            value: event.target.files[key],
                                            userPlatform: userPlatform,
                                            scannerResolution: null,
                                            guid: createGuid(),
                                            scanner: null,
                                            focus: false,
                                            icd10: null,
                                            icdO: null,
                                            code: null,
                                            tags: null,
                                        })
                                    }
                                }
                                setFieldValue(`series`, study.series.map(item => {
                                    return item?.active ? {
                                        ...item,
                                        slides: item.slides.concat(uploadFiles)
                                    } : item
                                }))
                            },
                        }
                    }}
                />
            </Submit>
        </Stack>
    )
}
