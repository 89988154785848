export default function operationTypes(state = {
    operationTypes: {
        data: [],
        total_pages: 0
    },
    operationType: null,
    filter: {}
}, action) {
    switch (action.type) {
        case "OPERATION_TYPES":
            return {
                ...state,
                ...{
                    operationTypes: {
                        data: action.payload.data,
                        total_pages: action.payload.totalPages
                    }
                }
            }
        case "OPERATION_TYPE":
            return {
                ...state,
                ...{
                    operationType: action.payload
                }
            }
        case "OPERATION_TYPES_FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload
                }
            }
        default:
            return state
    }
}