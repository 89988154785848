import React, {useState} from "react"
import {CustomBreadcrumb} from "../../../App/components/CustomBreadcrumb";
import {useDispatch} from "react-redux";
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {makeStyles} from '@mui/styles';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import {Data} from "./Data";

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        height: "100%",
        background: "#F9FAFA",
        padding: "24px 0px 0px 24px;"
    },
    icon: {
        height: "16px",
        width: "16px",
        display: "block",
        marginTop: "2px",
        marginRight: "5px"
    },
    tab_list: {
        marginTop: "12px",
        backgroundColor: "white",
        borderBottom: "1px solid #DBDEDF",
        color: "#495055",
        height: "50px",
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#2595FF',
            borderRadius: "4px 4px 0px 0px",
            height: "4px"
        },
    },
    tab: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        textTransform: "none",
        '&.Mui-selected': {
            color: '#2595FF',
        },
    },
    tab_panel: {
        padding: 0
    }
}))

export const EMC = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [loading, setLoading] = useState(false);
    const {moduleId, moduleValue, setModuleValue} = props

    const handleChangeModule = (event, newValue) => {
        setModuleValue(newValue);
        if (newValue === "1") {
            setLoading(false)
            cancelButton()
        }
    };

    const cancelButton = () => {
        dispatch({type: "GENETIC_STUDY", payload: null})
        setModuleValue('1')
    }

    return (
        <Grid className={classes.container} key={moduleId}>
            <CustomBreadcrumb
                partitionId={0}
                onClickPath={cancelButton}
            />
            <Grid style={{width: "98%"}}>
                <TabContext value={moduleValue}>
                    <Box>
                        <TabList
                            className={classes.tab_list}
                            onChange={handleChangeModule}
                            aria-label="Пункт меню"
                        >
                            <Tab
                                className={classes.tab}
                                label="Данные" value="1"
                            />
                        </TabList>
                    </Box>
                    <TabPanel className={classes.tab_panel} value="1">
                        <Data
                            moduleId={moduleId}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    )
}
