import React from "react";
import {withStyles} from '@mui/styles';
import {TextField} from '@mui/material';
import {fieldToTextField} from "formik-material-ui";


const CustomTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `2px`,
            },
        },
    },
})(TextField);

export const TextFieldWithError = (props) => {
    const {
        classes,
        inputProps,
        ...otherProps
    } = fieldToTextField(props)

    return (
        <CustomTextField
            classes={classes}
            {...otherProps}
            InputProps={inputProps}
            onChange={event => {
                if(otherProps.type === 'number') {
                    if(event.target.value === ''){
                        props.form.setFieldValue(props.field.name, null)
                    }else{
                        props.form.setFieldValue(props.field.name, Number(event.target.value))
                    }
                }else{
                    props.form.setFieldValue(props.field.name, event.target.value)
                }
            }
        }
        />
    )
}
