/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import {useEffect} from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";

export const Refresh = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()


    useEffect(() => {
        if(searchParams.get('to')) {
            navigate(searchParams.get('to'))
        }
    }, [searchParams]);


    return null
}
