export default function employees(state = {
    employees: {
        data: [],
        total: 0,
        total_pages: 0
    },
    employee: null,
    filter: {}
}, action) {
    switch (action.type) {
        case "EMPLOYEES":
            return {
                ...state,
                ...{
                    employees: {
                        data: action.payload.data,
                        total: action.payload.total,
                        total_pages: action.payload.totalPages
                    }
                }
            }
        case "EMPLOYEE":
            return {
                ...state,
                ...{
                    employee: action.payload
                }
            }
        case "EMPLOYEES_FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload
                }
            }
        default:
            return state
    }
}