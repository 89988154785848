import React, {useEffect} from 'react'
import {EmployeePositionActions} from "../../actions/employeePositions";
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {CustomSkeleton} from "../DataTable/CustomSkeleton";
import TableContainer from '@mui/material/TableContainer';
import {Status} from "../../../App/components/Status";
import {useDispatch, useSelector} from "react-redux";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {Actions} from "../Buttons/Actions";
import Table from '@mui/material/Table';
import {Head} from "../DataTable/Head";
import {Stats} from '../Stats/Stats';

const columns = [
    {field: 'name', name: 'Название', width: 300},
    {field: 'status', name: 'Статус', width: 50},
    {field: 'actions', name: 'Действия', width: 30},
];

export const EmployeePositions = (props) => {
    const dispatch = useDispatch()
    const {employeePositions} = useSelector(state => state.employeePositions);
    const {
        setDeleteDialog,
        setEditDialog,
        setLoading,
        setUseRow,
        moduleId,
        setValue,
        loading,
        page
    } = props

    useEffect(() => {
        const params = httpBuildQuery({
            offset: page === 1 ? 0 : (15 * page) - 15
        })

        if (!loading) {
            dispatch(EmployeePositionActions.get(params)).then(() => {
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading]);

    return (
        <>
            <Stats amount={employeePositions?.total}/>     
            <TableContainer style={{height: "calc(100vh - 315px)"}}>
                <Table stickyHeader aria-label='sticky table'>
                    <Head columns={columns}/>
                    <TableBody>
                        {loading ? employeePositions.data.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell><Status active={row.isActive}/></TableCell>
                                <TableCell>
                                    <Actions
                                        row={row}
                                        setValue={setValue}
                                        moduleId={moduleId}
                                        setUseRow={setUseRow}
                                        setLoading={setLoading}
                                        setEditDialog={setEditDialog}
                                        setDeleteDialog={setDeleteDialog}
                                    />
                                </TableCell>
                            </TableRow>
                        )) : <CustomSkeleton count={2}/>}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
