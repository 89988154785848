import React from "react"
import {useDispatch, useSelector} from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogContent} from "@mui/material";
import {useNavigate} from 'react-router-dom'
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    dialog_title: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        lineHeight: "20px"
    },
    cancel_button: {
        background: "#F9FAFA",
        border: "1px solid #DBDEDF",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        color: "black",
        '&:hover': {
            background: "#F9FAFA",
        },
        marginRight: "10px"
    },
    accept_button: {
        background: "#2595FF",
        border: "1px solid #2595FF",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        color: "white",
        '&:hover': {
            background: "#2595FF",
        }
    },
    dialog_description: {
        color: "#495055",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px"
    }
}))

export const ExitDialog = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const {exitDialog} = useSelector(state => state.pathomorphology);

    const handleCloseExitDialog = () => {
        dispatch({
            type: "PATHOMORPHOLOGY_CLOSE_EXIT_DIALOG", payload: {
                value: null,
                path: null
            }
        })
    }

    const goPage = () => {
        navigate(exitDialog?.value ?? exitDialog.path)
        dispatch({type: "PATHOMORPHOLOGY_CLOSE_EXIT_DIALOG", payload: null})
        dispatch({type: "PATHOMORPHOLOGY_CLEAR"})
    }

    return (
        <Dialog
            open={exitDialog.open}
        >
            <DialogTitle className={classes.dialog_title}>
                При обновлении страницы или переходе в другой раздел, заполненные поля не сохранятся.
            </DialogTitle>
            <DialogContent className={classes.dialog_description}>Вы действительно хотите
                продолжить?</DialogContent>
            <DialogActions>
                <Button
                    className={classes.cancel_button}
                    variant="contained"
                    onClick={handleCloseExitDialog}
                >
                    Отмена
                </Button>
                <Button
                    className={classes.accept_button}
                    variant="contained"
                    autoFocus
                    onClick={goPage}
                >
                    Да
                </Button>
            </DialogActions>
        </Dialog>
    );
};
