import React from 'react'
import {PathomorphologyActions} from "../../../../actions/pathomorphology";
import {IconButton, Typography, Tooltip, Stack} from "@mui/material";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import {DeleteSweepOutlined} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        paddingTop: "16px",
        alignItems:"center",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    file_size: {
        marginLeft: "5px",
        color: "#A7ADB2",
    },
    file_name: {
        color: "#2595FF",
        fontSize: "14px"
    },
    file_error: {
        color: "#DF3826",
        fontSize: "14px"
    },
    error_icon: {
        color: "#DF3826",
        fontSize: "18px"
    },
    stack: {
        width: "100%"
    }
}))

export const FileInfo = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {file, setFieldValue, seriesIndex, study} = props

    const onClickDelete = () => {
        setFieldValue('series', study.series.map((item, idx) => {
            return {
                ...item,
                ...{
                    slides: idx === seriesIndex ? item.slides.filter(slide => slide.guid !== file.guid) : item.slides
                }
            }
        }))
        if (file.id) {
            dispatch(PathomorphologyActions.deleteSlide(file.studyInstanceUid, file.seriesInstanceUid, file.slideInstanceUid)).then(
                () => {
                }
            )
        }
    }

    return (
        <Stack className={classes.container}>
            <Stack
                className={classes.stack}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Typography
                    className={file.extension?.toString().toUpperCase() === 'SVS' || file.extension?.toString().toUpperCase() === 'UNDEFINED' || file?.fileType?.toString().toUpperCase() === 'SVS' ? classes.file_name : classes.file_error}
                >
                    {file.value?.name ?? file.fileName}

                </Typography>
                {(file.extension === 'SVS' && file.value) && <Typography
                    className={classes.file_size}
                >
                    ({parseFloat(file.value.size / 1000000).toFixed(2)}MB)
                </Typography>}
            </Stack>
            {!(file.extension?.toString().toUpperCase() === 'SVS' || file.extension?.toString().toUpperCase() === 'UNDEFINED' || file?.fileType?.toString().toUpperCase() === 'SVS') &&
                <Tooltip title="Формат файла не поддерживается">
                    <IconButton>
                        <ErrorOutlinedIcon className={classes.error_icon}/>
                    </IconButton>
                </Tooltip>}
            <Tooltip title="Удалить">
                <IconButton
                    color="error"
                    onClick={onClickDelete}
                >
                    <DeleteSweepOutlined/>
                </IconButton>
            </Tooltip>
        </Stack>
    )
}
