/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect} from "react"
import {useSelector} from "react-redux";
import {TextFieldWithError} from "../../../../App/components/Input/TextField";
import {textFieldProps} from "../../../../App/helpers/textField";
import {Icd10} from "../../../../App/components/Input/Icd10";
import {Tags} from "../../../../App/components/Input/Tags";
import {makeStyles} from '@mui/styles';
import Grid from "@mui/material/Grid";
import {Field} from "formik";

const useStyles = makeStyles(() => ({
    text_field: {
        width: "350px",
        padding: "2px",
        '& .MuiInputLabel-root': {
            top: "4px",
            left: "7px",
        },
    },
    description: {
        width: "100%",
    },
    autocomplete: {
        height: "auto",
        width: "350px",
        marginTop: "2px",
        marginLeft: "16px",
        padding: "0 10px 0 0",
        background: "transparent",
        '& .MuiInputBase-input': {
            background: "transparent",
        },
    },
}))

export const Series = (props) => {
    const {study, setFieldValue, errors, seriesIndex} = props 
    
    const classes = useStyles()

    const {account} = useSelector(state => state.account)
    const {study: stateStudy} = useSelector(state => state.pathomorphology)

    useEffect(() => {
        setFieldValue("series", study?.series.map((item, idx) => {
            if (idx === seriesIndex) {
                return {
                    ...item,
                    slides: item.slides.map(slide => {
                        return {
                            ...slide,
                            seriesInstanceUid: study?.series[seriesIndex].seriesInstanceUid
                        }
                    })
                }
            }
            return item
        }))
    }, [study?.series[seriesIndex].seriesInstanceUid])

    const seriesTags = (study?.series[seriesIndex]?.tags
        ? [
            ...new Map(
            study.tags
                .concat(study.series[seriesIndex].tags)
                .map((item) => [item["id"], item]))
                .values()
        ]
        : study?.tags
    )

    const seriesFixedTags = () => {
        let fixed = []
        if (account?.role?.toString().toLowerCase() !== 'admin') {
            fixed = stateStudy?.series[seriesIndex]?.tags
            ? [
                    ...new Map(
                    stateStudy.tags
                        .concat(stateStudy.series[seriesIndex].tags)
                        .concat(study.tags)
                        .map((item) => [item["id"], item])).values()
                ]
            : study?.tags
        } else {
            fixed = study?.tags 
        }
        return fixed
    }

    return study ? (
        <>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Field
                    required
                    type="text"
                    size="small"
                    align="center"
                    label="№ серии"
                    variant="standard"
                    inputProps={textFieldProps}
                    placeholder="Введите № серии"
                    setFieldValue={setFieldValue}
                    className={classes.text_field}
                    component={TextFieldWithError}
                    disabled={study.series[seriesIndex]?.id ?? false}
                    name={`series[${seriesIndex}].seriesInstanceUid`}
                    error={Boolean(errors?.series?.[seriesIndex]?.seriesInstanceUid)}
                />
                <Grid item>
                    <Icd10
                        label="МКБ-10"
                        setFieldValue={setFieldValue}
                        className={classes.autocomplete}
                        values={study.series[seriesIndex]}
                        readValue={study.series[seriesIndex].icd10}
                        writeValue={`series[${seriesIndex}].icd10`}
                    />
                </Grid>
            </Grid>
            <Field
                multiline
                minRows={4}
                maxRows={4}
                size="small"
                align="center"
                type="textarea"
                label="МКБ-О-3"
                variant="standard"
                inputProps={textFieldProps}
                placeholder="Введите МКБ-О-3"
                component={TextFieldWithError}
                className={classes.description}
                name={`series[${seriesIndex}].icdO`}
            />
            <Field
                multiline
                minRows={4}
                maxRows={4}
                size="small"
                align="center"
                type="textarea"
                variant="standard"
                label="Макроописание"
                inputProps={textFieldProps}
                component={TextFieldWithError}
                className={classes.description}
                placeholder="Введите макроописание"
                name={`series[${seriesIndex}].macroDescription`}
            />
            <Field
                multiline
                minRows={4}
                maxRows={4}
                type="text"
                size="small"
                align="center"
                variant="standard"
                label="Микроописание"
                inputProps={textFieldProps}
                component={TextFieldWithError}
                className={classes.description}
                placeholder="Введите микроописание"
                name={`series[${seriesIndex}].microDescription`}
            />
            <Grid>
                <Tags
                    value={seriesTags}
                    fixed_tags={seriesFixedTags()}
                    onChange={(event, newValue) => {
                        let newQuery = newValue.map((query) => {
                            if (typeof query === "string") {
                                return {id: Date.now(), name: query};
                            }
                            return query;
                        });
                        setFieldValue(`series[${seriesIndex}].tags`, newQuery)
                    }}
                />
            </Grid>
        </>
    ) : null
}
