import {useState} from "react";
import {makeStyles} from '@mui/styles';
import {CircularProgress, Grid} from "@mui/material";

const useStyles = makeStyles(() => ({
    preview: {
        color: "#2595FF",
        cursor: "pointer"
    },
    preview_container: {
        position: "fixed",
        width: "100%",
        height: "100%",
        background: "rgb(0 0 0 / 75%)",
        zIndex: "100",
        top: 0,
        left: 0,
    },
    preview_body: {
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "30px 10px",
    },
    preview_content: {
        backgroundColor: "#fff",
        maxWidth: "600px",
        padding: "30px",
        position: "relative",
        borderBottom: "none !important"
    },
    preview_content_loading: {
        padding: "30px",
        position: "relative",
        borderBottom: "none !important"
    },
    image: {
        maxWidth: "100%"
    }
}))

export const RadioFilePreview = (props) => {
    const {onClosePreview, currentStudyUid, currentSeriesUid} = props
    const classes = useStyles()

    const [isLoading, setLoading] = useState(true);

    const handleLoad = async () => {
        setLoading(false)
    }

    return (           
        <Grid
            className={classes.preview_container}
            onClick={onClosePreview}
        >
            <Grid className={classes.preview_body}>
                <Grid className={isLoading ? classes.preview_content_loading : classes.preview_content}>
                    {isLoading && <CircularProgress/>}
                    <img 
                        style={{
                            display: isLoading ? "none" : "initial"
                        }}
                        src={`/api/radio/studies/${currentStudyUid}/series/${currentSeriesUid}/preview?access_token=${localStorage.getItem('token')}`}
                        alt="Предварительный просмотр файла"
                        onLoad={handleLoad}
                        className={classes.image}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
  }
