import httpBuildQuery from '../../App/helpers/httpBuildQuery'
import {DoctorsService} from '../services/doctors'

export const DoctorsActions = {get}

function get(filter) {
    return dispatch => new Promise((resolve, reject) => {
        DoctorsService.get(httpBuildQuery(filter))
            .then(
                response => {
                    dispatch({type: "DOCTORS", payload: response})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

