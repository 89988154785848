import React from 'react';
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import Tooltip from "@mui/material/Tooltip";
import {Chip} from "@mui/material";

const columns = [
  {
    field: 'patient_id', name: 'ID пациента', width: 100, getData: function (row) {
      return row.patientHash
    }
  },
  {
    field: 'institution', name: 'МО', width: 200, getData: function (row) {
      return row.medicalInstitution ? <Tooltip placement="right-start" arrow title={row.medicalInstitution?.name}>
        <Chip color="primary" variant="outlined" label={row.medicalInstitution?.code} />
      </Tooltip> : null
    }
  },
  {
    field: 'territory', name: 'Филиал', width: 200, getData: function (row) {
      return null
    }
  },
  {
    field: 'department', name: 'Отделение', width: 200, getData: function (row) {
      return row.department
    }
  },
  {
    field: 'recording_date', name: 'Дата записи', width: 200, getData: function (row) {
      return row.uploadedDate ? format(new Date(row.uploadedDate), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  },
  {
    field: 'start_date', name: 'Дата начала', width: 200, getData: function (row) {
      return row.startDate ? format(new Date(row.startDate), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  },
  {
    field: 'doctor', name: 'Врач', width: 200, getData: function (row) {
      return row.doctor.name
    }
  },
  {
    field: 'services', name: 'Услуги', width: 200, getData: function (row) {
      return row.services.map(service => service.name).join(',')
    }
  },
  {
    field: 'duration', name: 'Продолжительность приёма', width: 200, getData: function (row) {
      return row.duration
    }
  },
  {
    field: 'status', name: 'Статус', width: 200, getData: function (row) {
      switch (row.status) {
        case 'Booked':
          return 'Заблокирован'
        case 'Fulfilled':
          return 'Выполнен'
        default:
          return null
      }
    }
  },
  {
    field: 'source', name: 'Источник записи', width: 200, getData: function (row) {
      return null
    }
  },
  {
    field: 'price', name: 'Стоимость', width: 200, getData: function (row) {
      return row.cost
    }
  },
  {
    field: 'loaded', name: 'Дата загрузки', width: 200, getData: function (row) {
      return row.uploadedDate ? format(new Date(row.uploadedDate), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  }
]

export {columns as TricksTableColumns}