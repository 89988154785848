import React from 'react';
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import Tooltip from "@mui/material/Tooltip";
import {Chip} from "@mui/material";

const columns = [
  {
    field: 'patient_id', name: 'ID пациента', width: 100, getData: function (row) {
      return row.patientHash
    }
  },
  {
    field: 'institution', name: 'МО', width: 200, getData: function (row) {
      return row.medicalInstitution ? <Tooltip placement="left-start" arrow title={row.medicalInstitution?.name}>
        <Chip color="primary" variant="outlined" label={row.medicalInstitution?.code} />
      </Tooltip> : null
    }
  },
  {
    field: 'department', name: 'Отделение', width: 200, getData: function (row) {
      return row.department
    }
  },
  {
    field: 'period', name: 'Период действительности', width: 200, getData: function (row) {
      return row.validity?.start ? format(new Date(row.validity.start), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  },
  {
    field: 'created', name: 'Дата создания', width: 200, getData: function (row) {
      return row.uploadedDate ? format(new Date(row.uploadedDate), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  },
  {
    field: 'result', name: 'Результат', width: 200, getData: function (row) {
      return row.results.length ? row.results.join(',') : null
    }
  },
  {
    field: 'doctor', name: 'Врач', width: 200, getData: function (row) {
      return  row.doctors.length ? row.doctors.map(doctor => doctor.name).join(',') : null
    }
  },
  {
    field: 'interpretation', name: 'Интерпретация', width: 200, getData: function (row) {
      return null
    }
  },
  {
    field: 'investments', name: 'Вложения', width: 200, getData: function (row) {
      return null
    }
  },
  {
    field: 'services', name: 'Услуги', width: 200, getData: function (row) {
      return row.services.map(service => service.name).join(',')
    }
  },
  {
    field: 'categories', name: 'Категории', width: 200, getData: function (row) {
      return row.category
    }
  },
  {
    field: 'loaded', name: 'Дата загрузки', width: 200, getData: function (row) {
      return row.uploadedDate ? format(new Date(row.uploadedDate), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  },
]

export {columns as DiagnosticsTableColumns}