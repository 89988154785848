import {HttpService} from "../../App/services/http"

export const BiomaterialSourcesService = {
    add,
    get,
    edit,
    getBiomaterialSource,
    deleteBiomaterialSource,

}

function get(params) {
    return HttpService.getMethod('/api/biomaterial-sources', `limit=15&${params}`)
}

function getBiomaterialSource(id) {
    return HttpService.getMethod(`/api/biomaterial-sources/${id}`)
}

function add(values) {
    return HttpService.postMethod('/api/biomaterial-sources', JSON.stringify(values))
}

function edit(id, values) {
    return HttpService.patchMethod(`/api/biomaterial-sources/${id}`, values)
}

function deleteBiomaterialSource(id) {
    return HttpService.deleteMethod(`/api/biomaterial-sources/${id}`)
}