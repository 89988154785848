import { useEffect, useRef, useState } from "react";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  virtual_scroll_list_container: {
    width: "460px",
    overflowY: "auto",
    minHeight: '150px',
    maxHeight: '400px',
  },
  virtual_scroll_list_item: {
    gap: '1rem',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
  },
}))

const VirtualScrollList = ({ items }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);

  const containerRef = useRef(null);
  const classes = useStyles()

  const handleScroll = () => {
      const element = containerRef.current;
      const scrollTop = element.scrollTop;
      const visibleHeight = element.clientHeight;
      const totalHeight = element.scrollHeight;
 
      const isScrolledToBottom = scrollTop + visibleHeight >= totalHeight;

      if (isScrolledToBottom) {
          setEndIndex((prevEndIndex) => prevEndIndex + 10);
      }
  };

  const handleWheel = (event) => {
      const element = containerRef.current;
      const scrollTop = element.scrollTop;
      const wheelDelta = event.deltaY;

      if (wheelDelta < 0 && scrollTop === 0) {
          setStartIndex((prevStartIndex) => Math.max(prevStartIndex - 10, 0));
      } else if (
          wheelDelta > 0 &&
          scrollTop + element.clientHeight === element.scrollHeight
      ) {
          setEndIndex((prevEndIndex) => prevEndIndex + 10);
      }
  };

  useEffect(() => {
      const element = containerRef.current;
      element.addEventListener('scroll', handleScroll);
      element.addEventListener('wheel', handleWheel);
      return () => {
          element.removeEventListener('scroll', handleScroll);
          element.removeEventListener('wheel', handleWheel);
      };
  }, []);

  const visibleFiles = items?.slice(startIndex, endIndex); 

  return (
      <div
          ref={containerRef}
          onScroll={handleScroll}
          className={classes.virtual_scroll_list_container}
      >
          {visibleFiles?.slice(startIndex, endIndex + 1).map((item, index) => (
              <div key={index} className={classes.virtual_scroll_list_item}>
                <div>{startIndex + index + 1}.</div>
                <div>{item}</div>
              </div>
          ))}
      </div>
  );
};

export default VirtualScrollList;