import React, {useState} from 'react'
import {Button} from '@mui/material';
import {UploadForm} from './UploadForm';

export const UploadPopover = () => {

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button variant="outlined" onClick={handleClickOpen} sx={{ml: 3}}>
                Импорт ведомости
            </Button>
            <UploadForm
                open={open}
                onClose={handleClose}
            />
        </>
    );
}
