import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {getExtensionFile} from "../Database/helpers/study";
import File from "../App/components/Svg/File";
import {makeStyles} from '@mui/styles';
import {
    CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
    CancelOutlined as CancelOutlinedIcon,
    ErrorOutlined as ErrorOutlinedIcon,
    Sync as SyncIcon,
} from '@mui/icons-material';
import {
    LinearProgress,
    Typography,
    IconButton,
    Popover,
    Tooltip,
    Stack,
    Grid,
    Box,
 } from '@mui/material';


const useStyles = makeStyles(() => ({
    upload_manager: {
        "margin-right": "22px"
    },
    upload_manager_icon: {
        width: "32px",
        height: "32px",
    },
    container: {
        minWidth: "350px",
        height: "450px",
        padding: "15px"
    },
    title: {
        fontWeight: 600
    },
    no_upload: {
        marginTop: "50%",
        textAlign: "center",
    },
    no_upload_text: {
        color: "#9da5a9"
    },
    file: {
        width: "100%",
        height: "55px",
        borderBottom: "2px solid #DBDEDF",
        borderRadius: "2px",
        padding: "5px"
    },
    progress: {
        marginTop: "0px",
        background: "white",
        border: "1px solid #DBDEDF",
        borderRadius: "7px",
        padding: "2px",
    },
    upload_info: {
        width: "100%",
        paddingLeft: "10px",
    },
    file_name: {
        fontWeight: 400,
        lineHeight: "20px",
        fontSize: "14px",
    },
    linear_progress: {
        height: "7px",
        background: "white",
        borderRadius: "7px",
        '& .MuiLinearProgress-bar': {
            backgroundColor: "#2595FF"
        }
    },
    button: {
        marginTop: "-10px",
    },
    percent: {
        marginTop: "-5px",
        marginLeft: "5px",
    },
    file_extension: {
        fontSize: "8px",
        position: "absolute",
        textAlign: "center",
        fontWeight: 700,
        width: "31px"
    },
    file_extension_error: {
        color: "#DF3826",
        fontSize: "8px",
        position: "absolute",
        textAlign: "center",
        fontWeight: 700,
        width: "31px"
    },
    file_message: {
        fontSize: "13px",
        lineHeight: "17px"
    },
    loading_icon: {
        transition: "all 1s ease",
        transform: "rotate(0deg)",
        animation: "rotate-counter-clockwise 1s infinite linear"
    }
}))

export const UploadManager = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {anchorEl, setAnchorEl} = props
    const {files} = useSelector(state => state.uploadManager);
    const [intervalWorker, setIntervalWorker] = useState(true)
    const open = Boolean(anchorEl);
    const maxStreams = 10;

    useEffect(() => {
        if (intervalWorker) {
            setIntervalWorker(false)
            setTimeout(() => {
                const useFiles = files.filter(file => file.status === "waiting")
                for (const key in useFiles) {
                    if (useFiles.length && files.filter(file => file.status === 'pending' || file.status === 'loading').length < maxStreams) {
                        switch (useFiles[key].uploadParams.length) {
                            case 1:
                                useFiles[0].upload(useFiles[key].uploadParams[0])
                                break;
                            case 2:
                                useFiles[key].upload(useFiles[key].uploadParams[0], useFiles[key].uploadParams[1])
                                break;
                            case 3:
                                useFiles[key].upload(useFiles[key].uploadParams[0], useFiles[key].uploadParams[1], useFiles[key].uploadParams[2]).then(
                                    () => handleFileFinishSend(useFiles[0]),
                                    () => handleFileFinishSend(useFiles[0])
                                )
                                break;
                            default:
                                break;
                        }
                        dispatch({
                            type: "UPLOAD_MANAGER_CHANGE_STATUS", payload: {
                                guid: useFiles[key].guid,
                                status: 'pending'
                            }
                        })
                    }
                }
                setIntervalWorker(true)
            }, 5000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intervalWorker])

    const handleFileFinishSend = (file) => {
        if (file.uploadGuid) {
            const filesWithOneGuid = files.filter(item => item.uploadGuid === file.uploadGuid)
            if (filesWithOneGuid.filter(item => ['sending', 'waiting', 'loading'].indexOf(item.status) !== -1).length === 0) {
                const errorsCount = filesWithOneGuid.filter(item => item.status === 'error').length
                const successCount = filesWithOneGuid.filter(item => item.status === 'success').length
                dispatch({
                    type: "ALERT_INFO", payload: {
                        message: 'Слайдов отправлено успешно: ' + successCount + '. ' + (errorsCount > 0 ? 'Слайдов не отправлено: ' + errorsCount + '.' : ''),
                        type: 'info'
                    }
                })
            }
        }
    }


    const handleClose = () => {
        setAnchorEl(null);
    };

    const cancelUploadFile = (file) => {
        file.cancel()
        dispatch({
            type: "UPLOAD_MANAGER_DELETE", payload: {
                guid: file.guid
            }
        })
    }

    return (
        <Popover
            id='simple-popover'
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Grid
                className={classes.container}
            >
                <Typography className={classes.title}>Загрузка файлов</Typography>
                {files.length > 0 && files.map(file => {
                    return file ? <React.Fragment key={file.guid}>
                        <Stack
                            className={classes.file}
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                        >
                            {file.value && <>
                                <Typography className={file.status === 'error' ? classes.file_extension_error : classes.file_extension}>
                                    {getExtensionFile(file.value.name)}
                                </Typography>
                                <File
                                    type={file.status}
                                />
                            </>}
                            <Grid
                                item
                                className={classes.upload_info}
                                direction="column"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                            >
                                <Typography
                                    color={file.status === 'error' ? 'error' : null}
                                    style={file.status !== 'error' ? {paddingBottom: "5px"} : null}
                                    className={classes.file_name}
                                >
                                    {file.value ? file.value.name.length > 30 ? `${file.value.name.substr(0, 30)}...` : file.value.name : file.guid}
                                </Typography>
                                {(file.status !== 'error' && file.status !== 'waiting') && <Box
                                    sx={{flexGrow: 1}}
                                    className={classes.progress}
                                >
                                    <LinearProgress
                                        className={classes.linear_progress}
                                        variant="determinate"
                                        value={file.percent}
                                    />
                                </Box>}
                                {file.status === 'error' && <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                >
                                    <Typography
                                        className={classes.file_message}
                                        color="error"
                                    >
                                        {file.message}
                                    </Typography>
                                </Grid>
                                }
                            </Grid>
                            <Grid
                                item
                                direction="column"
                                alignItems="flex-end"
                                justifyContent="flex-end"
                            >
                                {file.status !== undefined && <React.Fragment>
                                    {file.status === 'pending' &&
                                        <Tooltip title={file.message}>
                                            <IconButton
                                                onClick={() => cancelUploadFile(file)}
                                                className={classes.button}
                                            >
                                                <CancelOutlinedIcon color="error"/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {file.status === 'waiting' &&
                                        <Tooltip title={file.message}>
                                            <IconButton
                                                onClick={() => cancelUploadFile(file)}
                                                className={classes.button}
                                            >
                                                <CancelOutlinedIcon color="error"/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {file.status === 'loading' &&
                                        <Tooltip title={file.message}>
                                            <IconButton>
                                                <SyncIcon className={classes.loading_icon} color="primary"/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {file.status === 'success' &&
                                        <Tooltip title={file.message}>
                                            <IconButton>
                                                <CheckCircleOutlineOutlinedIcon color="success"/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {file.status === 'error' &&
                                        <IconButton>
                                            <ErrorOutlinedIcon color="error"/>
                                        </IconButton>
                                    }
                                </React.Fragment>}
                                {((file.status !== 'error' && file.status !== 'waiting') && file.percent < 100) &&
                                    <Typography className={classes.percent}>{file.percent}%</Typography>}
                            </Grid>
                        </Stack>
                    </React.Fragment> : null
                })}
                {files.length === 0 && <Grid className={classes.no_upload}>
                    <Typography className={classes.no_upload_text}>В данный момент нет загрузок</Typography>
                </Grid>}
            </Grid>
        </Popover>
    );
};
