import { OperationTypesService } from "../services/operationTypes"



export const OperationTypesActions = {
    get
}

function get(values) {
    return dispatch => new Promise((resolve, reject) => {
        OperationTypesService.get(values)
            .then(
                response => {
                    dispatch({type: "OPERATION_TYPES", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

