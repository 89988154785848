/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {CustomAutocomplete} from "../../../App/components/Input/CustomAutocomplete";
import {getTargetValue, textFieldProps} from "../../../App/helpers/textField";
import {MedicalInstitutionsActions} from "../../actions/medicalInstitution";
import {TextFieldWithError} from "../../../App/components/Input/TextField";
import {EmployeePositionActions} from "../../actions/employeePositions";
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {Submit} from "../../../App/components/Button/Submit";
import {saveObject} from "../../../App/helpers/saveObject";
import {EmployeesActions} from "../../actions/employees";
import {useDispatch, useSelector} from "react-redux";
import {InputLabel, Grid} from '@mui/material';
import {Field, Form, Formik} from "formik";
import {useDebounce} from "use-debounce";
import {makeStyles} from "@mui/styles";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    container: {
        display: "grid",
        gridTemplateRows: "repeat(6, 70px)",
        alignItems: "start",
    },
    content: {
        height: "100%",
        width: "100%",
        padding: "10px",
        background: "#f5f5f5"
    },
    autocomplete: {
        marginTop: "5px",
        height: "24px",
        width: "100%",
        background: "white",
        '& .MuiInputBase-input': {
            background: "white",
        }
    },
    label: {
        marginTop: "15px",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
    },
    text_field: {
        marginTop: "5px",
        width: "100%",
        fontSize: "14px",
        background: "white",
        '& .MuiInputLabel-root': {
            marginLeft: "5px",
            marginTop: "2px"
        },
        '& .MuiInput-root': {
            paddingLeft: "5px",
            borderRadius: "2px",
            border: "1px solid #DBDEDF",
        },
        '& input:-webkit-autofill': {
            "-webkit-box-shadow": "0 0 0px 1000px white inset"
        }
    },
    submit: {
        marginTop: "25px",
        width: '100%',
        backgroundColor: "#2595FF",
        textTransform: "none",
        fontWeight: 600,
        borderRadius: '2px',
        '&:hover': {
            backgroundColor: "#2595FF",
        }
    },
}))

export const Filter = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const {employeePositions} = useSelector(state => state.employeePositions);
    const {medicalInstitutions} = useSelector(state => state.medicalInstitutions);

    const [loadingEmployeePositions, setLoadingEmployeePositions] = useState(false)
    const [employeePositionsSearch, setEmployeePositionsSearch] = useState(null)
    const [employeePositionsSearchRequest] = useDebounce(employeePositionsSearch, 400)

    const [loadingMedicalInstitutions, setLoadingMedicalInstitutions] = useState(false)
    const [medicalInstitutionsSearch, setMedicalInstitutionsSearch] = useState(null)
    const [medicalInstitutionsSearchRequest] = useDebounce(medicalInstitutionsSearch, 400)

    const status = [
        {
            id: 0,
            active: true,
            name: "Активный"
        },
        {
            id: 1,
            active: false,
            name: "Не активный"
        }
    ]

    useEffect(() => {
        if (!loadingEmployeePositions) {
            let params = httpBuildQuery({
                offset: 0,
                ...(employeePositionsSearchRequest ? {
                    name: employeePositionsSearchRequest
                } : {})
            })

            dispatch(EmployeePositionActions.get(params)).then(_ => {
                setLoadingEmployeePositions(true)
            })
        }
    }, [dispatch, loadingEmployeePositions]);

    useEffect(() => {
        if (!loadingMedicalInstitutions) {
            const params = httpBuildQuery({
                offset: 0,
                ...(medicalInstitutionsSearchRequest ? {
                    name: medicalInstitutionsSearchRequest
                } : {})
            })

            dispatch(MedicalInstitutionsActions.get(params)).then(_ => {
                setLoadingMedicalInstitutions(true)
            })
        }
    }, [dispatch, loadingMedicalInstitutions]);


    const submit = (values, {setSubmitting}) => {
        const params = saveObject(values)
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "EMPLOYEES_FILTER", payload: params})
        dispatch(EmployeesActions.get(httpBuildQuery(params))).then(
            () => dispatch({type: "LOADING", payload: false}),
            () => dispatch({type: "LOADING", payload: false})
        )
        setSubmitting(false)
    }


    useEffect(() => {
        setLoadingEmployeePositions(false)
    }, [employeePositionsSearchRequest]);


    useEffect(() => {
        setLoadingMedicalInstitutions(false)
    }, [medicalInstitutionsSearchRequest]);

    const employeePositionsInputChange = (e) => {
        setEmployeePositionsSearch(getTargetValue(e))
    }

    const medicalInstitutionsInputChange = (e) => {
        setMedicalInstitutionsSearch(getTargetValue(e))
    }

    return (
        <Grid className={classes.content}>
            <Formik
                initialValues={{
                    medicalInstitutionName: null,
                    employeePositionName: null,
                    email: '',
                    name: '',
                    isActive: null,
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={submit}
            >
                {({
                      values,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form>
                        <Grid className={classes.container}>
                            <Grid>
                                <InputLabel id="user-name-label" className={classes.label}>
                                    ФИО
                                </InputLabel>
                                <Field
                                    align="center"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    name={`name`}
                                    inputProps={textFieldProps}
                                    className={classes.text_field}
                                    component={TextFieldWithError}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="employee-position-label" className={classes.label}>
                                    Должность
                                </InputLabel>
                                <CustomAutocomplete
                                    id="employeePositionName"
                                    className={classes.autocomplete}
                                    options={employeePositions.data}
                                    loading={!loadingEmployeePositions}
                                    getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                                    inputChange={employeePositionsInputChange}
                                    onChange={(e, value) => setFieldValue('employeePositionName', value)}
                                    value={values.employeePositionName}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="medical-institutions-label" className={classes.label}>
                                    Организация
                                </InputLabel>
                                <CustomAutocomplete
                                    id="medicalInstitutionId"
                                    className={classes.autocomplete}
                                    options={medicalInstitutions.data}
                                    loading={!loadingMedicalInstitutions}
                                    getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                                    inputChange={medicalInstitutionsInputChange}
                                    onChange={(e, value) => setFieldValue('medicalInstitutionName', value)}
                                    value={values.medicalInstitutionName}
                                    renderOption={(props, option) => {
                                        return <li {...props} key={option.id}>
                                            {option.name}
                                        </li>
                                    }}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="email-label" className={classes.label}>
                                    Электронный адрес
                                </InputLabel>
                                <Field
                                    align="center"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    name={`email`}
                                    inputProps={textFieldProps}
                                    className={classes.text_field}
                                    component={TextFieldWithError}
                                />
                            </Grid>
                            <Grid>
                                <InputLabel id="email-label" className={classes.label}>
                                    Статус
                                </InputLabel>
                                <CustomAutocomplete
                                    options={status}
                                    id="isActive"
                                    name={'isActive'}
                                    className={classes.autocomplete}
                                    getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                                    value={values.isActive}
                                    onChange={(e, value) => {
                                        setFieldValue('isActive', value)
                                    }}
                                />
                            </Grid>
                            <Grid>
                                <Submit
                                    className={classes.submit}
                                    variant="contained"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Найти
                                </Submit>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}