const getLink = (link, values, bundle) => {
    return (values.length && bundle.hasOwnProperty(link)) ?
    Object.entries(bundle).reduce((result, [key, value]) => {
        if (key === link) {
            const params = new URLSearchParams(value);
            for (const [key, param] of params.entries()) {
                result[key] = param
            }
        }

        return result
    }, {}) : null
}


export {
    getLink,
}