export default function tags(state = {
    tags: {
        data: [],
        total_pages: 0
    },
    tag: null,
    filter: {}
}, action) {
    switch (action.type) {
        case "TAGS":
            return {
                ...state,
                ...{
                    tags: {
                        data: action.payload.data,
                        total_pages: action.payload.totalPages
                    }
                }
            }
        case "TAG":
            return {
                ...state,
                ...{
                    tag: action.payload
                }
            }
        default:
            return state
    }
}
