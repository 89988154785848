import React from "react"
import {Filter as MedicalInstitutionsFilter} from './components/MedicalInstitution/Filter'
import {Filter as ScannerResolutionsFilter} from './components/ScannerResolutions/Filter'
import {Filter as EmployeePositionsFilter} from './components/EmployeePosition/Filter'
import {Filter as EmployeeFilter} from "./components/Employees/Filter"
import {Filter as ScannerFilter} from "./components/Scanners/Filter"
import {Filter as DoctorFilter} from "./components/Doctors/Filter"
import {Filter as Icd10Filter} from "./components/Icd10/Filter"
import {Grid, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    content: {
        height: "100%",
        width: "100%",
        padding: "10px",
        borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
        background: "#f5f5f5"
    },
    title: {
        margin: "15px 0px 0px 15px",
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '25px',
        color: "black",
    }
}))

export const Filter = (props) => {
    const classes = useStyles()
    const {moduleId, setPage} = props

    const getFilterContent = () => {
        const filters = {
            "medicalInstitutions": MedicalInstitutionsFilter,
            "scanner_resolutions": ScannerResolutionsFilter,
            "employee_positions": EmployeePositionsFilter,
            "employees": EmployeeFilter,
            "scanners": ScannerFilter,
            "doctors": DoctorFilter,
            "icd10": Icd10Filter,
        }
        const FilterComponent = filters[moduleId]
        return <FilterComponent setPage={setPage}/>
    }

    return (
        <Grid key={moduleId} className={classes.content}>
            <Typography className={classes.title}>Фильтры</Typography>
            {getFilterContent()}
        </Grid>
    )
}