export const AlertActions = {
    success,
    error,
    clear
}

function success(message) {
    return {type: "ALERT_SUCCESS", payload: message}
}

function error(message) {
    return {type: "ALERT_ERROR", payload: message}
}

function clear(index) {
    return {type: "ALERT_CLEAR", payload: index}
}
