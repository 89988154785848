import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CustomSkeleton } from './components/DataTable/CustomSkeleton';
import httpBuildQuery from '../App/helpers/httpBuildQuery';
import { AuditActions } from './actions/audit';
import {makeStyles} from '@mui/styles';
import { Row } from './Row';
import {
    TableContainer,
    Pagination,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Table,
    Grid,
} from '@mui/material';

const useStyles = makeStyles(() => ({
    tableContainer: {
        width: "100%",
        height: "100%",
        padding: "24px",
        background: "#F9FAFA",
    },

    footer: {
        paddingTop: "30px",
        paddingBottom: "30px",
        margin: "auto",
        width: "max-content"
    },
    paper: {
        boxShadow: 'none',
    },
    cell: {
        fontWeight: 600,
        lineHeight: '18px',
        fontSize: '13px'
    }
}))

const columns = [
    {field: 'userName', name: 'ФИО', width: 150},
    {field: 'employeePosition', name: 'Должность', width: 50},
    {field: 'medicalInstitution', name: 'Организация', width: 50},
    {field: 'ipAddress', name: 'IP', width: 50},
    {field: 'operationDate', name: 'Дата', width: 50},
    {field: 'operationTime', name: 'Время', width: 50},
    {field: 'operationType', name: 'Действие', width: 50},
    {field: 'operationStatus', name: 'Статус', width: 50},
];

export const Data = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()

   const {audit, filter} = useSelector(state => state.audit);

   const [loading, setLoading] = useState(false);
   const [page, setPage] = useState(1);

   const toPage = (e, value) => {
    setPage(value)
    setLoading(false)
    }

    useEffect(() => {
        const params = httpBuildQuery({
            offset: page === 1 ? 0 : (10 * page) - 10,
            ...filter
        })
        if (!loading) {
            dispatch(AuditActions.get(params)).then(() => {
                setLoading(true)
            })
       }
    // eslint-disable-next-line
    }, [dispatch, loading])

  

       return (
        <Grid className={classes.tableContainer}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map(column => (
                                    <TableCell
                                        className={classes.cell}
                                        key={column.field}
                                    >
                                        {column.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? audit.data.map(row => (
                                <Row
                                    key={row.id}
                                    row={row}
                                />
                            )) : <CustomSkeleton count={8}/>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid className={classes.footer}>
                    <Pagination
                        onChange={toPage}
                        page={page}
                        count={audit.total_pages}
                        variant="outlined"
                        showFirstButton
                        showLastButton
                    />
                </Grid>
            </Paper>
        </Grid>
    )
}
