/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import TableContainer from '@mui/material/TableContainer';
import Pagination from '@mui/material/Pagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import {getSkeleton} from "../../helpers/study";
import {EMCActions} from "../../actions/emc";
import {PaginationItem, Typography} from "@mui/material";
import {PatientsTableColumns} from "./Table/Patients";
import {TricksTableColumns} from "./Table/Tricks";
import {ResultsTableColumns} from "./Table/Results";
import {RecipesTableColumns} from "./Table/Recipes";
import {ProceduresTableColumns} from "./Table/Procedures";
import {SurveysTableColumns} from "./Table/Surveys";
import {DiagnosticsTableColumns} from "./Table/Diagnostics";
import Tooltip from '@mui/material/Tooltip';
import {getLink} from '../../helpers/pagination';

const useStyles = makeStyles(() => ({
    fullWidth: {
        display: "grid",
        justifyContent: "space-between",
        gridTemplateColumns: "230px 35px",
    },
    footer: {
        paddingTop: "30px",
        paddingBottom: "30px",
        margin: "auto",
        width: "max-content"
    },
    paper: {
        boxShadow: 'none'
    },
    container: {
        height: "calc(100vh - 315px)"
    },
    headCell: {
        fontWeight: 600,
        padding: '16px 5px'
    },
    cell: {
        padding: '5px',
        lineHeight: '18px',
        fontSize: '13px'
    },
    row: {
        cursor: 'pointer'
    },
}))

export const Data = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {values, page, filter, bundle} = useSelector(state => state.emc);
    const [columns, setColumns] = useState([])
    const {moduleId, loading, setLoading} = props
    const [initial, setInitial] = useState(false)
    const [description, setDescription] = useState({index: null, data: null})

    const nextLink = getLink('nextLink', values, bundle)
    const previousLink = getLink('previousLink', values, bundle)

    useEffect(() => {
        if (initial) {
            switch (moduleId) {
                case 'patients':
                    setColumns(PatientsTableColumns)
                    if (!loading) {
                        dispatch(EMCActions.patients(filter)).then(r => {
                            setLoading(true)
                        })
                    }
                    break
                case 'tricks':
                    setColumns(TricksTableColumns)
                    if (!loading) {
                        dispatch(EMCActions.tricks(filter)).then(r => {
                            setLoading(true)
                        })
                    }
                    break
                case 'results':
                    setColumns(ResultsTableColumns)
                    if (!loading) {
                        dispatch(EMCActions.results(filter)).then(r => {
                            setLoading(true)
                        })
                    }
                    break
                case 'recipes':
                    setColumns(RecipesTableColumns)
                    if (!loading) {
                        dispatch(EMCActions.recipes(filter)).then(r => {
                            setLoading(true)
                        })
                    }
                    break
                case 'procedures':
                    setColumns(ProceduresTableColumns)
                    if (!loading) {
                        dispatch(EMCActions.procedures(filter)).then(r => {
                            setLoading(true)
                        })
                    }
                    break
                case 'surveys':
                    setColumns(SurveysTableColumns)
                    if (!loading) {
                        dispatch(EMCActions.surveys(filter)).then(r => {
                            setLoading(true)
                        })
                    }
                    break
                case 'diagnostics':
                    setColumns(DiagnosticsTableColumns)
                    if (!loading) {
                        dispatch(EMCActions.diagnostics(filter)).then(r => {
                            setLoading(true)
                        })
                    }
                    break
                default:
                    setLoading(true)
            }
        }
    }, [dispatch, loading, initial, filter]);

    useEffect(() => {
        if (!initial) {
            dispatch({type: 'EMC_FILTER_CLEAR'})
            setInitial(true)
        }
    }, [initial]);

    useEffect(() => {
        setLoading(false)
    }, [moduleId, filter]);

    return (
        <Grid key={moduleId}>
            <Paper className={classes.paper}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                {columns.map(column=> (
                                    <TableCell
                                        className={classes.headCell}
                                        key={column.field}
                                    >
                                        {column.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? values.map((row, index) => (
                              <Tooltip
                                onClose={() => setDescription({index: null, data: description.data})}
                                disableTouchListener
                                open={(description.index === index)}
                                placement="bottom-start"
                                title={
                                    <Grid container direction='column' justify='flex-start' alignItems='flex-start'>
                                        <Grid className={classes.fullWidth}>
                                            <Typography variant="subtitle2">Приёмы:</Typography>
                                            <Typography variant="subtitle2">{description.data?.appointments}</Typography>
                                        </Grid>
                                        <Grid className={classes.fullWidth}>
                                            <Typography variant="subtitle2">Рецепты:</Typography>
                                            <Typography variant="subtitle2">{description.data?.presctiptions}</Typography>
                                        </Grid>
                                        <Grid className={classes.fullWidth}>
                                            <Typography variant="subtitle2">Процедуры:</Typography>
                                            <Typography variant="subtitle2">{description.data?.procedures}</Typography>
                                        </Grid>
                                        <Grid className={classes.fullWidth}>
                                            <Typography variant="subtitle2">Обследования:</Typography>
                                            <Typography variant="subtitle2">{description.data?.observations}</Typography>
                                        </Grid>
                                        <Grid className={classes.fullWidth}>
                                            <Typography variant="subtitle2">Патоморфология:</Typography>
                                            <Typography variant="subtitle2">{description.data?.pathoStudyCount}</Typography>
                                        </Grid>
                                        <Grid className={classes.fullWidth}>
                                            <Typography variant="subtitle2">Результаты визита:</Typography>
                                            <Typography variant="subtitle2">{description.data?.appointmentResults}</Typography>
                                        </Grid>
                                        <Grid className={classes.fullWidth}>
                                            <Typography variant="subtitle2">Лучевая диагностика:</Typography>
                                            <Typography variant="subtitle2">{description.data?.radioStudyCount}</Typography>
                                        </Grid>
                                        <Grid className={classes.fullWidth}>
                                            <Typography variant="subtitle2">Диагностические отчёты:</Typography>
                                            <Typography variant="subtitle2">{description.data?.deagnosticReports}</Typography>
                                        </Grid>
                                        <Grid className={classes.fullWidth}>
                                            <Typography variant="subtitle2">Интерпретации микробиома:</Typography>
                                            <Typography variant="subtitle2">{description.data?.microbiomeStudyCount}</Typography>
                                        </Grid>
                                        <Grid className={classes.fullWidth}>
                                            <Typography variant="subtitle2">Интерпретации полногеномных данных:</Typography>
                                            <Typography variant="subtitle2">{description.data?.wgsStudyCount}</Typography>
                                        </Grid>
                                        <Grid className={classes.fullWidth}>
                                            <Typography variant="subtitle2">Интерпретации онкологических генетических биомаркеров:</Typography>
                                            <Typography variant="subtitle2">{description.data?.oncoStudyCount}</Typography>
                                        </Grid>
                                    </Grid>
                                }
                                arrow
                              >
                                <TableRow
                                  key={index}
                                  hover
                                  className={classes.row}
                                  onClick={() => {
                                      dispatch(EMCActions.patientStatistic(row.patientHash)).then(data => {
                                          setDescription({index: index, data: data})
                                      })
                                  }}
                                >
                                    {columns.map(column => (
                                      <TableCell key={column.field} className={classes.cell}>{column.getData(row) ?? '\u2014'}</TableCell>
                                    ))}
                                </TableRow>
                              </Tooltip>
                            )) : getSkeleton(10, columns.length)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid className={classes.footer}>
                    <Pagination
                        count={page}
                        page={page}
                        renderItem={(item) => {
                            switch (item.type) {
                                case 'next':
                                    return <PaginationItem
                                        {...{
                                            ...item,
                                            ...{
                                                disabled: (!values.length || !nextLink),
                                                onClick: () => {
                                                    dispatch(EMCActions[moduleId](nextLink ? Object.assign(nextLink, filter) : filter))
                                                    dispatch({type: "EMC_CURRENT_PAGE", payload: page + 1})
                                                }
                                            }
                                        }}
                                    />
                                case 'previous':
                                    return <PaginationItem
                                        {...{
                                            ...item,
                                            ...{
                                                disabled: (!values.length || !previousLink),
                                                onClick: () => {
                                                    dispatch(EMCActions[moduleId](previousLink ? Object.assign(previousLink, filter) : filter))
                                                    dispatch({type: "EMC_CURRENT_PAGE", payload: page - 1})
                                                }
                                            }
                                        }}
                                    />
                                case 'page':
                                    return <PaginationItem
                                      {...{
                                          ...item,
                                          ...{
                                              disabled: ((item.page !== 1) && (item.page !== page)),
                                              onClick: () => {
                                                  if (item.page === 1) {
                                                      dispatch({type: 'EMC_FILTER_CLEAR'})
                                                      setLoading(false)
                                                  }
                                              }
                                          }
                                      }}
                                    />
                                default:
                                    return <PaginationItem
                                      {...item}
                                    />
                            }
                        }}
                        variant="outlined"
                    />
                </Grid>
            </Paper>
        </Grid>
    )
}
