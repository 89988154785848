import React from 'react'
import {Grid, Input, Stack} from '@mui/material';
import {Submit} from '../Button/Submit';
import {makeStyles} from '@mui/styles';
import Excel from '../Svg/Excel';

const useStyles = makeStyles(() => ({
    buttons_view: {
        border: "1px solid #dadedf",
        transition: "0.3s",
        borderRadius: "3px",
        width: "fit-content",
        padding: "5px 0px",
        background: "white",
    },
    button_view_item: {
        padding: "0px 10px",
        '&:hover': {
            background: "aliceblue",
        }
    },
    button_menu: {
        background: "transparent",
        fontFamily: 'Open Sans',
        fontSize: "16px",
        fontWeight: "400",
        border: "none",
        textTransform: "none",
        color: "black",
        padding: "0px",
        width: "100%",
        justifyContent: "flex-start",
        transition: "0.3s",
        '&:hover': {
            color: "#2595FF",
            background: "aliceblue",
            border: "none",
        }
    },
}))

export const UploadMenu = (props) => {
    const classes = useStyles()

    const handleChange = (event) => {
        if (event.target.files.length) {
            props.onFileChange(event)
        }
    }

    return (
        <Grid>
            <Grid className={classes.buttons_view}>
                <Stack
                    container
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    className={classes.button_view_item}
                >
                    <Submit
                        size="small"
                        component="label"
                        variant="outlined"
                        startIcon={<Excel/>}
                        className={classes.button_menu}
                    >
                        Выбрать ведомость
                        <Input
                            key={props.key}
                            style={{display: "none"}}
                            inputProps={{
                                ...{
                                    type: 'file',
                                    accept: "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                    onChange: (event) => handleChange(event)
                                }
                            }}
                        />
                    </Submit>
                </Stack>
            </Grid>
        </Grid>
    )
}