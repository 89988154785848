import {AccountService} from '../services/account'

export const AccountActions = {
    account,
    registration
}

function account() {
    return dispatch => new Promise((resolve, reject) => {
        AccountService.account()
            .then(
                response => {
                    dispatch({type: "ACCOUNT", payload: response})
                    resolve(response)
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function registration(values) {
    return dispatch => new Promise((resolve, reject) => {
        AccountService.registration(values)
            .then(
                account => {
                    resolve(account)
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}
