import {RadiodiagnosisService} from '../services/radiodiagnosis'
import httpBuildQuery from "../../App/helpers/httpBuildQuery";

export const RadiodiagnosisActions = {
    getStudy,
    sendSlide,
    deleteFile,
    radioValues,
    deleteStudy,
}


function radioValues(filter) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        RadiodiagnosisService.radioValues(httpBuildQuery(filter))
            .then(
                response => {
                    dispatch({type: "RADIODIAGNOSIS_VALUES", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getStudy(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        RadiodiagnosisService.getStudy(id)
            .then(
                response => {
                    dispatch({type: "RADIODIAGNOSIS_VALUE", payload: response})

                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function deleteStudy(studyInstanceUid) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        RadiodiagnosisService.deleteStudy(studyInstanceUid)
            .then(
                response => {
                    dispatch({type: "RADIODIAGNOSIS_DELETE_VALUE", payload: studyInstanceUid})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function deleteFile(studyInstanceUid, seriesInstanceUid, sopInstanceUid) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        RadiodiagnosisService.deleteFile(studyInstanceUid, seriesInstanceUid, sopInstanceUid)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function sendSlide(params, formData, events) {
    return dispatch => new Promise((resolve, reject) => {
        RadiodiagnosisService.sendSlide(params, formData, events)
            .then(
                response => {
                    dispatch({type: "UPLOAD_MANAGER_SUCCESS_SEND", payload: {guid: params.guid}})
                    resolve(response)
                },
                error => {
                        dispatch({
                            type: "ALERT_ERROR", payload: {
                                message: error.message,
                                type: 'error'
                            }
                        })
                    reject(error.message)
                }
            )
    })
}