const toInitials = (operator) => {
    let name_string = operator?.split(' ')
    let operator_name = ''
    if (name_string.length > 2) {
        operator_name = `${name_string[0]} ${name_string[1][0]}.${name_string[2][0]}.`
    } else {
        operator_name = `${name_string[0]} ${name_string[1][0]}.`
    }
    return operator_name
}

const setMessage = (info) => {
    const uploadDate = new Date(info.uploadedDate)

    let text = `Исследование ${info?.studyInstanceUid ?? ''} уже загружено в базу данных ${info?.medicalInstitutionName ? `с привязкой к организации ${info.medicalInstitutionName}` : ''}${info.uploadedDate ? `. Дата/время: ${uploadDate.toLocaleString()}` : ''}${info.operatorName ? `. Лаборантом ${toInitials(info.operatorName)}` : ''}`

    return text 
}

export {
    setMessage,
};
