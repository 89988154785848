const filter = {
    medicalInstitutionId: null,
    interpretationStatus: null,
    biomaterialSourceId: null,
    studyInstanceUid: null,
    macroDescription: null,
    microDescription: null,
    operatorName: null,
    patientName: null,
    startDate: null,
    finalized: true,
    endDate: null,
    tags: null,
    offset: 0,
}
const exitDialog = {
    open: false,
    path: null
}
const initialState = {
    studies: [],
    stats: null,
    study: null,
    not_disk: [],
    total_pages: 0,
    filter: filter,
    import_files: [],
    total_remote_files: 0,
    exitDialog: exitDialog,
}

export default function pathomorphology(state = initialState, action) {
    switch (action.type) {
        case "PATHOMORPHOLOGY_STUDIES":
            return {
                ...state,
                ...{
                    studies: action.payload.data,
                    stats: action.payload.stats,
                    total_pages: action.payload.totalPages,
                }
            }
        case "PATHOMORPHOLOGY_STUDY":
            return {
                ...state,
                ...{
                    study: action.payload
                }
            }
        case "PATHOMORPHOLOGY_FILTER":
            return {
                ...state,
                ...{
                    filter: {
                        ...{finalized: state.filter.finalized},
                        ...action.payload
                    },
                }
            }
        case "PATHOMORPHOLOGY_FILTER_CLEAR":
            return {
                ...state,
                ...{
                    filter: filter
                }
            }
        case "PATHOMORPHOLOGY_OPEN_EXIT_DIALOG":
            return {
                ...state,
                ...{
                    exitDialog: {
                        ...action.payload,
                        ...{
                            open: true
                        }
                    }
                }
            }
        case "PATHOMORPHOLOGY_CLOSE_EXIT_DIALOG":
            return {
                ...state,
                ...{
                    exitDialog: exitDialog
                }
            }
        case "PATHOMORPHOLOGY_CLEAR":
            return initialState
        case "PATHOMORPHOLOGY_IMPORT_FILES":
            return {
                ...state,
                ...{
                    import_files: action.payload
                }
            }
        case "PATHOMORPHOLOGY_REMOTE_FILES_TOTAL":
            return {
                ...state,
                ...{
                    total_remote_files: action.payload
                }
            }
        case "PATHOMORPHOLOGY_IMPORT_FILES_NOT_DISK":
            return {
                ...state,
                ...{
                    not_disk: state.not_disk.concat(action.payload)
                }
            }
        case "PATHOMORPHOLOGY_IMPORT_FILES_NOT_DISK_REMOVE":
            return {
                ...state,
                ...{
                    not_disk: state.not_disk.filter(file => file.value.name !== action.payload.name && file.value.webkitRelativePath !== action.payload.webkitRelativePath)
                }
            }
        case "PATHOMORPHOLOGY_CLEAR_IMPORT":
            return {
                ...state,
                ...{
                    import_files: [],
                    not_disk: []
                }
            }
        default:
            return state
    }
}
