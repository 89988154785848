import React from 'react';
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import Tooltip from "@mui/material/Tooltip";
import {Chip} from "@mui/material";

const columns = [
  {
    field: 'patient_id', name: 'ID пациента', width: 100, getData: function (row) {
      return row.patientHash
    }
  },
  {
    field: 'institution', name: 'МО', width: 200, getData: function (row) {
      return row.medicalInstitution ? <Tooltip placement="right-start" arrow title={row.medicalInstitution?.name}>
        <Chip color="primary" variant="outlined" label={row.medicalInstitution?.code} />
      </Tooltip> : null
    }
  },
  {
    field: 'territory', name: 'Филиал', width: 200, getData: function (row) {
      return null
    }
  },
  {
    field: 'department', name: 'Отделение', width: 200, getData: function (row) {
      return row.department
    }
  },
  {
    field: 'created', name: 'Дата создания', width: 200, getData: function (row) {
      return row.createdDate ? format(new Date(row.createdDate), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  },
  {
    field: 'started', name: 'Дата начала', width: 200, getData: function (row) {
      return row.startDate ? format(new Date(row.startDate), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  },
  {
    field: 'duration', name: 'Продолжительность', width: 200, getData: function (row) {
      return row.duration
    }
  },
  {
    field: 'anamnesis', name: 'Анамнез', width: 200, getData: function (row) {
      return null
    }
  },
  {
    field: 'result', name: 'Результат осмотра', width: 200, getData: function (row) {
      return null
    }
  },
  {
    field: 'diagnosis', name: 'Диагнозы', width: 200, getData: function (row) {
      return row.diagnosis.length ? row.diagnosis.join(',') : null
    }
  },
  {
    field: 'loaded', name: 'Дата загрузки', width: 200, getData: function (row) {
      return row.uploadedDate ? format(new Date(row.uploadedDate), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  },
]

export {columns as ResultsTableColumns}