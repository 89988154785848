import React from 'react';
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import Tooltip from "@mui/material/Tooltip";
import {Chip} from "@mui/material";

const columns = [
  {
    field: 'patient_id', name: 'ID пациента', width: 100, getData: function (row) {
      return row.patientHash
    }
  },
  {
    field: 'institution', name: 'МО', width: 200, getData: function (row) {
      return row.medicalInstitution ? <Tooltip placement="right-start" arrow title={row.medicalInstitution?.name}>
        <Chip color="primary" variant="outlined" label={row.medicalInstitution?.code} />
      </Tooltip> : null
    }
  },
  {
    field: 'created', name: 'Дата создания', width: 200, getData: function (row) {
      return row.createdDate ? format(new Date(row.createdDate), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  },
  {
    field: 'status', name: 'Статус', width: 200, getData: function (row) {
      switch (row.status) {
        case 'Final':
          return 'Завершено'
        case 'Registered':
          return 'Зарегистрировано'
        default:
          return null
      }
    }
  },
  {
    field: 'doctor', name: 'Врач', width: 200, getData: function (row) {
      return row.doctor?.name
    }
  },
  {
    field: 'period', name: 'Период действительности', width: 200, getData: function (row) {
      return row.validity?.start ? format(new Date(row.validity.start), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  },
  {
    field: 'value', name: 'Значение', width: 200, getData: function (row) {
      return row.value
    }
  },
  {
    field: 'comment', name: 'Комментарий', width: 200, getData: function (row) {
      return null
    }
  },
  {
    field: 'interpretation', name: 'Интерпретация', width: 200, getData: function (row) {
      return row.interpritation
    }
  },
  {
    field: 'range', name: 'Диапазон', width: 200, getData: function (row) {
      return row.ranges.length ? row.ranges.join(',') : null
    }
  },
  {
    field: 'loaded', name: 'Дата загрузки', width: 200, getData: function (row) {
      return row.uploadedDate ? format(new Date(row.uploadedDate), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  },
]

export {columns as SurveysTableColumns}