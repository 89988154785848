import React from 'react'
import {TextFieldWithError} from "../../../App/components/Input/TextField";
import {textFieldProps} from "../../../App/helpers/textField";
import {Icd10TableActions} from "../../actions/icd10_table";
import {saveObject} from "../../../App/helpers/saveObject";
import {Icd10} from "../../../App/components/Input/Icd10";
import {useDispatch, useSelector} from "react-redux";
import {Icd10Actions} from "../../actions/icd10";
import {EditOrSave} from "../Buttons/EditOrSave";
import {Field, Form, Formik} from 'formik';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    form: {
        background: "white",
        padding: "10px",
        minHeight: "730px",
    },
    text_field: {
        padding: "2px",
        width: "350px",
        fontSize: "14px",
        '& .MuiInputLabel-root': {
            marginLeft: "5px",
            marginTop: "2px"
        },
        '& .MuiInput-root': {
            paddingLeft: "5px",
            borderRadius: "2px",
            border: "1px solid #DBDEDF",
        },
    },
    select: {
        padding: "2px",
        width: "350px",
        fontSize: "14px",
        '& .MuiInputLabel-root': {
            marginLeft: "5px",
            marginTop: "2px"
        },
        '& .MuiInput-root': {
            paddingLeft: "5px",
            borderRadius: "2px",
            border: "1px solid #DBDEDF",
            height: "30px"
        },
        height: "30px"
    },
    menuItem: {
        height: "30px"
    },
    autocomplete: {
        marginTop: "2px",
        height: "auto",
        width: "352px",
        background: "transparent",
        '& .MuiInputBase-input': {
            background: "transparent",
        },
    },
}))
export const AddAndEdit = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {code} = useSelector(state => state.icd10Table);
    const {setValue, setLoading} = props


    const submit = (values) => {
        const params = saveObject(values)
        return dispatch(code ? Icd10TableActions.edit(code.id, params) : Icd10Actions.add(params)).then(
            _ => {
                setValue('1')
                setLoading(false)
                dispatch({type: "ICD10_TABLE_CODE", payload: null})
            }
        )
    }

    return (
        <Grid>
            <Formik
                initialValues={{
                    code: code ? code.code : "",
                    diagnosis: code ? code.diagnosis : "",
                    parent: code?.parent,
                    isActive: code ? code.isActive : true,
                }}
                validationSchema={Yup.object().shape({
                    diagnosis: Yup.string()
                        .required("Введите диагноз"),
                    code: Yup.string()
                        .required("Введите код").test('len', 'Максимальное кол-во символов 10!', val => val?.length < 10)
                })}
                onSubmit={submit}
            >
                {({
                      handleSubmit,
                      setFieldValue,
                      values
                  }) => (
                    <Form className={classes.form}>
                        <Grid
                            container direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Field
                                align="center"
                                type="text"
                                size="small"
                                label="Код"
                                required
                                variant="standard"
                                name={`code`}
                                inputProps={textFieldProps}
                                className={classes.text_field}
                                component={TextFieldWithError}
                            />
                            <Field
                                align="center"
                                type="text"
                                size="small"
                                label="Диагноз"
                                required
                                variant="standard"
                                name={`diagnosis`}
                                inputProps={textFieldProps}
                                className={classes.text_field}
                                component={TextFieldWithError}
                            />
                            <Icd10
                                label="МКБ-10"
                                values={values}
                                setFieldValue={setFieldValue}
                                className={classes.autocomplete}
                            />
                            <EditOrSave
                                entity={code}
                                handleSubmit={handleSubmit}
                            />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
