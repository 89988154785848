import format from "date-fns/format";
import {ru} from "date-fns/locale";

const columns = [
  {
    field: 'patient_id', name: 'ID пациента', width: 100, getData: function (row) {
      return row.patientHash
    }
  },
  {
    field: 'created', name: 'Дата создания', width: 200, getData: function (row) {
      return row.createdDate ? format(new Date(row.createdDate), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  },
  {
    field: 'doctor', name: 'Врач', width: 200, getData: function () {
      return null
    }
  },
  {
    field: 'medications', name: 'Лекарства', width: 200, getData: function (row) {
      return row.medication?.name
    }
  },
  {
    field: 'dosage', name: 'Дозировка', width: 200, getData: function () {
      return null
    }
  },
  {
    field: 'cause', name: 'Причина', width: 200, getData: function () {
      return null
    }
  },
  {
    field: 'validity', name: 'Срок действия', width: 200, getData: function () {
      return null
    }
  },
  {
    field: 'amount', name: 'Кол-во действий', width: 200, getData: function (row) {
      return row.numberOfRepeats
    }
  },
  {
    field: 'loaded', name: 'Дата загрузки', width: 200, getData: function (row) {
      return row.uploadedDate ? format(new Date(row.uploadedDate), 'dd.MM.yyyy H:mm', {locale: ru}) : null
    }
  },
]

export {columns as RecipesTableColumns}