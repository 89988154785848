/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";

import {MedicalInstitutionsActions} from "../../../Dictionary/actions/medicalInstitution";
import {ScannerResolutionsActions} from "../../../Dictionary/actions/scannerResolutions";
import {CustomAutocomplete} from "../../../App/components/Input/CustomAutocomplete";
import {TextFieldWithError} from "../../../App/components/Input/TextField";
import {EmployeesActions} from "../../../Dictionary/actions/employees";
import {ScannersActions} from "../../../Dictionary/actions/scanners";
import {TagsActions} from "../../../Dictionary/actions/tags";
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {Icd10Actions} from "../../../Dictionary/actions/icd10";
import {Submit} from "../../../App/components/Button/Submit";
import Calendar from "../../../App/components/Svg/Calendar";
import {saveObject} from "../../../App/helpers/saveObject";
import {Icd10} from "../../../App/components/Input/Icd10";
import Search from "../../../App/components/Svg/Search";
import {Tags} from "../../../App/components/Input/Tags";
import DateRangePicker from "@mui/lab/DateRangePicker";
import {getShortName} from "../../../App/helpers/row";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {Field, Form, Formik} from 'formik';
import ruLocale from "date-fns/locale/ru";
import {useDebounce} from 'use-debounce';
import {makeStyles} from '@mui/styles';
import {DatePicker} from "@mui/lab";
import * as Yup from "yup";
import {
    InputAdornment,
    InputLabel,
    IconButton,
    Typography,
    TextField,
    Stack,
    Grid,
} from "@mui/material";

const useStyles = makeStyles(() => ({
    content: {
        width: "100%",
        height: "100%",
        padding: "10px",
        background: "#f5f5f5",
        borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
    },
    title: {
        color: "black",
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '25px',
        fontStyle: 'normal',
    },
    autocomplete: {
        width: "100%",
        height: "24px",
        marginTop: "5px",
        background: "white",
        '& .MuiInputBase-input': {
            background: "white",
        }
    },
    select: {
        width: "100%",
        background: "white",
        borderRadius: 'unset',
        '& .MuiInputBase-root': {
            height: "34px",
            paddingRight: '8px',
            borderRadius: 'unset',
        },
    },
    label: {
        fontWeight: 400,
        fontSize: '14px',
        marginTop: "15px",
        lineHeight: '16px',
        fontStyle: 'normal',
    },
    date_label: {
        fontWeight: 400,
        fontSize: '14px',
        marginTop: "25px",
        lineHeight: '16px',
        fontStyle: 'normal',
    },
    date: {
        width: "100%",
        height: "34px",
        paddingLeft: "5px",
        borderRadius: "2px",
        background: "white",
        border: "1px solid #DBDEDF",
    },
    date_text_field: {
        width: "95px",
        fontSize: '12px',
        lineHeight: '16px',
    },
    date_text_field_root: {
        '& .MuiInputBase-root': {
            border: "none"
        },
    },
    date_typography: {
        width: "15px",
        color: "black"
    },
    text_field: {
        width: "100%",
        height: "34px",
        borderRadius: "2px",
        background: "white",
        border: "1px solid #DBDEDF",
    },
    search_icon: {
        width: "18px",
        height: "18px",
        marginLeft: "5px",
    },
    submit: {
        width: '100%',
        fontWeight: 600,
        marginTop: "25px",
        borderRadius: '2px',
        textTransform: "none",
        backgroundColor: "#2595FF",
        '&:hover': {
            backgroundColor: "#2595FF",
        }
    },
    date_icon_grid: {
        height: "100%",
        borderLeft: "1px solid #DBDEDF",
    },
    date_icon: {
        width: "18px",
        height: "18px",
    }
}))

export const Filter = (props) => {
    const {moduleId} = props

    const classes = useStyles()
    const dispatch = useDispatch()

    const {medicalInstitutions} = useSelector(state => state.medicalInstitutions)
    const {scannerResolutions} = useSelector(state => state.scannerResolutions)
    const {employees} = useSelector(state => state.employees)
    const {scanners} = useSelector(state => state.scanners)
    const {account} = useSelector(state => state.account)

    const [receiveRangeDatePickerOpen, setReceiveRangeDatePickerOpen] = useState(false)
    const [datePickerOpen, setDatePickerOpen] = useState(false)

    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState({search: '', type: null})
    const [searchRequest] = useDebounce(search, 400)


    const inputChange = (e, type = null) => {
        setSearch((e && (typeof e.target.value === 'string')) ? {search: e.target.value, type: type} : {search: '', type: null})
    }

    useEffect(() => {
        setLoading(false)
    }, [searchRequest]);

    useEffect(() => {
        if (!loading) {
            let params = httpBuildQuery({
                offset: 0,
                ...(searchRequest.search ? {
                    name: searchRequest.search
                } : {})
            })
            switch (searchRequest.type) {
                case 'medicalInstitutionId':
                    dispatch(MedicalInstitutionsActions.get(params))
                    break
                case 'personAcceptedId':
                case 'operatorId':
                    dispatch(EmployeesActions.get(params))
                    break
                case 'tags':
                    dispatch(TagsActions.get(params))
                    break
                case 'scannerId':
                    params = httpBuildQuery({
                        offset: 0,
                        ...(searchRequest.search ? {
                            fullName: searchRequest.search
                        } : {})
                    })
                    dispatch(ScannersActions.get(params))
                    break
                case 'scannerResolutionId':
                    params = httpBuildQuery({
                        offset: 0,
                        ...(searchRequest.search ? {
                            value: searchRequest.search
                        } : {})
                    })
                    dispatch(ScannerResolutionsActions.get(params))
                    break;
                case 'icd10Id':
                    params = httpBuildQuery({
                        offset: 0,
                        limit: 1000,
                        ...(searchRequest.search ? {
                            search: searchRequest.search
                        } : {})
                    })
                    dispatch(Icd10Actions.getRoot(params))
                    break
                default:
            }

            setLoading(true)
        }
    }, [dispatch, searchRequest, loading]);
 
    const submit = (values, {setSubmitting}) => {
        dispatch({
            type: "PATHOMORPHOLOGY_FILTER", payload: saveObject(values)
        })
        setSubmitting(false)
    }

    const searchInputProps = {
        startAdornment: (
            <InputAdornment position="start">
                <Search className={classes.search_icon}/>
            </InputAdornment>
        ),
        disableUnderline: true,
    }

    const dateInputProps = (props) => {
        return {
            ...{
                ...props,
                ...{inputProps: {...props.inputProps, ...{disableUnderline: true, placeholder: 'дд/мм/гггг'}}}
            }
        }
    }
    const yearInputProps = (params) => {
        return {
            ...{
                ...params,
                ...{inputProps: {...params.inputProps, ...{placeholder: 'гггг'}}}
            }
        }
    }

    return (
        <Grid className={classes.content} key={moduleId}>
            <Typography className={classes.title}>Фильтры</Typography>
            <Formik
                initialValues={{
                    tags: null,
                    icd10Id: '',
                    scannerId: '',
                    operatorId: '',
                    studyDate: '',
                    studyInstanceUid: '',
                    personAcceptedId: '',
                    macroDescription: '',
                    microDescription: '',
                    seriesInstanceUid: '',
                    scannerResolutionId: '',
                    medicalInstitutionId: '',
                    histologicalDiagnosis: '',
                    createRangeDate: [null, null],
                    receiveRangeDate: [null, null],
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={submit}
            >
                {({
                      values,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form>
                        <InputLabel id="medical-institutions-label" className={classes.label}>
                            МО
                        </InputLabel>
                        <CustomAutocomplete
                            loading={!loading}
                            id="medicalInstitutionId"
                            className={classes.autocomplete}
                            value={values.medicalInstitutions}
                            options={medicalInstitutions.data}
                            onFocus={e => inputChange(e, 'medicalInstitutionId')}
                            inputChange={e => inputChange(e, 'medicalInstitutionId')}
                            getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                            onChange={(e, value) => setFieldValue('medicalInstitutionId', value ? value.id : '')}
                            renderOption={(props, option) => {
                                return <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                            }}
                        />
                        <InputLabel className={classes.date_label}>Год</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                            <DatePicker
                                views={['year']}
                                value={values.studyDate}
                                onChange={e => setFieldValue('studyDate', e)}
                                InputProps={{
                                    disableUnderline: true,
                               }}
                                renderInput={(params) =>  
                                    <TextField
                                        helperText={null}
                                        variant="standard"
                                        className={classes.select}
                                        {...yearInputProps(params)}
                                    />
                                }
                            />
                        </LocalizationProvider>
                        <InputLabel className={classes.date_label}>Дата загрузки</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                            <DateRangePicker
                                endText=""
                                startText=""
                                mask='__.__.____'
                                open={datePickerOpen}
                                name="createRangeDate"
                                value={values.createRangeDate}
                                onClose={() => setDatePickerOpen(false)}
                                onChange={e => setFieldValue('createRangeDate', e)}
                                renderInput={(startProps, endProps) =>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        className={classes.date}
                                        justifyContent="space-between"
                                    >
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            onClick={() => setDatePickerOpen(true)}
                                        >
                                            <Field
                                                name="date_from"
                                                variant="standard"
                                                component={TextFieldWithError}
                                                {...dateInputProps(startProps)}
                                                className={classes.date_text_field}
                                                classes={{root: classes.date_text_field_root}}
                                            />
                                            <Typography className={classes.date_typography}>-</Typography>
                                            <Field
                                                name="date_to"
                                                variant="standard"
                                                {...dateInputProps(endProps)}
                                                component={TextFieldWithError}
                                                className={classes.date_text_field}
                                                classes={{root: classes.date_text_field_root}}
                                            />
                                        </Stack>
                                        <Grid className={classes.date_icon_grid}>
                                            <IconButton
                                                onClick={() => setDatePickerOpen(true)}
                                            >
                                                <Calendar className={classes.date_icon}/>
                                            </IconButton>
                                        </Grid>
                                    </Stack>
                                }
                            />
                        </LocalizationProvider>
                        <InputLabel className={classes.date_label}>Поступило</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                            <DateRangePicker
                                endText=""
                                startText=""
                                mask='__.__.____'
                                name="receiveRangeDate"
                                value={values.receiveRangeDate}
                                open={receiveRangeDatePickerOpen}
                                onClose={() => setReceiveRangeDatePickerOpen(false)}
                                onChange={e => setFieldValue('receiveRangeDate', e)}
                                renderInput={(startProps, endProps) => 
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        className={classes.date}
                                        justifyContent="space-between"
                                    >
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            onClick={() => setReceiveRangeDatePickerOpen(true)}
                                        >
                                            <Field
                                                name="date_from"
                                                variant="standard"
                                                component={TextFieldWithError}
                                                {...dateInputProps(startProps)}
                                                className={classes.date_text_field}
                                                classes={{root: classes.date_text_field_root}}
                                            />
                                            <Typography className={classes.date_typography}>-</Typography>
                                            <Field
                                                name="date_to"
                                                variant="standard"
                                                {...dateInputProps(endProps)}
                                                component={TextFieldWithError}
                                                className={classes.date_text_field}
                                                classes={{root: classes.date_text_field_root}}
                                            />
                                        </Stack>
                                        <Grid className={classes.date_icon_grid}>
                                            <IconButton
                                                onClick={() => setReceiveRangeDatePickerOpen(true)}
                                            >
                                                <Calendar className={classes.date_icon}/>
                                            </IconButton>
                                        </Grid>
                                    </Stack>
                                }
                            />
                        </LocalizationProvider>
                        <InputLabel className={classes.label}>
                            Исследование
                        </InputLabel>
                        <Field
                            type="text"
                            align="center"
                            variant="standard"
                            name={`studyInstanceUid`}
                            inputProps={searchInputProps}
                            className={classes.text_field}
                            component={TextFieldWithError}
                            classes={{root: classes.date_text_field_root}}
                        />
                        <InputLabel className={classes.label}>
                            Серия
                        </InputLabel>
                        <Field
                            type="text"
                            align="center"
                            variant="standard"
                            onChange={setFieldValue}
                            name={`seriesInstanceUid`}
                            inputProps={searchInputProps}
                            className={classes.text_field}
                            component={TextFieldWithError}
                            classes={{root: classes.date_text_field_root}}
                        />
                        <InputLabel className={classes.label}>
                            Макроописание
                        </InputLabel>
                        <Field
                            type="text"
                            align="center"
                            variant="standard"
                            name={`macroDescription`}
                            inputProps={searchInputProps}
                            className={classes.text_field}
                            component={TextFieldWithError}
                            classes={{root: classes.date_text_field_root}}
                        />
                        <InputLabel className={classes.label}>
                            Микроописание
                        </InputLabel>
                        <Field
                            type="text"
                            align="center"
                            variant="standard"
                            name={`microDescription`}
                            inputProps={searchInputProps}
                            component={TextFieldWithError}
                            className={classes.text_field}
                            classes={{root: classes.date_text_field_root}}
                        />
                        <InputLabel className={classes.label}>
                            Гистологический диагноз
                        </InputLabel>
                        <Field
                            type="text"
                            align="center"
                            variant="standard"
                            inputProps={searchInputProps}
                            className={classes.text_field}
                            name={`histologicalDiagnosis`}
                            component={TextFieldWithError}
                            classes={{root: classes.date_text_field_root}}
                        />

                        <InputLabel id="icd10Id-label" className={classes.label}>
                            Код МКБ-10
                        </InputLabel>
                        <Icd10
                            values={values}
                            setFieldValue={setFieldValue}
                            className={classes.autocomplete}
                            onChange={(e, value) => setFieldValue('icd10Id', value ? value.id : '')}
                        />
                        <InputLabel id="scanner-id-label" className={classes.label}>
                            Модель сканера
                        </InputLabel>
                        <CustomAutocomplete
                            id="scannerId"
                            loading={!loading}
                            options={scanners.data}
                            value={values.scanners}
                            className={classes.autocomplete}
                            onFocus={e => inputChange(e, 'scannerId')}
                            inputChange={e => inputChange(e, 'scannerId')}
                            onChange={(e, value) => setFieldValue('scannerId', value ? value.id : '')}
                            getOptionLabel={option => option.hasOwnProperty('fullName') ? option.fullName : option}
                            renderOption={(props, option) => {
                                return <li {...props} key={option.id}>
                                    {option.fullName}
                                </li>
                            }}
                        />
                        <InputLabel id="scanner-resolution-id-label" className={classes.label}>
                            Разрешение
                        </InputLabel>
                        <CustomAutocomplete
                            loading={!loading}
                            id="scannerResolutionId"
                            loadingText='Загрузка...'
                            className={classes.autocomplete}
                            options={scannerResolutions.data}
                            value={values.scannerResolutions}
                            onFocus={e => inputChange(e, 'scannerResolutionId')}
                            inputChange={e => inputChange(e, 'scannerResolutionId')}
                            getOptionLabel={option => option.hasOwnProperty('value') ? option.value : option}
                            onChange={(e, value) => setFieldValue('scannerResolutionId', value ? value.id : '')}
                            renderOption={(props, option) => {
                                return <li {...props} key={option.id}>
                                    {option.value}
                                </li>
                            }}
                        />
                        <InputLabel id="person-accepted-id-label" className={classes.label}>
                            Принял
                        </InputLabel>
                        <CustomAutocomplete
                            loading={!loading}
                            id="personAcceptedId"
                            options={employees.data}
                            value={values.employees}
                            className={classes.autocomplete}
                            onFocus={e => inputChange(e, 'personAcceptedId')}
                            inputChange={e => inputChange(e, 'personAcceptedId')}
                            onChange={(e, value) => setFieldValue('personAcceptedId', value ? value.id : '')}
                            getOptionLabel={option => option.hasOwnProperty('firstName') ? getShortName(option) : option}
                            renderOption={(props, option) => {
                                return <li {...props} key={option.id}>
                                    {getShortName(option)}
                                </li>
                            }}
                        />
                        {(account?.role?.toString().toLowerCase() === 'admin' || account?.role?.toString().toLowerCase() === 'assistant') &&
                            <>
                                <InputLabel id="operator-id-label" className={classes.label}>
                                    Оператор
                                </InputLabel>
                                <CustomAutocomplete
                                    id="operatorId"
                                    loading={!loading}
                                    options={employees.data}
                                    value={values.employees}
                                    className={classes.autocomplete}
                                    onFocus={e => inputChange(e, 'operatorId')}
                                    inputChange={e => inputChange(e, 'operatorId')}
                                    onChange={(e, value) => setFieldValue('operatorId', value ? value.id : '')}
                                    getOptionLabel={option => option.hasOwnProperty('firstName') ? getShortName(option) : option}
                                    renderOption={(props, option) => {
                                        return <li {...props} key={option.id}>
                                            {getShortName(option)}
                                        </li>
                                    }}
                                />
                            </>
                        }
                        <Tags
                            limitTags={3}
                            freeSolo={false}
                            onChange={(e, value) => {setFieldValue('tags', value)}}
                        />
                        <Grid>
                            <Submit
                                type="submit"
                                variant="contained"
                                onClick={handleSubmit}
                                className={classes.submit}
                            >
                                Найти
                            </Submit>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
