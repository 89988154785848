/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect} from 'react'
import {createGuid} from "../../../App/helpers/createGuid";
import {SaveButtons} from "./Upload/SaveButtons";
import Stack from "@mui/material/Stack";
import {useSelector} from "react-redux";
import {Series} from "./Upload/Series";
import {Slides} from "./Upload/Slides";
import {makeStyles} from '@mui/styles';
import {Study} from "./Upload/Study";
import {Form, Formik} from "formik";
import {Upload} from "./Upload";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    container: {
        height: "100%",
        display: "grid",
        background: "white",
        gridTemplateColumns: "50px 200px 1fr",
    },
    form: {
        height: "100%",
        display: "grid",
        gridTemplateRows: "1fr 80px",
        borderLeft: "1px solid #DBDEDF",
    },
    form_container: {
        height: "100%",
        padding: "20px 30px",
        borderBottom: "1px solid #DBDEDF",
    },
    slides_container: {
        height: "100%",
        display: "grid",
        borderBottom: "1px solid #DBDEDF",
        gridTemplateRows: "50px 1fr"
    },
    description: {
        width: "100%",
    }
}))

export const UploadForm = (props) => {
    const {uploadStatus, setUploadStatus, tabId, handleChange} = props

    const classes = useStyles()

    const {study} = useSelector(state => state.pathomorphology)

    const getFormComponent = (values, seriesIndex, setFieldValue, errors) => {
        let Component
        switch (uploadStatus) {
            case 0:
                Component = Study
                break
            case 1:
                Component = Series
                break
            default:
                Component = Slides
                break
        }
        return <Component
            study={values}
            errors={errors}
            seriesIndex={seriesIndex}
            setFieldValue={setFieldValue}
        />
    }
 

    return (
        <Stack direction="row" alignItems="flex-start" className={classes.container}>
            <Formik
                key={study?.studyInstanceUid}
                initialValues={{
                    id: study?.id ?? null,
                    tags: study?.tags ?? [],
                    age: study?.age ?? null,
                    gender: study?.gender ?? null,
                    patientId: study?.patientId ?? null,
                    finalized: study ? study.finalized : false,
                    personAccepted: study?.personAccepted ?? null,
                    studyDate: study ? new Date(study.studyDate, 0, 1) : new Date(),
                    studyInstanceUid: study?.studyInstanceUid ?? '',
                    macroDescription: study?.macroDescription ?? null,
                    microDescription: study?.microDescription ?? null,
                    medicalInstitution: study?.medicalInstitution ?? null,
                    receivedDate: study ? study.receivedDate : new Date(),
                    histologicalDiagnosis: study?.histologicalDiagnosis ?? null, 
                    series: study?.series.map(series => {
                        series.localId = createGuid()
                        series.slides = series.slides.map(slide => {
                            slide.guid = createGuid()
                            return slide
                        })
                        return series
                    }) ?? [],
                }}
                validationSchema={Yup.object().shape({
                    gender: Yup.string().nullable(),
                    studyDate: Yup.string().required(`Выберите год`),
                    personAccepted: Yup.mixed().required(`Выберите сотрудника`),
                    medicalInstitution: Yup.mixed().required(`Выберите организацию`),
                    studyInstanceUid: Yup.string().required(`Введите № исследования`),
                    receivedDate: Yup.string().required(`Выберите дату поступления исследования`),
                    age: Yup.number()
                        .positive()
                        .nullable()
                        .min(0, 'Значение не может быть меньше 0')
                        .integer('Значение должно быть целым числом')
                        .max(110, 'Значение не может быть больше 110'),
                    histologicalDiagnosis: Yup.string()
                        .required(`Введите гистологический диагноз`)
                        .nullable()
                        .matches(/(\S)/, {
                            message: "Поле не должно быть пустым или состоять из одних пробелов!",
                            excludeEmptyString: true
                    }),
                    macroDescription: Yup.string()
                        .required(`Введите макроописание`)
                        .nullable()
                        .matches(/(\S)/, {
                            message: "Поле не должно быть пустым или состоять из одних пробелов!",
                            excludeEmptyString: true
                        }),
                    microDescription: Yup.string()
                        .required(`Введите микроописание`)
                        .nullable()
                        .matches(/(\S)/, {
                            message: "Поле не должно быть пустым или состоять из одних пробелов!",
                            excludeEmptyString: true
                        }),
                    series: Yup.array().of(Yup.object().shape({
                        seriesInstanceUid: Yup.mixed().required(`Введите № серии`),
                        slides: Yup.array().of(Yup.object().shape({
                            focus: Yup.boolean(),
                            additionalCode: Yup.string(),
                            scanner: Yup.mixed().required('Выберите сканер'),
                            scannerResolution: Yup.mixed().required('Выберите разрешение сканера'),
                        }))
                    }))

                })}
                onSubmit={() => {}}
            >
                {({
                    dirty,
                    errors,
                    values,
                    isValid,
                    validateForm,
                    isSubmitting,
                    setFieldValue,
                }) => {

                    useEffect(() => {
                        validateForm(values).then(() => {})
                    }, []);

                    const seriesIndex = values.series.findIndex(item => item?.active)
                    return (
                        <>
                            <Upload
                                tabId={tabId}
                                study={values}
                                errors={errors}
                                seriesIndex={seriesIndex}
                                handleChange={handleChange}
                                uploadStatus={uploadStatus}
                                setFieldValue={setFieldValue}
                                setUploadStatus={setUploadStatus}
                            />
                            <Stack key={seriesIndex} className={classes.form}>
                                <Form className={uploadStatus < 2 ? classes.form_container : classes.slides_container }>
                                    {getFormComponent(values, seriesIndex, setFieldValue, errors)}
                                </Form>
                                <SaveButtons
                                    dirty={dirty}
                                    tabId={tabId}
                                    study={values}
                                    isValid={isValid}
                                    seriesIndex={seriesIndex}
                                    isSubmitting={isSubmitting}
                                    setFieldValue={setFieldValue}
                                />
                            </Stack>
                        </>
                    )
                }}
            </Formik>
        </Stack>
    )
}

