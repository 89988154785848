import React from 'react'
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    arrow_cell: {
        width: 20,
    },
    cell: {
        fontWeight: 600,
        padding: '12px 16px 12px 8px',
        fontSize: "13px",
        lineHeight: "14px",
    }
}))

export const Head = (props) => {
    const classes = useStyles()
    const {columns} = props

    return (
        <TableHead>
            <TableRow>
                {columns.map(column => {
                    return column.field === 'arrow' ? <TableCell
                            key={column.field}
                            className={classes.arrow_cell}
                        /> : <TableCell
                            style={{width: column.width, fontWeight: 600}}
                            className={classes.cell}
                            key={column.field}
                        >
                            {column.name}
                        </TableCell>
                })}
            </TableRow>
        </TableHead>
    )
}
