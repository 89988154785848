import React from "react"
import {useDispatch, useSelector} from "react-redux"
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import {AlertActions} from "./actions/alert"
import MuiAlert from '@mui/material/Alert';
import {IconButton} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {AlertTitle} from "@mui/lab";

const useStyles = makeStyles(() => ({
    alert: {
        background: "white",
        borderRadius: "2px",
        filter: "drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.16))",
        boxShadow: "none",
        width: "564px"
    },
    alert_message: {
        color: "black",
        fontFamily: "Open Sans",
        fontWeight: 600,
        lineHeight: "20px"
    },
    alert_description: {
        color: "#495055",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px"
    }
}))

const colors = {
    success: "#33BE63",
    error: "#DF3826",
    info: "#FFA500",
}

const CustomAlert = React.forwardRef(function Alert(props, ref) {
    const {onClose} = props

    return <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled" {...props}
        action={
            <IconButton
                onClick={onClose}
                size="small"
            >
                <CloseIcon style={{color: "#495055"}}/>
            </IconButton>
        }
    />;
});

export const Alert = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {alerts} = useSelector(state => state.alerts)

    const close = (index) => {
        dispatch(AlertActions.clear(index))
    }

    if (alerts.length) {
        return alerts.map((alert, index) => {
            return (
                <Snackbar
                    key={alert.id}
                    open={true}
                    onClose={() => {
                        close(index)
                    }}
                    autoHideDuration={alert?.hasVirtualScroll ? null : 3500}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    style={{top: `${index ? index * 160 : 16}px`}}
                >
                    <CustomAlert
                        onClose={() => {
                            close(index)
                        }}
                        className={classes.alert}
                        style={{color: colors[alert.type]}}
                        severity={alert.type}
                        sx={{width: '100%'}}
                    >
                        <AlertTitle className={classes.alert_message}>{alert.message}</AlertTitle>
                    </CustomAlert>
                </Snackbar>
            )
        })
    } else {
        return null
    }
}
