import {HttpService} from '../../App/services/http'

export const AccountService = {
    account,
    registration
}

function account() {
    return HttpService.getMethod('/api/auth/account')
}

function registration(values) {
    return HttpService.postMethod('/api/auth/register', JSON.stringify(values))
}