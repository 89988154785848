import {HttpService} from "../../App/services/http";

export const ScannersService = {
    get,
    add,
    edit,
    getScanner,
    deleteScanner
}

function get(params) {
     return HttpService.getMethod('/api/scanners', `limit=15&${params}`)
}

function getScanner(id) {
    return HttpService.getMethod(`/api/scanners/${id}`)
}

function add(values) {
    return HttpService.postMethod('/api/scanners', JSON.stringify(values))
}

function edit(id, values) {
    return HttpService.patchMethod(`/api/scanners/${id}`, values)
}

function deleteScanner(id) {
    return HttpService.deleteMethod(`/api/scanners/${id}`)
}
