/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react"
import {CustomBreadcrumb} from "../../../App/components/CustomBreadcrumb";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Add from "../../../App/components/Svg/Add";
import {useNavigate} from "react-router-dom";
import {Grid, Tab, Box} from '@mui/material';
import {UploadStudy} from "./UploadStudy";
import {useDispatch} from "react-redux";
import {makeStyles} from '@mui/styles';
import {Data} from "./Data";


const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        height: "100%",
        background: "#F9FAFA",
        padding: "24px 0px 0px 24px;"
    },
    icon: {
        height: "16px",
        width: "16px",
        display: "block",
        marginTop: "2px",
        marginRight: "5px"
    },
    tab_list: {
        marginTop: "12px",
        backgroundColor: "white",
        borderBottom: "1px solid #DBDEDF",
        color: "#495055",
        height: "50px",
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#2595FF',
            borderRadius: "4px 4px 0px 0px",
            height: "4px"
        },
    },
    tab: {
        paddingBottom: "35px",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        textTransform: "none",
        '&.Mui-selected': {
            color: '#2595FF',
        },
    },
    tab_panel: {
        padding: 0
    }
}))

export const Radiodiagnosis = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const navigate = useNavigate()
    const {partitionId, tabId} = props

    const [loading, setLoading] = useState(false);

    const clearState = (newValue = null) => {
        dispatch({type: "RADIODIAGNOSIS_VALUE", payload: null})
        navigate(`/database/radiodiagnosis/${newValue}`)
    }

    const handleChangeTabValue = (event, newValue) => {
        clearState(newValue)
    }

    return (
        <Grid className={classes.container}>
            <CustomBreadcrumb
                partitionId={partitionId}
                onClickPath={() => clearState('data')}
            />
            <Grid style={{width: "98%"}}>
                <TabContext value={tabId}>
                    <Box>
                        <TabList
                            className={classes.tab_list}
                            onChange={handleChangeTabValue}
                            aria-label="Пункт меню"
                        >
                            <Tab
                                className={classes.tab}
                                label="Список исследований" value="data"
                            />
                            <Tab
                                className={classes.tab}
                                label="Добавить исследование"
                                icon={<Add className={classes.icon} fontSize="small"/>}
                                iconPosition="start"
                                value="add"
                            />
                        </TabList>
                    </Box>
                    <TabPanel className={classes.tab_panel} value="data">
                        <Data
                            loading={loading}
                            setLoading={setLoading}
                        />
                    </TabPanel>
                    <TabPanel className={classes.tab_panel} value="add">
                        <UploadStudy/>
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    )
}
