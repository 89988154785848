const mdash = `\u2014`;

function getOperationName(row) {
    switch (row) {
        case 'Success':
            return 'Успешно'
        case 'Failure':
            return 'Не успешно'      
        default:
            return mdash
    }}

    function getOperationDateTime(date, time) {
       const dateTime = date + 'T' + time + 'Z'
       return dateTime
    }

export {
    getOperationDateTime,
    getOperationName,
    mdash,
};
