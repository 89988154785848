import React from "react"
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    dialog_title: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        lineHeight: "20px"
    },
    cancel_button: {
        background: "#F9FAFA",
        border: "1px solid #DBDEDF",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        color: "black",
        '&:hover': {
            background: "#F9FAFA",
        },
        marginRight: "10px"
    },
    accept_button: {
        background: "#2595FF",
        border: "1px solid #2595FF",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        color: "white",
        '&:hover': {
            background: "#2595FF",
        }
    },
    dialog_description: {
        color: "#495055",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px"
    }
}))

export const DeleteSeries = (props) => {
    const classes = useStyles();
    const {open, onClose, onClickDelete} = props


    return (
        <Dialog
            open={open}
        >
            <DialogTitle className={classes.dialog_title}>
                Вы действительно хотите удалить серию?
            </DialogTitle>
            <DialogActions>
                <Button
                    className={classes.cancel_button}
                    variant="contained"
                    onClick={onClose}
                >
                    Отмена
                </Button>
                <Button
                    className={classes.accept_button}
                    variant="contained"
                    autoFocus
                    onClick={onClickDelete}
                >
                    Да
                </Button>
            </DialogActions>
        </Dialog>
    );
};
