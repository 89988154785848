import httpBuildQuery from "../../App/helpers/httpBuildQuery";
import {saveObject} from "../../App/helpers/saveObject";
import {GeneticService} from '../services/genetics'

export const GeneticActions = {
    studies,
    getStudy,
    sendStudies,
    editStudies,
    sendResource,
    deleteResource,
    downloadResource,
    requestReport,
    finalize,
    getResource
}

function studies(filter, moduleId) {
    return dispatch => new Promise((resolve, reject) => {
        GeneticService.studies(httpBuildQuery(saveObject({...{interpretationType: moduleId, limit: 15}, ...filter})), moduleId)
            .then(
                response => {
                    dispatch({type: "GENETIC_STUDIES", payload: response})
                    resolve(response)
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getStudy(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        GeneticService.getStudy(id)
            .then(
                response => {
                    dispatch({type: "GENETIC_STUDY", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function sendStudies(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        GeneticService.sendStudies(values)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function editStudies(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        GeneticService.editStudies(id, values)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function sendResource(file, formData, events) {
    return dispatch => new Promise((resolve, reject) => {
        GeneticService.sendResource(file, formData, events)
            .then(
                () => {
                    dispatch({type: "UPLOAD_MANAGER_SUCCESS_SEND", payload: {guid: file.guid}})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function deleteResource(studyInstanceUid, slideInstanceUid) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        GeneticService.deleteResource(studyInstanceUid, slideInstanceUid)
            .then(
                () => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function finalize(studyInstanceUid) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        GeneticService.finalize(studyInstanceUid)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function requestReport(studyInstanceUid, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        GeneticService.requestReport(studyInstanceUid, values)
            .then(
                () => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getResource(name) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        GeneticService.getResource(name)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    reject(error.message)
                }
            )
    })
}

function downloadResource(id) {
    return dispatch => new Promise((resolve, reject) => {
        GeneticService.downloadResource(id)
            .then(
                response => {
                    let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
                    let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${filename}.${extension}`.replaceAll('"', ''));
                    document.body.appendChild(link);
                    link.click();
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}
