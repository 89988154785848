import httpBuildQuery from "../../App/helpers/httpBuildQuery";
import {EMCService} from '../services/emc'

export const EMCActions = {
    patients,
    tricks,
    results,
    recipes,
    procedures,
    surveys,
    diagnostics,
    patientStatistic
}

function patients(filter) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        EMCService.patients(httpBuildQuery(filter))
            .then(
                response => {
                    dispatch({type: "EMC_VALUES", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function tricks(filter) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        EMCService.tricks(httpBuildQuery(filter))
            .then(
                response => {
                    dispatch({type: "EMC_VALUES", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function results(filter) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        EMCService.results(httpBuildQuery(filter))
            .then(
                response => {
                    dispatch({type: "EMC_VALUES", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function recipes(filter) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        EMCService.recipes(httpBuildQuery(filter))
            .then(
                response => {
                    dispatch({type: "EMC_VALUES", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function procedures(filter) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        EMCService.procedures(httpBuildQuery(filter))
            .then(
                response => {
                    dispatch({type: "EMC_VALUES", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function surveys(filter) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        EMCService.surveys(httpBuildQuery(filter))
            .then(
                response => {
                    dispatch({type: "EMC_VALUES", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function diagnostics(filter) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        EMCService.diagnostics(httpBuildQuery(filter))
            .then(
                response => {
                    dispatch({type: "EMC_VALUES", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function patientStatistic(hash) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        EMCService.patientStatistic(hash)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}
