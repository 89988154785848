/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {CustomBreadcrumb} from "../../../App/components/CustomBreadcrumb";
import {InfoOutlined, ModeEditOutlineOutlined} from '@mui/icons-material';
import {PathomorphologyActions} from "../../actions/pathomorphology";
import {TabContext, TabPanel, TabList} from "@mui/lab";
import {isEditTab} from "../../../App/helpers/tabPanel";
import Add from "../../../App/components/Svg/Add";
import {useNavigate} from "react-router-dom";
import {Grid, Tab} from '@mui/material';
import {UploadForm} from "./UploadForm";
import {makeStyles} from '@mui/styles';
import {Data} from "./Data";

const useStyles = makeStyles(() => ({
    container: {
        display: "grid",
        padding: "24px",
        background: "#F9FAFA",
        gridTemplateRows: "40px 64px 1fr",
    },
    icon: {
        height: "16px",
        width: "16px",
        display: "block",
        marginTop: "2px",
        marginRight: "5px"
    },
    tab_list: {
        marginTop: "8px",
        color: "#495055",
        backgroundColor: "white",
        borderBottom: "1px solid #DBDEDF",
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '&.MuiTabs-indicator': {
            height: "4px",
            backgroundColor: '#2595FF',
            borderRadius: "4px 4px 0px 0px",
        },
    },
    tab: {
        fontWeight: 400,
        fontSize: "14px",
        fontStyle: "normal",
        paddingBottom: "35px",
        textTransform: "none",
        '&.Mui-selected': {
            color: '#2595FF',
        },
    },
    tab_panel: {
        padding: 0,
    }
}))

export const Pathomorphology = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const classes = useStyles()
    const {partitionId, tabId} = props
    const {study, filter, isDirty} = useSelector(state => state.pathomorphology);
    const [uploadStatus, setUploadStatus] = useState(0)

    useEffect(() => {
        if (!study && isEditTab(tabId)) {
            dispatch(PathomorphologyActions.getStudy(tabId)).then(
                () => {},
                () => {navigate('/404')}
            )
        }
    }, [study, tabId])

    const cancelButton = () => {
        clearState("verification")
    }

    const clearState = (tab) => {
        if (isDirty) {
            dispatch({
                type: "PATHOMORPHOLOGY_OPEN_EXIT_DIALOG", payload: {
                    value: `/database/pathomorphology/${tab}`,
                    path: null,
                    setUploadStatus: setUploadStatus
                }
            })
        } else {
            dispatch({type: "PATHOMORPHOLOGY_STUDY", payload: null})
            setUploadStatus(0)
            navigate(`/database/pathomorphology/${tab}`)
        }
    }

    const handleChange = (e, newValue) => {
        dispatch({
            type: "PATHOMORPHOLOGY_FILTER", payload: {
                ...filter,
                ...{
                    finalized: (newValue !== 'needs-verification'),
                    offset: 0
                }
            }
        })
        clearState(newValue)
    }

    return ((isEditTab(tabId) && study) || !isEditTab(tabId)) ? (
        <Grid className={classes.container}>
            <CustomBreadcrumb
                partitionId={partitionId}
                onClickPath={cancelButton}
            />
            <TabContext value={tabId} style={{background: "red"}}>
                <TabList
                    className={classes.tab_list}
                    onChange={handleChange}
                    aria-label="Пункт меню"
                >
                    <Tab
                        className={classes.tab}
                        label="Список исследований"
                        value="verification"
                    />
                    <Tab
                        className={classes.tab}
                        label="Требует проверки"
                        icon={<InfoOutlined className={classes.icon} fontSize="small"/>}
                        iconPosition="start"
                        value="needs-verification"
                    />
                    <Tab
                        className={classes.tab}
                        label="Добавить исследование"
                        icon={<Add className={classes.icon} fontSize="small"/>}
                        iconPosition="start"
                        value="add"
                    />
                    {isEditTab(tabId) && <Tab
                        className={classes.tab}
                        label={study?.studyInstanceUid ?? 'Редактировать'}
                        icon={<ModeEditOutlineOutlined className={classes.icon} fontSize="small"/>}
                        iconPosition="start"
                        value={study?.studyInstanceUid}
                    />}
                </TabList>
                <TabPanel className={classes.tab_panel} value="verification">
                    <Data tabId={tabId}/>
                </TabPanel>
                <TabPanel className={classes.tab_panel} value="needs-verification">
                    <Data tabId={tabId}/>
                </TabPanel>
                <TabPanel className={classes.tab_panel} value="add">
                    <UploadForm
                        tabId={tabId}
                        handleChange={handleChange}
                        uploadStatus={uploadStatus}
                        setUploadStatus={setUploadStatus}
                    />
                </TabPanel>
                <TabPanel
                    className={classes.tab_panel} 
                    value={encodeURIComponent(study?.studyInstanceUid)}
                >
                    <UploadForm
                        tabId={tabId}
                        handleChange={handleChange}
                        uploadStatus={uploadStatus}
                        setUploadStatus={setUploadStatus}
                    />
                </TabPanel>
            </TabContext>
        </Grid>
    ) : null
}
