import React, {useState} from 'react';
import {TextFieldWithError} from '../../App/components/Input/TextField';
import {AuthActions} from '../../Auth/actions/authentication';
import httpBuildQuery from '../../App/helpers/httpBuildQuery';
import Eye from '../../App/components/Svg/Eye';
import {Field, Form, Formik} from 'formik';
import {useDispatch} from 'react-redux';
import {makeStyles} from '@mui/styles';
import * as Yup from "yup";
import clsx from "clsx";
import {
    InputAdornment,
    IconButton,
    Typography,
    Button,
    Grid,
} from '@mui/material';


const useStyles = makeStyles(() => ({
container: {
    top: 0,
    left: 0,
    zIndex: "100",
    width: "100vw",
    height: "100vh",
    display: "flex",
    position: "fixed",
    alignItems: "center",
    transform: "scale(0)",
    justifyContent: "center",
    background: "rgba(0,0,0,0.4)",
},
active: {
    transform: "scale(1)",
},
form_container: {
    padding: "30px",
    backgroundColor: "white",
},
buttons_container: {
    display: "flex",
    marginTop: "80px",
    alignItems: "center",
    justifyContent: "center",
    '& .MuiButtonBase-root': {
        color: 'white'
    }
},
}))

const styles = {
    title: {
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '44px',
        fontStyle: 'normal',
        marginBottom: "20px",
    },
    input: {
        width: "350px",
        height: '50px',
    }
}

export const PasswordPopover = (props) => {
    const {active, setActive} = props

    const dispatch = useDispatch();
    const classes = useStyles()

    const [textFieldPassType, setTextFieldPassType] = useState('password')

    const passwordProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    component="span"
                    onMouseDown={() => {
                        setTextFieldPassType('text')
                    }}
                    onMouseUp={() => {
                        setTextFieldPassType('password')
                    }}
                >
                    <Eye/>
                </IconButton>
            </InputAdornment>
        ),
    }

    const submit = (values, {setSubmitting}) => {
        let params = httpBuildQuery({
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
        })
        dispatch(AuthActions.change_pass(params)).then(
            _ => {
                dispatch({
                    type: "ALERT_SUCCESS", payload: {
                        message: 'Пароль успешно изменен',
                        type: 'success'
                    }
                })
                setActive(false)
            }
        )
            setSubmitting(false)
    }

    return (
        <Grid
            className={active ? clsx(classes.container, classes.active) : classes.container}
            onClick={() => {setActive(false)}}
        >
            <Formik
                initialValues={{}}
                validationSchema={Yup.object().shape({
                    oldPassword: Yup.string()
                        .required("Введите текущий пароль"),
                    newPassword: Yup.string()
                        .required("Введите новый пароль"),
                    passwordConfirmation: Yup.string()
                        .oneOf([Yup.ref('newPassword'), null], 'Пароли должны совпадать')
                        .required("Введите новый пароль еще раз")
                })}
                onSubmit={submit}
            >
                {({
                    dirty,
                    isValid,
                    isSubmitting,
                    handleSubmit,
                }) => (
                    <Form className={classes.form}>
                        <Grid
                            className={classes.form_container}
                            onClick={(e) => e.stopPropagation()}
                        >
                        <Typography sx={styles.title}>Смена пароля</Typography>
                            <Field
                                required
                                size="small"
                                sx={styles.input}
                                variant="outlined"
                                name={`oldPassword`}
                                label="Текущий пароль"
                                type={textFieldPassType}
                                inputProps={passwordProps}
                                placeholder="Текущий пароль"
                                component={TextFieldWithError}
                            />
                            <Field
                                required
                                size="small"
                                sx={styles.input}
                                variant="outlined"
                                label="Новый пароль"
                                name={`newPassword`}
                                type={textFieldPassType}
                                placeholder="Новый пароль"
                                style={{margin: "0 20px"}}
                                inputProps={passwordProps}
                                component={TextFieldWithError}
                            />
                            <Field
                                required
                                size="small"
                                sx={styles.input}
                                variant="outlined"
                                type={textFieldPassType}
                                inputProps={passwordProps}
                                name={`passwordConfirmation`}
                                component={TextFieldWithError}
                                label="Подтверждение нового пароля"
                                placeholder="Подтверждение нового пароля"
                            />
                            <Grid className={classes.buttons_container}>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    style={{marginRight: "20px"}}
                                    onClick={() => setActive(false)}
                                >
                                    Отменить
                                </Button>
                                <Button
                                    type='submit'
                                    color="success"
                                    variant='contained'
                                    onClick={handleSubmit}
                                    disabled={isSubmitting || !isValid || !dirty}
                                >
                                    Сохранить
                                </Button>
                            </Grid>
                        </Grid>                            
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}