const filter = {
    limit: 10
}

const initialState = {
    total_pages: 0,
    filter: filter,
    value: null,
    next: null,
    values: [],
    total: 0,
    page: 1,
}


export default function doctors(state = initialState, action) {
    switch (action.type) {
        case "DOCTORS":
            return {
                ...state,
                ...{
                    bundle: action.payload,
                    total_pages: action.payload.totalPages,
                    values: action.payload.resources,
                    total: action.payload.total,
                }
            }
        case "DOCTORS_CLEAR":
            return {
                ...state,
                ...{
                    filter: filter,
                    values: [],
                    next: null,
                    page: 1,
                }
            }
            case "DOCTORS_FILTER":
                return {
                    ...state,
                    ...{
                        filter: action.payload,
                        next: null,
                        page: 1

                    }
                }
            case "DOCTORS_CURRENT_PAGE":
                return {
                    ...state,
                    ...{
                        page: action.payload,
                    }
                }
        default:
            return state
    }
}

