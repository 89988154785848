import {HttpService} from "../../App/services/http"

export const RadiodiagnosisService = {
    getStudy,
    sendSlide,
    deleteFile,
    deleteStudy,
    radioValues,
}

function radioValues(filter = '') {
    return HttpService.getMethod(`/api/radio/studies`, filter)
}

function getStudy(studyInstanceUid) {
    return HttpService.getMethod(`/api/radio/studies/${studyInstanceUid}`)
}

function deleteStudy(studyInstanceUid) {
    return HttpService.deleteMethod(`/api/radio/studies/${studyInstanceUid}`)
}

function deleteFile(studyInstanceUid, seriesInstanceUid, sopInstanceUid) {
    return HttpService.deleteMethod(`/api/radio/studies?studyInstanceUid=${studyInstanceUid}&seriesInstanceUid=${seriesInstanceUid}&sopInstanceUid=${sopInstanceUid}`)
}

function sendSlide(params, values, events) {
    return HttpService.postMethod(`/api/radio/instances/batch?anonymize=${params.anonymize}&medicalInstitutionId=${params.medicalInstitutionId}`, values, true, events, params.guid)
}
