import React from 'react'
import {Submit} from "../../../App/components/Button/Submit";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    add_button: {
        height: '32px',
        fontWeight: 600,
        fontSize: '14px',
        marginTop: "25px",
        marginLeft: "2px",
        boxShadow: 'none',
        lineHeight: '20px',
        fontStyle: 'normal',
        background: '#33BE63',
        fontFamily: 'Open Sans',
        '&:hover': {
            background: '#33BE63',
        }
    },
}))

export const EditOrSave = (props) => {
    const classes = useStyles()
    const {entity, handleSubmit} = props

    return (
        <Submit
            className={classes.add_button}
            variant='contained'
            onClick={handleSubmit}
            type='submit'
        >
            {entity ? 'Сохранить' : 'Добавить'}
        </Submit>
    )
}
