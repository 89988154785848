/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";

import {Icd10Actions} from "../../../Dictionary/actions/icd10";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import httpBuildQuery from "../../helpers/httpBuildQuery";
import {CustomAutocomplete} from "./CustomAutocomplete";
import {getTargetValue} from "../../helpers/textField";
import {createGuid} from "../../helpers/createGuid";
import {useDebounce} from "use-debounce";
import {makeStyles} from '@mui/styles';
import clsx from "clsx";
import {
    ListItemIcon,
    IconButton,
    Typography,
    Stack,
} from '@mui/material';

const useStyles = makeStyles(() => ({
    autocomplete_stack: {
        paddingLeft: '10px',
    },
    autocomplete_icon_disabled: {
        visibility: "hidden"
    },
    item_text: {
        whiteSpace: "break-spaces"
    }
}))

export const Icd10 = (props) => {

    const classes = useStyles()
    const dispatch = useDispatch()

    const {codes, opens} = useSelector(state => state.icd10)

    const [loadingIcd10, setLoadingIcd10] = useState(false)
    const [icd10Search, setIcd10Search] = useState(null)
    const [icd10SearchRequest] = useDebounce(icd10Search, 400)

    const {
        setFieldValue,
        inputChange,
        writeValue,
        readValue,
        className,
        onChange,
        values,
        label,
        id
    } = props

    useEffect(() => {
        if (!loadingIcd10) {
            let params = httpBuildQuery({
                offset: 0,
                isActive: true,
                limit: icd10SearchRequest ? 15 : 1000,
                search: icd10SearchRequest
            })

            dispatch(icd10SearchRequest ? Icd10Actions.getAll(params) : Icd10Actions.getRoot(params)).then(_ => {
                setLoadingIcd10(true)
            })
        }
    }, [dispatch, loadingIcd10]);


    useEffect(() => {
        setLoadingIcd10(false)
    }, [icd10SearchRequest]);

    const icd10InputChange = (e) => {
        dispatch({type: "ICD10_CODE_SET_OPENS", payload: []})
        setIcd10Search(getTargetValue(e))
    }

    const filterById = (arr, filterIds) => {
        let deletedIds = []
        const ids = arr.filter(item => {
            if (item.parent && filterIds.indexOf(item.parent.id) !== -1) {
                deletedIds.push(item.id)
                return false
            }
            return true
        })
        if (deletedIds.length) {
            return filterById(ids, deletedIds)
        }
        return ids
    }

    const onClickOption = (e, option) => {
        e.stopPropagation()
        if (opens.indexOf(option.id) !== -1) {
            dispatch({type: "ICD10_CODE_SET_OPENS", payload: opens.filter(open => open !== option.id)})
            dispatch({
                type: "ICD10_CODE_ADD_CHILDREN", payload: filterById(codes.data, [option.id])
            })
        } else {
            dispatch(Icd10Actions.getChildren(option.id)).then(
                response => {
                    let newCodes = []
                    codes.data.forEach(code => {
                        if (code.id === response[0].parent.id) {
                            newCodes.push(code)
                            response.forEach(children => {
                                children.level = children.level ? (children.level + 1) : code.level + 1
                                newCodes.push(children)
                            })
                        } else {
                            newCodes.push(code)
                        }
                    })
                    dispatch({type: "ICD10_CODE_ADD_CHILDREN", payload: newCodes})
                    dispatch({type: "ICD10_CODE_SET_OPENS", payload: opens.concat([option.id])})
                    setTimeout(() => {
                        document.querySelector(
                            `[data-code=${option.code}]`
                        )?.scrollIntoView();
                    }, 1)
                }
            )
        }
    }

    const hasAnyChildren = () => {
        return codes.data.filter(code => code?.hasChildren).length > 0
    }

    const renderOption = (option, props) => {
        return (
            <li {...props} data-code={option.code} key={createGuid()}>
                <Stack
                    style={{width: "100%", marginLeft: option.level ? `${16 * (option.level + 1)}px` : 0}}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    {hasAnyChildren() ? <ListItemIcon>
                        <IconButton
                            disabled={!option.hasChildren}
                            className={option.hasChildren ? classes.autocomplete_icon : clsx(classes.autocomplete_icon, classes.autocomplete_icon_disabled)}
                            size="small"
                            onClick={e => onClickOption(e, option)}
                        >
                            {opens?.indexOf(option.id) !== -1 ? <ExpandMore/> : <ExpandLess/>}
                        </IconButton>
                    </ListItemIcon> : null}
                    <Typography
                        className={classes.item_text}
                        variant="body2"
                    >
                        {option.code} {option.diagnosis}
                    </Typography>
                </Stack>
            </li>
        )
    }

    const handleChange = (e, value) => {
        setFieldValue(writeValue ?? 'parent', value)
    }

    return (
        <CustomAutocomplete
            label={label}
            freeSolo={false}
            id={id ?? "icd10"}
            options={codes.data}
            className={className}
            loading={loadingIcd10}
            value={readValue ?? values.icd10}
            onChange={onChange ?? handleChange}
            inputChange={inputChange ?? icd10InputChange}
            getOptionLabel={(option) => option.code + " " + option.diagnosis}
            renderOption={(props, option) => renderOption(option, props)}
        />
    )
}
