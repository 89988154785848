import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import {PathomorphologyActions} from "../../actions/pathomorphology";
import {DeleteStudy} from '../../../Account/Dialogs/DeleteStudy';
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {Modal} from '../../../App/components/Modal/Modal';
import {Tags} from '../../../App/components/Tags';
import {getSkeleton} from "../../helpers/study";
import {makeStyles} from '@mui/styles';
import {Row} from "./Data/Row";
import {
    TableContainer,
    Pagination,
    Typography,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Button,
    Paper,
    Table,
    Link,
    Grid
} from "@mui/material";

const useStyles = makeStyles(() => ({
    footer: {
        margin: "auto",
        paddingTop: "30px",
        width: "max-content",
        paddingBottom: "30px",
    },
    tools_container: {
        padding: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    stats_container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
    },
    stats_title: {
        fontWeight: "600",
        paddingRight: "14px",
        fontSize: "0.875rem",
    },
    stats_info: {
        fontWeight: "400",
        fontSize: "0.875rem",
    },
    paper: {
        boxShadow: 'none'
    },
    container: {
        height: "calc(100vh - 374px)"
    },
    arrow_cell: {
        width: 20,
    },
    cell: {
        padding: '5px',
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '18px',
    },
    download_all_button: {
        padding: "3px",
        fontSize: "12px",
        borderRadius: "2px",
        border: "1px solid #2595FF",
    },
    inactive_MO: {
        background: '#00000005',
        '& .MuiTableCell-root': {
            color: "#858181"
        }
    },
    modal: {
        width: "800px",
        height: "140px"
    },
    disabledBtn: {
        opacity: "0",
    }
}))


const columns = [
    {field: 'studyInstanceUid', name: '№ исслед.', width: 200},
    {field: 'seriesInstanceUid', name: 'Серия', width: 100},
    {field: 'slidesInstanceUid', name: 'Слайды', width: 100},
    {field: 'slideNumber', name: 'Слайдов в группе', width: 200},
    {field: 'year', name: 'Год', width: 150},
    {field: 'icd10', name: 'МКБ-10', width: 150},
    {field: 'icdO', name: 'МКБ-О-3', width: 150},
    {field: 'histologicalDiagnosis', name: 'Гист. диагноз', width: 150},
    {field: 'scanner', name: 'Модель сканера', width: 150},
    {field: 'scannerResolution', name: 'Разрешение сканера', width: 150},
    {field: 'macroDescription', name: 'Макро описание', width: 150},
    {field: 'microDescription', name: 'Микро описание', width: 150},
    {field: 'medicalInstitution', name: 'МО', width: 150},
    {field: 'createdDate', name: 'Дата загрузки', width: 150},
    {field: 'receivedDate', name: 'Поступило', width: 150},
    {field: 'personAccepted', name: 'Принял', width: 100},
    {field: 'operator', name: 'Оператор', width: 100},
    {field: 'age', name: 'Возраст', width: 100},
    {field: 'gender', name: 'Пол', width: 100},
];

export const Data = (props) => {
    const {moduleId, tabId} = props

    const classes = useStyles()
    const dispatch = useDispatch()

    const {files} = useSelector(state => state.uploadManager);
    const {total_pages, stats, studies, filter} = useSelector(state => state.pathomorphology);

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hiddenLoad, setHiddenLoad] = useState(false);
    const [checkedStudies, setCheckedStudies] = useState([]);
  
    const [tagsPopoverActive, setTagsPopoverActive] = useState(false)
    const [showDialog, setShowDialog] = useState({
        id: null,
        isShowDialog: false
    });
    
    useEffect(() => {
        if (!loading) {
            dispatch(PathomorphologyActions.studies({
                ...filter,
                ...{
                    finalized: Boolean(tabId === 'verification')
                }
            })).then(() => {
                setLoading(true)
                if (files.filter(file => file.percent < 100).length) {
                    setHiddenLoad(true)
                    setTimeout(() => {
                        setLoading(false)
                    }, 5000);
                }
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading]);

    useEffect(() => {
        setLoading(false)
    }, [filter, files]);

    const toPage = (event, value) => {
        dispatch({
            type: "PATHOMORPHOLOGY_FILTER",
            payload: {...filter, ...{offset: value === 1 ? 0 : (15 * value) - 15}}
        })
        setPage(value);
        setLoading(false)
    };

    const onClickDeleteStudy = (studyInstanceUid) => {
        dispatch(PathomorphologyActions.deleteStudy(studyInstanceUid)).then(() => {
            setShowDialog({id: null, isShowDialog: false})
            setLoading(false)
        })
    }

    const handleCheckStudy = (event, row) => {
        let updatedList = [...checkedStudies];
        if (event.target.checked) {
          updatedList = [...checkedStudies, row.studyInstanceUid];
        } else {
            updatedList = updatedList.filter(item => item !== row.studyInstanceUid)
        }
        setCheckedStudies(updatedList);
      };
    
    const getUndefinedSlides = (study) => {
        for (const seriesKey in study?.series) {
            for (const slideKey in study?.series[seriesKey]?.slides) {
                if (study?.series[seriesKey].slides[slideKey].fileType.toUpperCase() === 'UNDEFINED') {
                    return true
                }
            }
        }
        return false
    }

    return (
        <Grid key={moduleId} style={{height: "100%"}}>
            <Paper className={classes.paper}>
                <Grid className={classes.tools_container}>
                    {stats &&
                        <Grid className={classes.stats_container}>
                            <Typography className={classes.stats_title}>
                                Исследований: <span className={classes.stats_info}>
                                    {stats?.studyCount}
                                </span>
                            </Typography>                    
                            <Typography className={classes.stats_title}>
                                Серий: <span className={classes.stats_info}>
                                    {stats?.seriesCount}
                                </span>
                            </Typography>                    
                            <Typography className={classes.stats_title}>
                                Слайдов: <span className={classes.stats_info}>
                                    {stats?.slideCount}
                                </span>
                            </Typography>
                        </Grid>
                    }
                    {!!stats?.studyCount &&
                        <Link
                            href={`/api/patho/studies/bulk-download?access_token=${localStorage.getItem('token')}&${httpBuildQuery({
                                ...filter,
                                ...{
                                    limit: null,
                                    offset: null,
                                }
                            })}`}
                            target="_blank"
                            underline={"none"}
                        >
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                className={classes.download_all_button}
                            >
                                <DownloadOutlinedIcon/>
                                Скачать все
                            </Grid>
                        </Link>
                    }
                </Grid>
                <TableContainer>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => setTagsPopoverActive(true)}
                                        disabled={Boolean(!checkedStudies.length)}
                                        className={!checkedStudies.length ? classes.disabledBtn : null}
                                    >
                                        НАЗНАЧИТЬ ТЕГ
                                    </Button>
                                </TableCell>
                                {columns.map(column => (
                                    <TableCell
                                        key={column.field}
                                        className={classes.cell}
                                    >
                                        {column.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {loading || hiddenLoad ? studies.map(row => (
                            <TableBody
                                key={row.id}
                                className={row.medicalInstitution?.isActive ? null : classes.inactive_MO}
                            >
                                <Row
                                    row={row}
                                    type="study"
                                    key={row.id}
                                    setShowDialog={setShowDialog}
                                    isRed={getUndefinedSlides(row)}
                                    onCheckStudy={handleCheckStudy}
                                />
                            </TableBody>
                        )) : getSkeleton(9, 16)}
                    </Table>
                </TableContainer>
                <Grid className={classes.footer}>
                    <Pagination
                        page={page}
                        showLastButton
                        showFirstButton
                        onChange={toPage}
                        variant="outlined"
                        count={total_pages}
                    />
                </Grid>
            </Paper>
            <DeleteStudy
                open={showDialog.isShowDialog}
                closeDialog={() => setShowDialog({id: null, isShow: false})}
                onClickStudyDelete={() => {
                    onClickDeleteStudy(showDialog.id)
                }}
            />
            <Modal 
                className={classes.modal}
                active={tagsPopoverActive} 
                setActive={setTagsPopoverActive}
            >
                <Tags
                    checkedStudies={checkedStudies}
                    setActive={setTagsPopoverActive}
                />
            </Modal>
        </Grid>
    )
}
