import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import httpBuildQuery from '../../App/helpers/httpBuildQuery';
import {Icd10TableActions} from "../actions/icd10_table";
import {Status} from "../../App/components/Status"
import {Actions} from "./Buttons/Actions";
import {makeStyles} from "@mui/styles";
import {
    IconButton,
    TableCell,
    TableRow
} from '@mui/material';

const useStyles = makeStyles(() => ({
    row0: {
        background: "white"
    },
    row1: {
        background: "#F1F9FF",
    },
    row2: {
        background: "#E4F6E9"
    },
    row3: {
        background: "#FFDBDD"
    },
    row4: {
        background: "#FEE082"
    },
    row5: {
        background: "#7BBFFF"
    },
    row6: {
        background: "#93D8A4"
    }
}))

export const IcdRow = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const {filter: filterIcd10} = useSelector(state => state.icd10Table)

    const {
        setDeleteDialog,
        setEditDialog,
        setLoading,
        setUseRow,
        moduleId,
        setValue,
        level,
        type,
        row,
    } = props

    const onClickOpen = (id) => {
        let params = {}
        if (Object.keys(filterIcd10).length) {
            params = filterIcd10
        }
        open ? setOpen(!open) : dispatch(Icd10TableActions.getChildren(id, httpBuildQuery(params)))
        .then(() => {
            setOpen(!open)
        })
    }


    const getRowClassName = () => {
        switch (level) {
            case 1:
                return classes.row1
            case 2:
                return classes.row2
            case 3:
                return classes.row3
            case 4:
                return classes.row4
            case 5:
                return classes.row5
            case 6:
                return classes.row6
            default:
                return classes.row0
        }
    }

    const getArrow = (id) => {
        return <TableCell style={{paddingLeft: `${16 * (level + 1)}px`}}>
            <IconButton
                aria-label="Раскрыть"
                size="small"
                onClick={() => onClickOpen(id)}
            >
                {open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
            </IconButton>
        </TableCell>
    }

    return (
        <React.Fragment>
            <TableRow hover key={row.id} className={getRowClassName()}>
                {row.hasChildren ? getArrow(row.id) : <TableCell/>}
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.diagnosis}</TableCell>
                <TableCell><Status active={row.isActive}/></TableCell>
                <TableCell>
                    <Actions
                        row={row}
                        setValue={setValue}
                        moduleId={moduleId}
                        setUseRow={setUseRow}
                        setLoading={setLoading}
                        setEditDialog={setEditDialog}
                        setDeleteDialog={setDeleteDialog}
                    />
                </TableCell>
            </TableRow>
            {open && row.childrens ? row.childrens.map((children) => (
                <IcdRow
                    type={type}
                    key={children.id}
                    setDeleteDialog={setDeleteDialog}
                    setEditDialog={setEditDialog}
                    setLoading={setLoading}
                    setUseRow={setUseRow}
                    moduleId={moduleId}
                    setValue={setValue}
                    level={level + 1}
                    row={children}
                />
            )) : null}
        </React.Fragment>
    )
}
