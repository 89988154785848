import React, {useState} from "react"
import {useSelector} from "react-redux";
import httpBuildQuery from "../../../../App/helpers/httpBuildQuery";
import {getShortOperatorName} from "../../../../App/helpers/row";
import {Download} from "../components/Button/Download";
import {makeStyles} from '@mui/styles';
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import {
    DeleteSweepOutlined,
    KeyboardArrowDown,
    KeyboardArrowUp,
    FileDownload,
} from '@mui/icons-material';
import clsx from "clsx";
import {
    TableContainer,
    Typography,
    IconButton,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Table,
    Stack,
    Chip,
    Fade,
    Link,
    Box,
} from "@mui/material";

const useStyles = makeStyles(() => ({
    arrow_cell: {
        width: 20,
        padding: "0px",
    },
    cell: {
        padding: '5px',
        lineHeight: '18px',
        fontSize: '13px'
    },
    tableCell: {
        overflow: 'hidden',
        wordBreak: 'break-word',
        minWidth: '200px',
    },
    study: {
        '&:hover': {
            background: "#F6F8FF"
        }
    },
    series: {
        '&:hover': {
            background: "#F5F6F6"
        }
    },
    preview: {
        color: "#2595FF",
        cursor: "pointer"
    }
}))

export const Row = (props) => {
    const classes = useStyles()

    const {row, setShowDialog, setShowPreview, onClickGetCurrentSeriesUid, onClickGetCurrentStudyUid} = props;

    const {account} = useSelector(state => state.account);

    const [openSeries, setOpenSeries] = useState(null);
    const [open, setOpen] = useState(false);

    const onClickOpen = () => {
        setOpen((prevState) => !prevState);
    } 

    const onClickOpenSeries = (series) => {
        setOpenSeries((openSeries === series) ? null : series)
    }

    const getArrow = (series = null) => {
        return <TableCell className={classes.arrow_cell}>
            <Stack direction="row" alignItems="center">
                <IconButton
                    aria-label="Раскрыть"
                    size="small"
                    onClick={() => {
                        !!series ? onClickOpenSeries(series) : onClickOpen()
                    }}
                >
                    {!series ? (open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>) : null}
                    {!!series ? ((openSeries === series) ? <KeyboardArrowUp/> : <KeyboardArrowDown/>) : null}
                </IconButton>
            </Stack>
        </TableCell>
    }

    const getDelete = () => {
        return <TableCell style={{margin: "0px", padding: "5px"}}>
            <Stack direction="row" alignItems="center">
                <IconButton
                    aria-label="Удалить"
                    color="error"
                    onClick={() => {
                        setShowDialog({
                            id: row.studyInstanceUid,
                            isShowDialog: true
                        });
                    }}
                >
                    <DeleteSweepOutlined/>
                </IconButton> 
            </Stack>
        </TableCell>
    }

    const getDownload = () => {
        return <TableCell style={{margin: "0px", padding: "5px"}}>
            <Stack direction="row" alignItems="center">
                <Link
                    href={`/api/radio/studies/${row.studyInstanceUid}/series/bulk-download?access_token=${localStorage.getItem('token')}&${httpBuildQuery({
                        ...{
                            limit: null,
                            offset: null,
                        }
                    })}`}
                    target="_blank"
                    underline={"none"}
                >
                    <IconButton
                        aria-label='Скачать'
                        size='small'
                        color='primary'
                    >
                    <FileDownload/>
                    </IconButton>
                </Link> 
            </Stack>
        </TableCell>
    }

    const getDownloadButtons = (modality, study, series, instance) => {
        return <Stack direction="row" alignItems="center" key={row.id}>
            <Download
                modality={modality}
                href={`/api/radio/studies/${study}/series/${series}/instances/${instance}?access_token=${localStorage.getItem('token')}`}
            />
        </Stack>
    }

    const getTableCells = (data) => {
        return  (
            <>
                {(account?.role?.toString().toLowerCase() === 'employee') ? <TableCell/> : null}
                <TableCell className={classes.tableCell}>{data.patientId}</TableCell>
                <TableCell className={classes.tableCell}>{data?.studyInstanceUid}</TableCell>
                <TableCell className={classes.cell}>{data.series.map(item => item.modality) ?? '\u2014'}</TableCell>
                <TableCell className={classes.cell}>{data.medicalInstitution ? <Tooltip placement="right-start" arrow title={data.medicalInstitution?.name}>
                    <Chip color="primary" variant="outlined" label={data.medicalInstitution?.code} />
                </Tooltip> : null}</TableCell>
                <TableCell className={classes.cell}>{data.series.length ?? null}</TableCell>
                <TableCell className={classes.cell}>{data.series.reduce((result, series) => {
                    return result + series.instances.length
                }, 0) ?? null}</TableCell>
                <TableCell className={classes.cell}>{data.description ?? '\u2014'}</TableCell>
                <TableCell className={classes.cell}>{data.accessionNumber ?? '\u2014'}</TableCell>
                <TableCell className={classes.cell}>{data.studyId ?? '\u2014'}</TableCell>
                <TableCell className={classes.cell}>{data.referringPhysicianName ?? '\u2014'}</TableCell>
                <TableCell className={classes.cell}>{data.nameOfPhysiciansReadingStudy ?? '\u2014'}</TableCell>
                <TableCell className={classes.cell}>{data.performingPhysicianName ?? '\u2014'}</TableCell>
                <TableCell className={classes.cell}>{data.deviceDescription ?? '\u2014'}</TableCell>
                <TableCell className={classes.cell}>{data.studyDate ? format(new Date(data.studyDate), 'dd.MM.yyyy H:mm', {locale: ru}) : '\u2014'}</TableCell>
                <TableCell className={classes.cell}>{data.uploadedDate ? format(new Date(data.uploadedDate), 'dd.MM.yyyy H:mm', {locale: ru}) : '\u2014'}</TableCell>
                <TableCell className={classes.cell}>{data.operatorName ? getShortOperatorName(data.operatorName) : '\u2014'}</TableCell>
            </>
        )
    }

    return (
        <>
            <TableRow key={row.studyInstanceUid} className={classes.study}>
                {getArrow(false)}
                {(account?.role?.toString().toLowerCase() !== 'employee') ? getDelete() : null}
                {getDownload()}
                {getTableCells(row)}
            </TableRow>
            {(open && !!row.series.length) &&
                <Fade in={true} style={{transitionDelay: '50ms'}}>
                    <TableRow className={classes.series}>
                        <TableCell colSpan={18}>
                            <TableContainer>
                                <Table aria-label='sticky table'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell/>
                                            {[
                                                {field: 'seriesInstanceUid', name: '№ серии', width: 100},
                                                {field: 'instanceNumber', name: 'Кол-во снимков в серии', width: 200},
                                                {field: 'description', name: 'Описание', width: 200},
                                                {field: 'protocolName', name: 'Протокол', width: 200},
                                            ].map(column => (
                                                <TableCell
                                                    className={classes.cell}
                                                    key={column.field}
                                                    width={column.width}
                                                >
                                                    <Typography component="div" variant="subtitle2"><Box sx={{ fontWeight: 600 }}>{column.name}</Box></Typography>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.series.map(series => (
                                            <React.Fragment key={series.seriesInstanceUid}>
                                                <TableRow>
                                                    {getArrow(series.seriesInstanceUid)} 
                                                        <TableCell
                                                            className={clsx(classes.tableCell, classes.preview)}
                                                            onClick={() => {
                                                                onClickGetCurrentStudyUid(row.studyInstanceUid)
                                                                onClickGetCurrentSeriesUid(series.seriesInstanceUid)
                                                                setShowPreview(true)
                                                            }}
                                                        >
                                                            {series.seriesInstanceUid ?? '\u2014'}
                                                        </TableCell>
                                                    <TableCell className={classes.cell}>{series.instances.length}</TableCell>
                                                    <TableCell className={classes.cell}>{series.description ?? '\u2014'}</TableCell>
                                                    <TableCell className={classes.cell}>{series.protocolName ?? '\u2014'}</TableCell>
                                                </TableRow>
                                                {(openSeries && (openSeries === series.seriesInstanceUid) && !!series.instances.length) &&
                                                    <Fade
                                                        in={!!openSeries ? (openSeries === series.seriesInstanceUid) : false}
                                                        style={{transitionDelay: '50ms'}}>
                                                        <TableRow className={classes.series}>
                                                            <TableCell colSpan={6}>
                                                                <TableContainer>
                                                                    <Table aria-label='sticky table'>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                {[
                                                                                    {field: 'instanceId', name: '№ снимка', width: 200},
                                                                                    {field: 'modal', name: 'Модальность', width: 200},
                                                                                    {field: 'sliceLocation', name: 'Локация', width: 200},
                                                                                    {field: '', name: '', width: 150}
                                                                                ].map(column => (
                                                                                    <TableCell
                                                                                        className={classes.cell}
                                                                                        key={column.field}
                                                                                        width={column.width}
                                                                                    >
                                                                                        <Typography component="div" variant="subtitle2"><Box sx={{ fontWeight: 600 }}>{column.name}</Box></Typography>
                                                                                    </TableCell>
                                                                                ))}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {series.instances.map(instance => (
                                                                                <React.Fragment key={instance.sopInstanceUid}>
                                                                                    <TableRow>
                                                                                        <TableCell className={classes.cell}>{instance.sopInstanceUid ?? '\u2014'}</TableCell>
                                                                                        <TableCell className={classes.cell}>{series.modality ?? '\u2014'}</TableCell>
                                                                                        <TableCell className={classes.cell}>{instance.sliceLocation ?? '\u2014'}</TableCell>
                                                                                        <TableCell className={classes.cell}>{getDownloadButtons(series.modality, row.studyInstanceUid, series.seriesInstanceUid, instance.sopInstanceUid)}</TableCell>
                                                                                    </TableRow>
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </TableCell>
                                                        </TableRow>
                                                    </Fade>
                                                }
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TableCell>
                    </TableRow>
                </Fade>
            }
        </>
    );
}

