/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {CustomAutocomplete} from "../../../App/components/Input/CustomAutocomplete";
import {getTargetValue, textFieldProps} from "../../../App/helpers/textField";
import {ScannerResolutionsActions} from "../../actions/scannerResolutions";
import {TextFieldWithError} from "../../../App/components/Input/TextField";
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {saveObject} from "../../../App/helpers/saveObject";
import {ScannersActions} from "../../actions/scanners";
import {useDispatch, useSelector} from "react-redux";
import {EditOrSave} from "../Buttons/EditOrSave";
import {Field, Form, Formik} from 'formik';
import {useDebounce} from "use-debounce";
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    form: {
        background: "white",
        padding: "10px",
        minHeight: "730px",
    },
    text_field: {
        width: "350px",
        fontSize: "14px",
        '& .MuiInputLabel-root': {
            marginLeft: "5px",
            marginTop: "2px"
        },
        '& .MuiInput-root': {
            paddingLeft: "5px",
            borderRadius: "2px",
            border: "1px solid #DBDEDF",
        },
    },
    autocomplete: {
        marginLeft: "5px",
        width: "350px",
    }
}))

export const AddAndEdit = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {scannerResolution} = useSelector(state => state.scannerResolutions);
    const {scanners} = useSelector(state => state.scanners);
    const [loadingScanners, setLoadingScanners] = useState(false)
    const {setValue, setLoading} = props

    const [scannersSearch, setScannersSearch] = useState(null)
    const [scannersSearchRequest] = useDebounce(scannersSearch, 400)

    useEffect(() => {
        if (!loadingScanners) {
            const params = httpBuildQuery({
                offset: 0,
                limit: 1000,
                ...(scannersSearchRequest ? {
                    name: scannersSearchRequest
                } : {})
            })

            dispatch(ScannersActions.get(params)).then(_ => {
                setLoadingScanners(true)
            })
        }
    }, [dispatch, loadingScanners]);

    useEffect(() => {
        setLoadingScanners(false)
    }, [scannersSearchRequest]);

    const scannersInputChange = (e) => {
        setScannersSearch(getTargetValue(e))
    }

    const submit = (values) => {
        return dispatch(scannerResolution ? ScannerResolutionsActions.edit(scannerResolution.id, saveObject(values)) : ScannerResolutionsActions.add(saveObject(values))).then(
            _ => {
                setValue('1')
                setLoading(false)
                dispatch({type: "SCANNER_RESOLUTION", payload: null})
            }
        )
    }

    return (
        <Grid>
            <Formik
                initialValues={{
                    value: scannerResolution ? scannerResolution.value : '',
                    scanner: scannerResolution ? scannerResolution.scanner : '',
                    isActive: scannerResolution ? scannerResolution.isActive : true
                }}
                validationSchema={
                    Yup.object().shape({
                        value: Yup.string().required("Введите разрешение сканера"),
                        scanner: Yup.object().shape({}).nullable().required("Выберите название сканера")
                })}
                onSubmit={submit}
            >
                {({
                      values,
                      errors,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form className={classes.form}>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Field
                                align="center"
                                type="text"
                                size="small"
                                label="Разрешение"
                                required
                                variant="standard"
                                name={`value`}
                                inputProps={textFieldProps}
                                className={classes.text_field}
                                component={TextFieldWithError}
                            />
                            <Grid item>
                                <CustomAutocomplete
                                    required
                                    label="Сканер"
                                    id="scanner"
                                    className={classes.autocomplete}
                                    options={scanners.data}
                                    loading={!loadingScanners}
                                    getOptionLabel={option => option.hasOwnProperty('fullName') ? option.fullName : option}
                                    error={Boolean(errors.scanner)}
                                    helperText={errors.scanner}
                                    onChange={(e, value) => setFieldValue('scanner', value)}
                                    value={values.scanner}
                                    inputChange={scannersInputChange}
                                />
                            </Grid>
                        </Grid>
                        <EditOrSave
                            entity={scannerResolution}
                            handleSubmit={handleSubmit}
                        />
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
