import React from 'react'
import Typography from "@mui/material/Typography";
import LensIcon from '@mui/icons-material/Lens';
import {makeStyles} from "@mui/styles";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles(() => ({
    active: {
        color: "#33BE63",
        width: "10px",
        marginRight: "5px"
    },
    disable: {
        color: "#DF3826",
        width: "10px",
        marginRight: "5px"
    }
}))

export const Status = (props) => {
    const classes = useStyles()
    const {active} = props

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            <LensIcon className={active ? classes.active : classes.disable}/>
            <Typography>
                {active ? 'Активный' : 'Не активный'}
            </Typography>
        </Grid>
    )
}
