import React from 'react'
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    text_field: {
        width: "100%",
        fontSize: "14px",
        background: "white"
    },
}))
export const CustomAutocomplete = (props) => {
    const classes = useStyles()
    const {
        id,
        value,
        label,
        error,
        onInput,
        loading,
        options,
        onFocus,
        disabled,
        onChange,
        required,
        freeSolo,
        className,
        helperText,
        inputChange,
        renderOption,
        disablePortal,
        getOptionLabel,
    } = props


    return (
        <Autocomplete
            id={id}
            value={value}
            options={options}
            onInput={onInput}
            onFocus={onFocus}
            loading={!loading}
            freeSolo={freeSolo}
            onChange={onChange}
            disabled={disabled}
            className={className}
            loadingText='Загрузка...'
            onInputChange={inputChange}
            renderOption={renderOption}
            getOptionLabel={getOptionLabel}
            noOptionsText='Ничего не найдено!'
            onClose={() => inputChange(false)}
            filterOptions={options => options}
            disablePortal={disablePortal ?? true}
            renderInput={(params) => {
                return <TextField
                    error={error}
                    label={label}
                    variant="standard"
                    helperText={helperText}
                    required={required ?? false}
                    className={classes.text_field}
                    {...{
                        ...params,
                        ...{InputProps: {...params.InputProps, ...{disableUnderline: true}}}
                    }}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                    }}
                />
            }}
        />
    )
}
