function setChildren(code, data) {
    if (code.id === data[0].parent.id) {
        code.childrens = data
    } else if (code.childrens) {
        code.childrens = code.childrens.map(children => {
            return setChildren(children, data)
        })
    }
    return code
}

export default function icd10Table(state = {
    codes: {
        data: [],
        total: 0,
        total_pages: 0
    },
    code: null,
    opens: [],
    filter: {}
}, action) {
    switch (action.type) {
        case "ICD10_TABLE_CODES":
            return {
                ...state,
                ...{
                    codes: {
                        data: action.payload.data.map(code => {
                            code.level = 0
                            return code
                        }),
                        total: action.payload.total,
                        total_pages: action.payload.totalPages
                    }
                }
            }
        case "ICD10_TABLE_CODE_ADD_CHILDREN":
            return {
                ...state,
                ...{
                    icd10_TABLE_codes: {
                        data: state.codes.data.map(code => {
                            return setChildren(code, action.payload)
                        }),
                        total_pages: state.codes.total_pages
                    }
                }
            }
        case "ICD10_TABLE_CODE_SET_OPENS":
            return {
                ...state,
                ...{
                    opens: action.payload
                }
            }
        case "ICD10_TABLE_CODE":
            return {
                ...state,
                ...{
                    code: action.payload
                }
            }
        case "ICD10_TABLE_FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload
                }
            }
        default:
            return state
    }
}

