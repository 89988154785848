import React from 'react'
import {MedicalInstitutionsActions} from "../actions/medicalInstitution";
import {BiomaterialSourcesActions} from "../actions/biomaterialSources";
import {ScannerResolutionsActions} from "../actions/scannerResolutions";
import {EmployeePositionActions} from "../actions/employeePositions";
import {Icd10TableActions} from "../actions/icd10_table";
import DialogActions from '@mui/material/DialogActions';
import {EmployeesActions} from "../actions/employees";
import {ScannersActions} from "../actions/scanners";
import DialogTitle from '@mui/material/DialogTitle';
import {TagsActions} from '../actions/tags';
import {DialogContent} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux"
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles(() => ({
    cancel_button: {
        background: "#F9FAFA",
        border: "1px solid #DBDEDF",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        color: "black",
        '&:hover': {
            background: "#F9FAFA",
        },
        marginRight: "10px"
    },
    accept_button: {
        background: "#2595FF",
        border: "1px solid #2595FF",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        color: "white",
        '&:hover': {
            background: "#2595FF",
        }
    },
    text_field: {
        border: "1px solid #DBDEDF",
        marginBottom: "15px",
        borderRadius: "2px",
        marginTop: "5px",
        padding: "2px",
        width: "88%",
        height: "30px"
    },
    dialog_title: {
        fontFamily: "Open Sans",
        fontWeight: 600,
        lineHeight: "20px"
    },
    dialog_description: {
        color: "#495055",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px"
    }
}))


export const DictionaryDialog = (props) => {
    const dispatch = useDispatch()
    const {
        deleteDialog,
        setDeleteDialog,
        setEditDialog,
        setLoading,
        moduleId,
        row
    } = props
    const classes = useStyles()

    const handleClose = () => {
        setDeleteDialog(false);
        setEditDialog(false);
    };

    const getDeleteAction = (id) => {
        switch (moduleId) {
            case 'biomaterial_sources':
                return BiomaterialSourcesActions.deleteBiomaterialSource(id)
            case 'scanners':
                return ScannersActions.deleteScanner(id)
            case 'medicalInstitutions':
                return MedicalInstitutionsActions.deleteMedicalInstitution(id)
            case 'employee_positions':
                return EmployeePositionActions.deletePosition(id)
            case 'scanner_resolutions':
                return ScannerResolutionsActions.deleteScannerResolution(id)
            case 'employees':
                return EmployeesActions.deleteEmployee(id)
            case 'icd10':
                return Icd10TableActions.deleteIcd10(id)
            case 'tags':
                return TagsActions.deleteTag(id)
            default:
                return null
        }
    }

    const deleteSubmit = () => {
        return dispatch(getDeleteAction(row.id)).then(
            _ => {
                setLoading(false)
                handleClose()
            }
        )
    }

    return row ? (
        <Grid>
            <Dialog
                open={deleteDialog}
                onClose={handleClose}
            >
                <DialogTitle className={classes.dialog_title}>
                    Удалить значение справочника ?
                </DialogTitle>
                <DialogContent className={classes.dialog_description}>После удаления, Вы сможете активировать его в
                    любой момент.</DialogContent>
                <DialogActions>
                    <Button
                        className={classes.cancel_button}
                        variant="contained"
                        onClick={handleClose}
                    >
                        Отмена
                    </Button>
                    <Button
                        className={classes.accept_button}
                        variant="contained"
                        onClick={deleteSubmit}
                        autoFocus
                    >
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    ) : null
}
