/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect, useState} from 'react'
import {ScannerResolutionsActions} from '../../../../Dictionary/actions/scannerResolutions';
import {CustomAutocomplete} from "../../../../App/components/Input/CustomAutocomplete";
import {TextFieldWithError} from "../../../../App/components/Input/TextField";
import NotSelected from "../../../../App/components/Svg/Checkbox/NotSelected";
import {ScannersActions} from "../../../../Dictionary/actions/scanners";
import Selected from "../../../../App/components/Svg/Checkbox/Selected";
import {Head} from "../../../../Dictionary/components/DataTable/Head";
import httpBuildQuery from "../../../../App/helpers/httpBuildQuery";
import {Icd10Actions} from "../../../../Dictionary/actions/icd10";
import {textFieldProps} from "../../../../App/helpers/textField";
import {Icd10} from "../../../../App/components/Input/Icd10";
import {Tags} from '../../../../App/components/Input/Tags';
import {useDispatch, useSelector} from "react-redux";
import {UploadButtons} from "./Slides/UploadButtons";
import {FileInfo} from "./Slides/FileInfo";
import {useDebounce} from "use-debounce";
import {makeStyles} from '@mui/styles';
import {Field} from "formik";
import {
    TableContainer,
    Typography,
    TableCell,
    TableBody,
    Checkbox,
    TableRow,
    Table
} from "@mui/material";

const useStyles = makeStyles(() => ({
    index: {
        fontWeight: 600,
        fontSize: "14px",
        paddingTop: "22px",
        textAlign: "center",
    },
    autocomplete: {
        paddingTop: "16px",
        background: "transparent",
        '& .MuiInputBase-input': {
            background: "transparent",
        },
    },
    text_field: {
        marginTop: "15px"
    },
    checkbox: {
        paddingTop: "22px"
    }
}))


const columns = [
    {field: 'name', name: '№ слайда.', width: 40},
    {field: 'created_the_laboratory', name: 'Имя файла', width: 300},
    {field: 'patient_name', name: 'Сканер', width: 230},
    {field: 'date_of_birth', name: 'Разрешение сканирования', width: 230},
    {field: 'lpu', name: 'Код МКБ10', width: 375},
    {field: 'biomaterial_source', name: 'Код МКБ-О-3', width: 375},
    {field: 'status_id', name: 'Дополнительный код', width: 50},
    {field: 'date_sample', name: 'Фокус', width: 50},
];

export const Slides = (props) => {
    const {setFieldValue, study, seriesIndex, errors} = props

    const classes = useStyles()
    const dispatch = useDispatch()

    const {account} = useSelector(state => state.account)
    const {scanners} = useSelector(state => state.scanners)
    const {study: stateStudy} = useSelector(state => state.pathomorphology) 
    const {scannerResolutions} = useSelector(state => state.scannerResolutions)

    const [loadingIcd10, setLoadingIcd10] = useState(false)
    const [selectedScanners, setSelectedScanners] = useState({})

    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState({search: '', type: null})
    const [searchRequest] = useDebounce(search, 400)

    useEffect(() => {
        setLoading(false)
    }, [searchRequest]);

    const inputChange = (e, type = null) => {
        setSearch((e && (typeof e.target.value === 'string')) ?  {...search , ...{search: e.target.value, type: type}} : {...search, ...{search: '', type: null}})
    }

    useEffect(() => {
        if (!loading) {
            let params = {
                offset: 0,
                isActive: true,
            }
            switch (searchRequest.type) {
                case 'scanner':
                    if (searchRequest.search) {
                        params.fullName = searchRequest.search;
                    }
                    params = httpBuildQuery(params)
                    dispatch(ScannersActions.get(params))
                break
                case 'scannerResolution':
                    if (searchRequest.search) {
                        params.value = searchRequest.search
                    }
                    if (searchRequest.scannerId) {
                        params.scannerId = searchRequest.scannerId
                    }
                    params = httpBuildQuery(params)
                    dispatch(ScannerResolutionsActions.get(params))
                    break;
                default:
            }
            setLoading(true)
        }
    }, [dispatch, searchRequest, loading]);


    const Icd10params = httpBuildQuery({
        offset: 0,
        isActive: true,
        limit: 1000
    })

    useEffect(() => {
        if (!loadingIcd10) {
            dispatch(Icd10Actions.getRoot(Icd10params)).then(_ => {
                setLoadingIcd10(true)
            })
        }
    }, [dispatch, loadingIcd10]);


    return (
        <>
            <UploadButtons
                study={study}
                seriesIndex={seriesIndex}
                setFieldValue={setFieldValue}
            />
                <TableContainer style={{maxHeight: 855}}>
                    <Table stickyHeader aria-label='sticky table'>
                        <Head columns={columns}/>
                        <TableBody>
                            {study.series[seriesIndex]?.slides.map((slide, index) => {
                                const {scanner, scannerResolution} = slide
                                const currentError = errors?.series?.[seriesIndex]?.slides?.[index]
                                return (
                                    <>
                                        <TableRow key={index} style={{verticalAlign: "top"}}>
                                            <TableCell>
                                                <Typography className={classes.index}>{(index + 1)}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <FileInfo
                                                    file={slide}
                                                    study={study}
                                                    seriesIndex={seriesIndex}
                                                    setFieldValue={setFieldValue}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <CustomAutocomplete
                                                    required
                                                    id="scanner"
                                                    value={scanner}
                                                    loading={!loading}
                                                    options={scanners.data}
                                                    className={classes.autocomplete}
                                                    helperText={currentError?.scanner}
                                                    error={Boolean(currentError?.scanner)}
                                                    onFocus={e => inputChange(e, 'scanner')}
                                                    getOptionLabel={option => option.fullName}
                                                    inputChange={e => inputChange(e, 'scanner')}
                                                    onChange={(e, value) => {
                                                        setFieldValue(`series[${seriesIndex}].slides[${index}]`, {
                                                            ...slide,
                                                            ...{
                                                                scanner: value,
                                                                scannerResolution: null
                                                            }
                                                        })
                                                        setSelectedScanners({ ...selectedScanners, ...{[index]: value.id}})
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <CustomAutocomplete
                                                    required
                                                    loading={!loading}
                                                    id="scannerResolution"
                                                    value={scannerResolution}
                                                    disabled={!Boolean(scanner?.id)}
                                                    className={classes.autocomplete}
                                                    getOptionLabel={option => option.value}
                                                    helperText={currentError?.scannerResolution}
                                                    error={Boolean(currentError?.scannerResolution)}
                                                    onFocus={e => {
                                                        setSearch({...search, ...{scannerId: selectedScanners[index], type: 'scannerResolution'}})
                                                    }}
                                                    inputChange={e => inputChange(e, 'scannerResolution')}
                                                    options={scannerResolutions.data}
                                                    onChange={(e, value) => {
                                                        setFieldValue(`series[${seriesIndex}].slides[${index}]`, {
                                                            ...slide,
                                                            ...{
                                                                scannerResolution: value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Icd10
                                                    label="МКБ-10"
                                                    setFieldValue={setFieldValue}
                                                    values={study.series[seriesIndex].slides[index]}
                                                    readValue={study.series[seriesIndex].slides[index].icd10}
                                                    writeValue={`series[${seriesIndex}].slides[${index}].icd10`}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Field
                                                    type="text"
                                                    size="small"
                                                    align="center"
                                                    variant="standard"
                                                    inputProps={textFieldProps}
                                                    component={TextFieldWithError}
                                                    className={classes.text_field}
                                                    value={study.series[seriesIndex].slides[index].icdO}
                                                    name={`series[${seriesIndex}].slides[${index}].icdO`}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Field
                                                    type="text"
                                                    size="small"
                                                    align="center"
                                                    variant="standard"
                                                    inputProps={textFieldProps}
                                                    className={classes.text_field}
                                                    component={TextFieldWithError}
                                                    value={study.series[seriesIndex].slides[index].additionalCode}
                                                    name={`series[${seriesIndex}].slides[${index}].additionalCode`}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    name={`focus`}
                                                    checkedIcon={<Selected />}
                                                    className={classes.checkbox}
                                                    icon={Boolean(study.series[seriesIndex].slides[index].focus) ? <Selected /> : <NotSelected />}
                                                    value={Boolean(study.series[seriesIndex].slides[index].focus) ? <NotSelected /> : <Selected />}
                                                    onChange={e => setFieldValue(`series[${seriesIndex}].slides[${index}].focus`, e.target.checked ? 1 : 0)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell />
                                            <TableCell colSpan={6}>
                                                <Tags
                                                    fixed_tags={
                                                        account?.role?.toString().toLowerCase() !== 'admin' ?
                                                        stateStudy?.series[seriesIndex]?.slides[index]?.tags
                                                            ? [
                                                                ...new Map(
                                                                stateStudy.tags
                                                                    .concat(study.tags)
                                                                    .concat(study.series[seriesIndex]?.tags)
                                                                    .concat(stateStudy.series[seriesIndex].tags)
                                                                    .concat(stateStudy.series[seriesIndex].slides[index].tags)
                                                                    ?.map((item) => [item['id'], item])
                                                                ).values(),
                                                            ]
                                                            : stateStudy?.series[seriesIndex]?.tags
                                                            ? [
                                                                ...new Map(
                                                                stateStudy.tags
                                                                    .concat(study.tags)
                                                                    .concat(study.series[seriesIndex].tags)
                                                                    .concat(stateStudy.series[seriesIndex].tags)
                                                                    .map((item) => [item['id'], item])
                                                                ).values(),
                                                            ]
                                                            : [...new Map(study?.series[seriesIndex]?.tags.concat(study.tags)?.map((item) => [item['id'], item])).values()]
                                                    : [...new Map(study?.series[seriesIndex]?.tags.concat(study.tags)?.map((item) => [item['id'], item])).values()]
                                                    }
                                                    value={
                                                        study.series[seriesIndex].slides[index]?.tags
                                                            ? [
                                                                ...new Map(
                                                                study.tags
                                                                    .concat(study.series[seriesIndex].tags)
                                                                    .concat(study.series[seriesIndex].slides[index].tags)
                                                                    ?.map((item) => [item['id'], item])
                                                                ).values(),
                                                            ]
                                                            : study.series[seriesIndex]?.tags
                                                    }
                                                    onChange={(e, newValue) => {
                                                        let newQuery = newValue.map((query) => {
                                                            if (typeof query === "string") {
                                                                return { id: Date.now(), name: query };
                                                            }
                                                            return query;
                                                        });
                                                        setFieldValue(`series[${seriesIndex}].slides[${index}.tags]`, [
                                                            ...newQuery
                                                        ]);
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
        </>
    )
}
