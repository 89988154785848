/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react"
import {MedicalInstitutionsActions} from "../../../Dictionary/actions/medicalInstitution";
import {CustomAutocomplete} from "../../../App/components/Input/CustomAutocomplete";
import {TextFieldWithError} from "../../../App/components/Input/TextField";
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {getTargetValue} from "../../../App/helpers/textField";
import {Submit} from "../../../App/components/Button/Submit";
import Calendar from "../../../App/components/Svg/Calendar";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "../../../App/components/Svg/Search";
import DateRangePicker from "@mui/lab/DateRangePicker";
import {useDispatch, useSelector} from "react-redux";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import {Field, Form, Formik} from 'formik';
import ruLocale from "date-fns/locale/ru";
import {IconButton} from "@mui/material";
import {useDebounce} from 'use-debounce';
import Stack from "@mui/material/Stack";
import {makeStyles} from '@mui/styles';
import Grid from "@mui/material/Grid";
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    content: {
        height: "100%",
        width: "100%",
        padding: "10px",
        borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
        background: "#f5f5f5"
    },
    title: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '25px',
        color: "black",
    },
    autocomplete: {
        marginTop: "5px",
        height: "24px",
        width: "100%",
        background: "white",
        '& .MuiInputBase-input': {
            background: "white",
        }
    },
    select: {
        marginTop: "5px",
        height: "24px",
        width: "100%",
        background: "white",
        '& .MuiInputBase-input': {
            background: "white",
            border: "1px solid #DBDEDF",
            borderRadius: "2px",
        }
    },
    label: {
        marginTop: "15px",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
    },
    date_label: {
        marginTop: "25px",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
    },
    date: {
        border: "1px solid #DBDEDF",
        borderRadius: "2px",
        height: "34px",
        width: "100%",
        background: "white",
        paddingLeft: "5px"
    },
    date_text_field: {
        width: "95px",
        fontSize: '12px',
        lineHeight: '16px',
    },
    date_text_field_root: {
        '& .MuiInputBase-root': {
            border: "none"
        },
    },
    date_typography: {
        width: "15px",
        color: "black"
    },
    text_field: {
        width: "100%",
        height: "34px",
        borderRadius: "2px",
        background: "white",
        border: "1px solid #DBDEDF",
    },
    search_icon: {
        width: "18px",
        height: "18px",
        marginLeft: "5px",
    },
    submit: {
        marginTop: "25px",
        width: '100%',
        backgroundColor: "#2595FF",
        textTransform: "none",
        fontWeight: 600,
        borderRadius: '2px',
        '&:hover': {
            backgroundColor: "#2595FF",
        }
    },
    date_icon_grid: {
        borderLeft: "1px solid #DBDEDF",
        height: "100%",
    },
    date_icon: {
        width: "18px",
        height: "18px",
    }
}))

export const Filter = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {medicalInstitutions} = useSelector(state => state.medicalInstitutions);

    const [loading, setLoading] = useState(true)
    const [datePickerOpen, setDatePickerOpen] = useState(false)

    const [loadingMedicalInstitutions, setLoadingMedicalInstitutions] = useState(false)
    const [medicalInstitutionsSearch, setMedicalInstitutionsSearch] = useState(null)
    const [medicalInstitutionsSearchRequest] = useDebounce(medicalInstitutionsSearch, 400)

    const [search, setSearch] = useState({search: '', type: null})
    const [searchRequest] = useDebounce(search, 400)

    const {moduleId}  = props

    const medicalInstitutionsInputChange = (e) => {
        setMedicalInstitutionsSearch(getTargetValue(e))
    }

    useEffect(() => {
        if (!loadingMedicalInstitutions) {
            const params = httpBuildQuery({
                offset: 0,
                ...(medicalInstitutionsSearchRequest ? {
                    name: medicalInstitutionsSearchRequest
                } : {})
            })

            dispatch(MedicalInstitutionsActions.get(params)).then(_ => {
                setLoadingMedicalInstitutions(true)
            })
        }
    }, [dispatch, loadingMedicalInstitutions]);

    useEffect(() => {
        setLoadingMedicalInstitutions(false)
    }, [medicalInstitutionsSearchRequest]);

    useEffect(() => {
        if (!loading) {

            switch (searchRequest.type) {
                default:
            }

            setLoading(true)
        }
    }, [dispatch, searchRequest]);

    useEffect(() => {
        setLoading(false)
    }, [medicalInstitutionsSearchRequest, searchRequest]);

    const submit = (values, {setSubmitting}) => {
        let params = {}
        for (const key in values) {
            if (values[key]) {
                switch (key) {
                    case 'date':
                        if (values[key][0] && values[key][1]) {
                            values[key][1] = new Date(values[key][1].setHours(23))
                            values[key][1] = new Date(values[key][1].setMinutes(59))
                            params.studyUploadedStartDate = format(values[key][0], "yyyy-MM-dd", {locale: ru})
                            params.studyUploadedEndDate = format(values[key][1], "yyyy-MM-dd", {locale: ru})
                        }
                        break
                    default:
                        params[key] = values[key]
                }
            }
        }
        dispatch({type: "RADIODIAGNOSIS_FILTER", payload: params})

        setSubmitting(false)
    }

    const searchInputProps = {
        startAdornment: (
            <InputAdornment position="start">
                <Search className={classes.search_icon}/>
            </InputAdornment>
        ),
        disableUnderline: true,
    }

    const dateInputProps = (props) => {
        return {
            ...{
                ...props,
                ...{inputProps: {...props.inputProps, ...{disableUnderline: true}}}
            }
        }
    }

    return (
        <Grid className={classes.content} key={moduleId}>
            <Typography className={classes.title}>Фильтры</Typography>
            <Formik
                initialValues={{
                    institutionCode: '',
                    date: [new Date(new Date().setDate(1)), new Date()],
                    patientHash: '',
                    seriesDescription: '',
                    studyInstanceUid: '',
                    modality: ''
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={submit}
            >
                {({
                      values,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form className={classes.form}>
                        <InputLabel id="medical-institutions-label" className={classes.label}>
                            МО
                        </InputLabel>
                        <CustomAutocomplete
                            id="institutionCode"
                            className={classes.autocomplete}
                            options={medicalInstitutions.data}
                            loading={!loadingMedicalInstitutions}
                            getOptionLabel={option => option.name}
                            inputChange={medicalInstitutionsInputChange}
                            onChange={(e, value) => setFieldValue('institutionCode', value ? value.code : '')}
                            value={values.medicalInstitutionName}
                            renderOption={(props, option) => {
                                return <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                            }}
                        />
                        <InputLabel className={classes.date_label}>Дата загрузки</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                            <DateRangePicker
                              startText=""
                              endText=""
                              mask='__.__.____'
                              name="date"
                              value={values.date}
                              open={datePickerOpen}
                              onClose={() => setDatePickerOpen(false)}
                              onChange={e => setFieldValue('date', e)}
                              renderInput={(startProps, endProps) =>
                                <Stack
                                  className={classes.date}
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      onClick={() => setDatePickerOpen(true)}
                                    >
                                        <Field
                                          name="date_from"
                                          classes={{root: classes.date_text_field_root}}
                                          className={classes.date_text_field}
                                          variant="standard"
                                          {...dateInputProps(startProps)}
                                          component={TextFieldWithError}
                                        />
                                        <Typography className={classes.date_typography}>-</Typography>
                                        <Field
                                          name="date_to"
                                          classes={{root: classes.date_text_field_root}}
                                          className={classes.date_text_field}
                                          variant="standard"
                                          {...dateInputProps(endProps)}
                                          component={TextFieldWithError}
                                        />
                                    </Stack>
                                    <Grid className={classes.date_icon_grid}>
                                        <IconButton
                                          onClick={() => setDatePickerOpen(true)}
                                        >
                                            <Calendar className={classes.date_icon}/>
                                        </IconButton>
                                    </Grid>
                                </Stack>
                              }
                            />
                        </LocalizationProvider>
                        <InputLabel className={classes.label}>
                            № Исследования
                        </InputLabel>
                        <Field
                            classes={{root: classes.date_text_field_root}}
                            className={classes.text_field}
                            align="center"
                            type="text"
                            name={`studyInstanceUid`}
                            variant="standard"
                            inputProps={searchInputProps}
                            component={TextFieldWithError}
                        />
                        <InputLabel className={classes.label}>
                            ID пациента
                        </InputLabel>
                        <Field
                          classes={{root: classes.date_text_field_root}}
                          className={classes.text_field}
                          align="center"
                          type="text"
                          name={`patientHash`}
                          variant="standard"
                          inputProps={searchInputProps}
                          component={TextFieldWithError}
                        />
                        <InputLabel className={classes.label}>
                            Описание
                        </InputLabel>
                        <Field
                          classes={{root: classes.date_text_field_root}}
                          className={classes.text_field}
                          align="center"
                          type="text"
                          name={`seriesDescription`}
                          variant="standard"
                          inputProps={searchInputProps}
                          component={TextFieldWithError}
                        />
                        <InputLabel className={classes.label}>
                            Модальность
                        </InputLabel>
                        <Field
                          classes={{root: classes.date_text_field_root}}
                          className={classes.text_field}
                          align="center"
                          type="text"
                          name={`modality`}
                          variant="standard"
                          inputProps={searchInputProps}
                          component={TextFieldWithError}
                        />
                        <Grid>
                            <Submit
                                className={classes.submit}
                                variant="contained"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Найти
                            </Submit>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
