const initialState = {
    audit: {
        total_pages: 0,
        data: []
    },
    filter: {}
}

export default function audit(state = initialState, action) {
    switch (action.type) {
        case "AUDIT":
            return {
                ...state,
                ...{
                    audit: {
                        data: action.payload.data,
                        total_pages: action.payload.totalPages
                    }
                }
            }
        case "AUDIT_FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload
                }
            }
        default:
            return state
    }
}
