import React, {useState} from "react"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Breadcrumbs, IconButton, Typography} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import {UploadPopover} from "./uploadStatement/UploadPopover";
import Button from "@mui/material/Button";
import Home from '../components/Svg/Home'
import Stack from "@mui/material/Stack";
import {makeStyles} from '@mui/styles';
import {Dialogs} from "./Dialogs";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    breadcrumbs_container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    home_icon: {
        width: "16px",
        height: "16px",
        display: "block"
    },
    prev: {
        fontStyle: "normal",
        color: "black",
        textDecoration: "none",
        textTransform: "none",
        fontWeight: 400,
        fontSize: "16px",
        fontHeight: "16px",
        background: "transparent",
        "&:hover": {
            background: "transparent",
        }
    },
    current: {
        color: "#6E7880"
    },
}))

const partitions = [
    {
        name: "База данных",
        path: "database",
        modules: [
            {
                name: "Патоморфология",
                path: "pathomorphology"
            },
            {
                name: "Лучевая диагностика",
                path: "radiodiagnosis"
            },
            {
                name: "ЭМК",
                path: "emc",
                modules: [
                    {
                        name: "Пациенты",
                        path: "patients"
                    },
                    {
                        name: "Приёмы",
                        path: "tricks"
                    },
                    {
                        name: "Результаты визита",
                        path: "results"
                    },
                    {
                        name: "Рецепты",
                        path: "recipes"
                    },
                    {
                        name: "Процедуры",
                        path: "procedures"
                    },
                    {
                        name: "Обследования",
                        path: "surveys"
                    },
                    {
                        name: "Диагностические отчёты",
                        path: "diagnostics"
                    }
                ]
            },
            {
                name: "Генетические данные",
                path: "genetic",
                modules: [
                    {
                        name: "Подсистема анализа и интерпретации онкологических генетических биомаркеров",
                        path: "oncology"
                    },
                    {
                        name: "Подсистема анализа и интерпретации полногеномных данных",
                        path: "wgs"
                    },
                    {
                        name: "Подсистема анализа и интерпретации микробиома",
                        path: "microbiome"
                    },
                ]
            }
        ]
    },
    {
        name: "Справочник",
        path: "dictionary",
        modules: [
            {
                name: "Источник биоматериала",
                path: "biomaterial_sources"
            },
            {
                name: "Сотрудники",
                path: "employees"
            },
            {
                name: "Должности",
                path: "employee_positions"
            },
            {
                name: "Врачи",
                path: "doctors"
            },
            {
                name: "Организации",
                path: "medicalInstitutions"
            },
            {
                name: "Сканеры",
                path: "scanners"
            },
            {
                name: "Разрешения сканеров",
                path: "scanner_resolutions"
            },
            {
                name: "МКБ-10",
                path: "icd10"
            },
            {
                name: "МКБ-О-3",
                path: "icdO"
            },
            {
                name: "Теги",
                path: "tags"
            },
        ]
    },
]

export const CustomBreadcrumb = (props) => {
    const classes = useStyles()
    const location = useLocation();
    const [oncoDialog, setOncoDialog] = useState(false);
    const [wgsDialog, setWgsDialog] = useState(false);
    const [biotaDialog, setBiotaDialog] = useState(false);
    const {partitionId, onClickPath} = props

    const paths = location.pathname.split('/')

    const parseModules = (modules, paths) => {
        let breadcrumbs = []

        modules.forEach(module => {
            if (module.path === paths[2] || module.path === paths[3]) {
                breadcrumbs.push(<Typography className={clsx(classes.prev, classes.current)}>{module.name}</Typography>)
            }
            if (module.modules) {
                breadcrumbs.push(parseModules(module.modules, paths))
            }
        })
        return breadcrumbs
    }

    const getBreadcrumb = () => {
        let breadcrumbs = []

        partitions.forEach((partition, index) => {
            if (partition.path === paths[1]) {
                breadcrumbs.push(<Button
                    className={classes.prev}
                    underline="hover"
                    color="inherit"
                    onClick={onClickPath}
                    key={index}
                >
                    {partitions[partitionId].name}
                </Button>)
                breadcrumbs.push(parseModules(partition.modules, paths))
            }
        })
        return breadcrumbs
    }

    return (
        <Stack className={classes.breadcrumbs_container}>
            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small"/>}
            >
                <Link to="/">
                    <Home className={classes.home_icon}/>
                </Link>
                {getBreadcrumb()}
            </Breadcrumbs>
            {paths[2] === 'pathomorphology' ? <UploadPopover/> : null}
            {paths[3] && paths[3] === 'oncology' && <IconButton
                onClick={() => setOncoDialog(true)}
            >
                <HelpOutlineOutlinedIcon/>
            </IconButton>}
            {paths[3] && paths[3] === 'wgs' && <IconButton
                onClick={() => setWgsDialog(true)}
            >
                <HelpOutlineOutlinedIcon/>
            </IconButton>}
            {paths[3] && paths[3] === 'microbiome' && <IconButton
                onClick={() => setBiotaDialog(true)}
            >
                <HelpOutlineOutlinedIcon/>
            </IconButton>}
            {partitionId === 0 && <Dialogs
                oncoDialog={oncoDialog}
                setOncoDialog={setOncoDialog}
                wgsDialog={wgsDialog}
                setWgsDialog={setWgsDialog}
                biotaDialog={biotaDialog}
                setBiotaDialog={setBiotaDialog}
            />}
        </Stack>
    )
}
