import React from "react"
import { getOperationDateTime, getOperationName, mdash } from "./helpers/audit";
import { TableCell, TableRow } from "@mui/material";
import { getShortName } from "../App/helpers/row";
import { format } from "date-fns"

export const Row = (props) => {
    const {row} = props;

    const localDate = new Date(getOperationDateTime(row.date, row.time));
    const currentDate = format(localDate, "dd-MM-yyyy")
    const currentTime = format(localDate, "HH:mm:ss")

    return (
        <TableRow key={row.id}>
            <TableCell>{row.employee ? getShortName(row.employee) : mdash }</TableCell>
            <TableCell>{row.employee ? row.employee?.employeePosition?.name : mdash}</TableCell>
            <TableCell>{row.employee ? row.employee?.medicalInstitution?.name : mdash}</TableCell>
            <TableCell>{row.ipAddress ? row.ipAddress : mdash}</TableCell>
            <TableCell>{row.date ? currentDate : mdash}</TableCell>
            <TableCell>{row.time ? currentTime : mdash}</TableCell>
            <TableCell>{row.operationType ?row.operationType.description : mdash}</TableCell>
            <TableCell>{row.operationStatus ? getOperationName(row.operationStatus) : mdash}</TableCell>
        </TableRow>
    )
}