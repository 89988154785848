export default function medicalInstitutions(state = {
    medicalInstitutions: {
        data: [],
        total: 0,
        total_pages: 0
    },
    medicalInstitution: null,
    filter: {}
}, action) {
    switch (action.type) {
        case "MEDICAL_INSTITUTIONS":
            return {
                ...state,
                ...{
                    medicalInstitutions: {
                        data: action.payload.data,
                        total: action.payload.total,
                        total_pages: action.payload.totalPages
                    }
                }
            }
        case "MEDICAL_INSTITUTION":
            return {
                ...state,
                ...{
                    medicalInstitution: action.payload
                }
            }
        case "MEDICAL_INSTITUTIONS_FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload
                }
            }
        default:
            return state
    }
}