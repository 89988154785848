import React from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {makeStyles} from '@mui/styles';
import {
    ClickAwayListener,
    ButtonGroup,
    MenuItem,
    MenuList,
    Divider,
    Button,
    Popper,
    Paper,
    Grow,
} from "@mui/material";

const useStyles = makeStyles(() => ({
    button: {
        minWidth: "50px !important",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: "1px solid rgb(25, 104, 178)",
        borderColor: "rgb(25, 104, 178)"
    },
    arrow: {
        minWidth: "40px",
        padding: "4px 0px",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
    }
}))

const options = {
    /*type: [
        {key: null, name: 'Оригинал'},
        {key: null, name: 'Без перс. данных'},
    ],*/
    exportFormat: [
        {key: 0, name: 'JPG', modality: {except: ['SR', 'DOC']}},
        {key: 1, name: 'BMP', modality: {except: ['SR', 'DOC']}},
        {key: 2, name: 'TIFF', modality: {except: ['SR', 'DOC']}},
    ],
    transferSyntaxType: [
        {key: 0, name: 'Deflate'},
        {key: 1, name: 'JPEG-LS'},
        {key: 2, name: 'JPEG Lossless'},
    ]
}

export const Download = (props) => {
    const classes = useStyles()
    const {modality, href} = props

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleMenuItemClick = () => {
        setOpen(false)
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup variant="contained" ref={anchorRef}>
                <Button size="small" href={href} className={(['SR', 'DOC'].indexOf(modality) === -1) ? classes.button : {}}>Скачать</Button>
                {(['SR', 'DOC'].indexOf(modality) === -1) &&
                    <Button
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleToggle}
                        className={classes.arrow}
                    >
                        <ArrowDropDownIcon size="small"/>
                    </Button>
                }
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{
                    zIndex: 1
                }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: (placement === 'bottom') ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList dense autoFocusItem>
                                    {Object.entries(options).map(([key, params]) => {
                                        return [...params.filter(option =>
                                            option.hasOwnProperty('modality')
                                                ? option.modality.hasOwnProperty('except') ? (option.modality.except.indexOf(modality) === -1) : option.modality.hasOwnProperty('only') ? (option.modality.only.indexOf(modality) !== -1) : true
                                                : true
                                        ).map((option, index) => (
                                            <MenuItem
                                                component="a"
                                                href={(key && options[key][index]) ? `${href}&${key}=${options[key][index]?.key}` : href}
                                                key={index}
                                                onClick={handleMenuItemClick}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        )), ((Object.keys(options)[Object.keys(options).length - 1] !== key) ? <Divider /> : null)]
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    )
}
