import {HttpService} from "../../App/services/http";

export const EmployeesService = {
    get,
    add,
    edit,
    getEmployee,
    deleteEmployee
}

function get(params) {
    return HttpService.getMethod('/api/employee', `limit=15&${params}`)
}

function getEmployee(id) {
    return HttpService.getMethod(`/api/employee/${id}`)
}

function add(values) {
    return HttpService.postMethod('/api/employee', JSON.stringify(values))
}

function edit(id, values) {
    return HttpService.patchMethod(`/api/employee/${id}`, values)
}

function deleteEmployee(id) {
    return HttpService.deleteMethod(`/api/employee/${id}`)
}