import React, {useEffect} from 'react'
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {Icd10TableActions} from "../../actions/icd10_table";
import {CustomSkeleton} from "../DataTable/CustomSkeleton";
import TableContainer from '@mui/material/TableContainer';
import {useDispatch, useSelector} from "react-redux";
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import {Head} from "../DataTable/Head";
import {Stats} from '../Stats/Stats';
import {IcdRow} from "../IcdRow";

const columns = [
    {field: 'arrow', name: 'Раскрыть', width: 300},
    {field: 'code', name: 'Код', width: 300},
    {field: 'diagnosis', name: 'Диагноз', width: 300},
    {field: 'status', name: 'Статус', width: 50},
    {field: 'actions', name: 'Действия', width: 30},
];


export const Icd10 = (props) => {
    const dispatch = useDispatch()
    const {codes, filter} = useSelector(state => state.icd10Table);

    const {
        setDeleteDialog,
        setEditDialog,
        setLoading,
        setUseRow,
        moduleId,
        setValue,
        loading,
        page
    } = props

    useEffect(() => {
        const params = httpBuildQuery({
            offset: page === 1 ? 0 : (10 * page) - 10,
            limit: 10,
            ...filter
        })
        let useRoot = true
        if(filter?.isActive === false){
            useRoot = false
        }
        if(filter.icd10_search){
            useRoot = false
        }
        if (!loading) {
            dispatch(useRoot ? Icd10TableActions.getRoot(params) : Icd10TableActions.getAll(params)).then(() => {
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading]);

    return (
        <>
            <Stats amount={codes?.total}/>
            <TableContainer style={{height: "calc(100vh - 315px)"}}>
                <Table stickyHeader aria-label='sticky table'>
                    <Head columns={columns}/>
                    <TableBody>
                        {loading ? codes.data.map((row) => (
                            <IcdRow
                                type="icd10"
                                key={row.id}
                                setDeleteDialog={setDeleteDialog}
                                setEditDialog={setEditDialog}
                                setLoading={setLoading}
                                setUseRow={setUseRow}
                                moduleId={moduleId}
                                setValue={setValue}
                                level={0}
                                row={row}
                            />
                        )) : <CustomSkeleton count={4}/>}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
