import {HttpService} from "../../App/services/http";

export const EmployeePositionService = {
    get,
    add,
    edit,
    getPosition,
    deletePosition,
}

function get(params) {
    return HttpService.getMethod('/api/employee-position', `limit=15&${params}`)
}

function getPosition(id) {
    return HttpService.getMethod(`/api/employee-position/${id}`)
}

function add(values) {
    return HttpService.postMethod('/api/employee-position', JSON.stringify(values))
}

function edit(id, values) {
    return HttpService.patchMethod(`/api/employee-position/${id}`, values)
}

function deletePosition(id) {
    return HttpService.deleteMethod(`/api/employee-position/${id}`)
}
