import React from 'react'
import {PathomorphologyActions} from "../../../actions/pathomorphology";
import {saveObject} from "../../../../App/helpers/saveObject";
import {getExtensionFile} from "../../../helpers/study";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {makeStyles} from '@mui/styles';
import Grid from "@mui/material/Grid";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    send_button: {
        marginTop: "25px",
        background: "#33BE63",
        color: "white",
        border: "none",
        boxShadow: "none",
        borderRadius: "2px",
        fontFamily: "Open Sans",
        textTransform: "none",
        fontWeight: 600,
        transition: "0.3s",
        "&:hover": {
            background: "#33BE63",
            color: "white",
            filter: "drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.16))",
        },
        height: "30px",
        marginRight: "10px",
        "&:disabled": {
            background: "rgba(0, 0, 0, 0.12)",
            color: "rgba(0, 0, 0, 0.26)",
        },
    },
    footer: {
        padding: "0px 30px",
        alignItems: "baseline"
    },
    blue_button: {
        background: "#2595FF",
        "&:hover": {
            background: "#2595FF",
            color: "white",
        },
        "&:disabled": {
            background: "rgba(0, 0, 0, 0.12)",
            color: "rgba(0, 0, 0, 0.26)",
        },
    }
}))

export const SaveButtons = (props) => {
    const {study, isSubmitting, isValid, dirty, setFieldValue} = props
    const classes = useStyles()
    const dispatch = useDispatch()
    const {files} = useSelector(state => state.uploadManager);

    const sendStudy = () => {
        let params = saveObject(study)
        delete (params.series)
        return dispatch(study.id ? PathomorphologyActions.editStudy(study.studyInstanceUid, params) : PathomorphologyActions.addStudy(params)).then(
            response => {
                setFieldValue('id', response.id)
            }
        )
    }

    const sendSeries = (seriesValue) => {
        let params = saveObject(seriesValue)
        delete (params.slides)
        return dispatch(seriesValue.id ? PathomorphologyActions.editSeries(study.studyInstanceUid, seriesValue.seriesInstanceUid, params) : PathomorphologyActions.addSeries(study.studyInstanceUid, params)).then(
            response => {
                setFieldValue('series', study.series?.map(series => {
                    if (series.localId === seriesValue.localId) {
                        series.id = response.id
                    }
                    return series
                }))
            }
        )
    }

    const onUploadProgress = (percent, guid, cancel) => {
        dispatch({
            type: "UPLOAD_MANAGER_CHANGE", payload: {
                percent: percent,
                guid: guid,
                cancel: cancel
            }
        })
    }

    const onUploadError = (guid, message) => {
        dispatch({
            type: "UPLOAD_MANAGER_ERROR", payload: {
                guid: guid,
                message: message
            }
        })
    }

    const events = {
        onUploadProgress: onUploadProgress,
        onUploadError: onUploadError
    }

    const uploadSlide = async (params, formData, events) => {
        delete (params.value)
        return await dispatch(PathomorphologyActions.sendSlide(params, formData, events)).then(
            response => {
                setFieldValue('series', study.series?.map(series => {
                    if (series.seriesInstanceUid === params.seriesInstanceUid) {
                        series.slides = series.slides?.map(slide => {
                            if (slide.slideInstanceUid === params.slideInstanceUid) {
                                slide.id = response.id
                                slide.slideInstanceUid = response.slideInstanceUid
                            }
                            return slide
                        })
                    }
                    return series
                }))
            }
        )
    }

    const sendSlide = async (series, slide) => {
        const formData = new FormData()
        formData.append('file', slide.value)
        slide.slideInstanceUid = slide.slideInstanceUid ?? slide?.value?.name
        slide.slideInstanceUid = slide.slideInstanceUid.replaceAll(getExtensionFile(slide.slideInstanceUid), '')
        const params = saveObject(slide)
        if (slide.id) {
            return await dispatch(PathomorphologyActions.editSlide(study.studyInstanceUid, series.seriesInstanceUid, slide.slideInstanceUid, params))
        } else {
            return dispatch({
                type: "UPLOAD_MANAGER_ADD_FILES", payload: {
                    ...params,
                    ...{
                        upload: uploadSlide,
                        uploadParams: [params, formData, events],
                        value: slide.value,
                        status: 'waiting',
                        percent: 0,
                        message: "В ожидании очереди",
                    },
                }
            });
        }
    }

    const onSubmitSaveStudy = () => {
        sendStudy().then(
            () => {
                if (study?.series?.length) {
                    study.series.forEach(series => {
                        sendSeries(series).then(
                            () => {
                                if (series?.slides?.length) {
                                    series.slides.forEach(slide => sendSlide(series, slide))
                                }
                            }
                        )
                    })
                }
            }
        )
    }

    const finalizeStudy = () => {
        let params = saveObject(study)

        dispatch(PathomorphologyActions.editStudy(study.studyInstanceUid, {...params, finalized: true}).then(

            setFieldValue('finalized', true),

            dispatch(PathomorphologyActions.finalizeStudy(study.studyInstanceUid)).then(
                () => {
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message: 'Исследование опубликовано',
                            type: 'success'
                        }
                    })
                }
            )
        ))
    }

    const getFinalizedDisabledButton = () => {
        let successSendCount = 0,
            allSendCount = 0
        files.forEach(file => {
            if (file.studyInstanceUid === study.studyInstanceUid) {
                if (file.status === 'success') {
                    successSendCount += 1
                }
                allSendCount += 1
            }
        })

        const isNotFinalized = study?.finalized === false
        const isEmptySeries = study.series.length !== 0 ? (study?.series.findIndex(seriesValue => seriesValue.slides.length === 0) >= 0) : true
        
        return ((isNotFinalized && (isEmptySeries || !study?.id)) || allSendCount > 0 ) && !(successSendCount > 0 && allSendCount > 0 && successSendCount === allSendCount)
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.footer}
        >
            <Button
                disabled={isSubmitting || !isValid || !dirty}
                onClick={onSubmitSaveStudy}
                className={classes.send_button}
            >
                Сохранить исследование
            </Button>
            {!study?.finalized && 
                <Button
                    disabled={getFinalizedDisabledButton() || !isValid}
                    onClick={finalizeStudy}
                    className={clsx(classes.send_button, classes.blue_button)}
                >
                    Опубликовать исследование
                </Button>
            }
        </Grid>
    )
}
