/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {MedicalInstitutionsActions} from "../../../Dictionary/actions/medicalInstitution";
import {BiomaterialSourcesActions} from "../../../Dictionary/actions/biomaterialSources";
import {CustomAutocomplete} from "../../../App/components/Input/CustomAutocomplete";
import {TextFieldWithError} from "../../../App/components/Input/TextField";
import {EmployeesActions} from "../../../Dictionary/actions/employees";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import httpBuildQuery from "../../../App/helpers/httpBuildQuery";
import {getTargetValue} from "../../../App/helpers/textField";
import {Submit} from "../../../App/components/Button/Submit";
import Calendar from "../../../App/components/Svg/Calendar";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "../../../App/components/Svg/Search";
import DateRangePicker from '@mui/lab/DateRangePicker';
import {getShortName} from "../../../App/helpers/row";
import Autocomplete from '@mui/material/Autocomplete';
import {useDispatch, useSelector} from "react-redux";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {Field, Form, Formik} from 'formik';
import ruLocale from "date-fns/locale/ru";
import {useDebounce} from 'use-debounce';
import {IconButton} from "@mui/material";
import Stack from "@mui/material/Stack";
import {makeStyles} from '@mui/styles';
import Grid from "@mui/material/Grid";
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    content: {
        height: "100%",
        width: "100%",
        padding: "10px",
        borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
        background: "#f5f5f5"
    },
    title: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '25px',
        color: "black",
    },
    autocomplete: {
        marginTop: "5px",
        height: "24px",
        width: "100%",
        background: "white",
        '& .MuiInputBase-input': {
            background: "white",
        }
    },
    select: {
        marginTop: "5px",
        height: "24px",
        width: "100%",
        background: "white",
        '& .MuiInputBase-input': {
            background: "white",
            borderRadius: "2px",
        }
    },
    label: {
        marginTop: "15px",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
    },
    date_label: {
        marginTop: "25px",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
    },
    date: {
        border: "1px solid #DBDEDF",
        borderRadius: "2px",
        height: "34px",
        width: "100%",
        background: "white",
        paddingLeft: "5px"
    },
    date_text_field: {
        width: "95px",
        fontSize: '12px',
        lineHeight: '16px',
        border: "none"
    },
    date_text_field_root: {
        '& .MuiInputBase-root': {
            border: "none"
        },
    },
    date_typography: {
        width: "15px",
        color: "black"
    },
    text_field: {
        width: "100%",
        height: "34px",
        borderRadius: "2px",
        background: "white",
    },
    search_icon: {
        width: "18px",
        height: "18px",
        marginLeft: "5px",
    },
    submit: {
        marginTop: "25px",
        width: '100%',
        backgroundColor: "#2595FF",
        textTransform: "none",
        fontWeight: 600,
        borderRadius: '2px',
        '&:hover': {
            backgroundColor: "#2595FF",
        }
    },
    date_icon_grid: {
        borderLeft: "1px solid #DBDEDF",
        height: "100%",
    },
    date_icon: {
        width: "18px",
        height: "18px",
    }
}))

const interpertationStatuses = [
    {
        name: "Создано",
        id: 0
    },
    {
        name: "На рассмотрении",
        id: 1
    },
    {
        name: "Обрабатывается",
        id: 2
    },
    {
        name: "Подготавливается",
        id: 3
    },
    {
        name: "Выполнено",
        id: 4
    },
    {
        name: "Ошибка",
        id: 5
    },
]

export const Filter = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {account} = useSelector(state => state.account);
    const {employees} = useSelector(state => state.employees);
    const {biomaterialSources} = useSelector(state => state.biomaterialSources);
    const {medicalInstitutions} = useSelector(state => state.medicalInstitutions);
    const [loadingBs, setLoadingBs] = useState(false)
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [datePickerOpen, setDatePickerOpen] = useState(false)


    const [loadingMedicalInstitutions, setLoadingMedicalInstitutions] = useState(false)
    const [medicalInstitutionsSearch, setMedicalInstitutionsSearch] = useState(null)
    const [medicalInstitutionsSearchRequest] = useDebounce(medicalInstitutionsSearch, 400)


    const [biomaterialSourcesSearch, setBiomaterialSourcesSearch] = useState(null)
    const [biomaterialSourcesSearchRequest] = useDebounce(biomaterialSourcesSearch, 400)

    const {moduleId} = props

    useEffect(() => {
        const getEmployees = async () => {
            return await dispatch(EmployeesActions.get())
        }
        if (!loadingUsers && (account?.role?.toString().toLowerCase()=== 'admin' || account?.role?.toString().toLowerCase() === 'assistant')) {
            getEmployees().then(_ => {
                setLoadingUsers(true)
            })
        }
    }, [dispatch, account, loadingUsers]);

    useEffect(() => {
        if (!loadingBs) {
            let params = httpBuildQuery({
                offset: 0,
                ...(biomaterialSourcesSearchRequest ? {
                    biomaterialSourceName: biomaterialSourcesSearchRequest
                } : {})
            })
            dispatch(BiomaterialSourcesActions.get(params)).then(_ => {
                setLoadingBs(true)
            })
        }
    }, [dispatch, loadingBs, biomaterialSourcesSearchRequest]);

    useEffect(() => {
        if (!loadingMedicalInstitutions) {
            const params = httpBuildQuery({
                offset: 0,
                ...(medicalInstitutionsSearchRequest ? {
                    name: medicalInstitutionsSearchRequest
                } : {})
            })

            dispatch(MedicalInstitutionsActions.get(params)).then(_ => {
                setLoadingMedicalInstitutions(true)
            })
        }
    }, [dispatch, loadingMedicalInstitutions]);

    useEffect(() => {
        setLoadingBs(true)
    }, [biomaterialSourcesSearchRequest]);

    useEffect(() => {
        setLoadingMedicalInstitutions(false)
    }, [medicalInstitutionsSearchRequest]);
    
    const submit = (values, {setSubmitting}) => {
        let params = {}
        for (const key in values) {
            switch (key) {
                case 'date':
                    if (values[key][0] && values[key][1]) {
                        values[key][1] = new Date(values[key][1].setHours(23))
                        values[key][1] = new Date(values[key][1].setMinutes(59))
                        params.createdDateBegin = format(values[key][0], "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {locale: ru})
                        params.createdDateEnd = format(values[key][1], "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {locale: ru})
                    }
                    break;
                default:
                    params[key] = values[key]
            }
        }
        dispatch({
            type: "GENETIC_FILTER", payload: params
        })
        setSubmitting(false)
    }

     const medicalInstitutionsInputChange = (e) => {
        setMedicalInstitutionsSearch(getTargetValue(e))
    }

    const biomaterialSourcesInputChange = (e) => {
        setBiomaterialSourcesSearch(getTargetValue(e))
    }

    const autocompleteRender = (params) => {
        return <TextField
            className={classes.text_field}
            variant="standard"
            {...{
                ...params,
                ...{InputProps: {...params.InputProps, ...{disableUnderline: true}}}
            }}
            InputProps={{
                ...params.InputProps,
                disableUnderline: true,
            }}
        />
    }

    const searchInputProps = {
        startAdornment: (
            <InputAdornment position="start">
                <Search className={classes.search_icon}/>
            </InputAdornment>
        ),
        disableUnderline: true,
    }


    const dateInputProps = (props) => {
        return {
            ...{
                ...props,
                ...{inputProps: {...props.inputProps, ...{disableUnderline: true}}}
            }
        }
    }

    return (
        <Grid className={classes.content} key={moduleId}>
            <Typography className={classes.title}>Фильтры</Typography>
            <Formik
                initialValues={{
                    medicalInstitutionId: null,
                    biomaterialSourceId: null,
                    studyInstanceUid: null,
                    date: [new Date(new Date().setDate(1)), new Date()],
                    patientId: null,
                    operator: (account?.role?.toString().toLowerCase()=== 'admin' || account?.role?.toString().toLowerCase() === 'assistant') ? null : account.employee,
                    interpretationStatus: null
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={submit}
            >
                {({
                      values,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form className={classes.form}>
                        <InputLabel
                            id="medical-institutions-label"
                            className={classes.label}
                        >
                            ЛПУ
                        </InputLabel>
                        <CustomAutocomplete
                            id="medicalInstitutionId"
                            className={classes.autocomplete}
                            options={medicalInstitutions.data}
                            loading={!loadingMedicalInstitutions}
                            getOptionLabel={option => option.hasOwnProperty('name') ? option.name : option}
                            inputChange={medicalInstitutionsInputChange}
                            onChange={(e, value) => setFieldValue('medicalInstitutionId', value ? value.id : null)}
                            value={values.medicalInstitutionName}
                            renderOption={(props, option) => {
                                return <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                            }}
                        />
                        <InputLabel className={classes.date_label}>Загружено в систему</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                            <DateRangePicker
                                startText=""
                                endText=""
                                mask='__.__.____'
                                name="date"
                                value={values.date}
                                open={datePickerOpen}
                                onClose={() => setDatePickerOpen(false)}
                                onChange={e => setFieldValue('date', e)}
                                renderInput={(startProps, endProps) =>
                                    <Stack
                                        className={classes.date}
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            onClick={() => setDatePickerOpen(true)}
                                        >
                                            <Field
                                                name="date_from"
                                                classes={{root: classes.date_text_field_root}}
                                                className={classes.date_text_field}
                                                variant="standard"
                                                {...dateInputProps(startProps)}
                                                component={TextFieldWithError}
                                            />
                                            <Typography className={classes.date_typography}>-</Typography>
                                            <Field
                                                name="date_to"
                                                classes={{root: classes.date_text_field_root}}
                                                className={classes.date_text_field}
                                                variant="standard"
                                                {...dateInputProps(endProps)}
                                                component={TextFieldWithError}
                                            />
                                        </Stack>
                                        <Grid className={classes.date_icon_grid}>
                                            <IconButton
                                                onClick={() => setDatePickerOpen(true)}
                                            >
                                                <Calendar className={classes.date_icon}/>
                                            </IconButton>
                                        </Grid>
                                    </Stack>
                                }
                            />
                        </LocalizationProvider>
                        <InputLabel
                            className={classes.label}
                        >
                            Идентификатор образца
                        </InputLabel>
                        <Field
                            className={classes.text_field}
                            align="center"
                            type="text"
                            name={`studyInstanceUid`}
                            variant="standard"
                            inputProps={searchInputProps}
                            component={TextFieldWithError}
                        />
                        <InputLabel
                            className={classes.label}
                        >
                            ID Пациента
                        </InputLabel>
                        <Field
                            className={classes.text_field}
                            align="center"
                            type="text"
                            name={`patientId`}
                            variant="standard"
                            inputProps={searchInputProps}
                            component={TextFieldWithError}
                        />
                        <InputLabel
                            id="biomaterial-source-label"
                            className={classes.label}
                        >
                            Источник биоматериала
                        </InputLabel>
                        <Autocomplete
                            disablePortal
                            id="biomaterialSourceId"
                            className={classes.autocomplete}
                            getOptionLabel={(option) => option.name}
                            options={biomaterialSources.data}
                            onInputChange={biomaterialSourcesInputChange}
                            onClose={() => biomaterialSourcesInputChange(false)}
                            onChange={(e, value) => setFieldValue('biomaterialSourceId', value === null ? null : value.id)}
                            noOptionsText='Ничего не найдено!'
                            loadingText='Загрузка...'
                            filterOptions={options => options}
                            loading={!loadingBs}
                            renderInput={autocompleteRender}
                        />
                        <InputLabel
                            id="interpretation-status-label"
                            className={classes.date_label}
                        >
                            Статус
                        </InputLabel>
                        <Autocomplete
                            disablePortal
                            id="interpretationStatus"
                            className={classes.autocomplete}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => setFieldValue('interpretationStatus', value === null ? null : value.id)}
                            options={interpertationStatuses}
                            noOptionsText='Ничего не найдено!'
                            renderInput={autocompleteRender}
                        />
                         {(account?.role?.toString().toLowerCase()=== 'admin' || account?.role?.toString().toLowerCase() === 'assistant') &&
                            <React.Fragment>
                                <InputLabel id="operator-label" className={classes.date_label}>Оператор</InputLabel>
                                <Autocomplete
                                    disablePortal
                                    id="operator"
                                    className={classes.autocomplete}
                                    getOptionLabel={option => getShortName(option)}
                                    onChange={(e, value) => setFieldValue('operator', value === null ? null : value)}
                                    options={employees.data}
                                    noOptionsText='Ничего не найдено!'
                                    renderInput={autocompleteRender}
                                />
                            </React.Fragment>
                        }
                        <Grid>
                            <Submit
                                className={classes.submit}
                                variant="contained"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Найти
                            </Submit>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
