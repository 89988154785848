import React, {useState} from 'react'
import {DoctorsModulePagination} from './Doctors/Components/Pagination';
import {ScannerResolutions} from "./ScannerResolutions/Index";
import {MedicalInstitution} from "./MedicalInstitution/Index";
import {BiomaterialSource} from "./BiomaterialSource/Index";
import {EmployeePositions} from "./EmployeePosition/Index";
import Pagination from '@mui/material/Pagination';
import {Employees} from "./Employees/Index";
import {DictionaryDialog} from "./Dialog";
import {Scanners} from "./Scanners/Index";
import {Doctors} from "./Doctors/Index";
import Paper from '@mui/material/Paper';
import {useSelector} from "react-redux";
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import {Icd10} from "./Icd10/Index";
import {Tags} from './Tag/Index'; 

const useStyles = makeStyles(() => ({
    cancel_button: {
        background: "#F9FAFA",
        border: "1px solid #DBDEDF",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        color: "black",
        '&:hover': {
            background: "#F9FAFA",
        }
    },
    accept_button: {
        background: "#2595FF",
        border: "1px solid #2595FF",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        color: "white",
        '&:hover': {
            background: "#2595FF",
        }
    },
    footer: {
        paddingTop: "30px",
        paddingBottom: "30px",
        margin: "auto",
        width: "max-content"
    },
    gray_text: {
        color: "#808080"
    }
}))


export const DataTable = (props) => {
    const classes = useStyles()
    const {scanners} = useSelector(state => state.scanners);
    const {total_pages} = useSelector(state => state.doctors);
    const {employees} = useSelector(state => state.employees);
    const {codes: icd10_codes} = useSelector(state => state.icd10Table);
    const {tags} = useSelector(state => state.tags)
    const {employeePositions} = useSelector(state => state.employeePositions);
    const {biomaterialSources} = useSelector(state => state.biomaterialSources);
    const {scannerResolutions} = useSelector(state => state.scannerResolutions);
    const {medicalInstitutions} = useSelector(state => state.medicalInstitutions);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [useRow, setUseRow] = useState(null);
    const {
        page,
        loading,
        setPage,
        moduleId,
        setValue,
        setLoading
    } = props

    const getModuleInfo = () => {
        switch (moduleId) {
            case 'biomaterial_sources':
                return {
                    total_pages: biomaterialSources.total_pages,
                    component: BiomaterialSource
                }
            case 'employees':
                return {
                    total_pages: employees.total_pages,
                    component: Employees
                }
            case 'employee_positions':
                return {
                    total_pages: employeePositions.total_pages,
                    component: EmployeePositions
                }
            case 'doctors':
                return {
                    total_pages: total_pages,
                    component: Doctors
                }
            case 'scanners':
                return {
                    total_pages: scanners.total_pages,
                    component: Scanners
                }
            case 'scanner_resolutions':
                return {
                    total_pages: scannerResolutions.total_pages,
                    component: ScannerResolutions
                }
            case 'medicalInstitutions':
                return {
                    total_pages: medicalInstitutions.total_pages,
                    component: MedicalInstitution
                }
            case 'icd10':
                return {
                    total_pages: icd10_codes.total_pages,
                    component: Icd10
                }
            case 'tags':
                return {
                    total_pages: tags.total_pages,
                    component: Tags
                }
            default:
                return null
        }
    }

    const ModuleComponent = getModuleInfo().component

    const toPage = (e, value) => {
        setPage(value)
        setLoading(false)
    }

    return (
        <Grid>
            <Paper style={{boxShadow: 'none'}}>
                <ModuleComponent
                    setDeleteDialog={setDeleteDialog}
                    setEditDialog={setEditDialog}
                    setLoading={setLoading}
                    setUseRow={setUseRow}
                    setValue={setValue}
                    moduleId={moduleId}
                    loading={loading}
                    page={page}
                />
                <Grid
                    className={classes.footer}
                >
                    {moduleId === 'doctors' ?
                        <DoctorsModulePagination
                            setLoading={setLoading}
                        /> :
                        <Pagination
                            page={page}
                            onChange={toPage}
                            count={getModuleInfo().total_pages}
                            variant="outlined"
                            showFirstButton
                            showLastButton
                        />
                    }
                </Grid>
            </Paper>
            <DictionaryDialog
                setDeleteDialog={setDeleteDialog}
                setEditDialog={setEditDialog}
                deleteDialog={deleteDialog}
                setLoading={setLoading}
                editDialog={editDialog}
                moduleId={moduleId}
                row={useRow}
            />
        </Grid>
    )
}
