const filter = {
    limit: 10
}

const initialState = {
    page: 1,
    values: [],
    next: null,
    value: null,
    filter: filter
}

export default function emc(state = initialState, action) {
    switch (action.type) {
        case "EMC_VALUES":
            return {
                ...state,
                ...{
                    bundle: action.payload,
                    values: action.payload.resources,
                }
            }
        case "EMC_VALUE":
            return {
                ...state,
                ...{
                    value: action.payload
                }
            }
        case "EMC_FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload,
                    next: null,
                    page: 1
                }
            }
        case "EMC_CURRENT_PAGE":
            return {
                ...state,
                ...{
                    page: action.payload,
                }
            }
        case "EMC_FILTER_CLEAR":
            return {
                ...state,
                ...{
                    values: [],
                    next: null,
                    page: 1,
                    filter: filter
                }
            }
        case "EMC_CLEAR":
            return initialState
        default:
            return state
    }
}
