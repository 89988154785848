const filter = {
    medicalInstitutionId: null,
    interpretationStatus: null,
    biomaterialSourceId: null,
    studyInstanceUid: null,
    operatorName: null,
    patientName: null,
    startDate: null,
    endDate: null,
    offset: 0,
}

const initialState = {
    studies: [],
    study: null,
    total_pages: 0,
    filter: filter
}

export default function genetic(state = initialState, action) {
    switch (action.type) {
        case "GENETIC_STUDIES":
            return {
                ...state,
                ...{
                    studies: action.payload.data,
                    total_pages: action.payload.totalPages
                }
            }
        case "FILES_CLEAR":
            return {
                ...state,
                ...{
                    files: {}
                }
            }
        case "GENETIC_STUDY":
            return {
                ...state,
                ...{
                    study: action.payload
                }
            }
        case "GENETIC_FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload
                }
            }
        case "GENETIC_FILTER_CLEAR":
            return {
                ...state,
                ...{
                    filter: filter
                }
            }
        case "GENETIC_CLEAR":
            return initialState
        default:
            return state
    }
}
