/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { MedicalInstitutionsActions } from '../../../../Dictionary/actions/medicalInstitution';
import { CustomAutocomplete } from '../../../../App/components/Input/CustomAutocomplete';
import { CustomDatePicker } from '../../../../App/components/Input/CustomDatePicker';
import { TextFieldWithError } from '../../../../App/components/Input/TextField';
import { EmployeesActions } from '../../../../Dictionary/actions/employees';
import httpBuildQuery from '../../../../App/helpers/httpBuildQuery';
import { textFieldProps } from '../../../../App/helpers/textField';
import { Tags } from '../../../../App/components/Input/Tags';
import { getShortName } from '../../../../App/helpers/row';
import { useDispatch, useSelector } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import { useDebounce } from 'use-debounce';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { Field } from 'formik';
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';


const useStyles = makeStyles(() => ({
    container: {
        display: 'grid',
        gridRowGap: '10px',
        alignItems: 'start',
        paddingBottom: '20px',
        gridColumnGap: '10px',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '75px 75px 75px',
        '@media (min-width: 1700px)': {
        gridRowGap: '10px',
        gridColumnGap: '10px',
        gridTemplateRows: '75px 75px',
        gridTemplateColumns: '1fr 1fr 1fr ',
        },
    },
    text_field: {
        width: '350px',
    },
    autocomplete: {
        height: 'auto',
        width: '350px',
        background: 'transparent',
        '& .MuiInputBase-input': {
        background: 'transparent',
        },
    },
    description: {
        width: '100%',
    },
}));

export const Study = (props) => {
    const { setFieldValue, study, errors } = props;

    const [gender, setGender] = React.useState(study.gender ?? '');

    const handleClearClick = () => {
        setGender('');
        setFieldValue('gender', null);
    };

    const classes = useStyles();
    const dispatch = useDispatch();

    const { account } = useSelector((state) => state.account);
    const { employees } = useSelector((state) => state.employees);
    const { study: stateStudy } = useSelector((state) => state.pathomorphology);
    const { medicalInstitutions } = useSelector(
        (state) => state.medicalInstitutions
    );

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({ search: '', type: null });
    const [searchRequest] = useDebounce(search, 400);

    useEffect(() => {
        setFieldValue(
        'series',
        study?.series.map((item) => {
            return {
            ...item,
            studyInstanceUid: study?.studyInstanceUid,
            slides: item.slides.map((slide) => {
                return {
                ...slide,
                studyInstanceUid: study?.studyInstanceUid,
                };
            }),
            };
        })
        );
    }, [study?.studyInstanceUid]);

    const inputChange = (e, type = null) => {
        setSearch(
        e && typeof e.target.value === 'string'
            ? { search: e.target.value, type: type }
            : { search: '', type: null }
        );
    };

    useEffect(() => {
        setLoading(false);
    }, [searchRequest]);

    useEffect(() => {
        if (!loading) {
        let params = httpBuildQuery({
            offset: 0,
            isActive: true,
            ...(searchRequest.search
            ? {
                name: searchRequest.search,
                }
            : {}),
        });
        switch (searchRequest.type) {
            case 'medicalInstitution':
            dispatch(MedicalInstitutionsActions.get(params));
            break;
            case 'personAccepted':
            dispatch(EmployeesActions.get(params));
            break;
            default:
        }

        setLoading(true);
        }
    }, [dispatch, searchRequest, loading]);

    const studyFixedTags = () => {
        return account?.role?.toString().toLowerCase() !== 'admin'
        ? stateStudy?.tags
        : [];
    };

    return (
        <>
            <Grid className={classes.container}>
                <Field
                    required
                    type="text"
                    size="small"
                    align="center"
                    variant="standard"
                    label="№ Исследования"
                    name={`studyInstanceUid`}
                    inputProps={textFieldProps}
                    className={classes.text_field}
                    component={TextFieldWithError}
                    placeholder="Введите № исследования"
                    helperText={errors.studyInstanceUid}
                    error={Boolean(errors.studyInstanceUid)}
                    disabled={Boolean((study && study.id) ?? false)}
                />
                <CustomAutocomplete
                    required
                    loading={!loading}
                    label="Организация"
                    id="medicalInstitution"
                    className={classes.autocomplete}
                    value={study.medicalInstitution}
                    options={medicalInstitutions.data}
                    helperText={errors.medicalInstitution}
                    getOptionLabel={(option) => option.name}
                    error={Boolean(errors.medicalInstitution)}
                    onFocus={(e) => inputChange(e, 'medicalInstitution')}
                    inputChange={(e) => inputChange(e, 'medicalInstitution')}
                    onChange={(e, value) => setFieldValue('medicalInstitution', value)}
                />
                <CustomAutocomplete
                    required
                    label="Принял"
                    loading={!loading}
                    id="personAccepted"
                    options={employees.data}
                    value={study.personAccepted}
                    className={classes.autocomplete}
                    helperText={errors.personAccepted}
                    error={Boolean(errors.personAccepted)}
                    getOptionLabel={(option) => getShortName(option)}
                    onFocus={(e) => inputChange(e, 'personAccepted')}
                    inputChange={(e) => inputChange(e, 'personAccepted')}
                    onChange={(e, value) => setFieldValue('personAccepted', value)}
                />
                <Field
                    type="text"
                    size="small"
                    align="center"
                    variant="standard"
                    name={`patientId`}
                    label="ID Пациента"
                    inputProps={textFieldProps}
                    className={classes.text_field}
                    component={TextFieldWithError}
                    placeholder="Введите ID пациента"
                />
                <FormControl sx={{ width: 200 }} variant="standard" size="small">
                    <InputLabel id="custom-select-label">Пол</InputLabel>
                    <Select
                        displayEmpty
                        value={gender}
                        label="gender"
                        disableUnderline
                        id="clearable-select"
                        labelId="clearable-select-label"
                        className={classes.autocomplete}
                        renderValue={(value) => value ?? null}
                        onChange={(e) => {
                            setFieldValue('gender', e.target.value);
                            setGender(e.target.value);
                        }}
                        sx={{
                            '& .MuiSelect-iconOutlined': { display: gender ? 'none' : '' },
                            '& .MuiSelect-icon': { display: gender ? 'none' : '' },
                            '&.Mui-focused .MuiIconButton-root': { color: 'primary.main' },
                        }}
                        endAdornment={
                        <IconButton
                            onClick={handleClearClick}
                            sx={{
                            visibility: gender ? 'visible' : 'hidden',
                            padding: '6px',
                            }}
                        >
                            <ClearIcon sx={{ fontSize: 16, padding: 0 }} />
                        </IconButton>
                        }
                    >
                        <MenuItem value={'М'}>М</MenuItem>
                        <MenuItem value={'Ж'}>Ж</MenuItem>
                    </Select>
                </FormControl>
                <Field
                    size="small"
                    name={`age`}
                    type="number"
                    align="center"
                    label="Возраст"
                    variant="standard"
                    inputProps={textFieldProps}
                    className={classes.text_field}
                    component={TextFieldWithError}
                    placeholder="Введите возраст пациента"
                />
                <CustomDatePicker
                    required
                    label="Год"
                    views={['year']}
                    inputFormat="yyyy"
                    value={study.studyDate}
                    onChange={(newValue) => setFieldValue('studyDate', newValue)}
                />
                <CustomDatePicker
                    required
                    disableFuture
                    label="Поступило"
                    inputFormat="dd/MM/yyyy"
                    value={study.receivedDate}
                    views={['day', 'month', 'year']}
                    onChange={(newValue) => setFieldValue('receivedDate', newValue)}
                />
            </Grid>
            <Field
                required
                multiline
                maxRows={4}
                minRows={4}
                size="small"
                align="center"
                type="textarea"
                variant="standard"
                label="Макроописание"
                name={`macroDescription`}
                inputProps={textFieldProps}
                component={TextFieldWithError}
                className={classes.description}
                placeholder="Введите макроописание"
                error={Boolean(errors.macroDescription)}
                helperText={
                Boolean(errors.macroDescription) ?? 'Заполните макроописание'
                }
            />
            <Field
                required
                multiline
                maxRows={4}
                minRows={4}
                type="text"
                size="small"
                align="center"
                variant="standard"
                label="Микроописание"
                name={`microDescription`}
                inputProps={textFieldProps}
                component={TextFieldWithError}
                className={classes.description}
                placeholder="Введите микроописание"
                error={Boolean(errors.microDescription)}
                helperText={
                Boolean(errors.microDescription) ?? 'Заполните микроописание'
                }
            />
            <Field
                required
                multiline
                minRows={4}
                maxRows={4}
                type="text"
                size="small"
                align="center"
                variant="standard"
                inputProps={textFieldProps}
                component={TextFieldWithError}
                name={`histologicalDiagnosis`}
                className={classes.description}
                label="Гистологический диагноз"
                placeholder="Введите гистологический диагноз"
                error={Boolean(errors.histologicalDiagnosis)}
                helperText={
                Boolean(errors.histologicalDiagnosis) ??
                'Заполните гистологический диагноз'
                }
            />
            <Grid>
                <Tags
                    value={study?.tags ?? []}
                    fixed_tags={studyFixedTags()}
                    onChange={(event, newValue) => {
                        let newQuery = newValue.map((query) => {
                        if (typeof query === 'string') {
                            return { id: Date.now(), name: query };
                        }
                        return query;
                        });
                        setFieldValue('tags', newQuery);
                    }}
                />
            </Grid>
        </>
    );
};
